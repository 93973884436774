import { useMutation, useQueryClient } from 'react-query'
import { ContactEntityData } from '../../interfaces/generated'
import { api } from '../api'
import { QueryKeyEnum } from '../../enums/QueryKeyEnum'
import { ApiUrlEnum } from '../../enums/ApiUrlEnum'
import { AxiosResponse } from 'axios'

export const useUpdateContactMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (contact: ContactEntityData) =>
      api.patch<ContactEntityData, AxiosResponse<ContactEntityData>>(
        `${ApiUrlEnum.CONTACT}/${contact.id}`,
        contact,
      ),
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyEnum.CONTACTS])
      queryClient.invalidateQueries([QueryKeyEnum.PROJECTS])
    },
  })
}
