import React, { useState } from 'react'
import { TaskRow } from './TaskRow'
import { useGetTasks } from '../../../model/api/task/useGetTasks'
import { Spinner, useDisclosure } from '@chakra-ui/react'
import { TaskQuery, TaskQueryStatus, TaskResponse } from 'shared'
import { TaskModalForm } from './TaskModalForm'
import { useSearchParams } from 'react-router-dom'
import { TaskFilterForm } from './TaskFilterForm'
import { PaginationButton } from '../../../components/PaginationButton'
import { TASKS_PER_PAGE } from '../constants'
import { Button } from '../../../components/ui/button'
import { CirclePlusIcon } from 'lucide-react'
import { cn } from '../../../lib/utils'

interface Props {
  taskQuery?: TaskQuery
  hideFilters?: boolean
  minimalist?: boolean
  noTitle?: boolean
}

export const TasksGrid: React.FC<Props> = (props) => {
  const [searchParams, setSearchParams] = useSearchParams({
    done: TaskQueryStatus.NOT_DONE,
    page: '1',
    limit: TASKS_PER_PAGE,
    ...props.taskQuery,
  })
  const {
    data: tasksData,
    isLoading: tasksLoading,
    refetch,
  } = useGetTasks(
    {
      page: parseInt(searchParams.get('page') ?? '1'),
      limit: parseInt(searchParams.get('limit') ?? TASKS_PER_PAGE),
    },
    {
      done: searchParams.get('done') as TaskQueryStatus,
      title: searchParams.get('title') ?? '',
      projectId: searchParams.get('projectId') ?? '',
      assignedTo: searchParams.get('assignedTo') ?? '',
      contact: searchParams.get('contact') ?? '',
    },
  )
  const formDisclosure = useDisclosure()
  const [editedTask, setEditedTask] = useState<undefined | TaskResponse>(undefined)

  const onTaskSelected = (task: TaskResponse) => {
    setEditedTask(task)
    formDisclosure.onOpen()
  }

  if (tasksLoading) {
    return <Spinner />
  }

  const { contact, projectId } = props.taskQuery ?? {}

  return (
    <div>
      <TaskModalForm
        key={editedTask?.id}
        disclosure={{
          ...formDisclosure,
          onClose: () => {
            formDisclosure.onClose()
            setEditedTask(undefined)
          },
        }}
        initValues={{ contact, project: projectId }}
        editedTask={editedTask}
      />
      <div className="flex flex-col md:flex-row flex-wrap justify-between items-center gap-6 sm:flex-nowrap ">
        {!props.noTitle && (
          <h1 className="text-base font-semibold leading-7 text-gray-900">Úkoly</h1>
        )}
        <div className="md:w-auto w-full flex gap-3">
          {!props.hideFilters && !props.minimalist && (
            <div className="flex justify-end mb-3">
              <TaskFilterForm onFiltersChange={refetch} taskQuery={props.taskQuery} />
            </div>
          )}
          <Button
            variant={props.minimalist ? 'secondary' : 'default'}
            size={props.minimalist ? 'xs' : 'default'}
            className="md:w-auto w-full"
            type="button"
            onClick={formDisclosure.onOpen}
          >
            {props.minimalist ? (
              <CirclePlusIcon
                className={cn('h-5', props.minimalist ? 'text-gray-600' : 'text-white')}
              />
            ) : (
              <span className="ml-2">Přidat</span>
            )}
          </Button>
        </div>
      </div>
      <div className="flex flex-col gap-1 mt-3">
        {tasksData &&
          tasksData?.data?.data?.map((task) => (
            <TaskRow
              key={task.id}
              task={task}
              onTaskSelected={onTaskSelected}
              minimalist={props.minimalist}
            />
          ))}
      </div>
      {!props.minimalist && (
        <div className="mt-5">
          <PaginationButton
            page={parseInt(searchParams.get('page') ?? '1')}
            limit={parseInt(searchParams.get('limit') ?? TASKS_PER_PAGE)}
            totalCount={tasksData?.data?.totalCount ?? 0}
            totalPages={tasksData?.data?.totalPages ?? 0}
            setPage={(page) => {
              const params = new URLSearchParams(searchParams)
              params.set('page', page.toString())
              setSearchParams(params)
              return refetch()
            }}
          />
        </div>
      )}
    </div>
  )
}
