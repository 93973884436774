import React from 'react'
import { FormSectionTwoColumns } from '../../../../../../components/forms/FormSectionTwoColumns'
import { Field } from 'formik'
import { TextAreaInput } from '../../../../../../components/inputs/TextAreaInput'
import { B2_FORM_INIT_VALUES } from '../constants'

interface Props {}

export const B27TechCharacteristicsSection: React.FC<Props> = (props) => {
  return (
    <FormSectionTwoColumns
      sectionKey="b2.techCharacteristics"
      title="B.2.7 Základní charakteristiky technických a technologických zařízení"
    >
      <div className="sm:col-span-6">
        <Field
          name="b2.techCharacteristics.technologicalSolution"
          component={TextAreaInput}
          label="a) Technické řešení"
        />
      </div>
      <div className="sm:col-span-6">
        <Field
          name="b2.techCharacteristics.listOfTechnologicalDevices"
          component={TextAreaInput}
          label="b) výčet technických a technologických zařízení"
        />
      </div>
    </FormSectionTwoColumns>
  )
}
