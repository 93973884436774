import React from 'react'
import { UseDisclosureProps } from '@chakra-ui/react'
import { TailwindModal } from '../../../../components/TailwindModal'
import { ObjectGridRow } from './ObjectsGrid'
import { Field, Form, Formik } from 'formik'
import { TextInput } from '../../../../components/inputs/TextInput'
import { useCreateObjectMutation } from '../../../../model/api/project/useCreateObjectMutation'
import { useUpdateObjectMutation } from '../../../../model/api/project/useUpdateObjectMutation'
import { Button } from '../../../../components/Button'

interface Props {
  disclosure: UseDisclosureProps
  objectInit: ObjectGridRow
  projectId: string
}

export const ObjectFormModal: React.FC<Props> = (props) => {
  const { mutateAsync: createObjectMutation, isLoading: isCreatingObject } =
    useCreateObjectMutation()
  const { mutateAsync: updateObjectMutation, isLoading: isUpdatingObject } =
    useUpdateObjectMutation()

  const onSubmit = async ({ name }: { name: string }) => {
    const objectId = props?.objectInit?.id
    const mutationParams = { name, projectId: props.projectId }

    objectId
      ? await updateObjectMutation({ ...mutationParams, objectId })
      : await createObjectMutation(mutationParams)

    props.disclosure.onClose?.()
  }

  return (
    <TailwindModal
      disclosure={props.disclosure}
      dialogTitle={typeof props.objectInit.id === 'undefined' ? 'Nový objekt' : 'Úprava objektu'}
    >
      <Formik initialValues={props.objectInit} onSubmit={onSubmit}>
        <Form>
          <div className="text-left">
            <Field name="name" label="Název" component={TextInput} />
          </div>
          <div className="flex justify-end mt-5">
            <Button type="submit">Uložit</Button>
          </div>
        </Form>
      </Formik>
    </TailwindModal>
  )
}
