import React, { PropsWithChildren, useState } from 'react'
import { BasicLayout } from '../../components/BasicLayout'
import { SidebarNavigation } from '../../components/SidebarNavigation'
import { SecondaryButton } from '../../components/SecondaryButton'
import {
  AdjustmentsHorizontalIcon,
  Bars3Icon,
  CreditCardIcon,
  UsersIcon,
} from '@heroicons/react/24/outline'
import { RoutesEnum } from '../../model/enums/RoutesEnum'
import { useGetCurrentUser } from '../../model/api/auth/useGetCurrentUser'
import { UserRoleEnum } from '../../model/enums/UserRoleEnum'
import { useNavigate } from 'react-router-dom'

interface Props extends PropsWithChildren {
  subtitle?: string
}

export const WorkspaceSettingsLayout: React.FC<Props> = (props) => {
  const navigate = useNavigate()
  const { data: currentUserData, isLoading: isLoadingCurrentUser } = useGetCurrentUser()
  const [sidebarOpen, setSidebarOpen] = useState(false)

  if (isLoadingCurrentUser) {
    return <BasicLayout title="Načítání dat">...</BasicLayout>
  }

  if (currentUserData?.data?.role !== UserRoleEnum.ADMIN) {
    navigate(RoutesEnum.PROJECTS)
  }

  return (
    <BasicLayout title={`Nastavení ${props.subtitle ? '- ' + props.subtitle : ''}`}>
      <div className="flex p-0 lg:flex-row flex-col">
        <div className="lg:hidden flex justify-end">
          <SecondaryButton
            className="flex justify-center items-center gap-3 mb-3"
            onClick={() => setSidebarOpen(!sidebarOpen)}
          >
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            Menu
          </SecondaryButton>
        </div>
        <div
          className={`${sidebarOpen ? 'block' : 'hidden'} lg:block`}
          style={{ minWidth: '280px' }}
        >
          <SidebarNavigation
            navigation={[
              {
                icon: AdjustmentsHorizontalIcon,
                name: 'Přehled',
                href: RoutesEnum.WORKSPACE_SETTINGS,
              },
              {
                icon: UsersIcon,
                name: 'Uživatelé',
                href: RoutesEnum.WORKSPACE_SETTINGS_USERS,
              },
              {
                icon: CreditCardIcon,
                name: 'Platby',
                href: RoutesEnum.WORKSPACE_SETTINGS_BILLING,
              },
            ]}
          />
        </div>
        <div className="block w-full">{props.children}</div>
      </div>
    </BasicLayout>
  )
}
