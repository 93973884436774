export enum ApiUrlEnum {
  CONTACT = '/contact',
  PROJECT = '/project',
  PROJECT_FORM = '/project-form',
  AUTH = '/auth',
  DOCUMENT = '/document',
  FOLDER = '/folder',
  ROOM = '/room',
  OBJECT = '/object',
  ISSUE = '/issue',
  USER = '/user',
  USER_RESET_PASSWORD = '/user-reset-password',
  INVITE_USER = '/invite-user',
  PROJECT_FORM_RICH_TEXT = '/project-form-rich-text',
  PROJECT_FORM_SECTION_HELP = '/project-form-section-help',
  PHOTO_DOCUMENTATION = '/photo-documentation',
  CONSTRUCTION_DIARY = '/construction-diary',
  CONSTRUCTION_DIARY_OTHER = '/construction-diary-other',
  TASK = '/task',
}
