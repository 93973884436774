import React, { Fragment } from 'react'
import { FieldProps } from 'formik'
import { Listbox, Transition } from '@headlessui/react'
import { SecondaryButton } from '../SecondaryButton'
import {
  CheckIcon,
  ChevronUpDownIcon,
  PencilIcon,
  UserMinusIcon,
  UserPlusIcon,
} from '@heroicons/react/20/solid'
import { getFieldValidity } from '../../model/helpers/formHelpers'
import { classNames } from '../../model/helpers/classNameHelpers'
import { useGetContacts } from '../../model/api/contact/useGetContacts'
import { contactToString } from '../../model/helpers/contactHelpers'
import { useDisclosure } from '@chakra-ui/react'
import { ContactFormModal } from '../modals/ContactFormModal'
import { ContactEntityData } from '../../model/interfaces/generated'

interface Props extends FieldProps {
  label?: string
  isLoading?: boolean
  extraButtons?: React.ReactNode
  forbidRemove?: boolean
  forbidEdit?: boolean
}

export const ContactSelectInput: React.FC<Props> = (props) => {
  const {
    data: contactsData,
    isLoading: isLoadingContacts,
    refetch: refetchContacts,
    isRefetching: isRefetchingContacts,
  } = useGetContacts()
  const newContactDisclosure = useDisclosure()
  const { isInvalid, message } = getFieldValidity(props.form, props.field.name)
  const selectedValue = contactsData?.data?.contacts?.find(
    (contact) => contact.id === props.field.value,
  )

  const onNewContactCreated = (contact?: ContactEntityData) => {
    if (!contact) {
      return
    }

    refetchContacts()

    props.form.setFieldValue(props.field.name, contact.id)
  }

  const eraseField = () => {
    props.form.setFieldValue(props.field.name, undefined)
  }

  return (
    <>
      <ContactFormModal
        editContact={selectedValue}
        disclosure={newContactDisclosure}
        onSuccess={onNewContactCreated}
      />
      <Listbox
        value={props.field.value}
        onChange={(value: any) => {
          props.form.setFieldValue(props.field.name, value)
        }}
      >
        {({ open }) => (
          <>
            <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
              {props.label}
              {isInvalid && <span className="text-red-500 ml-1">{message}</span>}
            </Listbox.Label>
            <div className="flex relative">
              <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6">
                <span className="block truncate">
                  {selectedValue ? contactToString(selectedValue) : 'Vyberte ze seznamu'}
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {contactsData?.data?.contacts?.map((option) => (
                    <Listbox.Option
                      key={option.id}
                      className={({ active }) =>
                        classNames(
                          active ? 'bg-blue-600 text-white' : 'text-gray-900',
                          'relative cursor-default select-none py-2 pl-8 pr-4',
                        )
                      }
                      value={option.id}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={classNames(
                              selected ? 'font-semibold' : 'font-normal',
                              'block truncate',
                            )}
                          >
                            {contactToString(option)}
                          </span>

                          {option.id === props.field.value ? (
                            <span
                              className={classNames(
                                active ? 'text-white' : 'text-blue-600',
                                'absolute inset-y-0 left-0 flex items-center pl-1.5',
                              )}
                            >
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
              {typeof selectedValue !== 'undefined' ? (
                <>
                  {!props.forbidRemove && (
                    <SecondaryButton onClick={eraseField} className="ml-3">
                      <UserMinusIcon className="w-4 h-4 text-gray-500" />
                    </SecondaryButton>
                  )}
                  {!props.forbidEdit && (
                    <SecondaryButton onClick={newContactDisclosure.onOpen} className="ml-3">
                      <PencilIcon className="w-4 h-4 text-gray-500" />
                    </SecondaryButton>
                  )}
                </>
              ) : (
                <SecondaryButton onClick={newContactDisclosure.onOpen} className="ml-3">
                  <UserPlusIcon className="w-4 h-4 text-gray-500" />
                </SecondaryButton>
              )}
              {props.extraButtons}
            </div>
          </>
        )}
      </Listbox>
    </>
  )
}
