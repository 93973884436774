import { FolderKeyEnum } from '../../enums/FolderKeyEnum'
import { useQuery } from 'react-query'
import { api } from '../api'
import { ApiUrlEnum } from '../../enums/ApiUrlEnum'
import { IFolderEntity } from '../../interfaces/IFolderEntity'
import { QueryKeyEnum } from '../../enums/QueryKeyEnum'

export const useGetFolderForProjectForm = (key: FolderKeyEnum|string, projectId?: string) => {
  return useQuery({
    queryFn: () => {
      if (!projectId) {
        return api.get<IFolderEntity>(`${ApiUrlEnum.FOLDER}/key/${key}`)
      }
      return api.get<IFolderEntity>(`${ApiUrlEnum.FOLDER}/project/${projectId}/${key}`)
    },
    queryKey: [QueryKeyEnum.FOLDERS, projectId, key],
  })
}
