import React from 'react'

interface Props {
  color: 'grey' | 'red' | 'yellow' | 'green' | 'blue' | 'indigo' | 'purple' | 'pink'
  text: string | React.ReactElement
}

export const Badge: React.FC<Props> = ({ color = 'grey', text }) => {
  let className = 'inline-flex items-center rounded-md px-2 py-1 text-xs font-medium'

  switch (color) {
    case 'grey':
      className += ' bg-gray-100 text-gray-600'
      break
    case 'red':
      className += ' bg-red-100 text-red-700'
      break
    case 'yellow':
      className += ' bg-yellow-100 text-yellow-800'
      break
    case 'green':
      className += ' bg-green-100 text-green-700'
      break
    case 'blue':
      className += ' bg-blue-100 text-blue-700'
      break
    case 'indigo':
      className += ' bg-blue-100 text-blue-700'
      break
    case 'purple':
      className += ' bg-purple-100 text-purple-700'
      break
    case 'pink':
      className += ' bg-pink-100 text-pink-700'
      break
  }

  return <span className={className}>{text}</span>
}
