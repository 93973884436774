import { useParams } from 'react-router-dom'
import { useGetProject } from '../api/project/useGetProject'
import { ProjectEntityData } from '../interfaces/generated'

export const useProject = () => {
  const { id } = useParams<{ id: string }>()

  if (!id) {
    throw new Error('useProject hook must be used inside a route with an id param')
  }

  const { data: projectData, isLoading: projectIsLoading } = useGetProject(id || '')
  const project: ProjectEntityData | undefined = projectData?.data?.project

  return { projectData, projectIsLoading, project, id }
}
