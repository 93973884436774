import React, { useMemo, useState } from 'react'
import { getDaysForMonth } from '../model/helpers/dateHelpers'
import { cn } from '../lib/utils'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import { Button } from './ui/button'
import moment from 'moment'

interface Props {
  year: number
  month: number
  onDateClicked?: (date: moment.Moment) => void
  selectedDates?: string[]
  unfilledDates?: string[]
}

export const CalendarGrid: React.FC<Props> = (props) => {
  const [date, setDate] = useState({ year: props.year, month: props.month })
  const { year, month } = date
  const momentDate = moment({ year, month: month - 1 })

  const goBackByMonth = () => {
    const date = moment({ year, month: month - 1 }).subtract(1, 'month')
    setDate({ year: date.year(), month: date.month() + 1 })
  }

  const goForwardByMonth = () => {
    const date = moment({ year, month: month - 1 }).add(1, 'month')
    setDate({ year: date.year(), month: date.month() + 1 })
  }

  const days = useMemo(() => {
    return getDaysForMonth(year, month)
  }, [year, month])

  return (
    <div className="mt-10 text-center lg:col-start-8 lg:col-end-13 lg:row-start-1 lg:mt-9 xl:col-start-9">
      <div className="flex items-center text-gray-900">
        <button
          type="button"
          className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
          onClick={goBackByMonth}
        >
          <span className="sr-only">Previous month</span>
          <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        <div className="flex-auto text-sm font-semibold">{momentDate.format('MM/YYYY')}</div>
        <button
          type="button"
          className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
          onClick={goForwardByMonth}
        >
          <span className="sr-only">Next month</span>
          <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
      <div className="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500">
        <div>P</div>
        <div>Ú</div>
        <div>S</div>
        <div>Č</div>
        <div>P</div>
        <div>S</div>
        <div>N</div>
      </div>
      <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
        {days.map((day: any, dayIdx) => {
          const isSelected = props.selectedDates?.includes(day.date)
          const isUnfilled = props.unfilledDates?.includes(day.date)
          const isInFuture = moment(day.date).isAfter(moment())
          return (
            <button
              disabled={isInFuture}
              key={day.date}
              onClick={() => {
                if (props.onDateClicked) {
                  props.onDateClicked(moment(day.date))
                }
              }}
              type="button"
              className={cn(
                'py-1.5 hover:bg-gray-100 focus:z-10',
                day.isCurrentMonth ? 'bg-white' : 'bg-gray-50',
                (isSelected || day.isToday || isUnfilled) && 'font-semibold',
                isSelected && 'text-white',
                isUnfilled && 'text-white',
                !isSelected && day.isCurrentMonth && !day.isToday && !isUnfilled && 'text-gray-900',
                !isSelected &&
                  !day.isCurrentMonth &&
                  !day.isToday &&
                  !isUnfilled &&
                  'text-gray-400',
                day.isToday && !isSelected && !isUnfilled && 'text-indigo-600',
                dayIdx === 0 && 'rounded-tl-lg',
                dayIdx === 6 && 'rounded-tr-lg',
                dayIdx === days.length - 7 && 'rounded-bl-lg',
                dayIdx === days.length - 1 && 'rounded-br-lg',
              )}
            >
              <time
                dateTime={day.date}
                className={cn(
                  'mx-auto flex h-7 w-7 items-center justify-center rounded-full',
                  isSelected && day.isToday && 'bg-indigo-600',
                  isSelected && !day.isToday && 'bg-blue-700',
                  isUnfilled && 'bg-gray-500',
                )}
              >
                {day.date.split('-').pop().replace(/^0/, '')}
              </time>
            </button>
          )
        })}
      </div>
      <Button
        className="w-full mt-8"
        onClick={() => {
          if (props.onDateClicked) {
            props.onDateClicked(moment())
          }
        }}
      >
        Dnešní záznam
      </Button>
    </div>
  )
}
