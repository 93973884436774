import React from 'react'
import { DocumentIcon } from '@heroicons/react/24/outline'
import { downloadPreview } from '../../../../model/api/project/downloadPreview'
import { Button } from '../../../../components/ui/button'
import { Spinner } from '@chakra-ui/react'
import { useGenerateReportAction } from '../../../../model/hooks/useGenerateReportAction'

interface Props {
  projectId: string
  section?: 'a' | 'b'
}

export const GenerateReportAction: React.FC<Props> = (props) => {
  const { isLoading, onDownload } = useGenerateReportAction(props.projectId, props.section || 'b')

  return (
    <>
      <Button onClick={onDownload} variant="outline" size="xs">
        <div className="flex justify-center gap-1">
          {isLoading ? (
            <Spinner size="sm" />
          ) : (
            <>
              <DocumentIcon className="h-5 w-5" />
              Stáhnout
            </>
          )}
        </div>
      </Button>
    </>
  )
}
