import { useMutation, useQueryClient } from 'react-query'
import { api } from '../api'
import { ApiUrlEnum } from '../../enums/ApiUrlEnum'

export const useUpdateUserPasswordResetMutation = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ password, hash }: { password: string; hash: string }) =>
      api.patch(`${ApiUrlEnum.USER_RESET_PASSWORD}/${hash}`, { password }),
    onSuccess: (data, mutationData) => {
      queryClient.invalidateQueries([ApiUrlEnum.USER_RESET_PASSWORD, mutationData.hash])
    },
  })
}
