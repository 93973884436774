import { useQuery } from 'react-query'
import { api } from '../api'
import { ApiUrlEnum } from '../../enums/ApiUrlEnum'
import { QueryKeyEnum } from '../../enums/QueryKeyEnum'

export const useGetIssueFeed = (projectId?: string, issueId?: string) => {
  return useQuery({
    queryKey: [QueryKeyEnum.PROJECTS, projectId, QueryKeyEnum.ISSUES, issueId],
    queryFn: () =>
      api.get(`${ApiUrlEnum.PROJECT}/${projectId}${ApiUrlEnum.ISSUE}/${issueId}/issue-feed`),
    enabled: !!projectId && !!issueId,
  })
}
