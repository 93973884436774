import React from 'react'
import { Field, FormikProps } from 'formik'
import RadioInput from '../../../../../components/inputs/RadioInput'
import { RichTextInput } from '../../../../../components/inputs/RichTextInput/RichTextInput'
import { FormSectionTwoColumns } from '../../../../../components/forms/FormSectionTwoColumns'

interface Props {
  formik: FormikProps<any>
}

export const TechnicalFormB1B: React.FC<Props> = ({ formik }) => {
  return (
    <FormSectionTwoColumns
      title="b) Souladu stavby"
      subtitle="údaje o souladu stavby s územně plánovací dokumentací, s cíli a úkoly územního
              plánování, včetně informace o vydané územně plánovací dokumentaci"
      sectionKey={'b1b'}
    >
      <div className="sm:col-span-6">
        <Field
          component={RadioInput}
          name="b1b.complianceWithPlanningDocumentation"
          label="Soulad s územně plánovací dokumentací"
          options={[
            { label: 'Ano', value: true },
            { label: 'Ne', value: false },
          ]}
        />
        <div className="mt-3">
          <Field
            component={RichTextInput}
            name="b1b.complianceWithPlanningDocumentationDescription"
            label={
              formik.values.b1b.complianceWithPlanningDocumentation
                ? 'Informace o vydané územně plánovací informaci'
                : 'Odůvodnění'
            }
          />
        </div>
      </div>

      <div className="sm:col-span-6">
        <Field
          component={RadioInput}
          name="b1b.complianceWithPlanningGoalsTasks"
          label="Soulad s cíli a úkoly územního plánování"
          options={[
            { label: 'Ano', value: true },
            { label: 'Ne', value: false },
          ]}
        />
        <div className="mt-3">
          <Field
            component={RichTextInput}
            name="b1b.complianceWithPlanningGoalsTasksDescription"
            label={formik.values.b1b.complianceWithPlanningGoalsTasks ? 'Informace' : 'Odůovdnění'}
          />
        </div>
      </div>
    </FormSectionTwoColumns>
  )
}
