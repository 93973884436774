import React from 'react'
import { B21CharacteristicsSection } from './sections/B21CharacteristicsSection'
import { B22UrbanAndArchitectureSection } from './sections/B22UrbanAndArchitectureSection'
import { B23TechnologyAndUsageSection } from './sections/B23TechnologyAndUsageSection'
import { B24BarrierFreeUsageSection } from './sections/B24BarrierFreeUsageSection'
import { B25SafetySection } from './sections/B25SafetySection'
import { B26ObjectCharacteristicsSection } from './sections/B26ObjectCharacteristicsSection'
import { B27TechCharacteristicsSection } from './sections/B27TechCharacteristicsSection'
import { B28FireSafetySection } from './sections/B28FireSafetySection'
import { B29EnergyAndHeatSection } from './sections/B29EnergyAndHeatSection'
import { B210HygieneSection } from './sections/B210HygieneSection'
import { B211ExternalFactorsProtection } from './sections/B211ExternalFactorsProtection'

interface Props {}

export const B2ConstructionDescriptionForm: React.FC<Props> = (props) => {
  return (
    <>
      <div className="mt-5">
        <B21CharacteristicsSection />
      </div>
      <div className="mt-5">
        <B22UrbanAndArchitectureSection />
      </div>
      <div className="mt-5">
        <B23TechnologyAndUsageSection />
      </div>
      <div className="mt-5">
        <B24BarrierFreeUsageSection />
      </div>
      <div className="mt-5">
        <B25SafetySection />
      </div>
      <div className="mt-5">
        <B26ObjectCharacteristicsSection />
      </div>
      <div className="mt-5">
        <B27TechCharacteristicsSection />
      </div>
      <div className="mt-5">
        <B28FireSafetySection />
      </div>
      <div className="mt-5">
        <B29EnergyAndHeatSection />
      </div>
      <div className="mt-5">
        <B210HygieneSection />
      </div>
      <div className="mt-5">
        <B211ExternalFactorsProtection />
      </div>
    </>
  )
}
