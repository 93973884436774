import React from 'react'

interface Props {
  name: string
  className?: string
  colour?: string
}

export const Avatar: React.FC<Props> = (props) => {
  const initials = props.name
    .split(' ')
    .map((n) => n[0].toUpperCase())
    .join('')
  return (
    <span
      className={`inline-flex h-6 w-6 min-h-6 min-w-6 items-center justify-center rounded-full ${
        props.colour ? props.colour : 'bg-gray-500'
      } ${props.className || ''}`}
    >
      <span className="text-xs font-medium leading-none text-white">{initials}</span>
    </span>
  )
}
