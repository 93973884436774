import React from 'react'
import { Field, FormikProps } from 'formik'
import { RichTextInput } from '../../../../../components/inputs/RichTextInput/RichTextInput'
import { FormSectionTwoColumns } from '../../../../../components/forms/FormSectionTwoColumns'
import { CheckboxInput } from '../../../../../components/inputs/CheckboxInput'
import RadioInput from '../../../../../components/inputs/RadioInput'
import { ConnectionToTransportHelp } from '../info/ConnectionToTransportHelp'
import { RadioInputPanel } from '../../../../../components/RadioInputPanel'
import { TextInput } from '../../../../../components/inputs/TextInput'
import { DropzoneInput } from '../../../../../components/inputs/DropzoneInput'
import { ArrayForm } from '../../../../../components/ArrayForm'

interface Props {
  formik: FormikProps<any>
}

export const TechnicalFormB1K: React.FC<Props> = ({ formik }) => {
  return (
    <FormSectionTwoColumns
      sectionKey={'b1k'}
      title="k) Územně technické podmínky"
      subtitle="územně technické podmínky - zejména možnost napojení na stávající dopravní a technickou infrastrukturu, možnost bezbariérového přístupu k navrhované stavbě"
    >
      <div className="sm:col-span-6">
        <Field
          component={CheckboxInput}
          name="b1k.connectionToTransportInfrastructure.enabled"
          label="Napojení na stávající dopravní infrastrukturu"
        />
      </div>
      {formik.values.b1k.connectionToTransportInfrastructure.enabled && (
        <div className="sm:col-span-6">
          <Field
            component={RadioInput}
            name="b1k.connectionToTransportInfrastructure.newOrExistingExit"
            options={[
              {
                label: 'Sjezd nový',
                value: 'new',
              },
              {
                label: 'Sjezd stávající',
                value: 'existing',
              },
            ]}
          />
          <div className="mt-3">
            <Field
              name="b1k.connectionToTransportInfrastructure.new.description"
              component={RichTextInput}
            />
          </div>
        </div>
      )}
      <div className="sm:col-span-6">
        <Field
          component={CheckboxInput}
          name="b1k.connectionToTechnicalInfrastructure.waterSupply.enabled"
          label="Napojení na technickou infrastrukturu - Vodovod"
        />
      </div>
      {formik.values.b1k.connectionToTechnicalInfrastructure.waterSupply.enabled && (
        <div className="sm:col-span-6">
          <Field
            component={RadioInput}
            name="b1k.connectionToTechnicalInfrastructure.waterSupply.newOrExistingConnection"
            options={[
              {
                label: 'Přípojka stávající',
                value: 'existing',
              },
              {
                label: 'Přípojka nová',
                value: 'new',
              },
            ]}
          />
          <div className="mt-3">
            <Field
              name="b1k.connectionToTechnicalInfrastructure.waterSupply.new.description"
              component={RichTextInput}
            />
          </div>
        </div>
      )}
      {/* Similar blocks for sewerage, stormDrain, gasPipeline, electricity, communicationLines */}
      <div className="sm:col-span-6">
        <Field
          component={CheckboxInput}
          name="b1k.connectionToTechnicalInfrastructure.sewerage.enabled"
          label="Napojení na technickou infrastrukturu - Splašková kanalizace"
        />
      </div>
      {formik.values.b1k.connectionToTechnicalInfrastructure.sewerage.enabled && (
        <div className="sm:col-span-6">
          <Field
            component={RadioInput}
            name="b1k.connectionToTechnicalInfrastructure.sewerage.newOrExistingConnection"
            options={[
              {
                label: 'Přípojka stávající',
                value: 'existing',
              },
              {
                label: 'Přípojka nová',
                value: 'new',
              },
            ]}
          />
          <div className="mt-3">
            <Field
              name="b1k.connectionToTechnicalInfrastructure.sewerage.new.description"
              component={RichTextInput}
            />
          </div>
        </div>
      )}
      <div className="sm:col-span-6">
        <Field
          component={CheckboxInput}
          name="b1k.connectionToTechnicalInfrastructure.stormDrain.enabled"
          label="Napojení na technickou infrastrukturu - Dešťová kanalizace"
        />
      </div>
      {formik.values.b1k.connectionToTechnicalInfrastructure.stormDrain.enabled && (
        <>
          <div className="sm:col-span-6">
            <Field
              component={RadioInput}
              name="b1k.connectionToTechnicalInfrastructure.stormDrain.newOrExistingConnection"
              options={[
                {
                  label: 'Přípojka stávající',
                  value: 'existing',
                },
                {
                  label: 'Přípojka nová',
                  value: 'new',
                },
                {
                  label: 'Likvidace na vlastním pozemku',
                  value: 'ownDisposal',
                },
              ]}
            />
            <div className="mt-3">
              <Field
                name="b1k.connectionToTechnicalInfrastructure.stormDrain.new.description"
                component={RichTextInput}
              />
            </div>
          </div>
        </>
      )}
      <div className="sm:col-span-6">
        <Field
          component={CheckboxInput}
          name="b1k.connectionToTechnicalInfrastructure.gasPipeline.enabled"
          label="Napojení na technickou infrastrukturu - Plynovod"
        />
      </div>
      {formik.values.b1k.connectionToTechnicalInfrastructure.gasPipeline.enabled && (
        <div className="sm:col-span-6">
          <Field
            component={RadioInput}
            name="b1k.connectionToTechnicalInfrastructure.gasPipeline.newOrExistingConnection"
            options={[
              {
                label: 'Přípojka stávající',
                value: 'existing',
              },
              {
                label: 'Přípojka nová',
                value: 'new',
              },
            ]}
          />
          <div className="mt-3">
            <Field
              name="b1k.connectionToTechnicalInfrastructure.gasPipeline.new.description"
              label=""
              component={RichTextInput}
            />
          </div>
        </div>
      )}
      <div className="sm:col-span-6">
        <Field
          component={CheckboxInput}
          name="b1k.connectionToTechnicalInfrastructure.electricity.enabled"
          label="Napojení na technickou infrastrukturu - Elektrická přípojka"
        />
      </div>
      {formik.values.b1k.connectionToTechnicalInfrastructure.electricity.enabled && (
        <div className="sm:col-span-6">
          <Field
            component={RadioInput}
            name="b1k.connectionToTechnicalInfrastructure.electricity.newOrExistingConnection"
            options={[
              {
                label: 'Přípojka stávající',
                value: 'existing',
              },
              {
                label: 'Přípojka nová',
                value: 'new',
              },
            ]}
          />
          <div className="mt-3">
            <Field
              name="b1k.connectionToTechnicalInfrastructure.electricity.new.description"
              label=""
              component={RichTextInput}
            />
          </div>
        </div>
      )}
      <div className="sm:col-span-6">
        <Field
          component={CheckboxInput}
          name="b1k.connectionToTechnicalInfrastructure.communicationLines.enabled"
          label="Napojení na technickou infrastrukturu - Sdělovací vedení"
        />
      </div>
      {formik.values.b1k.connectionToTechnicalInfrastructure.communicationLines.enabled && (
        <>
          <div className="sm:col-span-6">
            <Field
              component={RadioInput}
              name="b1k.connectionToTechnicalInfrastructure.communicationLines.newOrExistingConnection"
              options={[
                {
                  label: 'Přípojka stávající',
                  value: 'existing',
                },
                {
                  label: 'Přípojka nová',
                  value: 'new',
                },
              ]}
            />
            <div className="mt-3">
              <Field
                name="b1k.connectionToTechnicalInfrastructure.communicationLines.new.description"
                label=""
                component={RichTextInput}
              />
            </div>
          </div>
        </>
      )}
      <div className="sm:col-span-6">
        <ArrayForm
          name="b1k.connectionToTechnicalInfrastructure.custom"
          rows={formik.values.b1k.connectionToTechnicalInfrastructure.custom || []}
          setFieldValue={formik.setFieldValue}
          initializationObject={{ name: '', enabled: false, newOrExisting: '', description: '' }}
        >
          {(name: string, row: any, index: number) => (
            <li className="w-100">
              <div className="flex w-100">
                <Field name={`${name}.enabled`} component={CheckboxInput} />
                <div className="-mt-3 w-full">
                  <Field name={`${name}.name`} component={TextInput} placeholder="Další přípojka" />
                </div>
              </div>
              {row.enabled && (
                <div className="mt-5">
                  <Field
                    component={RadioInput}
                    name={`${name}.newOrExistingConnection`}
                    options={[
                      {
                        label: 'Přípojka stávající',
                        value: 'existing',
                      },
                      {
                        label: 'Přípojka nová',
                        value: 'new',
                      },
                    ]}
                  />
                  <div className="mt-3">
                    <Field name={`${name}.description`} component={RichTextInput} />
                  </div>
                </div>
              )}
            </li>
          )}
        </ArrayForm>
      </div>
      <div className="sm:col-span-6">
        <Field
          component={CheckboxInput}
          name="b1k.barrierFreeAccess.enabled"
          label="Bezbariérový přístup k navrhované stavbě"
        />
      </div>
      {formik.values.b1k.barrierFreeAccess.enabled && (
        <div className="sm:col-span-6">
          <Field component={RichTextInput} name="b1k.barrierFreeAccess.details" label="" />
        </div>
      )}
    </FormSectionTwoColumns>
  )
}
