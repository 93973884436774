import { useQuery } from 'react-query'
import { api } from '../api'
import { ApiUrlEnum } from '../../enums/ApiUrlEnum'
import { QueryKeyEnum } from '../../enums/QueryKeyEnum'

export const useGetLatestConstructionDiaryEntries = (projectId: string) => {
  return useQuery({
    queryFn: () => api.get(`${ApiUrlEnum.PROJECT}/${projectId}${ApiUrlEnum.CONSTRUCTION_DIARY}`),
    queryKey: [QueryKeyEnum.CONSTRUCTION_DIARY],
  })
}
