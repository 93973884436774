import React from 'react'
import { Drawer } from '../../../components/drawers/Drawer'
import { TasksGrid } from './TasksGrid'
import { Button } from '../../../components/ui/button'
import { ListTodoIcon } from 'lucide-react'
import { TaskQuery } from 'shared'

interface Props {
  taskQuery?: TaskQuery
  customTrigger?: React.ReactNode
  subtitle?: string
}

export const TaskGridDrawer: React.FC<Props> = (props) => {
  const [open, setOpen] = React.useState(false)
  return (
    <>
      {props.customTrigger ? (
        <div onClick={() => setOpen((val) => !val)}>{props.customTrigger}</div>
      ) : (
        <Button
          type="button"
          size="sm"
          className="flex gap-1 relative"
          variant="secondary"
          onClick={() => setOpen((val) => !val)}
        >
          <ListTodoIcon className="text-gray-600 h-6" />
          <span className="absolute h-5 -top-2 -right-2 inline-flex items-center rounded-full bg-red-100 px-1.5 py-0.5 text-[14px] font-medium text-red-700">
            9
          </span>
        </Button>
      )}
      <Drawer
        title="Úkoly"
        description={props.subtitle ?? ''}
        content={<TasksGrid minimalist={true} noTitle={true} taskQuery={props.taskQuery} />}
        open={open}
        setOpen={setOpen}
        children={null}
      />
    </>
  )
}
