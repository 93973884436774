import moment from 'moment'

export const formatDateTime = (dateString?: string): string => {
  if (!dateString) {
    return ''
  }

  const date = moment(dateString)

  if (!date.isValid()) {
    return ''
  }

  // Czech format of date and time
  return date.format('DD.MM.YYYY HH:mm')
}

export const formatDate = (dateString?: string): string => {
  if (!dateString) {
    return ''
  }

  const date = moment(dateString)

  if (!date.isValid()) {
    return ''
  }

  // Czech format of date and time
  return date.format('DD.MM.YYYY')
}

export const getDaysForMonth = (year: number, month: number): any[] => {
  const days = [];
  const startOfMonth = moment({year, month: month - 1}).startOf('month');
  const endOfMonth = moment({year, month: month - 1}).endOf('month');
  const startOfGrid = startOfMonth.clone().startOf('week').add(1, 'day'); // Ensure Monday start
  const endOfGrid = endOfMonth.clone().endOf('week').add(1, 'day'); // Ensure Sunday end

  let currentDay = startOfGrid.clone();
  while (currentDay.isBefore(endOfGrid)) {
    days.push({
      date: currentDay.format('YYYY-MM-DD'),
      isCurrentMonth: currentDay.isSame(startOfMonth, 'month'),
      isToday: currentDay.isSame(moment(), 'day'),
    });
    currentDay.add(1, 'day');
  }

  return days;
}

export const getDayOfWeek = (day: number) => {
  switch (day) {
    case 1:
      return 'Pondělí'
    case 2:
      return 'Úterý'
    case 3:
      return 'Středa'
    case 4:
      return 'Čtvrtek'
    case 5:
      return 'Pátek'
    case 6:
      return 'Sobota'
    case 0:
      return 'Neděle'
  }
}
