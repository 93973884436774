import React from 'react';
import {useDisclosure} from "@chakra-ui/react";
import {AuthImage} from "./AuthImage";
import {IDocumentEntity} from "../model/interfaces/IDocumentEntity";
import {cn} from "../lib/utils";
import {TailwindModal} from "./TailwindModal";

interface Props {
  image: IDocumentEntity
  className?: string
}

export const AuthImageModal: React.FC<Props> = (props) => {
  const disclosure = useDisclosure();
  
	return (
    <>
      <TailwindModal disclosure={disclosure} dialogTitle={props.image.name ?? 'Foto'}>
        <AuthImage image={props.image} />
      </TailwindModal>
      <AuthImage
        image={props.image}
        onClick={disclosure.onOpen}
        className={cn('cursor-pointer', props.className)}
      />
    </>
  )
}
