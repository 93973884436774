import React from 'react'
import { B2_FORM_INIT_VALUES } from '../constants'
import { FormSectionTwoColumns } from '../../../../../../components/forms/FormSectionTwoColumns'
import { FormLabel } from '../../../../../../components/FormLabel'
import { Field } from 'formik'
import { TextAreaInput } from '../../../../../../components/inputs/TextAreaInput'
import { RichTextInput } from '../../../../../../components/inputs/RichTextInput/RichTextInput'

interface Props {}

export const B22UrbanAndArchitectureSection: React.FC<Props> = () => {
  return (
    <FormSectionTwoColumns
      sectionKey="b2.urbanAndArchitecture"
      title="B.2.2 Celkové urbanistické a architektonické řešení"
    >
      <div className="sm:col-span-6">
        <FormLabel>a) Urbanismus</FormLabel>
        <div className="mt-3">
          <Field
            name="b2.urbanAndArchitecture.territorialRegulation"
            component={RichTextInput}
            label="Územní regulace"
          />
        </div>
        <div className="mt-3">
          <Field
            name="b2.urbanAndArchitecture.spaceComposition"
            component={RichTextInput}
            label="Kompozice prostorového řešení"
          />
        </div>
      </div>
      <div className="sm:col-span-6">
        <FormLabel>b) Architektonické řešené</FormLabel>
        <div className="mt-3">
          <Field
            name="b2.urbanAndArchitecture.shapeComposition"
            component={RichTextInput}
            label="Kompozice tvarového řešení"
          />
        </div>
        <div className="mt-3">
          <Field
            name="b2.urbanAndArchitecture.materialSolution"
            component={RichTextInput}
            label="Materiálové řešení"
          />
        </div>
        <div className="mt-3">
          <Field
            name="b2.urbanAndArchitecture.colourSolution"
            component={RichTextInput}
            label="Barevné řešení"
          />
        </div>
      </div>
    </FormSectionTwoColumns>
  )
}
