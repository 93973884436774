import { useQuery } from 'react-query'
import { api } from '../api'
import { ApiUrlEnum } from '../../enums/ApiUrlEnum'
import { QueryKeyEnum } from '../../enums/QueryKeyEnum'
import { PaginationParams, TaskQuery, TaskResponse } from 'shared'
import queryString from 'query-string'
import { IPaginatedResponse } from '../../interfaces/IPaginatedResponse'

export const useGetTasks = (pagination: PaginationParams, query?: TaskQuery) => {
  const queryParams = queryString.stringify(
    { ...query, ...pagination },
    { skipNull: true, skipEmptyString: true },
  )

  return useQuery({
    queryFn: async () =>
      api.get<IPaginatedResponse<TaskResponse>>(`${ApiUrlEnum.TASK}?${queryParams}`),
    queryKey: [QueryKeyEnum.TASK, queryParams],
  })
}
