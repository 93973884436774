import React from 'react'
import { FormLabel } from '../../../../../../../components/FormLabel'
import { Field, useFormikContext } from 'formik'
import { RichTextInput } from '../../../../../../../components/inputs/RichTextInput/RichTextInput'
import { ArrayInput } from '../../../../../../../components/inputs/ArrayInput'
import { TextInput } from '../../../../../../../components/inputs/TextInput'
import { NumberInput } from '../../../../../../../components/inputs/NumberInput'
import { SecondaryButton } from '../../../../../../../components/SecondaryButton'
import { get } from 'lodash'
import { array } from 'yup'
import { arrayToCSV } from '../../../../../../../model/helpers/arrayHelpers'
import { downloadCsv } from '../../../../../../../model/helpers/downloadHelpers'

interface Props {
  name: string
  label: string
  singular?: string
}

export const ConstructionAndMaterials: React.FC<Props> = (props) => {
  const onExportToCSV = (name: string, arrayValues: any) => {
    if (!Array.isArray(arrayValues) || arrayValues.length === 0) {
      return
    }

    const csvString = arrayToCSV(arrayValues, {
      name: 'Název vrstvy',
      width: 'Tloušťka (mm)',
      description: 'Popis (montáž; kotvení; atd.)',
    })
    downloadCsv(csvString, `${name.split(' ').join('-')}-export.csv`)
  }

  return (
    <div className="mt-3">
      <FormLabel>
        <i>{props.label}</i>
      </FormLabel>
      <div className="mt-3 pl-7">
        <Field
          name={`b2.objectCharacteristics.constructionAndMaterial.${props.name}.description`}
          component={RichTextInput}
        />
        <FormLabel>Označení a název konstrukce (skladby)</FormLabel>
        <div>
          <ArrayInput
            name={`b2.objectCharacteristics.constructionAndMaterial.${props.name}.grid`}
            initializationObject={{ layers: [{ name: '', width: '', description: '' }] }}
          >
            {(name, row, index) => (
              <div className="rounded-xl shadow-md bg-gray-50 p-3 mt-2" key={index}>
                <FormLabel>
                  <i>
                    {props.singular || props.label} - č. {index + 1}
                  </i>
                </FormLabel>
                <ArrayInput
                  name={`${name}.layers`}
                  initializationObject={{
                    name: '',
                    width: '',
                    description: '',
                  }}
                >
                  {(layerName, layerRow, layerIndex) => (
                    <React.Fragment key={layerIndex}>
                      <div className="flex items-center gap-3 justify-between">
                        <FormLabel>Název vrstvy</FormLabel>
                        <Field name={`${layerName}.name`} component={TextInput} />
                      </div>
                      <div className="flex items-center gap-3 justify-between">
                        <FormLabel>Tloušťka</FormLabel>
                        <Field name={`${layerName}.width`} component={NumberInput} trailing="mm" />
                      </div>
                      <div className="flex items-center gap-3 justify-between">
                        <FormLabel>Popis (montáž, kotvení, atd.)</FormLabel>
                        <Field name={`${layerName}.description`} component={TextInput} />
                      </div>
                    </React.Fragment>
                  )}
                </ArrayInput>
                <div className="flex justify-end mt-5">
                  <SecondaryButton
                    disabled={row?.layers?.length === 0}
                    onClick={() =>
                      onExportToCSV(
                        `${props.singular || props.label} - č. ${index + 1}`,
                        row.layers,
                      )
                    }
                  >
                    Exportovat vrstvy do Excelu
                  </SecondaryButton>
                </div>
              </div>
            )}
          </ArrayInput>
        </div>
      </div>
    </div>
  )
}
