import React from 'react'
import Logo from '../../assets/images/logo.png'

interface Props {}

export const UserResetPasswordWrapper: React.FC<Props & React.PropsWithChildren> = (props) => {
  return (
    <div className="mt-10 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div className="mx-auto max-w-md">
        <img className="mx-auto h-60 w-auto" src={Logo} alt="QikSolution" />
        <h1 className="font-bold text-2xl text-center">QIK</h1>
        <h2 className="font-bold text-xl text-center">Obnova hesla</h2>
        <div>{props.children}</div>
      </div>
    </div>
  )
}
