import { CheckIcon } from '@heroicons/react/20/solid'
import { RoutesEnum } from '../model/enums/RoutesEnum'
import { Link } from 'react-router-dom'
import React from 'react'
import { useGetTechnicalFormsList } from '../model/api/project/useGetTechnicalFormsList'
import { TechnicalFormStatusEnum } from 'shared'
import { Spinner } from '@chakra-ui/react'
import { GenerateReportAction } from '../pages/Project/subpages/TechnicalReportForm/GenerateReportAction'

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ')
}

interface Props {
  projectId: string
}

export const Timeline: React.FC<Props> = (props) => {
  const { data: projectFormsListData, isLoading: isLoadingProjectFormsList } =
    useGetTechnicalFormsList(props.projectId)

  if (isLoadingProjectFormsList) {
    return (
      <div className="flex justify-center">
        <Spinner size="lg" />
      </div>
    )
  }

  const techFormInProgress = (projectFormsListData?.data?.list?.b || []).some(
    (i) => i.status === TechnicalFormStatusEnum.EDIT,
  )
  const accompanyingInProgress = (projectFormsListData?.data?.list?.a || []).some(
    (i) => i.status === TechnicalFormStatusEnum.EDIT,
  )
  const timeline = [
    {
      id: 1,
      content: '',
      target: 'A) Průvodní zpráva',
      href: RoutesEnum.ACCOMPANYING_REPORT,
      date: 'Sep 20',
      datetime: '2020-09-20',
      icon: CheckIcon,
      iconBackground: accompanyingInProgress ? 'bg-gray-300' : 'bg-blue-500',
      done: !accompanyingInProgress,
    },
    {
      id: 2,
      content: '',
      target: 'B) Souhrnná technická zpráva',
      href: RoutesEnum.TECHNICAL_REPORT_FORM,
      date: 'Sep 22',
      datetime: '2020-09-22',
      icon: CheckIcon,
      iconBackground: techFormInProgress ? 'bg-gray-300' : 'bg-blue-500',
      done: !techFormInProgress,
    },
  ]

  return (
    <div className="flow-root">
      <ul role="list" className="-mb-8">
        {timeline.map((event, eventIdx) => (
          <li key={event.id}>
            <div className="relative pb-8">
              {eventIdx !== timeline.length - 1 ? (
                <span
                  aria-hidden="true"
                  className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200"
                />
              ) : null}
              <div className="relative flex space-x-3">
                <div>
                  <span
                    className={classNames(
                      event.iconBackground,
                      'flex h-8 w-8 items-center justify-center rounded-full ring-8 ring-white',
                    )}
                  >
                    <event.icon aria-hidden="true" className="h-5 w-5 text-white" />
                  </span>
                </div>
                <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                  <div>
                    <p className="text-sm text-gray-500">
                      {event.content}{' '}
                      <Link to={event.href} className="font-medium text-gray-900">
                        {event.target}
                      </Link>
                    </p>
                  </div>
                  <div className="whitespace-nowrap text-right text-sm text-gray-700">
                    {event.done && (
                      <GenerateReportAction
                        projectId={props.projectId}
                        section={event.href === RoutesEnum.ACCOMPANYING_REPORT ? 'a' : 'b'}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
        {(projectFormsListData?.data?.list?.b || []).map((projectFormItem, eventIdx) => (
          <li className="pl-9" key={projectFormItem.type}>
            <div className="relative pb-8">
              {eventIdx !== timeline.length - 1 ? (
                <span
                  aria-hidden="true"
                  className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200"
                />
              ) : null}
              <div className="relative flex space-x-3">
                <div>
                  <span
                    className={classNames(
                      projectFormItem.status === TechnicalFormStatusEnum.PREVIEW
                        ? 'bg-blue-500'
                        : 'bg-gray-300',
                      'flex h-8 w-8 items-center justify-center rounded-full ring-8 ring-white',
                    )}
                  >
                    <CheckIcon aria-hidden="true" className="h-5 w-5 text-white" />
                  </span>
                </div>
                <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                  <div>
                    <p className="text-sm text-gray-500">
                      <Link
                        to={`${RoutesEnum.PROJECTS}/${props.projectId}/${RoutesEnum.TECHNICAL_REPORT_FORM}/${projectFormItem.type}`}
                        className="font-medium text-gray-900"
                      >
                        {`${projectFormItem.identifier.replace(' ', '')}. ${projectFormItem.name}`}
                      </Link>
                    </p>
                  </div>
                  <div className="whitespace-nowrap text-right text-sm text-gray-500"></div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}
