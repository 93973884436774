import { useMutation, useQueryClient } from 'react-query'
import { api } from '../api'
import { ApiUrlEnum } from '../../enums/ApiUrlEnum'
import { QueryKeyEnum } from '../../enums/QueryKeyEnum'

export const useCreateIssueFeedCommentMutation = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({
      issueId,
      projectId,
      comment,
    }: {
      issueId: string
      projectId: string
      comment: string
    }) => {
      return api.post(
        `${ApiUrlEnum.PROJECT}/${projectId}${ApiUrlEnum.ISSUE}/${issueId}/issue-feed`,
        { comment },
      )
    },
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyEnum.PROJECTS])
    },
  })
}
