import React, { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import {
  ArchiveBoxIcon,
  ArrowRightCircleIcon,
  ChevronDownIcon,
  DocumentDuplicateIcon,
  EllipsisHorizontalIcon,
  HeartIcon,
  PencilSquareIcon,
  TrashIcon,
  UserPlusIcon,
} from '@heroicons/react/20/solid'

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ')
}

interface Props {
  items: Array<Array<{ name: string; icon: React.ReactNode; callback: (e: any) => Promise<void> }>>
}

export const DropdownButton: React.FC<Props> = (props) => {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div onClick={(e) => e.stopPropagation()}>
        <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 hover:bg-gray-50">
          <EllipsisHorizontalIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {props.items.map((itemGroup, index) => (
            <div key={index} className="py-1">
              {itemGroup.map((item) => (
                <Menu.Item>
                  {({ active, close }) => (
                    <a
                      onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()

                        item.callback(e).then(close)
                      }}
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'group flex items-center px-4 py-2 text-sm',
                      )}
                    >
                      <span className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500">
                        {item.icon}
                      </span>
                      {item.name}
                    </a>
                  )}
                </Menu.Item>
              ))}
            </div>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
