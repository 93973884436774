import React from 'react'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '../../../../components/ui/dropdown-menu'
import { Button } from '../../../../components/ui/button'
import { EllipsisHorizontalIcon, PencilIcon } from '@heroicons/react/20/solid'
import { CheckIcon } from '@radix-ui/react-icons'
import { ProjectFormEnum, TechnicalFormStatusEnum } from 'shared'
import { useSubmitForPreviewProjectFormMutation } from '../../../../model/api/project/useSubmitForPreviewProjectFormMutation'
import { useDeletePreviewProjectTechnicalFormMutation } from '../../../../model/api/project/useDeletePreviewProjectTechnicalFormMutation'
import { DownloadCloudIcon } from 'lucide-react'
import { useGenerateReportAction } from '../../../../model/hooks/useGenerateReportAction'
import { Spinner } from '@chakra-ui/react'
import { FormikProps, useFormikContext } from 'formik'

interface Props {
  projectId: string
  status: TechnicalFormStatusEnum
}

export const AccompanyingReportMenu: React.FC<Props> = (props) => {
  const { isLoading: isDownloading, onDownload } = useGenerateReportAction(props.projectId, 'a')
  const { mutate: submitForPreviewProjectForm } = useSubmitForPreviewProjectFormMutation()
  const { mutate: deletePreviewProjectTechnicalForm } =
    useDeletePreviewProjectTechnicalFormMutation()
  const formik = useFormikContext() as FormikProps<any>

  const submitForPreview = async () => {
    await formik.submitForm()
    setTimeout(() => {
      submitForPreviewProjectForm({
        id: props.projectId,
        form: ProjectFormEnum.ACCOMPANYING_REPORT_FORM_A1 as any,
      })
    }, 100)
  }
  const revertFromPreview = () => {
    deletePreviewProjectTechnicalForm({
      id: props.projectId,
      form: ProjectFormEnum.ACCOMPANYING_REPORT_FORM_A1 as any,
    })
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" size="sm" type="button" className="ml-1">
          <EllipsisHorizontalIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <DropdownMenuLabel>Menu</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <DropdownMenuItem
            onClick={submitForPreview}
            disabled={props.status === TechnicalFormStatusEnum.PREVIEW}
          >
            <CheckIcon className="mr-2 h-4 w-4" />
            <span>Dokončit</span>
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={revertFromPreview}
            disabled={props.status === TechnicalFormStatusEnum.EDIT}
          >
            <PencilIcon className="mr-2 h-4 w-4" />
            <span>Editovat</span>
          </DropdownMenuItem>
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <DropdownMenuItem
            onClick={onDownload}
            disabled={props.status === TechnicalFormStatusEnum.EDIT || isDownloading}
          >
            {isDownloading ? <Spinner size="sm" /> : <DownloadCloudIcon className="mr-2 h-4 w-4" />}
            <span>Stáhnout</span>
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
