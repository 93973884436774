import React, { useCallback, useEffect } from 'react'
import { useFormikContext } from 'formik'
import { debounce } from 'lodash'

interface Props {
  debounceMs?: number
}

export const AutoSave: React.FC<Props> = ({ debounceMs = 2_500 }) => {
  const { dirty, values, submitForm, setFieldValue } = useFormikContext()

  const submit = useCallback(async () => {
    await setFieldValue('submitter', 'auto-save')
    return submitForm()
  }, [values, submitForm])

  const debouncedSubmit = useCallback(debounce(submit, debounceMs), [debounceMs, submitForm])

  useEffect(() => {
    if (dirty) {
      debouncedSubmit()
    }
  }, [debouncedSubmit, dirty, values])

  useEffect(() => {
    return () => {
      debouncedSubmit.cancel()
    }
  }, [debouncedSubmit])

  return null
}
