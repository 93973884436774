export function removeElement<T>(array: T[], index: number): T[] {
  return array.slice(0, index).concat(array.slice(index + 1))
}

export function arrayToCSV(
  array: any[],
  mappingHeaderLabels?: { [key in string]: string },
): string {
  const replacer = (key: any, value: any) => (value === null ? '' : value) // specify how you want to handle null values here
  const header = Object.keys(array[0])

  return [
    header.map((h) => (mappingHeaderLabels ? mappingHeaderLabels[h] : h)).join(','), // header row first
    ...array.map((row) =>
      header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(','),
    ),
  ].join('\r\n')
}
