import { useMutation, useQueryClient } from 'react-query'
import { api } from '../api'
import { ApiUrlEnum } from '../../enums/ApiUrlEnum'
import { QueryKeyEnum } from '../../enums/QueryKeyEnum'

export const useMarkTaskAsDone = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ id, done }: { id: string; done: boolean }) =>
      api.put(`${ApiUrlEnum.TASK}/${id}/done`, { done }),
    onSuccess: () => {
      // So that you can see "check" animation
      setTimeout(() => {
        queryClient.invalidateQueries(QueryKeyEnum.TASK)
      }, 500)
    },
  })
}
