import { useMutation, useQueryClient } from 'react-query'
import { ContactEntityData, CreateContactRequestData } from '../../interfaces/generated'
import { api } from '../api'
import { QueryKeyEnum } from '../../enums/QueryKeyEnum'
import { ApiUrlEnum } from '../../enums/ApiUrlEnum'

export const useDeleteContactMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (id: string) => api.delete(`${ApiUrlEnum.CONTACT}/${id}`),
    onSuccess: () => {
      return queryClient.invalidateQueries([QueryKeyEnum.CONTACTS])
    },
  })
}
