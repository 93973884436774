import React from 'react'
import { useProject } from '../../../../model/hooks/useProject'
import { ProjectLoadingSkeleton } from '../../layout/ProjectLoadingSkeleton'
import { ProjectLayout } from '../../layout/ProjectLayout'
import { Button } from '../../../../components/ui/button'
import { PlusIcon } from '@heroicons/react/20/solid'
import { PhotoDocumentationSmartDrawer } from './PhotoDocumentationSmartDrawer'
import { PhotoDocumentationAllList } from './PhotoDocumentationAllList'
import { Tabs, TabsList, TabsTrigger } from '../../../../components/ui/tabs'
import { useSearchParams } from 'react-router-dom'
import { PhotoDocumentationGroups } from './PhotoDocumentationGroups'

interface Props {}

enum PhotoViewsEnum {
  ALL = 'all',
  ROOMS = 'rooms',
}

export const PhotoDocumentation: React.FC<Props> = (props) => {
  const [searchParams, setSearchParamsRaw] = useSearchParams({
    view: PhotoViewsEnum.ALL,
  })
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false)
  const { project, id: projectId } = useProject()

  if (!project) {
    return <ProjectLoadingSkeleton />
  }

  return (
    <ProjectLayout project={project}>
      <PhotoDocumentationSmartDrawer
        open={isDrawerOpen}
        setOpen={setIsDrawerOpen}
        projectId={project.id}
      />
      <div>
        <div className="flex flex-wrap justify-between items-center gap-6 sm:flex-nowrap ">
          <h1 className="text-base font-semibold leading-7 text-gray-900">Fotodokumentace</h1>
          <Tabs
            defaultValue={searchParams.get('view') ?? PhotoViewsEnum.ALL}
            className="w-full sm:w-[250px]"
          >
            <TabsList className="grid w-full grid-cols-2">
              <TabsTrigger
                onClick={() => {
                  setSearchParamsRaw({
                    view: PhotoViewsEnum.ALL,
                    allPhotosPage: '1',
                    allPhotosLimit: '50',
                  })
                }}
                value={PhotoViewsEnum.ALL}
              >
                Vše
              </TabsTrigger>
              <TabsTrigger
                onClick={() => {
                  setSearchParamsRaw({
                    view: PhotoViewsEnum.ROOMS,
                    allPhotosPage: '1',
                    allPhotosLimit: '50',
                  })
                }}
                value={PhotoViewsEnum.ROOMS}
              >
                Místnosti
              </TabsTrigger>
            </TabsList>
          </Tabs>
          <Button className="w-full sm:w-auto" onClick={() => setIsDrawerOpen(true)}>
            <PlusIcon className="h-5 w-5 mr-2" />
            Přidat
          </Button>
        </div>
        <div className="mt-8">
          {searchParams.get('view') === PhotoViewsEnum.ROOMS ? (
            <PhotoDocumentationGroups />
          ) : (
            <PhotoDocumentationAllList projectId={projectId} />
          )}
        </div>
      </div>
    </ProjectLayout>
  )
}
