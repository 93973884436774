import React from 'react'
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
} from '@chakra-ui/react'
import { Field, FormikProps } from 'formik'
import { RichTextInput } from '../../../../../components/inputs/RichTextInput/RichTextInput'
import { SecondaryButton } from '../../../../../components/SecondaryButton'
import { Button } from '../../../../../components/Button'
import { SectionHeadingWithDescription } from '../../../../../components/SectionHeadingWithDescription'
import { FormSectionTwoColumns } from '../../../../../components/forms/FormSectionTwoColumns'
import { CheckboxInput } from '../../../../../components/inputs/CheckboxInput'
import { SelectInput } from '../../../../../components/inputs/SelectInput'
import { TextAreaInput } from '../../../../../components/inputs/TextAreaInput'
import { useApiMutation } from '../../../../../model/api/ai/useApiMutation'
import { TYPE_OF_PLOT_SELECT_OPTIONS } from '../../../constants/options'

interface Props {
  formik: FormikProps<any>
}

export const TechnicalFormB1A: React.FC<Props> = ({ formik }) => {
  const [showPreview, setShowPreview] = React.useState(false)
  const { mutateAsync, isLoading } = useApiMutation()

  return (
    <>
      <Modal size="3xl" isOpen={showPreview} onClose={() => setShowPreview(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>B.1 Popis území stavby</ModalHeader>
          <ModalBody>
            <p>
              <b>
                Popis území stavby, včetně informací o souladu stavby s územně plánovací
                dokumentací, s cíli a úkoly územního plánování, včetně informace o vydané územně
                plánovací dokumentaci, informace o vydaných rozhodnutích o povolení výjimky z
                obecných požadavků na využívání území, informace o vydaných stanoviscích dotčených
                orgánů
              </b>
            </p>
            <div className="mt-5">
              <Field
                name="b1a.aiGenerated"
                component={RichTextInput}
                large={true}
                disableFullscreen={true}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <SecondaryButton onClick={() => setShowPreview(false)} className="mr-3">
              Zahodit
            </SecondaryButton>
            <Button
              onClick={() => {
                formik.setFieldValue('b1a.aiGeneratedAccepted', true)
                setShowPreview(false)
              }}
            >
              Příjmout
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <FormSectionTwoColumns
        accepted={!!formik.values.b1a.aiGeneratedAccepted}
        title="a) Charakteristika území"
        subtitle="charakteristika území a stavebního pozemku, zastavěné území a nezastavěné území,
                soulad navrhované stavby s charakterem území, dosavadní využití a zastavěnost území"
        sectionKey="b1a"
      >
        {formik.values.b1a.aiGeneratedAccepted ? (
          <>
            <div className="sm:col-span-6">
              <Field
                name="b1a.aiGenerated"
                component={RichTextInput}
                large={true}
                disableFullscreen={true}
              />
            </div>
            <div className="sm:col-span-6">
              <SecondaryButton
                onClick={() => {
                  formik.setFieldValue('b1a.aiGeneratedAccepted', false)
                }}
              >
                Vrátit se
              </SecondaryButton>
            </div>
          </>
        ) : (
          <>
            <div className="sm:col-span-6">
              <Field component={CheckboxInput} name="b1a.isBuildingPlot" label="Stavební pozemek" />
            </div>
            <div className="sm:col-span-6">
              <Field
                component={SelectInput}
                name="b1a.typeOfPlot"
                options={TYPE_OF_PLOT_SELECT_OPTIONS}
                label="Druh pozemku podle katastru nemovitostí"
              />
            </div>
            <div className="sm:col-span-6">
              <Field
                component={SelectInput}
                name="b1a.placement"
                options={[
                  { label: 'Střední část obce', value: 'Střední část obce' },
                  { label: 'Severní část obce', value: 'Severní část obce' },
                  { label: 'Jižní část obce', value: 'Jižní část obce' },
                  { label: 'Východní část obce', value: 'Východní část obce' },
                  { label: 'Západní část obce', value: 'Západní část obce' },
                ]}
                label="Umístění"
                isCustom={formik.values.b1a.placement.custom}
                setIsCustom={(value: any) => formik.setFieldValue('b1a.placement.custom', value)}
              />
            </div>
            <div className="sm:col-span-6">
              <Field
                component={SelectInput}
                name="b1a.shape"
                options={[
                  { label: 'Čtverec', value: 'Čtverec' },
                  { label: 'Obdélník', value: 'Obdélník' },
                  { label: 'Lichoběžník', value: 'Lichoběžník' },
                  { label: 'Nepravidelný tvar', value: 'Nepravidelný tvar' },
                ]}
                label="Tvar"
              />
            </div>
            <div className="sm:col-span-6">
              <Field
                component={SelectInput}
                name="b1a.landSlope"
                options={[
                  { label: 'Rovinatý pozemek', value: 'Rovinatý pozemek' },
                  { label: 'Mírně svažitý pozemek', value: 'Mírně svažitý pozemek' },
                  { label: 'Svažitý pozemek', value: 'Svažitý pozemek' },
                  { label: 'Strmý pozemek', value: 'Strmý pozemek' },
                ]}
                label="Svažitost parcely"
              />
            </div>
            <div className="sm:col-span-6">
              <Field
                component={SelectInput}
                name="b1a.urbanization"
                options={[
                  { label: 'Hustě zastavěné území', value: 'Hustě zastavěné území' },
                  { label: 'Zřídka zastavěné území', value: 'Zřídka zastavěné území' },
                  { label: 'Málo zastavěné území', value: 'Málo zastavěné území' },
                  { label: 'Nezastavěné území', value: 'Nezastavěné území' },
                ]}
                label="Zastavěnost"
              />
            </div>
            <div className="sm:col-span-6">
              <Field
                component={SelectInput}
                name="b1a.surroundingArea"
                options={[
                  { label: 'Rodinné domy', value: 'Rodinné domy' },
                  { label: 'Bytové domy', value: 'Bytové domy' },
                  { label: 'Účelové hřiště', value: 'Účelové hřiště' },
                  { label: 'Občanská vybavenost', value: 'Občanská vybavenost' },
                  { label: 'Zahrady', value: 'Zahrady' },
                  { label: 'Chaty', value: 'Chaty' },
                  { label: 'Pole', value: 'Pole' },
                ]}
                label="Okolí"
              />
            </div>
            <div className="sm:col-span-6">
              <Field
                component={SelectInput}
                name="b1a.previousUtilization"
                options={[
                  {
                    label: 'Stávající stavba',
                    value: 'Stávající stavba',
                  },
                  { label: 'Zahrada', value: 'Zahrada' },
                  { label: 'Pole', value: 'Pole' },
                  { label: 'Žádné', value: 'Žádné' },
                ]}
                label="Dosavadní využití"
              />
            </div>
            <div className="sm:col-span-6">
              <Field
                component={CheckboxInput}
                name="b1a.landscapeCharacter"
                label="Soulad s charaterem území"
              />
              {!formik.values.b1a.landscapeCharacter && (
                <div className="mt-3">
                  <Field
                    component={TextAreaInput}
                    name="b1a.landscapeCharacterDescription"
                    label="Odůvodnění"
                  />
                </div>
              )}
            </div>
            <div className="sm:col-span-6 flex justify-end">
              <Button
                onClick={async () => {
                  const result = await mutateAsync(formik.values)
                  await formik.setFieldValue('b1a.aiGenerated', result.data)
                  setShowPreview(true)
                }}
              >
                {isLoading ? <Spinner /> : <>Náhed</>}
              </Button>
            </div>
          </>
        )}
      </FormSectionTwoColumns>
    </>
  )
}
