import React, { JSX } from 'react'
import { FormikProps, useFormikContext } from 'formik'
import { get } from 'lodash'
import { Button } from '../Button'
import { SecondaryButton } from '../SecondaryButton'
import { MinusIcon, PlusIcon } from '@heroicons/react/20/solid'

interface Props {
  name: string
  initializationObject: any
  children: (name: string, row: any, index: number) => React.ReactNode | JSX.Element
}

export const ArrayInput: React.FC<Props> = (props) => {
  const formik = useFormikContext() as FormikProps<any>
  const rows = get(formik.values, props.name) as unknown as Array<any>

  if (!Array.isArray(rows)) {
    throw new Error('ArrayInput must be used with array field')
  }

  const onAdd = () => {
    formik.setFieldValue(props.name, [...rows, { ...props.initializationObject }])
  }

  const onDelete = (index: number) => {
    formik.setFieldValue(
      props.name,
      rows.filter((_: any, i: number) => i !== index),
    )
  }

  return (
    <div className="sm:col-span-6">
      {rows.map((row, index) => (
        <React.Fragment key={index}>
          {props.children(`${props.name}[${index}]`, row, index)}
          <div className="sm:col-span-6 flex justify-end mt-3">
            <SecondaryButton onClick={() => onDelete(index)}>
              <MinusIcon className="h-6 inline" />
            </SecondaryButton>
          </div>
        </React.Fragment>
      ))}
      <div className="sm:col-span-6 flex justify-end mt-3">
        <Button onClick={onAdd}>
          <span>
            <PlusIcon className="h-6 inline" />
          </span>
        </Button>
      </div>
    </div>
  )
}
