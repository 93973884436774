import React from 'react'
import { AuthImage } from '../../../../components/AuthImage'
import { formatDateTime } from '../../../../model/helpers/dateHelpers'

interface Props {
  photo: any
}

export const PhotosGridItem: React.FC<Props> = ({ photo }) => {
  return (
    <div className="rounded-md shadow sm:shadow-md pb-1 hover:scale-[1.02] group z-0 hover:z-10 transition-all">
      <AuthImage image={photo.image} className="rounded-t-md w-full h-40 object-cover" />
      <div className="mt-1 px-2 bg-white rounded-b-md group-hover:z-10">
        {photo.room ? (
          <>{[photo.room?.object?.name, photo.room?.name].filter(Boolean).join(' - ')}</>
        ) : (
          <span className="text-gray-400">Nezařazené</span>
        )}
        <br />
        <span className="text-xs text-gray-500">{formatDateTime(photo.createdAt)}</span>
      </div>
    </div>
  )
}
