import { useQuery } from 'react-query'
import { api } from '../api'
import { ContactEntityData } from '../../interfaces/generated'
import { ApiUrlEnum } from '../../enums/ApiUrlEnum'

export const useGetContact = (id: string) => {
  return useQuery(['contacts', id], async () =>
    api.get<ContactEntityData>(`${ApiUrlEnum.CONTACT}/${id}`),
  )
}
