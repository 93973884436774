import React, { PropsWithChildren } from 'react'
import { useProject } from '../../../../../model/hooks/useProject'
import { useTechnicalReportForm } from '../../../../../model/hooks/useTechnicalReportForm'
import { ProjectFormEnum } from '../../../../../model/enums/ProjectFormEnum'
import { ProjectLoadingSkeleton } from '../../../layout/ProjectLoadingSkeleton'
import { ProjectLayout } from '../../../layout/ProjectLayout'
import { Form, Formik } from 'formik'
import { TechnicalFormHeading } from '../TechnicalFormHeading'
import { ProjectFormIdentityContext } from '../../../../../model/context/ProjectFormIdentityContext'
import { Button } from '../../../../../components/ui/button'
import { CheckIcon } from '@radix-ui/react-icons'
import { TechnicalFormStatusEnum } from 'shared'
import { Spinner } from '@chakra-ui/react'

interface Props extends PropsWithChildren {
  form: ProjectFormEnum
  initialValues: any
  title: string
}

export const TechnicalFormWrapper: React.FC<Props> = (props) => {
  const { project, id } = useProject()
  const technicalForm = useTechnicalReportForm(id, props.form, props.initialValues)

  if (!project || technicalForm.technicalFormIsLoading || technicalForm.technicalFormIsRefetching) {
    return (
      <div style={{ height: '9999px' }}>
        <ProjectLoadingSkeleton />
      </div>
    )
  }

  return (
    <ProjectLayout project={project}>
      <ProjectFormIdentityContext.Provider value={{ formEnum: props.form, projectId: id }}>
        <Formik
          initialValues={technicalForm.initialValues}
          onSubmit={(values) => {
            technicalForm.storeTechnicalForm({ data: { ...values }, id })
          }}
        >
          {(formik) => {
            return (
              <Form>
                <TechnicalFormHeading
                  revertFromPreview={technicalForm.deletePreviewProjectTechnicalForm}
                  submitForPreview={async () => {
                    await formik.submitForm()
                    setTimeout(() => {
                      return technicalForm.submitForPreviewProjectForm()
                    }, 100)
                  }}
                  title={props.title}
                  isLoading={
                    technicalForm.storeTechnicalFormIsLoading ||
                    technicalForm.deletePreviewIsLoading ||
                    technicalForm.submitForPreviewIsLoading
                  }
                  isInPreviewMode={
                    technicalForm?.technicalForm?.data?.status === TechnicalFormStatusEnum.PREVIEW
                  }
                  projectId={project.id}
                />
                {technicalForm?.technicalForm?.data?.status === TechnicalFormStatusEnum.PREVIEW ? (
                  <div
                    className="p-3"
                    dangerouslySetInnerHTML={{
                      __html: technicalForm!.technicalForm?.data?.preview || '',
                    }}
                  ></div>
                ) : (
                  <>
                    {props.children}
                    <div className="mt-5 flex justify-end">
                      <Button
                        type="button"
                        role="button"
                        onClick={async () => {
                          await formik.submitForm()
                          setTimeout(() => {
                            return technicalForm.submitForPreviewProjectForm()
                          }, 100)
                        }}
                      >
                        {technicalForm.submitForPreviewIsLoading ? (
                          <Spinner size="sm" />
                        ) : (
                          <>
                            <CheckIcon className="bg-white rounded-full text-blue-700 w-4 h-4 mr-3" />
                            Dokončit sekci
                          </>
                        )}
                      </Button>
                    </div>
                  </>
                )}
              </Form>
            )
          }}
        </Formik>
      </ProjectFormIdentityContext.Provider>
    </ProjectLayout>
  )
}
