import axios from 'axios'
import { RoutesEnum } from '../enums/RoutesEnum'

const axiosInstance = axios.create({
  baseURL: '/api',
})

axiosInstance.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem('token')
    config.headers.Authorization = `Bearer ${token}`
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  },
)

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401 && !window.location.href.includes('auth')) {
      localStorage.setItem('token', '')
      window.location.href = `${RoutesEnum.LOGIN}?redirect=${window.location.pathname}`
      return
    }

    return Promise.reject(error)
  },
)

export const api = axiosInstance
