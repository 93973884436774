import { useQuery } from 'react-query'
import { QueryKeyEnum } from '../../enums/QueryKeyEnum'
import { api } from '../api'
import { ApiUrlEnum } from '../../enums/ApiUrlEnum'
import { IIssueEntity } from '../../interfaces/IIssueEntity'

export const useGetIssue = (projectId?: string, issueId?: string) => {
  return useQuery({
    queryKey: [QueryKeyEnum.ISSUES, projectId, issueId],
    queryFn: () =>
      api.get<IIssueEntity>(`${ApiUrlEnum.PROJECT}/${projectId}${ApiUrlEnum.ISSUE}/${issueId}`),
    enabled: !!projectId && !!issueId,
  })
}
