import { useQuery } from 'react-query'
import { api } from '../api'
import { ProjectEntityData } from '../../interfaces/generated'
import { QueryKeyEnum } from '../../enums/QueryKeyEnum'
import { ApiUrlEnum } from '../../enums/ApiUrlEnum'

export const useGetProjects = () => {
  return useQuery([QueryKeyEnum.PROJECTS], () =>
    api.get<{ projects: ProjectEntityData[] }>(ApiUrlEnum.PROJECT),
  )
}
