/*
  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import React, { Fragment } from 'react'
import { CheckCircleIcon } from '@heroicons/react/24/solid'
import { useGetIssueFeed } from '../../../../model/api/project/useGetIssueFeed'
import { Spinner } from '@chakra-ui/react'
import { Avatar } from '../../../../components/Avatar'
import { IssueFeedTypeEnum } from '../../../../model/enums/IssueFeedTypeEnum'
import { formatDateTime } from '../../../../model/helpers/dateHelpers'
import { FlagIcon, MegaphoneIcon } from '@heroicons/react/24/outline'
import { transformStatusToActionName } from '../../../../model/helpers/issueHelpers'
import { useGetCurrentUser } from '../../../../model/api/auth/useGetCurrentUser'
import { Formik } from 'formik'
import { useCreateIssueFeedCommentMutation } from '../../../../model/api/project/useCreateIssueFeedCommentMutation'

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ')
}

interface Props {
  issueId: string
  projectId: string
}

export const IssuesFeed: React.FC<Props> = (props) => {
  const { data: currentUserData } = useGetCurrentUser()
  const { mutate: createComment, isLoading: isCreatingComment } =
    useCreateIssueFeedCommentMutation()
  const { data: issuesFeedData, isLoading: issuesFeedDataIsLoading } = useGetIssueFeed(
    props.projectId,
    props.issueId,
  )
  console.log({ issuesFeedData })

  if (issuesFeedDataIsLoading) {
    return <Spinner />
  }

  return (
    <>
      <ul role="list" className="space-y-6">
        {issuesFeedData?.data?.map((issueFeed: any, issueFeedIndex: number) => {
          const userName = issueFeed.user?.name || issueFeed.userName || ''
          const isExternal = !issueFeed.user
          return (
            <li key={issueFeed.id} className="relative flex gap-x-4">
              <div
                className={classNames(
                  issueFeedIndex === issuesFeedData.data.length - 1 ? 'h-6' : '-bottom-6',
                  'absolute left-0 top-0 flex w-6 justify-center',
                )}
              >
                <div className="w-px bg-gray-200" />
              </div>
              {issueFeed.type === IssueFeedTypeEnum.COMMENTED ? (
                <>
                  <Avatar
                    name={userName || ''}
                    className="relative mt-3 h-6 w-6 flex-none rounded-full bg-gray-50"
                  />
                  <div className="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200">
                    <div className="flex justify-between gap-x-4">
                      <div className="py-0.5 text-xs leading-5 text-gray-500">
                        <span className="font-medium text-gray-900">{userName}</span> komentoval
                      </div>
                      <time
                        dateTime={issueFeed.createdAt}
                        className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                      >
                        {formatDateTime(issueFeed.createdAt)}
                      </time>
                    </div>
                    <p className="text-sm leading-6 text-gray-500">{issueFeed.comment}</p>
                  </div>
                </>
              ) : (
                <>
                  <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                    {issueFeed.type === IssueFeedTypeEnum.REPORTED && (
                      <MegaphoneIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                    )}
                    {issueFeed.type === IssueFeedTypeEnum.FIXED && (
                      <FlagIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                    )}
                    {issueFeed.type === IssueFeedTypeEnum.CHECKED && (
                      <CheckCircleIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                    )}
                  </div>
                  <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                    <span className="font-medium text-gray-900">{userName}</span>{' '}
                    {isExternal ? ' (Externí uživatel) ' : ''}
                    {transformStatusToActionName(issueFeed.type)} vadu.
                  </p>
                  <time
                    dateTime={issueFeed.createdAt}
                    className="flex-none py-0.5 text-xs leading-5 text-gray-500"
                  >
                    {formatDateTime(issueFeed.createdAt)}
                  </time>
                </>
              )}
            </li>
          )
        })}
      </ul>

      {/* New comment form */}
      <div className="mt-6 flex gap-x-3">
        <Avatar name={currentUserData?.data?.name || ''} />
        <Formik
          initialValues={{ comment: '' }}
          onSubmit={(values, formikHelpers) => {
            createComment({
              projectId: props.projectId,
              issueId: props.issueId,
              comment: values.comment,
            })
            formikHelpers.resetForm()
          }}
        >
          {(formik) => {
            return (
              <form onSubmit={formik.handleSubmit} className="relative flex-auto">
                <div className="overflow-hidden rounded-lg pb-12 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-blue-600">
                  <textarea
                    rows={2}
                    name="comment"
                    id="comment"
                    className="block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    placeholder="Váš komentář..."
                    value={formik.values.comment}
                    onChange={formik.handleChange}
                  />
                </div>

                <div className="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
                  <div className="flex items-center space-x-5">
                    <div className="flex items-center"></div>
                  </div>
                  <button
                    disabled={!formik.values.comment || isCreatingComment}
                    type="submit"
                    className={`rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold ${
                      formik.values.comment ? 'text-gray-900' : 'text-gray-300'
                    }  shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50`}
                  >
                    {isCreatingComment ? <Spinner size="xs" /> : 'Komentovat'}
                  </button>
                </div>
              </form>
            )
          }}
        </Formik>
      </div>
    </>
  )
}
