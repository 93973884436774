import { useMutation, useQueryClient } from 'react-query'
import { api } from '../api'
import { ApiUrlEnum } from '../../enums/ApiUrlEnum'
import { QueryKeyEnum } from '../../enums/QueryKeyEnum'

export const useDeletePhotoDocumentationMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ projectId, photoId }: { projectId: string; photoId: string }) =>
      api.delete(`${ApiUrlEnum.PROJECT}/${projectId}${ApiUrlEnum.PHOTO_DOCUMENTATION}/${photoId}`),
    onSuccess: () => {
      return queryClient.invalidateQueries([QueryKeyEnum.PHOTO_DOCUMENTATION])
    },
  })
}
