import React, { useContext, useEffect } from 'react'
import { SmartDrawer } from '../../SmartDrawer'
import { ProjectFormIdentityContext } from '../../../model/context/ProjectFormIdentityContext'
import { useGetProjectFormRichTextHelp } from '../../../model/api/project/useGetProjectFormRichTextHelp'
import { Spinner } from '@chakra-ui/react'
import { FormLabel } from '../../FormLabel'
import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '../../ui/dropdown-menu'
import { Trash } from 'lucide-react'
import { useDeleteProjectFormRichTextHelpMutation } from '../../../model/api/project/useDeleteProjectFormRichTextHelpMutation'

interface Props {
  open: boolean
  setOpen: (open: boolean) => void
  fieldName: string
  onSelected: (text: string) => void
}

export const LoadRichTextModal: React.FC<Props> = (props) => {
  const context = useContext(ProjectFormIdentityContext)
  const { data, isLoading, refetch } = useGetProjectFormRichTextHelp(
    props.fieldName,
    context.formEnum!,
  )
  const { mutateAsync: deleteAsync, isLoading: isDeleting } =
    useDeleteProjectFormRichTextHelpMutation()

  useEffect(() => {
    if (props.open) {
      refetch()
    }
  }, [props.open, refetch])

  return (
    <SmartDrawer title="Načíst z oblíbených" open={props.open} setOpen={props.setOpen}>
      <div className="w-full">
        {isLoading ? (
          <div className="h-20">
            <Spinner />
          </div>
        ) : (
          <div className="flex flex-col gap-8 overflow-scroll max-h-80">
            {data?.data?.length === 0 && <span>Žádné položky k dispozici</span>}
            {data?.data?.map((item) => (
              <div key={item.id} className="flex flex-col gap-1">
                <div className="flex justify-between">
                  <FormLabel>{item.name}</FormLabel>
                  <DropdownMenu>
                    <DropdownMenuTrigger>
                      {/* TODO: Not user friendly */}
                      <EllipsisHorizontalIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="w-56">
                      <DropdownMenuLabel>{item.name ?? 'Položka'}</DropdownMenuLabel>
                      <DropdownMenuSeparator />
                      <DropdownMenuGroup>
                        <DropdownMenuItem
                          disabled={isDeleting}
                          onClick={async (e) => {
                            e.stopPropagation()
                            e.preventDefault()

                            await deleteAsync(item.id)
                            return refetch()
                          }}
                        >
                          <Trash className="mr-2 h-4 w-4" />
                          {isDeleting ? <Spinner size="xs" /> : <span>Smazat</span>}
                        </DropdownMenuItem>
                      </DropdownMenuGroup>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
                <div
                  className="cursor-pointer p-3 border rounded-md flex flex-col gap-3 hover:border-blue-700"
                  onClick={() => {
                    props.onSelected(item.text)
                    props.setOpen(false)
                  }}
                >
                  <p
                    className="h-20 overflow-scroll"
                    dangerouslySetInnerHTML={{ __html: item.text }}
                  />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </SmartDrawer>
  )
}
