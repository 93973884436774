import { ISelectOption } from '../../../../model/interfaces/ISelectOption'

export const RADON_OPTIONS: ISelectOption[] = [
  {
    label: 'Nízký',
    value: 'Nízký',
    description:
      'Dostatečnou ochranu stavby zajišťuje běžná izolace proti vodě a vlhkosti nejčastěji v podobě asfaltových pásů nebo plastových fólií se svařenými spoji a utěsněnými prostupy. Zároveň se doporučuje uzavřít schodišťový prostor ze suterénu dveřmi.',
  },
  {
    label: 'Střední',
    value: 'Střední',
    description:
      'Dostatečnou ochranu stavby zajišťuje protiradonová izolace, která musí být provedena spojitě v celé ploše stavby přiléhající k zemině. Při použití asfaltových pásů je vhodné provést protiradonovou izolaci spojitě ve dvou vrstvách (pásech) a to ve dvou směrech – podélně a příčně, s dostatečným překrytím a utěsněním spojů a prostupů. Minimální tloušťka izolace se určí podrobným výpočtem podle ČSN 730601. U podsklepeného objektu se doporučuje schodišťový prostor uzavřít dveřmi.',
  },
  {
    label: 'Vysoký',
    value: 'Vysoký',
    description:
      'Ochranu stavby zajišťuje protiradonová izolace, která musí být provedena spojitě v celé ploše stavby přiléhající k zemině. Tato postačí, pokud koncentrace radonu v podloží rozhodující pro stanovení radonového indexu stavby nepřesáhne:\n' +
      '200 kBq/m3 pro nízkopropustné podloží\n' +
      '140 kBq/m3 pro středněpropustné podloží\n' +
      '60 kBq/m3 pro vysokopropustné podloží\n' +
      'Jestliže jsou výše uvedené hodnoty překročeny, musí být protiradonová izolace kombinována s větracím systémem podloží nebo s ventilační vrstvou v kontaktní konstrukci.',
  },
]
