import React from 'react'
import { Field, Form, Formik } from 'formik'
import { TextInput } from '../../../components/inputs/TextInput'
import { TailwindModal } from '../../../components/TailwindModal'
import { useGetUsers } from '../../../model/api/user/useGetUsers'
import { Spinner, UseDisclosureProps, useToast } from '@chakra-ui/react'
import { RichTextInput } from '../../../components/inputs/RichTextInput/RichTextInput'
import { ComboInput } from '../../../components/inputs/ComboInput'
import { DatePickerInput } from '../../../components/inputs/DatePickerInput'
import { useGetCurrentUser } from '../../../model/api/auth/useGetCurrentUser'
import { UserAvatar } from '../../../components/UserAvatar'
import { useGetProjects } from '../../../model/api/project/useGetProjects'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { createTaskSchema, CreateTaskValues } from 'shared'
import { Button } from '../../../components/ui/button'
import { useCreateTask } from '../../../model/api/task/useCreateTask'
import moment from 'moment/moment'
import { IsDisabledContext } from '../../../model/context/IsDisabledContext'
import { CalendarCheck, InfoIcon, LinkIcon } from 'lucide-react'
import { cn } from 'src/lib/utils'
import { ProjectAvatar } from 'src/components/ProjectAvatar'
import { useUpdateTask } from '../../../model/api/task/useUpdateTask'
import { useMarkTaskAsDone } from '../../../model/api/task/useMarkTaskAsDone'
import { useDeleteTask } from '../../../model/api/task/useDeleteTask'
import { Checkbox } from '../../../components/catalyst/checkbox'
import { pick } from 'lodash'
import { MultiImageDropzoneInput } from '../../../components/MultiImageDropzoneInput'
import { IDocumentEntity } from '../../../model/interfaces/IDocumentEntity'
import { TrashIcon } from '@heroicons/react/20/solid'
import { v4 as uuidv4 } from 'uuid'
import { DropzoneInput } from '../../../components/inputs/DropzoneInput'
import { ContactSelectInput } from '../../../components/inputs/ContactSelectInput'
import { ContactAvatar } from '../../../components/ContactAvatar'
import { FormLabel } from '../../../components/FormLabel'

interface Props {
  disclosure: UseDisclosureProps
  initValues?: Partial<CreateTaskValues>
  editedTask?: any
}

export const TaskModalForm: React.FC<Props> = (props) => {
  const toast = useToast()
  const { data: projectsData } = useGetProjects()
  const { data: usersData } = useGetUsers()
  const { data: loggedUser } = useGetCurrentUser()
  const { mutateAsync: createTask } = useCreateTask()
  const { mutateAsync: updateTask } = useUpdateTask()
  const { mutateAsync: markTaskAsDone } = useMarkTaskAsDone()
  const { mutateAsync: deleteTask } = useDeleteTask()
  const users = usersData?.data || []
  const projects = projectsData?.data?.projects ?? []
  const [isEditMode, setIsEditMode] = React.useState(!props.editedTask)
  const isDueDateInPast = moment(props.editedTask?.due).isBefore(moment(), 'day')

  if (!loggedUser) {
    return <Spinner />
  }

  return (
    <TailwindModal
      className="w-full sm:max-w-4xl"
      disclosure={props.disclosure}
      dialogTitle={props.editedTask ? props.editedTask.title : 'Vytvořit úkol'}
    >
      <Formik
        validationSchema={toFormikValidationSchema(createTaskSchema)}
        initialValues={
          props.editedTask
            ? {
                ...props.editedTask,
                project: props.editedTask?.project?.id,
                assignedTo: props.editedTask?.assignedTo?.id,
                contact: props.editedTask?.contact?.id,
                doneAt: props.editedTask?.doneAt,
              }
            : {
                id: uuidv4(),
                title: '',
                description: '',
                assignedTo: loggedUser?.data?.id,
                due: undefined,
                project: '',
                doneAt: undefined,
                ...props.initValues,
              }
        }
        onSubmit={async (values: Partial<CreateTaskValues>) => {
          const pickedValues = pick(values, [
            'id',
            'due',
            'project',
            'assignedTo',
            'title',
            'description',
            'doneAt',
            'images',
            'contact',
          ])
          const formData = new FormData()

          for (const key in pickedValues) {
            if (key === 'images' && values[key as keyof CreateTaskValues]) {
              const images = values[key as keyof CreateTaskValues] as Array<File | IDocumentEntity>

              images.forEach((image, imageIndex) => {
                if (image.hasOwnProperty('id')) {
                  return
                }
                formData.append(`images[${imageIndex}]`, image as File)
              })
              continue
            }
            if (key === 'due' && values[key as keyof CreateTaskValues]) {
              formData.append(
                key,
                moment(values[key as keyof CreateTaskValues]).format('YYYY-MM-DD'),
              )
              continue
            }

            if (values[key as keyof CreateTaskValues]) {
              formData.append(key, values[key as keyof CreateTaskValues] as string)
            }
          }

          if (props.editedTask?.id) {
            await updateTask({ id: props.editedTask.id, values: formData })
            toast({
              title: 'Úkol byl upraven',
              status: 'success',
              duration: 5000,
              isClosable: true,
              position: 'bottom',
            })
          } else {
            await createTask(formData)
            toast({
              title: 'Úkol byl vytvořen',
              status: 'success',
              duration: 5000,
              isClosable: true,
              position: 'bottom',
            })
          }
          props.disclosure.onClose && props.disclosure.onClose()
        }}
      >
        {(formik) => (
          <Form>
            <IsDisabledContext.Provider value={!isEditMode}>
              <div className="text-left">
                <div className="grid grid-cols-1 sm:grid-cols-3 gap-3">
                  <div className="col-span-1 sm:col-span-2 flex flex-col gap-3">
                    {isEditMode && <Field name="title" component={TextInput} label="Název" />}
                    <Field name="description" component={RichTextInput} noMenu />
                    <Field
                      name="images"
                      component={MultiImageDropzoneInput}
                      label="Fotky"
                      accept={{ images: ['image/*'] }}
                    />
                    <div className="mt-5">
                      <Field
                        name="files"
                        component={DropzoneInput}
                        label="Soubory"
                        folderKey={`task-${formik.values.id}`}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col gap-3">
                    {!isEditMode ? (
                      <>
                        <div className="bg-gray-50 p-3 rounded-md flex flex-col gap-3">
                          <span className="text-sm font-semibold flex gap-2 items-center border-b border-gray-300 border-dotted pb-3">
                            <InfoIcon className="h-5 text-gray-600" />
                            Základní informace
                          </span>
                          <div className="items-center flex">
                            <Checkbox
                              className="scale-110 ml-0.5"
                              color="blue"
                              checked={!!formik.values?.doneAt}
                              onChange={(checked) => {
                                formik.setFieldValue(
                                  'doneAt',
                                  checked ? moment().format('YYYY-MM-DD') : undefined,
                                )
                                return markTaskAsDone({
                                  id: props.editedTask.id,
                                  done: checked,
                                })
                              }}
                            />
                            <span className="text-sm ml-3">
                              {formik.values?.doneAt ? 'Hotovo' : 'Čeká na dokončení'}
                            </span>
                          </div>
                          {props.editedTask?.due && (
                            <div
                              className={cn(
                                'flex items-center gap-2',
                                isDueDateInPast ? 'text-red-500 font-semibold' : 'text-gray-600',
                              )}
                            >
                              <div className="rounded-full bg-gray-200 p-1">
                                <CalendarCheck className="h-4 w-4" />
                              </div>
                              <span className="text-sm">
                                {moment(props.editedTask?.due).format('DD.MM.YYYY')}
                              </span>
                            </div>
                          )}
                          {props.editedTask?.assignedTo && (
                            <div>
                              <UserAvatar user={props.editedTask.assignedTo} />
                            </div>
                          )}
                        </div>
                        <div className="bg-gray-50 p-3 rounded-md flex flex-col gap-3">
                          <span className="text-sm font-semibold flex gap-2 items-center border-b border-gray-300 border-dotted pb-3">
                            <LinkIcon className="h-5 text-gray-600" />
                            Propojení
                          </span>
                          {props.editedTask?.project && (
                            <div>
                              <ProjectAvatar project={props.editedTask.project} />
                            </div>
                          )}
                          {props.editedTask?.contact && (
                            <div>
                              <ContactAvatar contact={props.editedTask.contact} />
                            </div>
                          )}
                        </div>
                        <div
                          className="flex justify-end items-center gap-2 cursor-pointer hover:underline"
                          onClick={() => {
                            if (window.confirm('Opravdu chcete smazat tento úkol?')) {
                              deleteTask(props.editedTask.id)
                              props.disclosure.onClose && props.disclosure.onClose()
                            }
                          }}
                        >
                          <div className="rounded-full bg-red-500 p-1">
                            <TrashIcon className="h-4 w-4 text-white" />
                          </div>
                          <span className="text-sm">Smazat</span>
                        </div>
                      </>
                    ) : (
                      <>
                        <Field name="due" component={DatePickerInput} label="Termín dokončení" />
                        <Field
                          name="assignedTo"
                          component={ComboInput}
                          label="Řešitel"
                          options={users.map((user) => ({
                            value: user.id,
                            label: user.name,
                            displayOnlyLabel: <UserAvatar user={user} />,
                          }))}
                        />
                        <div className="bg-gray-50 p-3 rounded-md flex flex-col gap-3">
                          <span className="text-sm font-semibold flex gap-1 items-center">
                            <LinkIcon className="h-4 text-gray-600" />
                            Propojení
                          </span>
                          <Field
                            name="project"
                            label="Projekt"
                            component={ComboInput}
                            options={projects.map((p) => ({
                              label: p.name,
                              value: p.id,
                            }))}
                          />
                          <Field label="Kontakt" name="contact" component={ContactSelectInput} />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex justify-end mt-5 gap-2">
                <IsDisabledContext.Provider value={false}>
                  <>
                    {isEditMode ? (
                      <>
                        {props.editedTask && (
                          <Button variant="ghost" type="button" onClick={() => setIsEditMode(false)}>
                            Zrušit úpravy
                          </Button>
                        )}
                        <Button isLoading={formik.isSubmitting} type="submit">
                          {props.editedTask ? 'Uložit změny' : 'Vytvořit'}
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button type="button" onClick={() => setIsEditMode(true)}>
                          Upravit
                        </Button>
                      </>
                    )}
                  </>
                </IsDisabledContext.Provider>
              </div>
            </IsDisabledContext.Provider>
          </Form>
        )}
      </Formik>
    </TailwindModal>
  )
}
