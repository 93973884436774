import { useMutation, useQueryClient } from 'react-query'
import { api } from '../api'
import { ApiUrlEnum } from '../../enums/ApiUrlEnum'
import { QueryKeyEnum } from '../../enums/QueryKeyEnum'

export const useSimpleProjectUpdateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: any) => {
      const { id, ...rest } = data
      return api.patch(`${ApiUrlEnum.PROJECT}/${data.id}`, rest)
    },
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeyEnum.PROJECTS)
    },
  })
}
