import { useMutation, useQueryClient } from 'react-query'
import { UpdateUserRoleValues } from 'shared'
import { api } from '../api'
import { ApiUrlEnum } from '../../enums/ApiUrlEnum'
import { QueryKeyEnum } from '../../enums/QueryKeyEnum'

export const useUpdateUserRoleMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ userId, values }: { userId: string; values: UpdateUserRoleValues }) =>
      api.put(`${ApiUrlEnum.USER}/role/${userId}`, values),
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeyEnum.USERS)
    },
  })
}
