"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createTaskSchema = exports.createProjectSchema = exports.updatePhotoDocumentationSchema = exports.createPhotoDocumentationSchema = exports.createWorkspaceSchema = exports.createProjectFormRichTextHelpSchema = exports.updateUserRoleSchema = exports.inviteUserSchema = void 0;
const zod_1 = require("zod");
const enums_1 = require("./enums");
exports.inviteUserSchema = zod_1.z.object({
    email: zod_1.z.string({
        message: "Pole je povinné"
    }).email("Je potřeba zadat validní email"),
    role: zod_1.z.object({
        value: zod_1.z.nativeEnum(enums_1.UserRoleEnum)
    })
});
exports.updateUserRoleSchema = zod_1.z.object({
    role: zod_1.z.nativeEnum(enums_1.UserRoleEnum)
});
exports.createProjectFormRichTextHelpSchema = zod_1.z.object({
    projectId: zod_1.z.string(),
    name: zod_1.z.string().optional(),
    text: zod_1.z.string(),
    key: zod_1.z.string(),
    formEnum: zod_1.z.nativeEnum(enums_1.ProjectFormEnum)
});
exports.createWorkspaceSchema = zod_1.z.object({
    workspace: zod_1.z.object({
        name: zod_1.z.string({
            message: "Pole je povinné"
        }),
        slug: zod_1.z.string({
            message: "Pole je povinné"
        }),
    }),
    user: zod_1.z.object({
        email: zod_1.z.string({
            message: "Pole je povinné"
        }).email("Je potřeba zadat validní email"),
        password: zod_1.z.string({
            message: "Pole je povinné"
        }),
        name: zod_1.z.string({
            message: "Pole je povinné"
        }),
    })
});
exports.createPhotoDocumentationSchema = zod_1.z.object({
    image: zod_1.z.any(),
    description: zod_1.z.string().optional(),
    room: zod_1.z.string().optional(),
});
exports.updatePhotoDocumentationSchema = zod_1.z.object({
    description: zod_1.z.string().optional(),
    room: zod_1.z.string().optional(),
});
exports.createProjectSchema = zod_1.z.object({
    name: zod_1.z.string({
        message: "Pole je povinné"
    }),
    subject: zod_1.z.string({
        message: "Pole je povinné"
    }),
    number: zod_1.z.string({
        message: "Pole je povinné"
    }),
    investorId: zod_1.z.string({ message: "Pole je povinné" }),
    latitude: zod_1.z.number({ message: 'Vyberte polohu na mapě' }),
});
exports.createTaskSchema = zod_1.z.object({
    id: zod_1.z.any(),
    title: zod_1.z.string({
        message: "Pole je povinné"
    }),
    description: zod_1.z.string().nullable().optional(),
    assignedTo: zod_1.z.string().nullable().optional(),
    due: zod_1.z.any(),
    project: zod_1.z.string().nullable().optional(),
    contact: zod_1.z.string().nullable().optional(),
    doneAt: zod_1.z.any(),
});
