import { useQuery } from 'react-query'
import { api } from '../api'
import { ProjectEntityData } from '../../interfaces/generated'
import { ApiUrlEnum } from '../../enums/ApiUrlEnum'
import { QueryKeyEnum } from '../../enums/QueryKeyEnum'

export const useGetProject = (id: string) => {
  return useQuery([QueryKeyEnum.PROJECTS, id], () =>
    api.get<{ project: ProjectEntityData }>(`${ApiUrlEnum.PROJECT}/${id}`),
  )
}
