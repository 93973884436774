import React from 'react'
import { Button } from './ui/button'

interface Props {
  title: string | React.ReactNode
  description: string
  buttonText: string
  onClick: () => void
}

export const ActionPanel: React.FC<Props> = (props) => {
  return (
    <div className="bg-gray-50 shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-base font-semibold leading-6 text-gray-900">{props.title}</h3>
        <div className="mt-2 sm:flex sm:items-start sm:justify-between">
          <div className="max-w-xl text-sm text-gray-600">
            <p>{props.description}</p>
          </div>
          <div className="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center">
            <Button onClick={props.onClick} type="button">
              {props.buttonText}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
