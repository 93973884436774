import * as React from 'react'
import {Button} from 'src/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from 'src/components/ui/dialog'
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from 'src/components/ui/drawer'
import {useMediaQuery} from '@uidotdev/usehooks'
import {cn} from "../lib/utils";

interface Props extends React.PropsWithChildren {
  title: string | React.ReactNode
  description?: string | React.ReactNode
  open: boolean
  setOpen: (val: boolean) => void
  classNameWrapper?: string
}

export const SmartDrawer: React.FC<Props> = (props) => {
  const isDesktop = useMediaQuery('(min-width: 768px)')

  if (isDesktop) {
    return (
      <Dialog open={props.open} onOpenChange={props.setOpen}>
        <DialogContent className={cn("sm:max-w-[600px]", props.classNameWrapper)}>
          <DialogHeader>
            <DialogTitle>{props.title}</DialogTitle>
            <DialogDescription>{props.description}</DialogDescription>
          </DialogHeader>
          <div className="max-h-[80vh] overflow-scroll">{props.children}</div>
        </DialogContent>
      </Dialog>
    )
  }

  return (
    <Drawer open={props.open} onOpenChange={props.setOpen}>
      <DrawerContent>
        <DrawerHeader className="text-left">
          <DrawerTitle>{props.title}</DrawerTitle>
          <DrawerDescription>{props.description}</DrawerDescription>
        </DrawerHeader>
        <div className="px-4 max-h-[80vh] overflow-scroll">{props.children}</div>
        <DrawerFooter className="pt-2">
          <DrawerClose asChild>
            <Button variant="outline">Zavřít</Button>
          </DrawerClose>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}
