import React from 'react'
import { Field, useFormikContext } from 'formik'
import { SelectInput } from '../../../../components/inputs/SelectInput'
import { IssueStatusEnum } from '../../../../model/enums/IssueStatusEnum'
import { Spinner } from '@chakra-ui/react'
import { useGetObjects } from '../../../../model/api/project/useGetObjects'
import { ISelectOption } from '../../../../model/interfaces/ISelectOption'
import { IObjectEntity } from '../../../../model/interfaces/IObjectEntity'

interface Props {
  setSearchParams: (params: any) => void
  objects: IObjectEntity[]
  hideStatus?: boolean
  hidePerPage?: boolean
}

export const IssuesFilter: React.FC<Props> = (props) => {
  const formik = useFormikContext<{
    status?: { value: string }
    object: { value: string }
    limit?: { value: string }
  }>()
  const { status, object, limit } = formik.values

  const objectOptions: ISelectOption[] =
    props.objects.map((object) => ({
      label: object.name,
      value: object.id,
    })) || []

  React.useEffect(() => {
    if (props.hideStatus) {
      return
    }

    props.setSearchParams({ status: status?.value })
  }, [props.hideStatus, status?.value])

  React.useEffect(() => {
    props.setSearchParams({ object: object.value })
  }, [object.value])

  React.useEffect(() => {
    if (props.hidePerPage) {
      return
    }

    props.setSearchParams({ limit: limit?.value })
  }, [props.hidePerPage, limit?.value])

  return (
    <div className="order-last flex w-full gap-x-8 text-sm font-semibold leading-6 sm:order-none sm:w-auto sm:border-l sm:border-gray-200 sm:pl-6 sm:leading-7">
      {!props.hideStatus && (
        <Field
          name="status"
          component={SelectInput}
          wrapperClassname="w-40"
          options={[
            {
              label: 'Nahlášeno',
              value: IssueStatusEnum.REPORTED,
            },
            {
              label: 'Opraveno',
              value: IssueStatusEnum.FIXED,
            },
            {
              label: 'Zkontrolováno',
              value: IssueStatusEnum.CHECKED,
            },
          ]}
          forbidCustom={true}
          placeholder="Všechny stavy"
          emptyPlaceholder="Všechny stavy"
        />
      )}
      <Field
        name="object"
        component={SelectInput}
        options={objectOptions}
        forbidCustom={true}
        placeholder="Všechny objekty"
        emptyPlaceholder="Všechny objekty"
        wrapperClassname="w-60"
      />
      {!props.hidePerPage && (
        <Field
          name="limit"
          component={SelectInput}
          options={[
            { label: '10 na stránku', value: '10' },
            { label: '20 na stránku', value: '20' },
            { label: '50 na stránku', value: '50' },
            { label: '100 na stránku', value: '100' },
            { label: 'Vše', value: '999999' },
          ]}
          forbidCustom={true}
          placeholder="Na stránku"
          emptyPlaceholder="Položek na stránku"
          wrapperClassname="w-40"
        />
      )}
    </div>
  )
}
