import React from 'react'
import { PrinterIcon } from '@heroicons/react/24/outline'
import moment from 'moment'

export const IssuesTable: React.FC<{ issues: any[]; onIssueSelected: (issue: any) => void }> = (
  props,
) => {
  return (
    <div className="mt-8 flow-root">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <table className="min-w-full divide-y divide-gray-300 table-fixed">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                >
                  Foto
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Pořadové číslo
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Objekt
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Místnost
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Popis
                </th>
                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0 print:hidden">
                  <span className="sr-only">Odstraněno</span>
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {props.issues.map((issue) => (
                <tr key={issue.id}>
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                    <img
                      className="max-w-sm print:w-24"
                      src={`/uploads/generic/${issue.image?.name}`}
                    />
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {issue.number}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {issue.room?.object?.name}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {issue.room?.name}
                  </td>
                  <td className="px-3 py-4 text-sm text-gray-500 break-words">
                    <p>{issue.description}</p>
                  </td>
                  <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0 print:hidden">
                    <a
                      onClick={() => props.onIssueSelected(issue)}
                      className="text-blue-600 hover:text-blue-900 cursor-pointer"
                    >
                      Označit jako odstraněno
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
