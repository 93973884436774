import React from 'react'
import { useProject } from '../../../../../model/hooks/useProject'
import { ProjectLoadingSkeleton } from '../../../layout/ProjectLoadingSkeleton'
import { TechnicalFormWrapper } from '../B2ConstructionDescription/TechnicalFormWrapper'
import { ProjectFormEnum } from '../../../../../model/enums/ProjectFormEnum'
import { B4_FORM_INITIAL_VALUES } from './constants'
import { B4TransportSolutionForm } from './B4TransportSolutionForm'

interface Props {}

export const B4TransportSolutions: React.FC<Props> = (props) => {
  const { project } = useProject()

  if (!project) {
    return <ProjectLoadingSkeleton />
  }

  return (
    <TechnicalFormWrapper
      form={ProjectFormEnum.TECHNICAL_FORM_B4}
      initialValues={B4_FORM_INITIAL_VALUES}
      title="B.4 Dopravní řešení"
    >
      <B4TransportSolutionForm />
    </TechnicalFormWrapper>
  )
}
