import React from 'react'
import { AuthImage } from '../../../../components/AuthImage'
import { Link, useSearchParams } from 'react-router-dom'
import { RoutesEnum } from '../../../../model/enums/RoutesEnum'
import { IssueSeverityBadge } from '../../components/IssueSeverityBadge'
import { IssueStatusBadge } from '../../components/IssueStatusBadge'
import { Avatar } from '../../../../components/Avatar'
import { formatDateTime } from '../../../../model/helpers/dateHelpers'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { IIssueEntity } from '../../../../model/interfaces/IIssueEntity'
import { TailwindModal } from '../../../../components/TailwindModal'
import { useDisclosure } from '@chakra-ui/react'
import { Button } from '../../../../components/Button'
import { SecondaryButton } from '../../../../components/SecondaryButton'

interface Props {
  issues: IIssueEntity[]
  projectId: string
  forPreview?: boolean
  onReportFixed?: (issue: IIssueEntity) => void
}

export const IssuesList: React.FC<Props> = (props) => {
  const [searchParams] = useSearchParams()
  const [previewItem, setPreviewItem] = React.useState<any>()
  const previewDisclosure = useDisclosure()
  return (
    <>
      <TailwindModal
        disclosure={{
          ...previewDisclosure,
          onClose: () => {
            previewDisclosure.onClose && previewDisclosure.onClose()
            setTimeout(() => setPreviewItem(undefined), 500)
          },
        }}
        dialogTitle={previewItem?.title || 'Náhled'}
      >
        {previewItem?.image && <AuthImage image={previewItem.image} alt="" />}
      </TailwindModal>
      <ul className="divide-y divide-gray-100 mt-3">
        {props.issues.map((issue, issueIndex) => {
          return (
            <li
              key={issueIndex}
              className="relative flex justify-between gap-x-6 py-5 border-none hover:bg-gray-50 rounded-xl px-2"
            >
              <div className="flex min-w-0 gap-x-4">
                <AuthImage
                  className={`${
                    props.forPreview ? 'sm:h-40 sm:w-40' : ''
                  } h-24 w-24 flex-none rounded-xl bg-gray-50 cursor-pointer object-cover drop-shadow-md`}
                  alt=""
                  onClick={() => {
                    setPreviewItem(issue)
                    previewDisclosure.onOpen && previewDisclosure.onOpen()
                  }}
                  image={issue.image}
                />
                <div className="min-w-0 flex-auto">
                  <p className="text-sm font-semibold leading-6 text-gray-900">
                    {props.forPreview ? (
                      <span className="font-semibold text-black">
                        {[`#${issue.number}`, issue.room.object?.name, issue.room.name]
                          .filter(Boolean)
                          .join(' / ')}
                      </span>
                    ) : (
                      <Link
                        className="hover:underline"
                        to={`${RoutesEnum.PROJECTS}/${props.projectId}/${RoutesEnum.ISSUES}/${
                          RoutesEnum.ISSUES_FORM
                        }/${issue.id}?${searchParams.toString()}`}
                      >
                        {[`#${issue.number}`, issue.room.object?.name, issue.room.name]
                          .filter(Boolean)
                          .join(' / ')}
                      </Link>
                    )}
                    {!props.forPreview && (
                      <span className="ml-3">
                        <IssueStatusBadge status={issue.status} />
                      </span>
                    )}
                  </p>
                  <div className="mt-1 flex flex-col text-xs leading-5 text-gray-500">
                    <p>{issue.description}</p>
                    {props.forPreview && (
                      <SecondaryButton
                        className="mt-3 sm:hidden"
                        onClick={() => props.onReportFixed?.(issue)}
                      >
                        Označit jako odstraněno
                      </SecondaryButton>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex shrink-0 items-center gap-x-4">
                {props.forPreview ? (
                  <div className="hidden sm:flex sm:flex-col sm:items-end">
                    <SecondaryButton onClick={() => props.onReportFixed?.(issue)}>
                      Označit jako odstraněno
                    </SecondaryButton>
                  </div>
                ) : (
                  <>
                    <div className="hidden sm:flex sm:flex-col sm:items-end">
                      <p className="text-xs leading-5 text-gray-500">
                        Nahlásil: <Avatar name={issue.reportedBy.name} /> {issue.reportedBy.name}
                      </p>
                      <p className="text-xs leading-5 text-gray-500">
                        Nahlášeno {formatDateTime(issue.createdAt)}
                      </p>
                    </div>
                    <ChevronRightIcon
                      className="cursor-pointer h-5 w-5 flex-none text-gray-400"
                      aria-hidden="true"
                    />
                  </>
                )}
              </div>
            </li>
          )
        })}
      </ul>
    </>
  )
}
