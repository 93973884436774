import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { TaskQuery, TaskQueryStatus } from 'shared'
import { Field, Form, Formik } from 'formik'
import { TextInput } from '../../../components/inputs/TextInput'
import { Button } from '../../../components/ui/button'
import { ComboInput } from '../../../components/inputs/ComboInput'
import { Popover, PopoverContent, PopoverTrigger } from '../../../components/ui/popover'
import { FilterIcon } from 'lucide-react'
import { useGetProjects } from '../../../model/api/project/useGetProjects'
import RadioInput from '../../../components/inputs/RadioInput'
import { useGetUsers } from '../../../model/api/user/useGetUsers'

interface Props {
  taskQuery?: TaskQuery
  onFiltersChange?: () => void
}

export const TaskFilterForm: React.FC<Props> = (props) => {
  const { data: projectsData } = useGetProjects()
  const { data: usersData } = useGetUsers()
  const projects = projectsData?.data?.projects ?? []
  const users = usersData?.data ?? []
  const [searchParams, setSearchParamsRaw] = useSearchParams({
    done: TaskQueryStatus.NOT_DONE,
    ...props.taskQuery,
  })

  const onParamsChange = (values: Partial<TaskQuery>) => {
    const newParams = new URLSearchParams(searchParams)
    let shouldResetPagination = false
    Object.entries(values).forEach(([key, value]) => {
      if (key !== 'page') {
        shouldResetPagination = true
      }

      if (value) {
        newParams.set(key, value)
      } else {
        newParams.delete(key)
      }
    })

    if (shouldResetPagination) {
      newParams.set('page', '1')
    }

    setSearchParamsRaw(newParams)
    setTimeout(() => {
      props.onFiltersChange?.()
    }, 0)
  }

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="outline">
          <FilterIcon className="text-gray-600" size={16} />
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <Formik
          initialValues={{
            title: searchParams.get('title') ?? '',
            description: searchParams.get('description') ?? '',
            done: (searchParams.get('done') as TaskQueryStatus) ?? TaskQueryStatus.NOT_DONE,
            projectId: searchParams.get('projectId') ?? '',
            assignedTo: searchParams.get('assignedTo') ?? '',
          }}
          onSubmit={(values: TaskQuery) => {
            onParamsChange(values)
          }}
        >
          {(formik) => (
            <Form className="flex flex-col gap-3">
              <Field
                label="Název"
                wrapperClassname="w-full"
                name="title"
                component={TextInput}
                placeholder="Vyhledávat"
              />
              <Field
                label="Stav"
                name="done"
                component={RadioInput}
                options={[
                  { value: TaskQueryStatus.NOT_DONE, label: 'Nedokončené' },
                  { value: TaskQueryStatus.DONE, label: 'Dokončené' },
                  { value: TaskQueryStatus.ALL, label: 'Všechny' },
                ]}
              />
              <Field
                label="Projekt"
                name="projectId"
                component={ComboInput}
                options={projects.map((p) => ({
                  value: p.id,
                  label: p.name,
                }))}
              />
              <Field
                label="Řešitel"
                name="assignedTo"
                component={ComboInput}
                options={users.map((u) => ({
                  value: u.id,
                  label: u.name,
                }))}
              />
              <div className="flex flex-col">
                <Button type="submit" variant="secondary">
                  Filtrovat
                </Button>
                <Button
                  type="button"
                  variant="link"
                  onClick={() => {
                    formik.resetForm()
                    setSearchParamsRaw('')
                    setTimeout(() => {
                      props.onFiltersChange?.()
                    }, 0)
                  }}
                >
                  Resetovat
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </PopoverContent>
    </Popover>
  )
}
