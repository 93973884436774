import { PersonTypeEnum } from '../enums/PersonTypeEnum'
import { ContactEntityData } from '../interfaces/generated'
import { ISelectOption } from '../interfaces/ISelectOption'

export const contactToString = (contact: ContactEntityData) => {
  switch (contact.type) {
    case PersonTypeEnum.PERSON:
      return `${contact.name!} ${contact.lastName!}`
    case PersonTypeEnum.COMPANY:
      return contact.name!
    case PersonTypeEnum.PERSON_COMPANY:
      return `${contact.name!} ${contact.lastName!} (${contact.name!})`
    default:
      throw new Error('Invalid contact type')
  }
}

export const contactsToOptions = (contacts: ContactEntityData[]): ISelectOption[] =>
  contacts.map((contact) => ({
    value: contact.id,
    label: contactToString(contact),
  }))

export const contactTypeToLabel = (type: PersonTypeEnum) => {
  switch (type) {
    case PersonTypeEnum.PERSON:
      return 'Fyzická osoba'
    case PersonTypeEnum.COMPANY:
      return 'Právnická osoba'
    case PersonTypeEnum.PERSON_COMPANY:
      return 'Fyzická osoba podnikající'
  }
}
