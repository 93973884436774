import React from 'react'
import { downloadPreview } from '../api/project/downloadPreview'

export const useGenerateReportAction = (projectId: string, section: 'a' | 'b') => {
  const [isLoading, setIsLoading] = React.useState(false)
  const onDownload = async () => {
    if (isLoading) {
      return
    }
    setIsLoading(true)
    try {
      await downloadPreview(projectId, section)
    } finally {
      setIsLoading(false)
    }
  }

  return { isLoading, onDownload }
}
