import React from 'react'
import { FormSectionTwoColumns } from '../../../../../../components/forms/FormSectionTwoColumns'
import { B2_FORM_INIT_VALUES } from '../constants'
import { Field } from 'formik'
import { TextAreaInput } from '../../../../../../components/inputs/TextAreaInput'

interface Props {}

export const B23TechnologyAndUsageSection: React.FC<Props> = () => {
  return (
    <FormSectionTwoColumns
      sectionKey="b2.technologyAndUsage"
      title="B.2.3 Celkové provozní řešení, technologie výroby"
    >
      <div className="sm:col-span-6">
        <div className="mt-3">
          <Field
            component={TextAreaInput}
            name="b2.technologyAndUsage.overallOperationalSolution"
            label="Provozní řešení"
          />
        </div>
        <div className="mt-3">
          <Field
            component={TextAreaInput}
            name="b2.technologyAndUsage.technology"
            label="Technologie výroby"
          />
        </div>
      </div>
    </FormSectionTwoColumns>
  )
}
