import React from 'react'
import { Field, Form, Formik } from 'formik'
import { SelectInput } from '../../../../components/inputs/SelectInput'
import { RichTextInput } from '../../../../components/inputs/RichTextInput/RichTextInput'
import { useGetRooms } from '../../../../model/api/project/useGetRooms'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '../../../../components/ui/accordion'
import { useCreatePhotoDocumentationMutation } from '../../../../model/api/photoDocumentation/useCreatePhotoDocumentationMutation'
import { Button } from '../../../../components/ui/button'
import { useToast } from '@chakra-ui/react'
import { MultiImageDropzoneInput } from '../../../../components/MultiImageDropzoneInput'
import { useSearchParams } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { QueryKeyEnum } from '../../../../model/enums/QueryKeyEnum'

interface Props {
  projectId: string
  onSuccess: () => void
}

enum SubmitButton {
  SAVE = 'save',
  SAVE_AND_NEXT = 'save&next',
}

export const PhotoDocumentationForm: React.FC<Props> = (props) => {
  const toast = useToast()
  const [searchParams] = useSearchParams()
  const { data: roomsData, isLoading: roomsLoading } = useGetRooms(props.projectId, 'all')
  const { mutateAsync, isLoading: isSubmitting } = useCreatePhotoDocumentationMutation()
  const queryClient = useQueryClient()

  return (
    <div>
      <Formik
        initialValues={{ description: '', room: { value: searchParams.get('room') || undefined } }}
        onSubmit={async (values: any, formikHelpers) => {
          const { submitter, images, ...rest } = values

          for (const image of images) {
            const formData = new FormData()
            formData.append('description', values.description)
            if (values.room?.value) {
              formData.append('room', values.room?.value)
            }
            formData.append('image', image)

            await mutateAsync({ projectId: props.projectId, data: formData })
          }

          await queryClient.invalidateQueries([QueryKeyEnum.PHOTO_DOCUMENTATION])

          toast({
            title: 'Úspešně vytvořeno',
            status: 'success',
            duration: 5000,
            isClosable: true,
            position: 'top',
          })

          if (submitter === SubmitButton.SAVE) {
            return props.onSuccess()
          }

          // reset form
          formikHelpers.resetForm({ values: { ...rest, images: [] } })
        }}
      >
        {(formik) => {
          return (
            <Form>
              <div className="">
                <Field
                  isLoading={roomsLoading}
                  name="room"
                  component={SelectInput}
                  options={[
                    ...(
                      roomsData?.data?.map((r: any) => ({
                        value: r.id,
                        label: [r.object?.name, r.name].filter(Boolean).join(' - '),
                      })) || []
                    ).sort((a: any, b: any) => a.label.localeCompare(b.label)),
                  ]}
                  forbidCustom={true}
                />
                <Field
                  name="images"
                  component={MultiImageDropzoneInput}
                  accept={{ images: ['image/*'] }}
                />
                <div className="mt-5">
                  <Accordion type="single" collapsible className="w-full">
                    <AccordionItem value="item-1">
                      <AccordionTrigger>Popis</AccordionTrigger>
                      <AccordionContent>
                        <Field noMenu={true} name="description" component={RichTextInput} />
                      </AccordionContent>
                    </AccordionItem>
                  </Accordion>
                </div>
              </div>
              <div className="mt-5 flex flex-col items-stretch sm:flex-row sm:justify-end gap-1">
                <Button
                  onClick={async (e) => {
                    await formik.setFieldValue('submitter', SubmitButton.SAVE)
                    await formik.submitForm()
                  }}
                  type="button"
                  size="sm"
                  isLoading={formik.isSubmitting}
                >
                  Uložit
                </Button>
                <Button
                  onClick={async (e) => {
                    await formik.setFieldValue('submitter', SubmitButton.SAVE_AND_NEXT)
                    await formik.submitForm()
                  }}
                  type="button"
                  size="sm"
                  isLoading={formik.isSubmitting}
                >
                  Uložit & Další
                </Button>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}
