import { useQuery } from 'react-query'
import { api } from '../api'
import { ApiUrlEnum } from '../../enums/ApiUrlEnum'

export const useGetUserPasswordResetHashStatus = (hash?: string) => {
  return useQuery(['hash', hash], {
    queryFn: () => api.get<{ valid: boolean }>(`${ApiUrlEnum.USER_RESET_PASSWORD}/${hash}`),
    enabled: !!hash,
  })
}
