import React from 'react'
import { Field, FormikProps, useFormikContext } from 'formik'
import { B6_FORM_INITIAL_VALUES } from './constants'
import { FormSectionTwoColumns } from '../../../../../components/forms/FormSectionTwoColumns'
import { CheckboxInput } from '../../../../../components/inputs/CheckboxInput'
import { RichTextInput } from '../../../../../components/inputs/RichTextInput/RichTextInput'
import { FormLabel } from '../../../../../components/FormLabel'
import { ArrayInput } from '../../../../../components/inputs/ArrayInput'
import { TextInput } from '../../../../../components/inputs/TextInput'

interface Props {}

type B6EnvironmentSectionKeys = keyof typeof B6_FORM_INITIAL_VALUES.b6.environment.sections
const environmentSectionKeys: B6EnvironmentSectionKeys[] = ['air', 'noise', 'waste', 'soil']
type B6LandscapeSectionKeys = keyof typeof B6_FORM_INITIAL_VALUES.b6.landscape.sections
const landscapeSectionKeys: B6LandscapeSectionKeys[] = [
  'wood',
  'protectedTrees',
  'flowersAndAnimals',
  'ecoRelationships',
]
type B6ProtectedAreaSectionKeys = keyof typeof B6_FORM_INITIAL_VALUES.b6.protectedArea.sections
const protectedAreaSectionKeys: B6ProtectedAreaSectionKeys[] = [
  'protectionOfArea',
  'scaleOfProtection',
  'rulesForProtection',
]

export const B6EnvironmentProtectionForm: React.FC<Props> = (props) => {
  const formik = useFormikContext() as FormikProps<typeof B6_FORM_INITIAL_VALUES>
  return (
    <>
      <FormSectionTwoColumns
        sectionKey="b6.environment"
        title="a) vliv na životní prostředí "
        subtitle="ovzduší, hluk, voda, odpady a půda"
      >
        <div className="sm:col-span-6 mt-3">
          <Field
            name="b6.environment.hasNoImpact"
            component={CheckboxInput}
            label="Nemá trvalý vliv na životní prostředí"
          />
          {formik.values.b6.environment.hasNoImpact ? (
            <Field name="b6.environment.predefinedDescription" component={RichTextInput} />
          ) : (
            <div className="pl-7">
              {environmentSectionKeys.map((section) => (
                <div className="mt-3" key={section}>
                  <Field
                    name={`b6.environment.sections.${section}.enabled`}
                    component={CheckboxInput}
                    label={formik.values.b6.environment.sections[section].label}
                  />
                  {formik.values.b6.environment.sections[section].enabled && (
                    <div className="pl-7">
                      <Field
                        name={`b6.environment.sections.${section}.description`}
                        component={RichTextInput}
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </FormSectionTwoColumns>
      <FormSectionTwoColumns
        sectionKey="b6.landscape"
        title="b) vliv na přírodu a krajinu"
        subtitle="ochrana dřevin, ochrana památných stromů, ochrana rostlin a živočichů, zachování ekologických funkcí a vazeb v krajině apod."
      >
        <div className="sm:col-span-6">
          {landscapeSectionKeys.map((section) => (
            <div className="mt-3" key={section}>
              <Field
                name={`b6.landscape.sections.${section}.enabled`}
                component={CheckboxInput}
                label={formik.values.b6.landscape.sections[section].label}
              />
              {formik.values.b6.landscape.sections[section].enabled && (
                <div className="pl-7">
                  <Field
                    name={`b6.landscape.sections.${section}.description`}
                    component={RichTextInput}
                  />
                </div>
              )}
            </div>
          ))}
          <div className="mt-3">
            <FormLabel>Vlastní</FormLabel>
            <div className="pl-7">
              <ArrayInput name="b6.landscape.custom" initializationObject={{}}>
                {(name, row, index) => (
                  <div className="mt-3" key={index}>
                    <Field name={`${name}.name`} component={TextInput} placeholder={`Název`} />
                    <div className="mt-3">
                      <Field name={`${name}.description`} component={RichTextInput} />
                    </div>
                  </div>
                )}
              </ArrayInput>
            </div>
          </div>
        </div>
      </FormSectionTwoColumns>
      <FormSectionTwoColumns
        sectionKey="b6.natura2000"
        title="c) vliv na soustavu chráněných území Natura 2000"
      >
        <div className="sm:col-span-6 mt-3">
          <Field
            name="b6.natura2000.hasImpact"
            component={CheckboxInput}
            label="Má vliv na soustavu chráněných území Natura 2000"
          />
          {formik.values.b6.natura2000.hasImpact && (
            <div className="pl-7">
              <Field name="b6.natura2000.description" component={RichTextInput} />
            </div>
          )}
        </div>
      </FormSectionTwoColumns>
      <FormSectionTwoColumns
        sectionKey="b6.integratedPrevention"
        title="e) Zákon o integrované prevenci"
        subtitle="v případě záměrů spadajících do režimu zákona o integrované prevenci základní parametry způsobu naplnění závěrů o nejlepších dostupných technikách nebo integrované povolení, bylo-li vydáno"
      >
        <div className="sm:col-span-6 mt-3">
          <Field
            name="b6.integratedPrevention.hasImpact"
            component={CheckboxInput}
            label="Spadá do zákona o integrované prevenci"
          />
          {formik.values.b6.integratedPrevention.hasImpact && (
            <div className="pl-7">
              <Field name="b6.integratedPreventionLaw.description" component={RichTextInput} />
            </div>
          )}
        </div>
      </FormSectionTwoColumns>
      <FormSectionTwoColumns
        sectionKey="b6.protectedArea"
        title="f) navrhovaná ochranná a bezpečnostní pásma"
        subtitle="rozsah omezení a podmínky ochrany podle jiných právních předpisů"
      >
        <div className="sm:col-span-6">
          {protectedAreaSectionKeys.map((section) => (
            <div className="mt-3" key={section}>
              <Field
                name={`b6.protectedArea.sections.${section}.enabled`}
                component={CheckboxInput}
                label={formik.values.b6.protectedArea.sections[section].label}
              />
              {formik.values.b6.protectedArea.sections[section].enabled && (
                <div className="pl-7">
                  <Field
                    name={`b6.protectedArea.sections.${section}.description`}
                    component={RichTextInput}
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      </FormSectionTwoColumns>
    </>
  )
}
