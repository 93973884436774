import { useMutation, useQueryClient } from 'react-query'
import { api } from '../api'
import { ApiUrlEnum } from '../../enums/ApiUrlEnum'
import { QueryKeyEnum } from '../../enums/QueryKeyEnum'

export const useCreateTask = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (data: FormData) => api.post(`${ApiUrlEnum.TASK}`, data),
    onSuccess: () => {
      return queryClient.invalidateQueries([QueryKeyEnum.TASK])
    },
  })
}
