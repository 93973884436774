import { useQuery } from 'react-query'
import { api } from '../api'
import { ApiUrlEnum } from '../../enums/ApiUrlEnum'
import { QueryKeyEnum } from '../../enums/QueryKeyEnum'
import { IFolderEntity } from '../../interfaces/IFolderEntity'

export const useGetFolder = (id: string) => {
  return useQuery([QueryKeyEnum.FOLDERS, id], () =>
    api.get<IFolderEntity>(`${ApiUrlEnum.FOLDER}/${id}`),
  )
}
