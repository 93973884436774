import React from 'react'
import { Spinner } from '@chakra-ui/react'

interface Props {
  children: React.ReactNode
  onClick?: (event: React.MouseEvent) => void
  size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl'
  className?: string
  type?: 'button' | 'submit' | 'reset' | undefined
  isLoading?: boolean
  color?: 'primary' | 'danger'
  name?: string
  disabled?: boolean
}

export const Button: React.FC<Props> = (props) => {
  const baseClassname =
    'rounded bg-blue-600 font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600'

  const size = props.size || 'lg'
  const sizeClassname = React.useMemo(() => {
    switch (size) {
      case 'sm':
        return 'rounded bg-blue-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600'
      case 'md':
        return 'rounded bg-blue-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600'
      case 'lg':
        return 'rounded-md bg-blue-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600'
      case 'xl':
        return 'rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600'
      case '2xl':
        return 'rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600'
    }
  }, [size])

  const colorClassname = React.useMemo(() => {
    switch (props.color) {
      case 'danger':
        return 'bg-red-600 hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600'
      default:
        return 'bg-blue-600 hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600'
    }
  }, [props.color])

  const disabledClassname = React.useMemo(() => {
    return props.disabled ? 'cursor-not-allowed opacity-50' : ''
  }, [props.disabled])

  const className = React.useMemo(() => {
    return `${baseClassname} ${sizeClassname} ${colorClassname} ${disabledClassname} ${
      props.className || ''
    }`
  }, [sizeClassname, props.className, disabledClassname])

  const onClick = (e: React.MouseEvent) => {
    if (props.isLoading) {
      return
    }

    return props.onClick ? props.onClick(e) : undefined
  }

  return (
    <button name={props.name} type={props.type || 'button'} className={className} onClick={onClick}>
      {props.isLoading ? <Spinner /> : <>{props.children}</>}
    </button>
  )
}
