import React, { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import { useGetProject } from '../../model/api/project/useGetProject'
import { Link, useParams } from 'react-router-dom'
import { ProjectEntityData } from '../../model/interfaces/generated'
import { ProjectLayout } from './layout/ProjectLayout'
import { ProjectLoadingSkeleton } from './layout/ProjectLoadingSkeleton'
import { InvestorSmallCard } from '../../components/InvestorSmallCard'
import { RoutesEnum } from '../../model/enums/RoutesEnum'
import { Timeline } from '../../components/Timeline'
import { formatDate } from '../../model/helpers/dateHelpers'
import { TasksGrid } from '../Tasks/components/TasksGrid'
import { useGetCurrentUser } from '../../model/api/auth/useGetCurrentUser'
import { Button } from '../../components/ui/button'

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ')
}

export function Project() {
  const { id } = useParams<{ id: string }>()
  const { data: currentUser } = useGetCurrentUser()
  const { data: projectData, isLoading: projectIsLoading } = useGetProject(id || '')
  const project: ProjectEntityData | undefined = projectData?.data?.project

  if (typeof project === 'undefined' || projectIsLoading) {
    return <ProjectLoadingSkeleton />
  }

  return (
    <ProjectLayout project={project}>
      <main>
        <header className="relative isolate pt-16">
          <div className="absolute inset-0 -z-10 overflow-hidden" aria-hidden="true">
            <div className="absolute left-16 top-full -mt-16 transform-gpu opacity-50 blur-3xl xl:left-1/2 xl:-ml-80">
              <div
                className="aspect-[1154/678] w-[72.125rem] bg-gradient-to-br from-[#FF80B5] to-[#9089FC]"
                style={{
                  clipPath:
                    'polygon(100% 38.5%, 82.6% 100%, 60.2% 37.7%, 52.4% 32.1%, 47.5% 41.8%, 45.2% 65.6%, 27.5% 23.4%, 0.1% 35.3%, 17.9% 0%, 27.7% 23.4%, 76.2% 2.5%, 74.2% 56%, 100% 38.5%)',
                }}
              />
            </div>
            <div className="absolute inset-x-0 bottom-0 h-px bg-gray-900/5" />
          </div>

          <div className="mx-auto max-w-7xl px-4 py-10 sm:px-6 lg:px-8">
            <div className="mx-auto flex max-w-2xl items-center justify-between gap-x-8 lg:mx-0 lg:max-w-none">
              <div className="flex items-center gap-x-6">
                <img
                  src="https://tailwindui.com/img/logos/48x48/tuple.svg"
                  alt=""
                  className="h-16 w-16 flex-none rounded-full ring-1 ring-gray-900/10"
                />
                <h1>
                  <div className="text-sm leading-6 text-gray-500">
                    Projekt #{project.number} &nbsp;
                    <span className="text-gray-700">{project.name}</span>
                  </div>
                  <div className="mt-1 text-base font-semibold leading-6 text-gray-900">
                    {project.places &&
                      project.places.map((place) => {
                        const bits = [
                          place.municipality,
                          place.streetNumber,
                          place.cadastre,
                          place.parcelNumber,
                        ].filter((b) => !!b)
                        return <>{bits.join(',')}</>
                      })}
                  </div>
                </h1>
              </div>
              <div className="flex items-center gap-x-4 sm:gap-x-6">
                <Link
                  to={`${RoutesEnum.PROJECTS}/${project.id}/${RoutesEnum.SETTINGS}`}
                  className="hidden text-sm font-semibold leading-6 text-gray-900 sm:block"
                >
                  Upravit
                </Link>

                <Menu as="div" className="relative sm:hidden">
                  <Menu.Button className="-m-3 block p-3">
                    <span className="sr-only">Více</span>
                    <EllipsisVerticalIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                  </Menu.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            type="button"
                            className={classNames(
                              active ? 'bg-gray-50' : '',
                              'block w-full px-3 py-1 text-left text-sm leading-6 text-gray-900',
                            )}
                          >
                            Copy URL
                          </button>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active ? 'bg-gray-50' : '',
                              'block px-3 py-1 text-sm leading-6 text-gray-900',
                            )}
                          >
                            Edit
                          </a>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
        </header>

        <div className="mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 grid-rows-1 items-start gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            <div className="lg:col-start-3 lg:row-end-1">
              <h2 className="sr-only">Sourhn</h2>
              {project.investor && <InvestorSmallCard contactId={project.investor.id} />}
              <div className="py-3 px-2 rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5 mt-5">
                <TasksGrid
                  taskQuery={{ projectId: project.id, assignedTo: currentUser?.data?.id }}
                  minimalist={true}
                />
                <div className="flex justify-center mt-3">
                  <Link to={`${RoutesEnum.PROJECTS}/${project.id}/${RoutesEnum.TASKS}`}>
                    <Button variant="link">Všechny úkoly</Button>
                  </Link>
                </div>
              </div>
            </div>

            {/* Invoice */}
            <div className="-mx-4 px-4 py-8 shadow-sm ring-1 ring-gray-900/5 sm:mx-0 sm:rounded-lg sm:px-8 sm:pb-14 lg:col-span-2 lg:row-span-2 lg:row-end-2 xl:px-16 xl:pb-20 xl:pt-16">
              <h2 className="text-base font-semibold leading-6 text-gray-900">Projekt</h2>
              <dl className="mt-6 grid grid-cols-1 text-sm leading-6 sm:grid-cols-2">
                <div className="sm:pr-4">
                  <dt className="inline text-gray-500">Vytvořeno</dt>{' '}
                  <dd className="inline text-gray-700">
                    <time dateTime="2023-23-01">{formatDate(project.createdAt)}</time>
                  </dd>
                </div>
                <div className="mt-2 sm:mt-0 sm:pl-4">
                  <dt className="inline text-gray-500"></dt>{' '}
                  <dd className="inline text-gray-700">
                    <time dateTime="2023-31-01"></time>
                  </dd>
                </div>
              </dl>
              <div className="mt-8 sm:mt-6 sm:border-t sm:border-gray-900/5 ">
                <h2 className="mt-8 mb-8 text-base font-semibold leading-6 text-gray-900">
                  Projektová dokumentace pro společné povolení stavby
                </h2>
                <Timeline projectId={project.id} />
              </div>
            </div>

            <div className="lg:col-start-3 max-w-100"></div>
          </div>
        </div>
      </main>
    </ProjectLayout>
  )
}
