import React from 'react'
import { useProject } from '../../../../../model/hooks/useProject'
import { ProjectLoadingSkeleton } from '../../../layout/ProjectLoadingSkeleton'
import { B5_FORM_INITIAL_VALUES } from './constants'
import { TechnicalFormWrapper } from '../B2ConstructionDescription/TechnicalFormWrapper'
import { ProjectFormEnum } from '../../../../../model/enums/ProjectFormEnum'
import { B5TerrainForm } from './B5TerrainForm'

interface Props {}

type B5Keys = keyof typeof B5_FORM_INITIAL_VALUES.b5

const sections: B5Keys[] = ['terrainChange', 'usedVegetation', 'bioTech']

export const B5Terrain: React.FC<Props> = (props) => {
  const { project } = useProject()

  if (!project) {
    return <ProjectLoadingSkeleton />
  }

  return (
    <TechnicalFormWrapper
      form={ProjectFormEnum.TECHNICAL_FORM_B5}
      initialValues={B5_FORM_INITIAL_VALUES}
      title="B.5 Řešení vegetace a souvisejících terénních úprav"
    >
      <B5TerrainForm />
    </TechnicalFormWrapper>
  )
}
