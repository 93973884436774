import React from 'react'
import * as Yup from 'yup'
import Logo from '../../assets/images/logo.png'
import { Form, Formik } from 'formik'
import { useLoginMutation } from '../../model/api/auth/useLoginMutation'
import { Spinner, useToast } from '@chakra-ui/react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { RoutesEnum } from '../../model/enums/RoutesEnum'
import { LoginRequestData } from '../../model/interfaces/generated'
import { LinkButton } from '../../components/LinkButton'

const initialValues: LoginRequestData = {
  email: '',
  password: '',
}

const schema = Yup.object().shape({
  email: Yup.string().email('Email musí být ve správném formátu').required('Email je povinný'),
  password: Yup.string().required('Heslo je povinné'),
})

export const Login: React.FC = () => {
  const { isLoading, mutate } = useLoginMutation()
  const toast = useToast()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        mutate(values, {
          onSuccess: () => {
            toast({
              title: 'Přihlášení proběhlo úspěšně',
              status: 'success',
            })

            if (
              searchParams.get('redirect') &&
              !(searchParams.get('redirect') as string).includes(RoutesEnum.LOGIN)
            ) {
              navigate(searchParams.get('redirect') as string)
              return
            }

            navigate(RoutesEnum.PROJECTS)
          },
          onError: (error) => {
            toast({
              title: 'Přihlášení se nezdařilo',
              status: 'error',
              position: 'top',
            })
          },
        })
      }}
      validationSchema={schema}
    >
      {(formik) => {
        return (
          <Form>
            <div className="flex min-h-full flex-1 items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
              <div className="w-full max-w-sm space-y-10">
                <div>
                  <img className="mx-auto h-100 w-auto" src={Logo} alt="QikSolution" />
                  <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                    Přihlaste se do svého účtu
                  </h2>
                </div>
                <div className="relative -space-y-px rounded-md shadow-sm">
                  <div className="pointer-events-none absolute inset-0 z-10 rounded-md ring-1 ring-inset ring-gray-300" />
                  <div>
                    <label htmlFor="email-address" className="sr-only">
                      Emailová adresa
                    </label>
                    <input
                      id="email-address"
                      name="email"
                      type="email"
                      autoComplete="email"
                      className="relative block w-full rounded-t-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      placeholder="Email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div>
                    <label htmlFor="password" className="sr-only">
                      Heslo
                    </label>
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      className="relative block w-full rounded-b-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      placeholder="Heslo"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>

                <div>
                  <div>
                    <LinkButton onClick={() => navigate(RoutesEnum.USER_FORGOTTEN_PASSWORD)}>
                      Zapomenuté heslo?
                    </LinkButton>
                  </div>
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                  >
                    {isLoading ? <Spinner /> : 'Přihlásit se'}
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}
