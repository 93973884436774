import React from 'react'
import { FormSectionTwoColumns } from '../../../../../../components/forms/FormSectionTwoColumns'
import { Field } from 'formik'
import { TextAreaInput } from '../../../../../../components/inputs/TextAreaInput'
import { FormLabel } from '../../../../../../components/FormLabel'
import { B2_FORM_INIT_VALUES } from '../constants'
import { RichTextInput } from '../../../../../../components/inputs/RichTextInput/RichTextInput'
import { ArrayInput } from '../../../../../../components/inputs/ArrayInput'
import { TextInput } from '../../../../../../components/inputs/TextInput'
import { NumberInput } from '../../../../../../components/inputs/NumberInput'
import { ConstructionAndMaterials } from './partials/ConstructionAndMaterials'

interface Props {}

export const B26ObjectCharacteristicsSection: React.FC<Props> = () => {
  return (
    <FormSectionTwoColumns
      sectionKey="b2.objectCharacteristics"
      title="B.2.6 Základní charakteristika objektů"
    >
      <div className="sm:col-span-6">
        <Field
          name="b2.objectCharacteristics.building"
          component={TextAreaInput}
          label="a) stavební řešení"
        />
      </div>
      <div className="sm:col-span-6">
        <FormLabel>b) konstrukční a materiálové řešení</FormLabel>
        <ConstructionAndMaterials name="foundation" label="Základové konstrukce" />
        <ConstructionAndMaterials
          name="vertical"
          label="Svislé konstrukce"
          singular="Svislá konstrukce"
        />
        <ConstructionAndMaterials
          name="horizontal"
          label="Vodorovné konstrukce"
          singular="Vodorovná konstrukce"
        />
        <ConstructionAndMaterials name="roof" label="Střešní konstrukce" />
        <ConstructionAndMaterials name="isolation" label="Izolace" />
        <ConstructionAndMaterials name="fillings" label="Výplň otvorů" singular="Výplň otvoru" />
        <ConstructionAndMaterials name="other" label="Ostatní konstrukce" singular="Konstrukce" />
      </div>
      <div className="sm:col-span-6">
        <Field
          name="b2.objectCharacteristics.mechanicsAndStability"
          component={TextAreaInput}
          label="c) mechanická odolnost a stabilita"
        />
      </div>
    </FormSectionTwoColumns>
  )
}
