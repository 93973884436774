// @ts-nocheck
export function bbox(coords) {
  let minLatitude = Infinity
  let minLongitude = Infinity
  let maxLatitude = -Infinity
  let maxLongitude = -Infinity

  coords.forEach((coor) => {
    minLongitude = Math.min(coor[0], minLongitude)
    maxLongitude = Math.max(coor[0], maxLongitude)
    minLatitude = Math.min(coor[1], minLatitude)
    maxLatitude = Math.max(coor[1], maxLatitude)
  })

  return [
    [minLongitude, minLatitude],
    [maxLongitude, maxLatitude],
  ]
}
