import React, { useState } from 'react'
import { Field, FormikProps, useFormikContext } from 'formik'
import RadioInput from '../../../../../../components/inputs/RadioInput'
import { RichTextInput } from '../../../../../../components/inputs/RichTextInput/RichTextInput'
import { SelectInput } from '../../../../../../components/inputs/SelectInput'
import { DatePickerInput } from '../../../../../../components/inputs/DatePickerInput'
import { FormLabel } from '../../../../../../components/FormLabel'
import { CheckboxInput } from '../../../../../../components/inputs/CheckboxInput'
import { NumberInput } from '../../../../../../components/inputs/NumberInput'
import { FormSectionTwoColumns } from '../../../../../../components/forms/FormSectionTwoColumns'
import { B2_FORM_INIT_VALUES } from '../constants'
import { GridInput } from '../../../../../../components/inputs/GridInput'
import { values } from 'lodash'
import { ArrayInput } from '../../../../../../components/inputs/ArrayInput'

interface Props {}

export const B21CharacteristicsSection: React.FC<Props> = () => {
  const formik = useFormikContext() as FormikProps<typeof B2_FORM_INIT_VALUES>
  const { values } = formik
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)

  return (
    <FormSectionTwoColumns
      sectionKey="b2.characteristics"
      title="B.2.1 Základní charakteristika stavby a jejího užívání"
    >
      <div className="sm:col-span-6 hover:bg-gray-100 p-5 rounded-md">
        <Field
          label="a) Nova stavba nebo zmena uzivani stavby"
          name="b2.characteristics.a.newOrChange"
          component={RadioInput}
          options={[
            {
              label: 'Nova stavba',
              value: 'new',
            },
            {
              label: 'Zmena dokoncene stavby',
              value: 'change',
            },
          ]}
        />
        {values.b2.characteristics.a.newOrChange === 'change' && (
          <div className="pl-7">
            <div className="sm:col-span-6 mt-3">
              <Field
                name="b2.characteristics.a.currentState"
                label="Soucasny stav"
                component={RichTextInput}
              />
            </div>
            <div className="sm:col-span-6 mt-3">
              <Field
                name="b2.characteristics.a.technicalState"
                label="Stavebne technicky stav"
                component={RichTextInput}
              />
            </div>
            <div className="sm:col-span-6 mt-3">
              <Field
                name="b2.characteristics.a.historicalState"
                label="Stavebne historicky pruzkum"
                component={RichTextInput}
              />
            </div>
            <div className="sm:col-span-6 mt-3">
              <Field
                name="b2.characteristics.a.bearingConstructionsCheck"
                label="Staticke posouzeni nosnych konstrukci"
                component={RichTextInput}
              />
            </div>
          </div>
        )}
      </div>
      <div className="sm:col-span-6">
        <Field
          name="b2.characteristics.b.usage"
          label="b) Ucel uzivani stavby"
          component={SelectInput}
          options={[
            {
              label: 'Stavba pro bydleni - Rodinny dum',
              value: 'familyHouse',
            },
            {
              label: 'Stavba pro bydleni - Bytovy dum',
              value: 'flatHouse',
            },
            {
              label: 'Stavba pro rodinnou rekreaci',
              value: 'recreation',
            },
            {
              label: 'Stavba ubytovaciho zarizeni',
              value: 'livingQuarters',
            },
          ]}
        />
      </div>
      <div className="sm:col-span-6">
        <Field
          name="b2.characteristics.c.temporaryOrPermanent"
          component={RadioInput}
          label="c) Trvalá nebo dočasná stavba"
          options={[
            {
              label: 'Trvalá stavba',
              value: 'permanent',
            },
            {
              label: 'Dočasná stavba',
              value: 'temporary',
            },
          ]}
        />
        {values.b2.characteristics.c.temporaryOrPermanent === 'temporary' && (
          <div className="sm:col-span-6 flex gap-3 mt-3">
            <Field
              component={DatePickerInput}
              name="b2.characteristics.c.temporaryStartDate"
              selected={startDate}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              dateFormat="MM/yyyy"
              showMonthYearPicker
              placeholderText="Od"
            />
            <Field
              component={DatePickerInput}
              name="b2.characteristics.c.temporaryEndDate"
              selected={endDate}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              dateFormat="MM/yyyy"
              showMonthYearPicker
              placeholderText="Do"
            />
          </div>
        )}
      </div>
      <div className="sm:col-span-6">
        <FormLabel>d) Vyjimky</FormLabel>
        <div className="mt-3">
          <Field
            name="b2.characteristics.d.technicalExceptions"
            component={CheckboxInput}
            label="Výjimky z technických požadavků na stavbu"
          />
          {values.b2.characteristics.d.technicalExceptions && (
            <div className="pl-7">
              <div className="sm:col-span-6 mt-3">
                <Field
                  name="b2.characteristics.d.technicalExceptionsDescription"
                  label="Popis vyjimek"
                  component={RichTextInput}
                />
              </div>
            </div>
          )}
        </div>
        <div className="mt-3">
          <Field
            name="b2.characteristics.d.barrierFreeExceptions"
            component={CheckboxInput}
            label="Výjimky z technických požadavků zabezpečujících bezbariérové užívání stavby"
          />
          {values.b2.characteristics.d.barrierFreeExceptions && (
            <div className="pl-7">
              <div className="sm:col-span-6 mt-3">
                <Field
                  name="b2.characteristics.d.barrierFreeExceptionsDescription"
                  label="Popis vyjimek"
                  component={RichTextInput}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="sm:col-span-6">
        <Field
          name="b2.characteristics.e.doss"
          label="e) Podmínky dotčených orgánů DOSS"
          component={RichTextInput}
        />
      </div>
      <div className="sm:col-span-6">
        <Field
          name="b2.characteristics.f.constructionProtection"
          label="f) Ochrana stavby"
          component={CheckboxInput}
        />
        {values.b2.characteristics.f.constructionProtection && (
          <div className="pl-7">
            <div className="sm:col-span-6 mt-3">
              <Field
                name="b2.characteristics.f.constructionProtectionDescription"
                label="Popis ochrany stavby"
                component={RichTextInput}
              />
            </div>
          </div>
        )}
      </div>
      <div className="sm:col-span-6">
        <FormLabel>g) Parametry stavby</FormLabel>
        <div className="mt-3">
          <div className="rounded-xl shadow-md bg-gray-50 p-3">
            <div className="flex items-center gap-3 justify-between">
              <FormLabel>Zastavěná plocha</FormLabel>
              <Field
                name="b2.characteristics.g.buildUpArea"
                component={NumberInput}
                trailing={
                  <span>
                    m<sup>2</sup>
                  </span>
                }
              />
            </div>
            <div className="flex items-center gap-3 justify-between">
              <FormLabel>Obestavěný prostor</FormLabel>
              <Field
                name="b2.characteristics.g.enclosedArea"
                component={NumberInput}
                trailing={
                  <span>
                    m<sup>2</sup>
                  </span>
                }
                label=""
              />
            </div>
            <div className="flex items-center gap-3 justify-between">
              <FormLabel>Užitná plocha</FormLabel>
              <Field
                name="b2.characteristics.g.usableArea"
                component={NumberInput}
                trailing={
                  <span>
                    m<sup>2</sup>
                  </span>
                }
              />
            </div>
            <ArrayInput name="b2.characteristics.g.units" initializationObject={{ value: '' }}>
              {(name, row, index) => (
                <div className="flex items-center gap-3 justify-between ">
                  <FormLabel>
                    <span className="whitespace-nowrap">Velikost jednotky č. {index + 1}</span>
                  </FormLabel>
                  <Field
                    name={`${name}.value`}
                    component={NumberInput}
                    trailing={
                      <span>
                        m<sup>2</sup>
                      </span>
                    }
                  />
                </div>
              )}
            </ArrayInput>
          </div>
        </div>
      </div>
      <div className="sm:col-span-6">
        <FormLabel>h) Základní bilance stavby</FormLabel>
        <div className="mt-3">
          <Field
            name="b2.characteristics.h.constructionBalanceSubstanceConsumtion"
            component={CheckboxInput}
            label="Potřeby a spotřeby médií a hmot"
          />
          {values.b2.characteristics.h.constructionBalanceSubstanceConsumtion && (
            <div className="pl-7">
              <div className="sm:col-span-6 mt-3">
                <Field
                  name="b2.characteristics.h.constructionBalanceSubstanceConsumtionDescription"
                  placeholder="Popis"
                  component={RichTextInput}
                />
              </div>
            </div>
          )}
        </div>
        <div className="mt-3">
          <Field
            name="b2.characteristics.h.constructionBalanceRainWater"
            component={CheckboxInput}
            label="Hospodaření s dešťovou vodou"
          />
          {values.b2.characteristics.h.constructionBalanceRainWater && (
            <div className="pl-7">
              <div className="sm:col-span-6 mt-3">
                <Field
                  name="b2.characteristics.h.constructionBalanceRainWaterDescription"
                  placeholder="Popis"
                  component={RichTextInput}
                />
              </div>
            </div>
          )}
        </div>
        <div className="mt-3">
          <Field
            name="b2.characteristics.h.constructionBalanceWasteAndEmissions"
            component={CheckboxInput}
            label="Celkové produkotvané množství a druh odpadů a emisí"
          />
          {values.b2.characteristics.h.constructionBalanceWasteAndEmissions && (
            <div className="pl-7">
              <div className="sm:col-span-6 mt-3">
                <Field
                  name="b2.characteristics.h.constructionBalanceWasteAndEmissionsDescription"
                  placeholder="Popis"
                  component={RichTextInput}
                />
              </div>
            </div>
          )}
        </div>
        <div className="mt-3">
          <Field
            name="b2.characteristics.h.constructionBalanceEnergyConsumption"
            component={CheckboxInput}
            label="Třída energetické náročnosti budov"
          />
          {values.b2.characteristics.h.constructionBalanceEnergyConsumption && (
            <div className="pl-7">
              <div className="sm:col-span-6 mt-3">
                <Field
                  name="b2.characteristics.h.constructionBalanceEnergyConsumptionDescription"
                  placeholder="Popis"
                  component={RichTextInput}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="sm:col-span-6">
        <FormLabel>i) Základní předpoklady výstavby</FormLabel>
        <div className="mt-3">
          <Field
            label="Termín zahájení"
            name="b2.characteristics.i.startDate"
            component={DatePickerInput}
          />
        </div>
        <div className="mt-3">
          <Field
            label="Termín dokončení"
            name="b2.characteristics.i.endDate"
            component={DatePickerInput}
          />
        </div>
        <div className="mt-3">
          <Field
            label="Členění na etapy"
            name="b2.characteristics.i.divisionToStages"
            component={RichTextInput}
          />
        </div>
      </div>
      <div className="sm:col-span-6">
        <Field
          name="b2.characteristics.j.estimatedCosts"
          component={NumberInput}
          label="j) Orientační náklady stavby bez DPH"
          trailing="Kč"
        />
      </div>
    </FormSectionTwoColumns>
  )
}
