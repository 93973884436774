import { useMutation, useQueryClient } from 'react-query'
import { api } from '../api'
import { useToast } from '@chakra-ui/react'
import { ApiUrlEnum } from '../../enums/ApiUrlEnum'
import { ProjectFormEnum } from '../../enums/ProjectFormEnum'
import { QueryKeyEnum } from '../../enums/QueryKeyEnum'

export const useSubmitForPreviewProjectFormMutation = () => {
  const toast = useToast()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (data: { id: string; form: ProjectFormEnum }) =>
      api.post(`${ApiUrlEnum.PROJECT_FORM}/${data.id}/technical-form/publish/${data.form}`),
    onSuccess: () => {
      toast({
        title: 'Náhled vytvořen',
        position: 'top',
        status: 'success',
      })
      queryClient.invalidateQueries([QueryKeyEnum.PROJECT_FORMS])
    },
  })
}
