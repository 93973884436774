import React, { useContext } from 'react'
import { FieldProps } from 'formik'
import { getFieldValidity } from '../../model/helpers/formHelpers'
import { IsDisabledContext } from '../../model/context/IsDisabledContext'

interface Props extends FieldProps {
  label?: string
  placeholder?: string
  wrapperClassname?: string
  type?: string
  disabled?: boolean
}

export const TextInput: React.FC<Props> = (props) => {
  const { isInvalid, message } = getFieldValidity(props.form, props.field.name)
  const disabledContext = useContext(IsDisabledContext)
  const isDisabled = props.disabled || disabledContext

  return (
    <div className={props.wrapperClassname}>
      {props.label && (
        <label
          htmlFor={props.field.name}
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          {props.label}
          {isInvalid && <span className="text-red-600 ml-3">{message}</span>}
        </label>
      )}
      <div className="mt-2">
        {isDisabled ? (
          <p>{props.field.value}</p>
        ) : (
          <input
            disabled={isDisabled}
            type={props.type || 'text'}
            name={props.field.name}
            id={props.field.name}
            value={props.field.value}
            className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ${
              isInvalid ? 'ring-red-500' : 'ring-gray-300'
            } ring-1 ring-inset  placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6`}
            placeholder={props.placeholder}
            onChange={props.field.onChange}
          />
        )}
      </div>
    </div>
  )
}
