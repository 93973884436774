import React from 'react'
import { Skeleton, Stack } from '@chakra-ui/react'
import { BasicLayout } from '../../../components/BasicLayout'

interface Props {}

export const ProjectLoadingSkeleton: React.FC<Props> = (props) => {
  return (
    <BasicLayout title="">
      <Stack>
        <Skeleton height="20px" />
        <Skeleton height="20px" />
        <Skeleton height="20px" />
        <Skeleton height="20px" />
        <Skeleton height="20px" />
        <Skeleton height="20px" />
        <Skeleton height="20px" />
        <Skeleton height="20px" />
        <Skeleton height="20px" />
      </Stack>
    </BasicLayout>
  )
}
