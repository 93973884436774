import React, { PropsWithChildren } from 'react'

interface Props extends PropsWithChildren {
  htmlFor?: string
}

export const FormLabel: React.FC<Props> = (props) => {
  return (
    <label htmlFor={props.htmlFor} className="block text-sm font-semibold leading-6 text-gray-900">
      {props.children}
    </label>
  )
}
