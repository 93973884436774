import * as React from 'react'
import { Minus, Plus, TriangleAlertIcon, TriangleIcon } from 'lucide-react'

import { Button as QikButton } from 'src/components/Button'
import { Button } from 'src/components/ui/button'
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from 'src/components/ui/drawer'
import { Field, Form, Formik } from 'formik'
import { TextInput } from '../../../components/inputs/TextInput'
import { SelectInput } from '../../../components/inputs/SelectInput'
import { UserRoleEnum } from '../../../model/enums/UserRoleEnum'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { inviteUserSchema } from 'shared'
import { useUpdateUserRoleMutation } from '../../../model/api/auth/useUpdateUserRoleMutation'
import { useToast } from '@chakra-ui/react'
import { useInviteUserMutation } from '../../../model/api/user/useInviteUserMutation'

export const InviteUserDrawer: React.FC = () => {
  const toast = useToast()
  const { mutateAsync: inviteUserMutation, isLoading: isInvitingUser } = useInviteUserMutation()

  return (
    <Formik
      initialValues={{
        email: '',
        role: { value: UserRoleEnum.USER },
      }}
      validationSchema={toFormikValidationSchema(inviteUserSchema)}
      onSubmit={async (values) => {
        try {
          toast({
            title: 'Role uživatele byla změněna',
            status: 'success',
            duration: 5000,
            isClosable: true,
          })
        } catch (err) {
          toast({
            title: 'Chyba',
            description: 'Nepodařilo se změnit roli uživatele',
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
        }
      }}
    >
      {(formik) => {
        return (
          <Form>
            <Drawer>
              <DrawerTrigger asChild>
                <QikButton>Pozvat uživatele</QikButton>
              </DrawerTrigger>
              <DrawerContent>
                <div className="mx-auto w-full max-w-sm">
                  <DrawerHeader>
                    <DrawerTitle>Pozvat nového uživatele</DrawerTitle>
                    <DrawerDescription>
                      <div className="flex gap-3 align-middle">
                        <TriangleAlertIcon size={24} />
                        Prozatím nelze pozvat další uživatele.
                      </div>
                    </DrawerDescription>
                  </DrawerHeader>
                  <div className="p-4 pb-0">
                    <div className="flex items-center justify-center space-x-2">
                      <div className="w-full flex flex-col gap-3">
                        <Field
                          wrapperClassname="w-full"
                          name="email"
                          label="Email"
                          component={TextInput}
                        />
                        <Field
                          wrapperClassname="w-full"
                          forbidCustom={true}
                          name="role"
                          label="Role"
                          component={SelectInput}
                          options={[
                            {
                              label: 'Administrátor',
                              value: UserRoleEnum.ADMIN,
                            },
                            {
                              label: 'Běžný uživatel',
                              value: UserRoleEnum.USER,
                            },
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                  <DrawerFooter>
                    <Button disabled={true} onClick={(e) => formik.handleSubmit()} type="submit">
                      Pozvat
                    </Button>
                    <DrawerClose asChild>
                      <Button variant="outline">Zrušit</Button>
                    </DrawerClose>
                  </DrawerFooter>
                </div>
              </DrawerContent>
            </Drawer>
          </Form>
        )
      }}
    </Formik>
  )
}
