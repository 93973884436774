import React from 'react'
import { ContactEntityData } from '../model/interfaces/generated'
import { HoverCard, HoverCardContent, HoverCardTrigger } from './ui/hover-card'
import { cn } from '../lib/utils'
import { PersonIcon } from '@radix-ui/react-icons'
import { contactToString, contactTypeToLabel } from '../model/helpers/contactHelpers'
import { ContactFormModal } from './modals/ContactFormModal'
import { useDisclosure } from '@chakra-ui/react'
import { Button } from './ui/button'
import { IsDisabledContext } from '../model/context/IsDisabledContext'

interface Props {
  contact: ContactEntityData
  minimalist?: boolean
  className?: string
}

export const ContactAvatar: React.FC<Props> = ({ contact, minimalist, className }) => {
  const disclosure = useDisclosure()
  return (
    <>
      <IsDisabledContext.Provider value={false}>
        <ContactFormModal disclosure={disclosure} editContact={contact} />
      </IsDisabledContext.Provider>
      <HoverCard>
        <HoverCardTrigger asChild>
          <div className={cn('flex gap-2 items-center hover:underline', className)}>
            <div className="rounded-full bg-gray-200 p-1">
              <PersonIcon className="h-4 w-4 text-gray-600" />
            </div>
            {!minimalist && <span className="text-sm">{contactToString(contact)}</span>}
          </div>
        </HoverCardTrigger>
        <HoverCardContent>
          <div className="flex gap-3">
            <div className="rounded-full bg-blue-600 p-1 h-6 w-6">
              <PersonIcon className="h-4 w-4 text-white" />
            </div>
            <div className="flex flex-col gap-1">
              <span className="text-sm font-bold">{contactToString(contact)}</span>
              <span className="text-sm">{contactTypeToLabel(contact.type)}</span>
              {contact.email && <span className="text-sm">{contact.email}</span>}
              {contact.phone && <span className="text-sm">{contact.phone}</span>}
              <Button className="mt-3" variant="outline" type="button" onClick={disclosure.onOpen}>
                Detail
              </Button>
            </div>
          </div>
        </HoverCardContent>
      </HoverCard>
    </>
  )
}
