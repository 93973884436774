import React from 'react'
import { useProject } from '../../../../model/hooks/useProject'
import { useGetLatestConstructionDiaryEntries } from '../../../../model/api/constructionDiary/useGetLatestConstructionDiaryEntries'
import { ConstructionDiaryGridRow } from './ConstructionDiaryGridRow'

interface Props {}

export const ConstructionDiaryGrid: React.FC<Props> = (props) => {
  const { id: projectId } = useProject()
  const { data: lastEntries } = useGetLatestConstructionDiaryEntries(projectId)

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
      {lastEntries?.data?.map((entry: any) => (
        <ConstructionDiaryGridRow entry={entry} key={entry.date} />
      ))}
    </div>
  )
}
