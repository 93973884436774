import { QueryKeyEnum } from '../../enums/QueryKeyEnum'
import { useMutation, useQueryClient } from 'react-query'
import { api } from '../api'
import { ApiUrlEnum } from '../../enums/ApiUrlEnum'

export const useDeleteProjectFormRichTextHelpMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (id: string) => api.delete(`${ApiUrlEnum.PROJECT_FORM_RICH_TEXT}/${id}`),
    onSuccess: () => {
      return queryClient.invalidateQueries(QueryKeyEnum.PROJECT_FORM_RICH_TEXT)
    },
  })
}
