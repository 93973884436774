import { useMutation, useQueryClient } from 'react-query'
import { api } from '../api'
import { ApiUrlEnum } from '../../enums/ApiUrlEnum'
import { QueryKeyEnum } from '../../enums/QueryKeyEnum'

export const useDeleteRoomMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ projectId, roomId }: { projectId: string; roomId: string }) =>
      api.delete(`${ApiUrlEnum.PROJECT}/${projectId}${ApiUrlEnum.ROOM}/${roomId}`),
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyEnum.OBJECTS])
      queryClient.invalidateQueries([QueryKeyEnum.ROOMS])
    },
  })
}
