import React from 'react'
import { RoutesEnum } from '../../../../model/enums/RoutesEnum'
import { ShortWeatherCard } from '../../../../components/ShortWeatherCard'
import moment from 'moment/moment'
import { ClipboardSignatureIcon, UserIcon, XIcon } from 'lucide-react'
import { AuthImage } from '../../../../components/AuthImage'
import { Link } from 'react-router-dom'
import { useProject } from '../../../../model/hooks/useProject'
import { getDayOfWeek } from '../../../../model/helpers/dateHelpers'
import {Avatar} from "../../../../components/Avatar";

interface Props {
  entry: any
}

export const ConstructionDiaryGridRow: React.FC<Props> = ({ entry }) => {
  const { id: projectId } = useProject()
  const dateMoment = moment(entry.date)
  const significantPerson = entry.signedBy || entry.createdBy

  return (
    <Link
      to={`${RoutesEnum.PROJECTS}/${projectId}/${RoutesEnum.CONSTRUCTION_DIARY}/${RoutesEnum.CONSTRUCTION_DIARY_ENTRY}/${entry.date}`}
      key={entry.id}
    >
      <div className="cursor-pointer bg-gray-50 rounded-lg p-3 gap-3 hover:bg-gray-100 hover:shadow group text-gray-700">
        <div className="flex">
          <ShortWeatherCard weatherData={entry.weather} />
          <div className="flex flex-col gap-1">
            <div className="text-md font-bold flex gap-1 items-center">
              {getDayOfWeek(dateMoment.day())} {dateMoment.format('DD. MM. YYYY')}
            </div>
            {entry.signedBy ? (
              <div className="text-md flex gap-1 items-center">
                <ClipboardSignatureIcon className="text-green-600" size={20} />
                Podepsáno
              </div>
            ) : (
              <div className="text-md flex gap-1 items-center">
                <XIcon className="text-red-600" size={20} />
                Ceka na podpis
              </div>
            )}
            <div className="text-md flex gap-1 items-center">
              <Avatar name={significantPerson.name} />
              {significantPerson.name}
            </div>
            <div className="flex -space-x-2 overflow-hidden mt-1">
              {entry?.photos
                ?.slice(0, 4)
                .map((photo: any) => (
                  <AuthImage
                    alt=""
                    image={photo}
                    className="inline-block h-8 w-8 rounded-full ring-2 ring-white object-cover"
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}
