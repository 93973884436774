import React from 'react'
import { useProject } from '../../../../model/hooks/useProject'
import { ProjectLoadingSkeleton } from '../../layout/ProjectLoadingSkeleton'
import { ProjectLayout } from '../../layout/ProjectLayout'
import { TechnicalReportFormsList } from './TechnicalReportFormsList'
import { SectionHeading } from '../../../../components/SectionHeading'
import { Button } from '../../../../components/Button'
import { DocumentIcon } from '@heroicons/react/24/outline'
import { GenerateReportAction } from './GenerateReportAction'

interface Props {}

export const TechnicalReportForm: React.FC<Props> = (props) => {
  const { project } = useProject()

  if (!project) {
    return <ProjectLoadingSkeleton />
  }

  return (
    <ProjectLayout project={project}>
      <SectionHeading
        title="B) Souhrnná technická zpráva"
        buttons={
          <>
            <GenerateReportAction projectId={project.id} />
          </>
        }
      />
      <TechnicalReportFormsList projectId={project.id} />
    </ProjectLayout>
  )
}
