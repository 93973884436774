import { useQuery } from 'react-query'
import { api } from '../api'
import { IMunicipalityEntity } from '../../interfaces/IMunicipalityEntity'

export const useGetMunicipality = (id: string) => {
  return useQuery({
    queryKey: ['municipality', id],
    queryFn: () => api.get<IMunicipalityEntity>(`/municipality/${id}`),
    keepPreviousData: true,
    staleTime: 500,
    enabled: !!id,
  })
}
