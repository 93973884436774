import { Field, FormikProps } from 'formik'
import React from 'react'
import { ACCOMPANYING_REPORT_FORM_INITIAL_DATA } from './constants'
import { Button } from '../../../../components/Button'
import { MinusIcon } from '@chakra-ui/icons'
import { PlusIcon } from '@heroicons/react/24/outline'
import { SecondaryButton } from '../../../../components/SecondaryButton'
import { TextInput } from '../../../../components/inputs/TextInput'
import { removeElement } from '../../../../model/helpers/arrayHelpers'
import { generateRandomString } from '../../../../model/helpers/stringHelpers'
import { Divider } from '../../../../components/Divider'

interface Props {
  formik: FormikProps<typeof ACCOMPANYING_REPORT_FORM_INITIAL_DATA>
}

export const AccompanyingReportA2: React.FC<Props> = ({ formik }) => {
  return (
    <>
      <Divider title="A.2 Členění stavby" className="mt-8" />
      {formik.values.a2.structuralDivisions.map((division, divisionIndex) => (
        <div key={division.indexIdentifier} className="mt-3 grid grid-cols-1 sm:grid-cols-3 gap-8">
          <div>
            <Field
              wrapperClassName="w-full"
              label="Značení"
              name={`a2.structuralDivisions[${divisionIndex}].code`}
              component={TextInput}
            />
          </div>
          <div className="col-span-1 sm:col-span-2 flex gap-3">
            <Field
              wrapperClassname="w-full"
              label="Popis"
              name={`a2.structuralDivisions[${divisionIndex}].description`}
              component={TextInput}
            />
            <SecondaryButton
              className="mt-8 h-9"
              onClick={() =>
                formik.setFieldValue(
                  'a2.structuralDivisions',
                  removeElement(formik.values.a2.structuralDivisions, divisionIndex),
                )
              }
            >
              <MinusIcon className="h-5 w-5" />
            </SecondaryButton>
          </div>
        </div>
      ))}
      <div className="flex justify-end mt-3 gap-3">
        <Button
          onClick={() =>
            formik.setFieldValue('a2.structuralDivisions', [
              ...formik.values.a2.structuralDivisions,
              { indexIdentifier: generateRandomString() },
            ])
          }
        >
          <PlusIcon className="h-5 w-5" />
        </Button>
      </div>
    </>
  )
}
