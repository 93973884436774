import { useMutation, useQueryClient } from 'react-query'
import { api } from '../api'
import { ApiUrlEnum } from '../../enums/ApiUrlEnum'
import { QueryKeyEnum } from '../../enums/QueryKeyEnum'

export const useDeleteObjectMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ projectId, objectId }: { projectId: string; objectId: string }) =>
      api.delete(`${ApiUrlEnum.PROJECT}/${projectId}${ApiUrlEnum.OBJECT}/${objectId}`),
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyEnum.OBJECTS])
    },
  })
}
