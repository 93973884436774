import { useMutation, useQueryClient } from 'react-query'
import { api } from '../api'
import { ApiUrlEnum } from '../../enums/ApiUrlEnum'
import { QueryKeyEnum } from '../../enums/QueryKeyEnum'

export const useCreateObjectMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: { name: string; projectId: string }) =>
      api.post(`${ApiUrlEnum.PROJECT}/${data.projectId}${ApiUrlEnum.OBJECT}`, { name: data.name }),
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyEnum.OBJECTS])
    },
  })
}
