import { useQuery } from 'react-query'
import { api } from '../api'
import { ContactEntityData } from '../../interfaces/generated'
import { ApiUrlEnum } from '../../enums/ApiUrlEnum'

export const useGetContacts = () => {
  return useQuery('contacts', async () =>
    api.get<{ contacts: ContactEntityData[] }>(ApiUrlEnum.CONTACT),
  )
}
