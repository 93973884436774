import React from 'react'
import { AuthImage } from '../../../../components/AuthImage'
import { PhotosGridItem } from './PhotosGridItem'
import { PhotoModal } from './PhotoModal'

interface Props {
  photos: Array<any>
}

export const PhotosGrid: React.FC<Props> = ({ photos }) => {
  const [photoInFocus, setPhotoInFocus] = React.useState<any>(undefined)

  return (
    <>
      <PhotoModal
        open={typeof photoInFocus !== 'undefined'}
        onClose={() => setPhotoInFocus(undefined)}
        photo={photoInFocus}
      />
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-5">
        {photos.map((photo: any, index: number) => (
          <div className="cursor-pointer" key={index} onClick={() => setPhotoInFocus(photo)}>
            <PhotosGridItem photo={photo} />
          </div>
        ))}
      </div>
    </>
  )
}
