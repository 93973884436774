import React from 'react'
import { FormSectionTwoColumns } from '../../../../../../components/forms/FormSectionTwoColumns'
import { B2_FORM_INIT_VALUES } from '../constants'
import { Field, FormikProps, useFormikContext } from 'formik'
import { RichTextInput } from '../../../../../../components/inputs/RichTextInput/RichTextInput'
import { TextInput } from '../../../../../../components/inputs/TextInput'
import { ArrayForm } from '../../../../../../components/ArrayForm'
import { ArrayInput } from '../../../../../../components/inputs/ArrayInput'

interface Props {}

export const B210HygieneSection: React.FC<Props> = (props) => {
  const formik = useFormikContext() as FormikProps<typeof B2_FORM_INIT_VALUES>

  return (
    <FormSectionTwoColumns
      sectionKey={'b2.hygiene'}
      title="B.2.10 Hygienické požadavky na stavby, požadavky na pracovní a komunální prostředí"
    >
      <div className="sm:col-span-6">
        <Field name="b2.hygiene.air" component={RichTextInput} label="Větrání" />
      </div>
      <div className="sm:col-span-6">
        <Field
          name="b2.hygiene.heatingAndCooling"
          component={RichTextInput}
          label="Vytápění a chlazení"
        />
      </div>
      <div className="sm:col-span-6">
        <Field name="b2.hygiene.lighting" component={RichTextInput} label="Osvětlení" />
      </div>
      <div className="sm:col-span-6">
        <Field name="b2.hygiene.waterSupply" component={RichTextInput} label="Zásobování vodou" />
      </div>
      <div className="sm:col-span-6">
        <Field name="b2.hygiene.waste" component={RichTextInput} label="Odpady" />
      </div>
      <div className="sm:col-span-6">
        <Field
          name="b2.hygiene.buildingImpactVibration"
          component={RichTextInput}
          label="Vliv stavby na okolí - vibrace"
        />
      </div>
      <div className="sm:col-span-6">
        <Field
          name="b2.hygiene.buildingImpactNoise"
          component={RichTextInput}
          label="Vliv stavby na okolí - hluk"
        />
      </div>
      <div className="sm:col-span-6">
        <Field
          name="b2.hygiene.buildingImpactDust"
          component={RichTextInput}
          label="Vliv stavby na okolí - prašnost"
        />
      </div>
      <ArrayInput name="b2.hygiene.others" initializationObject={{ name: '', description: '' }}>
        {(name, row, index) => (
          <div className="sm:col-span-6">
            <Field name={`${name}.name`} component={TextInput} label={`Název`} />
            <div className="mt-3">
              <Field name={`${name}.description`} component={RichTextInput} label={`Popis`} />
            </div>
          </div>
        )}
      </ArrayInput>
    </FormSectionTwoColumns>
  )
}
