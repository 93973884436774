import { useMutation, useQueryClient } from 'react-query'
import { api } from '../api'
import { ApiUrlEnum } from '../../enums/ApiUrlEnum'

export const useDeleteTask = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (id: string) => api.delete(`${ApiUrlEnum.TASK}/${id}`),
    onSuccess: () => {
      return queryClient.invalidateQueries([ApiUrlEnum.TASK])
    },
  })
}
