import { useQuery } from 'react-query'
import { ApiUrlEnum } from '../../enums/ApiUrlEnum'
import { api } from '../api'
import { IProjectFormRichText, ProjectFormEnum } from 'shared'
import queryString from 'query-string'

export const useGetProjectFormRichTextHelp = (key: string, formEnum: ProjectFormEnum) => {
  const query = queryString.stringify({ key, formEnum })

  return useQuery({
    queryFn: () => api.get<IProjectFormRichText[]>(`${ApiUrlEnum.PROJECT_FORM_RICH_TEXT}?${query}`),
    queryKey: [ApiUrlEnum.PROJECT_FORM_RICH_TEXT, formEnum, key],
    enabled: false,
  })
}
