import { useQuery } from 'react-query'
import { api } from '../api'
import { QueryKeyEnum } from '../../enums/QueryKeyEnum'
import { ApiUrlEnum } from '../../enums/ApiUrlEnum'
import { PaginationParams } from 'shared'
import queryString from 'query-string'

export const useGetPhotoDocumentation = (projectId: string, pagination: PaginationParams) => {
  const query = queryString.stringify(pagination, {
    skipEmptyString: true,
    skipNull: true,
  })

  return useQuery({
    queryFn: () =>
      api.get(`${ApiUrlEnum.PROJECT}/${projectId}${ApiUrlEnum.PHOTO_DOCUMENTATION}?${query}`),
    queryKey: [QueryKeyEnum.PHOTO_DOCUMENTATION, projectId, query],
  })
}
