import React from 'react'
import { BasicLayout } from '../../components/BasicLayout'
import {
  ReactGrid,
  Column,
  Row,
  CellChange,
  TextCell,
  Id,
  MenuOption,
  SelectionMode,
  CellLocation,
} from '@silevis/reactgrid'
import '@silevis/reactgrid/styles.css'
import { Field, Form, Formik } from 'formik'
import { GridInput } from '../../components/inputs/GridInput'

interface Person {
  name: string
  surname: string
  nextColumn?: string
}

const getPeople = (): Person[] => [
  { name: 'Thomas', surname: 'Goldman' },
  { name: 'Susie', surname: 'Quattro' },
  { name: '', surname: '' },
]

const getColumns = (): Column[] => [
  { columnId: 'name', width: 150 },
  { columnId: 'surname', width: 150 },
  { columnId: 'nextColumn', width: 150 },
]

const headerRow: Row = {
  rowId: 'header',
  cells: [
    { type: 'header', text: 'Name' },
    { type: 'header', text: 'Surname' },
    { type: 'header', text: 'nextColumn' },
  ],
}

const getRows = (people: Person[]): Row[] => [
  headerRow,
  ...people.map<Row>((person, idx) => ({
    rowId: idx,
    cells: [
      { type: 'text', text: person.name },
      { type: 'text', text: person.surname },
      { type: 'text', text: person.nextColumn || '' },
    ],
  })),
]

const applyChangesToPeople = (changes: CellChange<TextCell>[], prevPeople: Person[]): Person[] => {
  changes.forEach((change) => {
    const personIndex = change.rowId
    const fieldName = change.columnId
    ;(prevPeople[personIndex as any] as any)[fieldName] = change.newCell.text
  })
  return [...prevPeople]
}
export const Playground: React.FC<{}> = (props) => {
  const [people, setPeople] = React.useState<Person[]>(getPeople())

  const rows = getRows(people)
  const columns = getColumns()

  const handleChanges = (changes: CellChange<TextCell>[]) => {
    console.log({ changes })
    setPeople((prevPeople) => applyChangesToPeople(changes, prevPeople))
  }

  const simpleHandleContextMenu = (
    selectedRowIds: Id[],
    selectedColIds: Id[],
    selectionMode: SelectionMode,
    menuOptions: MenuOption[],
  ): MenuOption[] => {
    console.log({ selectedRowIds, selectedColIds, selectionMode, menuOptions })
    return [
      {
        id: 'test',
        label: 'Custom menu option',
        handler: (
          selectedRowIds: Id[],
          selectedColIds: Id[],
          selectionMode: SelectionMode,
          selectedRanges: Array<CellLocation[]>,
        ) => {
          console.log({ selectedRowIds, selectedColIds, selectionMode, selectedRanges })
        },
      },
    ]
  }

  return (
    <>
      <Formik
        initialValues={{
          grid: [
            {
              name: '0',
            },
          ],
        }}
        onSubmit={() => {}}
      >
        <Form>
          <Field
            name="grid"
            component={GridInput}
            columns={[
              {
                columnId: 'name',
                width: 150,
                label: 'Nazev',
              },
            ]}
          />
        </Form>
      </Formik>
      {/*<ReactGrid*/}
      {/*  rows={rows}*/}
      {/*  columns={columns}*/}
      {/*  onCellsChanged={handleChanges as any}*/}
      {/*  onContextMenu={simpleHandleContextMenu as any}*/}
      {/*/>*/}
    </>
  )
}
