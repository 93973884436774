import React from 'react'
import { useGetCurrentUser } from '../../model/api/auth/useGetCurrentUser'
import { Spinner, useToast } from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik'
import { FormLabel } from '../../components/FormLabel'
import { TextInput } from '../../components/inputs/TextInput'
import { Button } from '../../components/Button'
import { useUpdateUserMutation } from '../../model/api/user/useUpdateUserMutation'
import { useResetPasswordMutation } from '../../model/api/auth/useResetPasswordMutation'

interface Props {}

export const UserProfileDetail: React.FC<Props> = () => {
  const toast = useToast()
  const { data: userData, isLoading: isLoadingUser } = useGetCurrentUser()
  const { mutate: updateUserMutate, isLoading: isUpdatingUser } = useUpdateUserMutation()
  const { mutateAsync: resetPasswordMutate, isLoading: isResetingPassword } =
    useResetPasswordMutation()

  if (isLoadingUser || !userData) {
    return (
      <div>
        <Spinner />
      </div>
    )
  }

  const user = userData.data

  const resetPassword = async () => {
    await resetPasswordMutate({})
    toast({
      title: 'Email s obnovou hesla byl odeslán',
      status: 'success',
      duration: 5000,
      isClosable: true,
    })
  }

  return (
    <div className="space-y-12">
      <Formik
        initialValues={{ name: user.name }}
        onSubmit={async (values) => {
          console.log('Called')
          updateUserMutate(values)
        }}
      >
        {(formik) => (
          <Form>
            <div className="border-b border-gray-900/10 pb-12">
              <h2 className="text-base font-semibold leading-7 text-gray-900">{user.name}</h2>
              <p className="mt-1 text-sm leading-6 text-gray-600">
                Přihlašovací email {user.email} nelze v tuto chvíli změnit.
              </p>

              <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2">
                <div className="sm:col-span-1">
                  <FormLabel>Jméno</FormLabel>
                  <Field component={TextInput} name="name" />
                </div>
              </div>

              {user.name !== formik.values.name && (
                <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2">
                  <div className="sm:col-span-1">
                    <Button isLoading={isUpdatingUser} type="submit">
                      Uložit
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
      <div className="pb-12">
        <h2 className="text-base font-semibold leading-7 text-gray-900">Změna hesla</h2>
        <p className="mt-1 text-sm leading-6 text-gray-600">
          Pro změnu hesla použíjte odkaz v emailu, který Vám zašleme po kliknutí na tlačítko níže.
        </p>

        <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="sm:col-span-4">
            <Button onClick={resetPassword} isLoading={isResetingPassword} type="button">
              Odeslat email s obnovou hesla
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
