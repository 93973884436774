import { CalendarCheck } from 'lucide-react'
import moment from 'moment'
import React from 'react'
import { TaskResponse } from 'shared'
import { UserAvatar } from '../../../components/UserAvatar'
import { cn } from '../../../lib/utils'
import { ProjectAvatar } from '../../../components/ProjectAvatar'
import { Checkbox } from '../../../components/catalyst/checkbox'
import { useMarkTaskAsDone } from '../../../model/api/task/useMarkTaskAsDone'
import { Tooltip, TooltipContent, TooltipTrigger } from '../../../components/ui/tooltip'

interface Props {
  task: TaskResponse
  onTaskSelected: (task: TaskResponse) => void
  minimalist?: boolean
}

export const TaskRow: React.FC<Props> = (props) => {
  const isDueDateInPast = moment(props.task.due).isBefore(moment(), 'day')
  const { mutateAsync: markAsDone } = useMarkTaskAsDone()

  const handleMarkAsDone = async (checked: boolean) => {
    await markAsDone({ id: props.task.id, done: checked })
  }

  return (
    <div
      onClick={() => props.onTaskSelected(props.task)}
      className="text-left text-sm w-full flex flex-col md:flex-row justify-between gap-2 md:gap -3 hover:bg-gray-50 rounded-md p-2 cursor-pointer"
    >
      <div className="flex gap-3">
        <div className="flex items-center gap-3">
          <Checkbox
            color="blue"
            defaultChecked={!!props.task.doneAt}
            onClick={(e) => {
              e.stopPropagation()
              return handleMarkAsDone(!props.task.doneAt)
            }}
          />
          <p>{props.task.title}</p>
        </div>
      </div>
      <div className={cn('flex text-sm ml-7 md:ml-0', props.minimalist ? 'gap-1' : 'gap-3')}>
        {props.task.project && (
          <ProjectAvatar minimalist={props.minimalist} project={props.task.project} />
        )}
        {props.task.due && (
          <div
            className={cn(
              'flex gap-1 items-center',
              isDueDateInPast ? 'text-red-500 font-semibold ' : 'text-gray-900',
            )}
          >
            <Tooltip>
              <TooltipTrigger>
                <CalendarCheck className="h-5 w-5" />
              </TooltipTrigger>
              <TooltipContent>
                <span>{moment(props.task.due).format('DD. MM. YYYY')}</span>
              </TooltipContent>
            </Tooltip>
            {!props.minimalist && <span>{moment(props.task.due).format('DD. MM. YYYY')}</span>}
          </div>
        )}
        {props.task.assignedTo && (
          <div className="flex gap-1 items-center">
            <UserAvatar minimalist={props.minimalist} user={props.task.assignedTo} />
          </div>
        )}
      </div>
    </div>
  )
}
