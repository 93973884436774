import {useMutation, useQueryClient} from "react-query";
import {QueryKeyEnum} from "../../enums/QueryKeyEnum";
import {api} from "../api";
import {ApiUrlEnum} from "../../enums/ApiUrlEnum";

export const useCreateUpdateConstructionDiaryEntryMutation = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (data: FormData) => api.post(`${ApiUrlEnum.PROJECT}/${data.get('projectId')}${ApiUrlEnum.CONSTRUCTION_DIARY}/${data.get('date')}`, data),
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeyEnum.CONSTRUCTION_DIARY)
    },
  })
}
