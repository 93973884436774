import React from 'react'
import { B2_FORM_INIT_VALUES } from '../constants'
import { FormSectionTwoColumns } from '../../../../../../components/forms/FormSectionTwoColumns'
import { Field, FormikProps, useFormikContext } from 'formik'
import { CheckboxInput } from '../../../../../../components/inputs/CheckboxInput'
import RadioInput from '../../../../../../components/inputs/RadioInput'
import { TextAreaInput } from '../../../../../../components/inputs/TextAreaInput'
import { values } from 'lodash'

interface Props {}

export const B24BarrierFreeUsageSection: React.FC<Props> = () => {
  const formik = useFormikContext() as FormikProps<typeof B2_FORM_INIT_VALUES>

  return (
    <FormSectionTwoColumns
      sectionKey="b2.barrierFreeUsage"
      title="B.2.4 Bezbariérové užívání stavby  "
    >
      <div className="sm:col-span-6">
        <Field
          name="b2.barrierFreeUsage.isBarrierFree"
          component={RadioInput}
          options={[
            {
              label: 'Ano',
              value: true,
            },
            {
              label: 'Ne',
              value: false,
            },
          ]}
        />
        {formik.values.b2.barrierFreeUsage.isBarrierFree && (
          <div className="mt-3">
            <Field
              name="b2.barrierFreeUsage.description"
              component={TextAreaInput}
              placeholder="Popis"
            />
          </div>
        )}
      </div>
    </FormSectionTwoColumns>
  )
}
