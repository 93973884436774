import React from 'react'
import { BasicLayout } from 'src/components/BasicLayout'
import { TasksGrid } from './components/TasksGrid'

interface Props {}

export const Tasks: React.FC<Props> = () => {
  return (
    <BasicLayout title="Úkoly">
      <TasksGrid />
    </BasicLayout>
  )
}
