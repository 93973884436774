import React from 'react'
import { useProject } from '../../../../../model/hooks/useProject'
import { ProjectLoadingSkeleton } from '../../../layout/ProjectLoadingSkeleton'
import { ProjectLayout } from '../../../layout/ProjectLayout'
import { Button } from '../../../../../components/ui/button'
import { ProjectSimpleForm } from '../ProjectSimpleForm'

interface Props {}

export const BasicInformation: React.FC<Props> = (props) => {
  const { project } = useProject()

  if (!project) {
    return <ProjectLoadingSkeleton />
  }

  return (
    <ProjectLayout project={project}>
      <div>
        <div className="flex flex-col md:flex-row flex-wrap justify-between items-center gap-6 sm:flex-nowrap ">
          <h1 className="text-base font-semibold leading-7 text-gray-900">
            Základní informace o projektu
          </h1>
          <div className="md:w-auto w-full"></div>
        </div>
        <ProjectSimpleForm project={project} />
      </div>
    </ProjectLayout>
  )
}
