import React, { Fragment, useMemo } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import {
  Bars3Icon,
  BellIcon,
  BoltIcon,
  BookmarkIcon,
  CogIcon,
  FolderIcon,
  HomeIcon,
  NewspaperIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import { RoutesEnum } from '../model/enums/RoutesEnum'
import { Link, useLocation } from 'react-router-dom'
import { useGetCurrentUser } from '../model/api/auth/useGetCurrentUser'
import { Avatar } from './Avatar'
import { UserRoleEnum } from '../model/enums/UserRoleEnum'
import QikLogo from '../assets/images/qik-logo.png'
import { CheckboxIcon } from '@radix-ui/react-icons'
import { TaskGridDrawer } from '../pages/Tasks/components/TaskGridDrawer'
import { ListTodoIcon } from 'lucide-react'
import { Button } from './ui/button'

const navigationSource = [
  { name: 'Domů', href: '/', icon: HomeIcon, current: false },
  { name: 'Projekty', href: RoutesEnum.PROJECTS, icon: FolderIcon, current: true },
  { name: 'Adresář', href: RoutesEnum.CONTACTS, icon: BookmarkIcon, current: false },
  {
    name: 'Stavebník (AI)',
    href: RoutesEnum.AI,
    icon: BoltIcon,
    current: false,
  },
  { name: 'Úkoly', href: RoutesEnum.TASKS, icon: CheckboxIcon, current: false },
]
const user = {
  name: 'Tom Cook',
  email: 'tom@example.com',
  imageUrl:
    'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

export const BasicLayout: React.FC<{ children: React.ReactNode; title: string }> = (props) => {
  const { data: currentUserData } = useGetCurrentUser()
  const currentUserName = currentUserData?.data?.name || 'Jan Novak'
  const userNavigation = [
    { name: 'Profil', href: RoutesEnum.USER_PROFILE },
    {
      name: 'Odhlásit se',
      href: '#',
      onClick: () => {
        localStorage.removeItem('token')
        window.location.reload()
      },
    },
  ]
  const location = useLocation()
  const { isLoading: userIsLoading, data: userData } = useGetCurrentUser()

  const role = userData?.data.role
  const navigationSourceWithAdminPaths = useMemo(() => {
    if (role === UserRoleEnum.ADMIN) {
      return [
        ...navigationSource,
        {
          name: 'Nastavení',
          href: RoutesEnum.WORKSPACE_SETTINGS,
          icon: CogIcon,
          current: false,
        },
      ]
    }

    return navigationSource
  }, [role])

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  if (userIsLoading || !userData?.data) {
    return <div>Loading...</div>
  }

  const navigation = navigationSourceWithAdminPaths.map((n) => {
    if (n.href === '/') {
      return { ...n, current: location.pathname === '/' }
    }

    return {
      ...n,
      current:
        (location.pathname === '/' && n.href === '/') || location.pathname.startsWith(n.href),
    }
  })

  return (
    <>
      <div className="min-h-full bg-gray-100">
        <div className="bg-blue-600 pb-32">
          <Disclosure
            as="nav"
            className="border-b border-blue-300 border-opacity-25 bg-blue-600 lg:border-none"
          >
            {({ open }) => (
              <>
                <div className="mx-auto max-w-7xl px-2 sm:px-4 lg:px-8">
                  <div className="relative flex h-16 items-center justify-between lg:border-b lg:border-blue-400 lg:border-opacity-25">
                    <div className="flex items-center px-2 lg:px-0">
                      <div className="flex-shrink-0">
                        <img className="block h-12 invert" src={QikLogo} alt="Your Company" />
                      </div>
                      <div className="hidden lg:ml-10 lg:block">
                        <div className="flex space-x-4">
                          {navigation.map((item) => (
                            <Link
                              key={item.name}
                              to={item.href}
                              className={classNames(
                                item.current
                                  ? 'bg-blue-700 text-white'
                                  : 'text-white hover:bg-blue-500 hover:bg-opacity-75',
                                'rounded-md py-2 px-3 text-sm font-medium',
                              )}
                              aria-current={item.current ? 'page' : undefined}
                            >
                              {item.name}
                            </Link>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-1 justify-center px-2 lg:ml-6 lg:justify-end">
                      <div className="w-full max-w-lg lg:max-w-xs"></div>
                    </div>
                    <div className="flex lg:hidden">
                      {/* Mobile menu button */}
                      <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md bg-blue-600 p-2 text-blue-200 hover:bg-blue-500 hover:bg-opacity-75 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-blue-600">
                        <span className="absolute -inset-0.5" />
                        <span className="sr-only">Open main menu</span>
                        {open ? (
                          <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                        ) : (
                          <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                        )}
                      </Disclosure.Button>
                    </div>
                    <div className="hidden lg:ml-4 lg:block">
                      <div className="flex items-center">
                        <TaskGridDrawer
                          subtitle="Přehled vašich úkolů"
                          customTrigger={
                            <button
                              type="button"
                              className="relative flex-shrink-0 rounded-full bg-blue-600 p-1 text-blue-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-blue-600"
                            >
                              <span className="absolute -inset-1.5" />
                              <span className="sr-only">View notifications</span>
                              <ListTodoIcon className="h-6 w-6" aria-hidden="true" />
                              <span className="absolute -top-1 -right-1 inline-flex items-center rounded-full bg-red-100 px-1.5 py-0.5 text-[10px] font-medium text-red-700">
                                9
                              </span>
                            </button>
                          }
                        />

                        {/* Profile dropdown */}
                        <Menu as="div" className="relative ml-3 flex-shrink-0">
                          <div>
                            <Menu.Button className="relative flex rounded-full bg-blue-600 text-sm text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-blue-600">
                              <span className="absolute -inset-1.5" />
                              <span className="sr-only">Open user menu</span>
                              <Avatar name={currentUserName} />
                            </Menu.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                              {userNavigation.map((item) => (
                                <Menu.Item key={item.name}>
                                  {({ active }) => (
                                    <Link
                                      to={item.href}
                                      onClick={item.onClick}
                                      className={classNames(
                                        active ? 'bg-gray-100' : '',
                                        'block px-4 py-2 text-sm text-gray-700',
                                      )}
                                    >
                                      {item.name}
                                    </Link>
                                  )}
                                </Menu.Item>
                              ))}
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </div>
                    </div>
                  </div>
                </div>

                <Disclosure.Panel className="lg:hidden">
                  <div className="space-y-1 px-2 pb-3 pt-2">
                    {navigation.map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        as="a"
                        href={item.href}
                        className={classNames(
                          item.current
                            ? 'bg-blue-700 text-white'
                            : 'text-white hover:bg-blue-500 hover:bg-opacity-75',
                          'block rounded-md py-2 px-3 text-base font-medium',
                        )}
                        aria-current={item.current ? 'page' : undefined}
                      >
                        {item.name}
                      </Disclosure.Button>
                    ))}
                  </div>
                  <div className="border-t border-blue-700 pb-3 pt-4">
                    <div className="flex items-center px-5">
                      <div className="flex-shrink-0">
                        <Avatar name={currentUserName} />
                      </div>
                      <div className="ml-3">
                        <div className="text-base font-medium text-white">{user.name}</div>
                        <div className="text-sm font-medium text-blue-300">{user.email}</div>
                      </div>
                      <button
                        type="button"
                        className="relative ml-auto flex-shrink-0 rounded-full bg-blue-600 p-1 text-blue-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-blue-600"
                      >
                        <span className="absolute -inset-1.5" />
                        <span className="sr-only">View notifications</span>
                        <BellIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                    <div className="mt-3 space-y-1 px-2">
                      {userNavigation.map((item) => (
                        <Link to={item.href}>
                          <Disclosure.Button
                            key={item.name}
                            as="a"
                            onClick={item.onClick}
                            className="block rounded-md px-3 py-2 text-base font-medium text-white hover:bg-blue-500 hover:bg-opacity-75"
                          >
                            {item.name}
                          </Disclosure.Button>
                        </Link>
                      ))}
                    </div>
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
          <header className="py-10">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <h1 className="text-3xl font-bold tracking-tight text-white">{props.title}</h1>
            </div>
          </header>
        </div>

        <main className="-mt-32">
          <div className="mx-auto max-w-full sm:max-w-7xl px-0 pb-12 sm:px-6 lg:px-8">
            <div className="rounded-lg bg-white px-5 py-6 shadow sm:px-6">{props.children}</div>
          </div>
        </main>
      </div>
    </>
  )
}
