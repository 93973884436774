import React, { useState } from 'react'
import { Bars3Icon } from '@heroicons/react/24/outline'
import { ProjectSidebarNavigation } from './ProjectSidebarNavigation'
import { ProjectEntityData } from '../../../model/interfaces/generated'
import { SecondaryButton } from '../../../components/SecondaryButton'
import { BasicLayout } from '../../../components/BasicLayout'

interface Props {
  children: React.ReactNode
  project: ProjectEntityData
}

export const ProjectLayout: React.FC<Props> = (props) => {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const title = [props.project.number, props.project.name, props.project.subject]
    .filter((a) => !!a)
    .join(' - ')

  return (
    <BasicLayout title={title}>
      <div className="flex p-0 lg:flex-row flex-col">
        <div className="lg:hidden flex justify-end">
          <SecondaryButton
            className="flex justify-center items-center gap-3 mb-3"
            onClick={() => setSidebarOpen(!sidebarOpen)}
          >
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            Menu
          </SecondaryButton>
        </div>
        <div
          className={`${sidebarOpen ? 'block' : 'hidden'} lg:block`}
          style={{ minWidth: '280px' }}
        >
          <ProjectSidebarNavigation project={props.project} />
        </div>
        <div className="block w-full">{props.children}</div>
      </div>
    </BasicLayout>
  )
}
