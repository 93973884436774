import { BuildingIcon } from 'lucide-react'
import React from 'react'
import { ProjectEntityData } from 'src/model/interfaces/generated'
import { HoverCard, HoverCardContent, HoverCardTrigger } from './ui/hover-card'
import { Avatar } from './Avatar'
import { Button } from './ui/button'
import { Link } from 'react-router-dom'
import { cn } from '../lib/utils'

interface Props {
  project: ProjectEntityData
  className?: string
  minimalist?: boolean
}

export const ProjectAvatar: React.FC<Props> = ({ project, className, minimalist = false }) => {
  return (
    <HoverCard>
      <HoverCardTrigger asChild>
        <div className={cn('flex gap-2 items-center hover:underline', className)}>
          <div className="rounded-full bg-gray-200 p-1">
            <BuildingIcon className="h-4 w-4 text-gray-600" />
          </div>
          {!minimalist && <span className="text-sm">{project.name}</span>}
        </div>
      </HoverCardTrigger>
      <HoverCardContent>
        <div className="flex gap-3">
          <div className="rounded-full bg-blue-600 p-1 h-6 w-6">
            <BuildingIcon className="h-4 w-4 text-white" />
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-sm font-semibold">{project.name}</span>
            <span className="text-sm">{project.subject}</span>
            <span className="text-sm">{project.number}</span>
            <Link to={`/projects/${project.id}`} className="mt-3">
              <Button variant="outline" type="button">
                Přejít na projekt
              </Button>
            </Link>
          </div>
        </div>
      </HoverCardContent>
    </HoverCard>
  )
}
