import React from 'react'
import { B2_FORM_INIT_VALUES } from '../B2ConstructionDescription/constants'
import { Field, FormikProps, useFormikContext } from 'formik'
import { B4_FORM_INITIAL_VALUES } from './constants'
import { FormSectionTwoColumns } from '../../../../../components/forms/FormSectionTwoColumns'
import { FormLabel } from '../../../../../components/FormLabel'
import { CheckboxInput } from '../../../../../components/inputs/CheckboxInput'
import { TextInput } from '../../../../../components/inputs/TextInput'
import RadioInput from '../../../../../components/inputs/RadioInput'
import { RichTextInput } from '../../../../../components/inputs/RichTextInput/RichTextInput'

interface Props {}

export const B4TransportSolutionForm: React.FC<Props> = (props) => {
  const formik = useFormikContext() as FormikProps<typeof B4_FORM_INITIAL_VALUES>
  return (
    <>
      <FormSectionTwoColumns
        sectionKey="b4.currentSolution"
        title="a) popis dopravního řešení"
        subtitle="včetně bezbariérových opatření pro přístupnost a užívání stavby osobami se sníženou schopností pohybu nebo orientace"
      >
        <div className="sm:col-span-6">
          <FormLabel>V okolí se nachází komunikace</FormLabel>
          <Field
            name="b4.currentSolution.communications.firstClass"
            component={CheckboxInput}
            label="I. třídy"
          />
          <Field
            name="b4.currentSolution.communications.secondClass"
            component={CheckboxInput}
            label="II. třídy"
          />
          <Field
            name="b4.currentSolution.communications.thirdClass"
            component={CheckboxInput}
            label="III. třídy"
          />
          <Field
            name="b4.currentSolution.communications.local"
            component={CheckboxInput}
            label="Místní"
          />
          <Field
            name="b4.currentSolution.communications.purpose"
            component={CheckboxInput}
            label="Účelová"
          />
          <Field
            name="b4.currentSolution.communications.custom"
            component={CheckboxInput}
            label="Vlastní"
          />
          {formik.values.b4.currentSolution.communications.custom && (
            <div className="pl-7">
              <Field
                name="b4.currentSolution.communications.customDescription"
                component={TextInput}
              />
            </div>
          )}
          <Field
            name="b4.currentSolution.communications.barrierFreeSolution"
            component={CheckboxInput}
            label="Bezbariérové řešení"
          />
          {formik.values.b4.currentSolution.communications.barrierFreeSolution && (
            <div className="pl-7">
              <Field
                name="b4.currentSolution.communications.barrierFreeSolutionDescription"
                component={TextInput}
              />
            </div>
          )}
        </div>
        <div className="sm:col-span-6">
          <Field name="b4.currentSolution.communications.description" component={RichTextInput} />
        </div>
      </FormSectionTwoColumns>
      <FormSectionTwoColumns
        sectionKey="b4.connections"
        title="b) napojení území na stávající dopravní infrastrukturu"
      >
        <div className="sm:col-span-6">
          <Field
            name="b4.connections.newOrExisting"
            component={RadioInput}
            options={[
              { label: 'Stávající sjezd', value: 'existing' },
              { label: 'Nový sjezd', value: 'new' },
            ]}
          />
          {formik.values.b4.connections.newOrExisting === 'new' && (
            <div className="pl-7">
              <Field name="b4.connections.newOrExistingDescription" component={RichTextInput} />
            </div>
          )}
        </div>
      </FormSectionTwoColumns>
      <FormSectionTwoColumns sectionKey="b4.transportationInPeace" title="c) doprava v klidu">
        <div className="sm:col-span-6">
          <Field
            name="b4.transportationInPeace.solvedInProject"
            component={RadioInput}
            options={[
              { label: 'Není řešena v projektu', value: 'notSolvedInProject' },
              { label: 'Řešena v projektu', value: 'solvedInProject' },
            ]}
          />
          {formik.values.b4.transportationInPeace.solvedInProject === 'solvedInProject' && (
            <div className="pl-7">
              <Field
                name="b4.transportationInPeace.solvedInProjectDescription"
                component={RichTextInput}
              />
            </div>
          )}
        </div>
      </FormSectionTwoColumns>
      <FormSectionTwoColumns
        sectionKey="b4.pedestrianAndCyclingRoutes"
        title="d) pěší a cyklistické stezby"
      >
        <div className="sm:col-span-6">
          <Field
            name="b4.pedestrianAndCyclingRoutes.solvedInProject"
            component={RadioInput}
            options={[
              { label: 'Nejsou součástí projektu', value: 'notSolvedInProject' },
              { label: 'Jsou součástí projektu', value: 'solvedInProject' },
            ]}
          />
          {formik.values.b4.pedestrianAndCyclingRoutes.solvedInProject === 'solvedInProject' && (
            <div className="pl-7">
              <Field
                name="b4.pedestrianAndCyclingRoutes.solvedInProjectDescription"
                component={RichTextInput}
              />
            </div>
          )}
        </div>
      </FormSectionTwoColumns>
    </>
  )
}
