import React from 'react'
import { BasicLayout } from '../../components/BasicLayout'
import { UserProfileDetail } from './UserProfileDetail'

interface Props {}

export const UserProfile: React.FC<Props> = (props) => {
  return (
    <BasicLayout title="Uživatelský profil">
      <UserProfileDetail />
    </BasicLayout>
  )
}
