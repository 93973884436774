import { useMutation, useQueryClient } from 'react-query'
import { api } from '../api'
import { ApiUrlEnum } from '../../enums/ApiUrlEnum'
import { QueryKeyEnum } from '../../enums/QueryKeyEnum'

export const useCreatePhotoDocumentationMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({
      projectId,
      data,
      invalidateCache,
    }: {
      projectId: string
      data: FormData
      invalidateCache?: boolean
    }) => api.postForm(`${ApiUrlEnum.PROJECT}/${projectId}${ApiUrlEnum.PHOTO_DOCUMENTATION}`, data),
    onSuccess: (response, request) => {
      if (request.invalidateCache) {
        return queryClient.invalidateQueries([QueryKeyEnum.PHOTO_DOCUMENTATION])
      }
    },
  })
}
