import React from 'react'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu'
import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid'
import { FileText, WandSparkles } from 'lucide-react'

interface Props {}

export const FormSectionAsideMenu: React.FC<Props> = (props) => {
  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger>
          <EllipsisHorizontalIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56">
          <DropdownMenuLabel>Menu</DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownMenuGroup>
            <DropdownMenuItem>
              <FileText className="mr-2 h-4 w-4" />
              <span>Předvyplnit z projektu</span>
            </DropdownMenuItem>
          </DropdownMenuGroup>
          <DropdownMenuSeparator />
          <DropdownMenuGroup>
            <DropdownMenuItem>
              <WandSparkles className="mr-2 h-4 w-4" />
              <span>Doplnit pomocí AI</span>
            </DropdownMenuItem>
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  )
}
