import React from 'react'
import { useProject } from '../../../../../model/hooks/useProject'
import { ProjectLoadingSkeleton } from '../../../layout/ProjectLoadingSkeleton'
import { B8_FORM_INITIAL_VALUES } from './constats'
import { TechnicalFormWrapper } from '../B2ConstructionDescription/TechnicalFormWrapper'
import { ProjectFormEnum } from '../../../../../model/enums/ProjectFormEnum'
import { B8OrganizationForm } from './B8OrganizationForm'

interface Props {}

export const B8Organization: React.FC<Props> = (props) => {
  const { project } = useProject()

  if (!project) {
    return <ProjectLoadingSkeleton />
  }

  return (
    <TechnicalFormWrapper
      form={ProjectFormEnum.TECHNICAL_FORM_B8}
      initialValues={B8_FORM_INITIAL_VALUES}
      title="B.8 Zásady organizace výstavby"
    >
      <B8OrganizationForm />
    </TechnicalFormWrapper>
  )
}
