import React from 'react'
import { UserResetPasswordWrapper } from '../UserResetPassword/UserResetPasswordWrapper'
import { Field, Form, Formik } from 'formik'
import { TextInput } from '../../components/inputs/TextInput'
import { Button } from '../../components/Button'
import * as Yup from 'yup'
import { useResetPasswordMutation } from '../../model/api/auth/useResetPasswordMutation'
import { useToast } from '@chakra-ui/react'

interface Props {}

const forgottenPasswordValidationSchema = Yup.object().shape({
  email: Yup.string().email('Email musí být ve správném formátu').required('Email je povinný'),
})

export const UserForgottenPassword: React.FC<Props> = () => {
  const { mutateAsync: sendEmail, isLoading } = useResetPasswordMutation()
  const toast = useToast()

  return (
    <UserResetPasswordWrapper>
      <Formik
        validationSchema={forgottenPasswordValidationSchema}
        initialValues={{ email: '' }}
        onSubmit={async ({ email }) => {
          await sendEmail({ email })
          toast({
            title: 'Email s obnovou hesla byl odeslán',
            status: 'success',
            duration: 5000,
            isClosable: true,
            position: 'top',
          })
        }}
      >
        <Form>
          <div className="flex flex-col gap-5">
            <Field name="email" component={TextInput} label="Váš email" />
            <Button isLoading={isLoading} type="submit">
              Odeslat odkaz s obnovou hesla
            </Button>
          </div>
        </Form>
      </Formik>
    </UserResetPasswordWrapper>
  )
}
