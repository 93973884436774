import React from 'react'
import { Field, FormikProps } from 'formik'
import { RichTextInput } from '../../../../../components/inputs/RichTextInput/RichTextInput'
import { FormSectionTwoColumns } from '../../../../../components/forms/FormSectionTwoColumns'
import { CheckboxInput } from '../../../../../components/inputs/CheckboxInput'
import { InfoPopover } from '../../../../../components/InfoPopover'

interface Props {
  formik: FormikProps<any>
}

export const TechnicalFormB1H: React.FC<Props> = ({ formik }) => {
  return (
    <FormSectionTwoColumns
      sectionKey={'b1h'}
      title="h) Vliv stavby na okolní"
      subtitle="vliv stavby na okolní stavby a pozemky, ochrana okolí, vliv stavby na odtokové poměry v území"
    >
      <div className="sm:col-span-6">
        <Field
          component={CheckboxInput}
          name="b1h.impactOnSurroundingBuildings.enabled"
          label="Vliv stavby na okolní stavby"
        />
      </div>
      {formik.values.b1h.impactOnSurroundingBuildings.enabled && (
        <div className="sm:col-span-6">
          <Field
            component={RichTextInput}
            name="b1h.impactOnSurroundingBuildings.measures"
            label="Opatření"
          />
        </div>
      )}
      <div className="sm:col-span-6">
        <Field
          component={CheckboxInput}
          name="b1h.impactOnSurroundingLands.enabled"
          label="Vliv stavby na okolní pozemky"
        />
      </div>
      {formik.values.b1h.impactOnSurroundingLands.enabled && (
        <div className="sm:col-span-6">
          <Field
            component={RichTextInput}
            name="b1h.impactOnSurroundingLands.measures"
            label="Opatření"
          />
        </div>
      )}
      <div className="sm:col-span-6">
        <div className="flex">
          <Field
            component={CheckboxInput}
            name="b1h.protectionOfSurroundings.enabled"
            label="Ochrana okolí"
          />
          <InfoPopover
            content="Stavebními pracemi nesmí docházet k negativnímu rušení okolí. Z hlediska péče o životní prostředí se musí účastníci stavby zaměřit na ochranu proti hluku a vibracím, zabránit nadměrnému znečištění ovzduší a komunikace, znečišťování povrchových a podzemních vod a respektování hygienických předpisů a opatření v objektech zařízení staveniště.
Komunální odpad bude shromažďován do kontejnerových nádob s pravidelným režimem odvozu. "
          />
        </div>
      </div>
      {formik.values.b1h.protectionOfSurroundings.enabled && (
        <div className="sm:col-span-6">
          <Field
            component={RichTextInput}
            name="b1h.protectionOfSurroundings.measures"
            label="Opatření"
          />
        </div>
      )}
      <div className="sm:col-span-6">
        <Field
          component={CheckboxInput}
          name="b1h.impactOnDrainageConditions.enabled"
          label="Vliv stavby na odtokové poměry v území"
        />
      </div>
      {formik.values.b1h.impactOnDrainageConditions.enabled && (
        <div className="sm:col-span-6">
          <Field
            component={RichTextInput}
            name="b1h.impactOnDrainageConditions.measures"
            label="Opatření"
          />
        </div>
      )}
    </FormSectionTwoColumns>
  )
}
