import { useMutation, useQueryClient } from 'react-query'
import { api } from '../api'
import { ApiUrlEnum } from '../../enums/ApiUrlEnum'
import { QueryKeyEnum } from '../../enums/QueryKeyEnum'

export const useDeleteIssueMutation = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ projectId, issueId }: { projectId: string; issueId: string }) =>
      api.delete(`${ApiUrlEnum.PROJECT}/${projectId}${ApiUrlEnum.ISSUE}/${issueId}`),
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyEnum.ISSUES])
    },
  })
}
