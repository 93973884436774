import React from 'react'
import { SmartDrawer } from '../SmartDrawer'
import { useGetProjectFormSectionHelp } from '../../model/api/project/useGetProjectFormSectionHelp'
import { ProjectFormIdentityContext } from '../../model/context/ProjectFormIdentityContext'
import { FormLabel } from '../FormLabel'
import { useFormikContext } from 'formik'
import { Spinner, useToast } from '@chakra-ui/react'

interface Props {
  open: boolean
  setOpen: (open: boolean) => void
}

export const LoadFormSectionModal: React.FC<Props> = (props) => {
  const formik = useFormikContext()
  const context = React.useContext(ProjectFormIdentityContext)
  const toast = useToast()

  const { data, isLoading, refetch } = useGetProjectFormSectionHelp(
    context.projectId!,
    context.formEnum!,
  )

  React.useEffect(() => {
    if (props.open) {
      refetch()
    }
  }, [props.open, refetch])

  const onSelected = async (data: any) => {
    if (!window.confirm('Opravdu chcete načíst tuto sekci? Všechny změny budou ztraceny.')) {
      return
    }

    await formik.setValues(data)
    props.setOpen(false)
    toast({
      title: 'Sekce byla úspěšně načtena',
      status: 'success',
      duration: 5000,
      isClosable: true,
    })
  }

  return (
    <SmartDrawer title="Načíst sekci z projektu" open={props.open} setOpen={props.setOpen}>
      <FormLabel>Projekty s vyplněnou sekcí</FormLabel>
      <div className="mt-3 flex flex-col gap-3">
        {isLoading && <Spinner />}
        {data?.data?.map((section) => (
          <div
            onClick={() => onSelected(section.data)}
            className="p-3 rounded-md shadm-sm border hover:border-blue-700 cursor-pointer"
          >
            {section.project.name}
          </div>
        ))}
      </div>
    </SmartDrawer>
  )
}
