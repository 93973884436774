import React from 'react'
import { IWeatherDailySummary } from 'shared'
import {
  ArrowDownFromLineIcon,
  ArrowDownIcon,
  ArrowUp,
  ArrowUpIcon,
  CloudyIcon,
  DropletsIcon,
  LucideIcon,
  SunIcon,
  SunriseIcon,
  SunsetIcon,
  ThermometerSnowflakeIcon,
  ThermometerSunIcon,
  UmbrellaIcon,
  WindIcon,
} from 'lucide-react'
import { Button } from './ui/button'

interface Props {
  weather: IWeatherDailySummary
}

export const WeatherCard: React.FC<Props> = ({ weather }) => {
  const [showDetail, setShowDetail] = React.useState(false)
  const data: Array<{ header: string; icon: LucideIcon; value: string | number; unit: string }> = [
    { header: 'Oblačnost', icon: CloudyIcon, value: weather.cloud_cover.afternoon, unit: '%' },
    { header: 'Ráno', icon: SunriseIcon, value: weather.temperature.morning, unit: '°C' },
    { header: 'Odpoledne', icon: SunIcon, value: weather.temperature.afternoon, unit: '°C' },
    { header: 'Večer', icon: SunsetIcon, value: weather.temperature.evening, unit: '°C' },
    { header: 'Vlhkosť', icon: DropletsIcon, value: weather.humidity.afternoon, unit: '%' },
    {
      header: 'Tlak',
      icon: ArrowDownFromLineIcon,
      value: weather.pressure.afternoon,
      unit: ' hPa',
    },
    { header: 'Déšť', icon: UmbrellaIcon, value: weather.precipitation.total, unit: ' mm' },
    {
      header: 'Vítr',
      icon: WindIcon,
      value: `${weather.wind.max.speed} m/s | ${weather.wind.max.direction}°`,
      unit: '',
    },
    { header: 'Min', icon: ThermometerSnowflakeIcon, value: weather.temperature.min, unit: '°C' },
    { header: 'Max', icon: ThermometerSunIcon, value: weather.temperature.max, unit: '°C' },
  ]

  return (
    <div className="w-full">
      <div className="grid grid-cols-2 sm:grid-cols-4 gap-3">
        {data.slice(0, showDetail ? data.length : 4).map((item, index) => (
          <WeatherDetail key={index}>
            <WeatherHeader>{item.header}</WeatherHeader>
            <WeatherIcon icon={item.icon} />
            <WeatherValue>
              {typeof item.value === 'number' ? Math.round(item.value) : item.value}
              {item.unit}
            </WeatherValue>
          </WeatherDetail>
        ))}
      </div>
      <div className="flex justify-end">
        <Button type="button" variant="link" onClick={() => setShowDetail((val) => !val)}>
          {showDetail ? (
            <>
              <ArrowUpIcon size={16} className="mr-2" />
              Schovat
            </>
          ) : (
            <>
              <ArrowDownIcon size={16} className="mr-2" />
              Podrobnosti
            </>
          )}
        </Button>
      </div>
    </div>
  )
}

export const WeatherDetail: React.FC<{ children: React.ReactNode }> = (props) => {
  return (
    <div className="rounded-md bg-gray-50 p-5 flex flex-col items-center justify-center gap-3 hover:bg-gray-100 hover:shadow">
      {props.children}
    </div>
  )
}

export const WeatherHeader: React.FC<{ children: React.ReactNode }> = (props) => {
  return <span className="font-semibold">{props.children}</span>
}

export const WeatherIcon: React.FC<{ icon: LucideIcon }> = (props) => {
  return <props.icon size={48} className="text-gray-700" />
}

export const WeatherValue: React.FC<{ children: React.ReactNode }> = (props) => {
  return <span>{props.children}</span>
}
