import { useMutation, useQueryClient } from 'react-query'
import { api } from '../api'
import { IDocumentEntity } from '../../interfaces/IDocumentEntity'
import { AxiosResponse } from 'axios'
import { ApiUrlEnum } from '../../enums/ApiUrlEnum'
import { QueryKeyEnum } from '../../enums/QueryKeyEnum'

export const useDeleteDocumentMutation = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (id: string) => api.delete(`${ApiUrlEnum.DOCUMENT}/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeyEnum.FOLDERS)
    },
  })
}
