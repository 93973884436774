import {
  AdjustmentsHorizontalIcon,
  ArchiveBoxIcon,
  DocumentTextIcon,
  HomeIcon,
  HomeModernIcon,
  WrenchScrewdriverIcon,
} from '@heroicons/react/24/outline'
import React from 'react'
import { ProjectEntityData } from '../../../model/interfaces/generated'
import { RoutesEnum } from '../../../model/enums/RoutesEnum'
import { SidebarNavigation } from '../../../components/SidebarNavigation'
import { ISidebarNavigationItem } from '../../../model/interfaces/ISidebarNavigationItem'
import { CheckboxIcon } from '@radix-ui/react-icons'

interface Props {
  project: ProjectEntityData
}

export const ProjectSidebarNavigation: React.FC<Props> = (props) => {
  const { project } = props
  const { id } = project

  const navigation = React.useMemo((): ISidebarNavigationItem[] => {
    return [
      {
        name: 'Přehled',
        href: `${RoutesEnum.PROJECTS}/${id}`,
        icon: HomeIcon,
      },
      {
        name: 'Podklady a průzkumy',
        href: `${RoutesEnum.PROJECTS}/${id}/${RoutesEnum.RESEARCH_AND_SURVEY}`,
        icon: ArchiveBoxIcon,
      },
      {
        name: 'Projektová dokumentace',
        icon: DocumentTextIcon,
        href: '#',
        children: [
          {
            name: 'A) Průvodní zpráva',
            href: `${RoutesEnum.PROJECTS}/${id}/${RoutesEnum.ACCOMPANYING_REPORT}`,
          },
          {
            name: 'B) Sourhnná technická zpráva',
            href: `${RoutesEnum.PROJECTS}/${id}/${RoutesEnum.TECHNICAL_REPORT_FORM}`,
          },
          {
            name: 'C) Situační výkresy',
            href: `${RoutesEnum.PROJECTS}/${id}/${RoutesEnum.SITUATION_DOCUMENTATION}`,
          },
          {
            name: 'D) Dokumentace objektů',
            href: `${RoutesEnum.PROJECTS}/${id}/${RoutesEnum.OBJECT_DOCUMENTATION}`,
          },
          {
            name: 'E) Dokladová část',
            href: `${RoutesEnum.PROJECTS}/${id}/${RoutesEnum.DOCUMENTATION_SECTION}`,
          },
        ],
      },
      {
        name: 'Inženýring',
        href: `${RoutesEnum.PROJECTS}/${id}/${RoutesEnum.ENGINEERING}`,
        icon: WrenchScrewdriverIcon,
      },
      {
        name: 'Stavba',
        href: '#',
        icon: HomeModernIcon,
        children: [
          {
            name: 'Stavební deník',
            href: `${RoutesEnum.PROJECTS}/${id}/${RoutesEnum.CONSTRUCTION_DIARY}`,
          },
          {
            name: 'Fotodokumentace',
            href: `${RoutesEnum.PROJECTS}/${id}/${RoutesEnum.PHOTO_DOCUMENTATION}`,
          },
          {
            name: 'Vady a nedodělky',
            href: `${RoutesEnum.PROJECTS}/${id}/${RoutesEnum.ISSUES}`,
          },
        ],
      },
      {
        name: 'Úkoly',
        href: `${RoutesEnum.PROJECTS}/${id}/${RoutesEnum.TASKS}`,
        icon: CheckboxIcon,
      },
      {
        name: 'Nastavení',
        href: '#',
        icon: AdjustmentsHorizontalIcon,
        children: [
          {
            name: 'Základní informace',
            href: `${RoutesEnum.PROJECTS}/${id}/${RoutesEnum.SETTINGS}/${RoutesEnum.SETTINGS_BASIC_INFO}`,
          },
          {
            name: 'Objekty a místnosti',
            href: `${RoutesEnum.PROJECTS}/${id}/${RoutesEnum.SETTINGS}/${RoutesEnum.SETTINGS_OBJECTS_AND_ROOMS}`,
          },
          {
            name: 'Identifikační údaje stavby',
            href: `${RoutesEnum.PROJECTS}/${id}/${RoutesEnum.SETTINGS}/${RoutesEnum.SETTINGS_CONSTRUCTION_DIARY_INFO}`,
          },
        ],
      },
    ]
  }, [id])

  return <SidebarNavigation navigation={navigation} />
}
