import React from 'react'
import { Field, FormikProps } from 'formik'
import { RichTextInput } from '../../../../../components/inputs/RichTextInput/RichTextInput'
import { FormSectionTwoColumns } from '../../../../../components/forms/FormSectionTwoColumns'
import { InfoPopover } from '../../../../../components/InfoPopover'
import { CheckboxInput } from '../../../../../components/inputs/CheckboxInput'
import { RADON_OPTIONS } from '../constants'
import { RadioInputPanel } from '../../../../../components/RadioInputPanel'
import { DropzoneInput } from '../../../../../components/inputs/DropzoneInput'
import { ArrayForm } from '../../../../../components/ArrayForm'
import { TextInput } from '../../../../../components/inputs/TextInput'

interface Props {
  formik: FormikProps<any>
}

export const TechnicalFormB1E: React.FC<Props> = ({ formik }) => {
  return (
    <FormSectionTwoColumns
      sectionKey={'b1e'}
      title="e) Průzkumy a rozbory"
      subtitle={
        <>
          výčet a závěry provedených průzkumů a rozborů - geologický průzkum, hydrogeologický
          průzkum, stavebně historický průzkum apod.
        </>
      }
    >
      <div className="sm:col-span-6">
        <ul role="list" className="">
          {/* Geological Survey */}
          <li className="px-4 py-4 sm:px-6">
            <Field
              component={CheckboxInput}
              name="b1e.geologicalSurvey"
              label="Geologický průzkum"
            />
            {formik.values.b1e.geologicalSurvey && (
              //   TODO: + SOUBORY
              <div className="mt-3">
                <Field component={RichTextInput} name="b1e.geologicalSurveyConclusion" label="" />
                <Field component={DropzoneInput} name="b1e.geologicalSurveyFiles" label="" />
              </div>
            )}
          </li>

          {/* Hydrogeological Survey */}
          <li className="px-4 py-4 sm:px-6">
            <Field
              component={CheckboxInput}
              name="b1e.hydrogeologicalSurvey"
              label="Hydrogeologický průzkum"
            />
            {formik.values.b1e.hydrogeologicalSurvey && (
              <div className="mt-3">
                <Field
                  component={RichTextInput}
                  name="b1e.hydrogeologicalSurveyConclusion"
                  label=""
                />
                <Field component={DropzoneInput} name="b1e.hydrogeologicalSurveyFiles" label="" />
              </div>
            )}
          </li>

          <li className="px-4 py-4 sm:px-6">
            <Field
              component={CheckboxInput}
              name="b1e.engineerGeologySurvey"
              label="Inženýrsko-geologický průzkum"
            />
            {formik.values.b1e.engineerGeologySurvey && (
              <div className="mt-3">
                <Field
                  component={RichTextInput}
                  name="b1e.engineerGeologySurveyConclusion"
                  label=""
                />
                <Field component={DropzoneInput} name="b1e.engineerGeologySurveyFiles" label="" />
              </div>
            )}
          </li>

          {/* Historical Building Survey */}
          <li className="px-4 py-4 sm:px-6">
            <Field
              component={CheckboxInput}
              name="b1e.historicalBuildingSurvey"
              label="Stavebně historický průzkum"
            />
            {formik.values.b1e.historicalBuildingSurvey && (
              <div className="mt-3">
                <Field
                  component={RichTextInput}
                  label=""
                  name="b1e.historicalBuildingSurveyConclusion"
                />
                <Field
                  component={DropzoneInput}
                  name="b1e.historicalBuildingSurveyFiles"
                  label=""
                />
              </div>
            )}
          </li>

          {/* Radon Measurement */}
          <li className="px-4 py-4 sm:px-6">
            <div className="flex">
              <Field component={CheckboxInput} name="b1e.radonMeasurement" label="Měření radonu" />
              <InfoPopover content="Pokud je pod stavbou bez ohledu na kategorii radonového indexu stavby vytvořena drenážní vrstva o vysoké propustnosti nebo je součástí kontaktní konstrukce podlahové vytápění, musí být provedena kombinace s větracím systémem podloží nebo s ventilační vrstvou v kontaktní konstrukci." />
            </div>

            {formik.values.b1e.radonMeasurement && (
              <div className="mt-3">
                <Field
                  name="b1e.radonMeasurementLevel"
                  component={RadioInputPanel}
                  options={RADON_OPTIONS}
                />
              </div>
            )}
          </li>
          <ArrayForm
            name="b1e.custom"
            rows={formik.values.b1e.custom || []}
            setFieldValue={formik.setFieldValue}
            initializationObject={{ name: '', enabled: false, conclusions: '', files: [] }}
          >
            {(name: string, row: any, index: number) => (
              <li className={`px-4 py-4 sm:px-6`}>
                <div className="flex w-100">
                  <Field name={`${name}.enabled`} component={CheckboxInput} />
                  <div className="-mt-3 w-full">
                    <Field name={`${name}.name`} component={TextInput} placeholder="Vlastní" />
                  </div>
                </div>
                {row.enabled && (
                  <div className="mt-5">
                    <Field
                      name={`${name}.conclusions`}
                      component={RichTextInput}
                      label="Opatření"
                    />
                    <Field name={`${name}.files`} component={DropzoneInput} label="Opatření" />
                  </div>
                )}
              </li>
            )}
          </ArrayForm>
        </ul>
      </div>
    </FormSectionTwoColumns>
  )
}
