"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getProjectFormIdentifier = exports.getProjectFormNameBasedOnType = exports.generateRandomString = exports.dumpTest = void 0;
const enums_1 = require("./enums");
const dumpTest = () => {
    console.log("Hello world");
};
exports.dumpTest = dumpTest;
function generateRandomString(length = 8) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}
exports.generateRandomString = generateRandomString;
function getProjectFormNameBasedOnType(type) {
    switch (type) {
        case enums_1.ProjectFormEnum.ACCOMPANYING_REPORT_FORM_A1:
            return "Průvodní zpráva";
        case enums_1.ProjectFormEnum.TECHNICAL_FORM_B1:
            return "Popis území stavby";
        case enums_1.ProjectFormEnum.TECHNICAL_FORM_B2:
            return "Celkový popis stavby";
        case enums_1.ProjectFormEnum.TECHNICAL_FORM_B3:
            return "Přpojení na technickou infrastrukturu";
        case enums_1.ProjectFormEnum.TECHNICAL_FORM_B4:
            return "Dopravní řešení";
        case enums_1.ProjectFormEnum.TECHNICAL_FORM_B5:
            return "Řešení vegetace a souvisejících terénních úprav";
        case enums_1.ProjectFormEnum.TECHNICAL_FORM_B6:
            return "Popis vlivu stavby na životní prostředí a jeho ochrana";
        case enums_1.ProjectFormEnum.TECHNICAL_FORM_B7:
            return "Ochrana obyvatelstva";
        case enums_1.ProjectFormEnum.TECHNICAL_FORM_B8:
            return "Zásady organizace výstavby";
        case enums_1.ProjectFormEnum.TECHNICAL_FORM_B9:
            return "Celkové vodohospodářské řešení";
        case enums_1.ProjectFormEnum.CONSTRUCTION_DIARY_FORM:
            return "Stavební deník";
        default:
            throw new Error("Unknown Project Form Type " + type);
    }
}
exports.getProjectFormNameBasedOnType = getProjectFormNameBasedOnType;
function getProjectFormIdentifier(type) {
    switch (type) {
        case enums_1.ProjectFormEnum.TECHNICAL_FORM_B1:
            return "B 1";
        case enums_1.ProjectFormEnum.TECHNICAL_FORM_B2:
            return "B 2";
        case enums_1.ProjectFormEnum.TECHNICAL_FORM_B3:
            return "B 3";
        case enums_1.ProjectFormEnum.TECHNICAL_FORM_B4:
            return "B 4";
        case enums_1.ProjectFormEnum.TECHNICAL_FORM_B5:
            return "B 5";
        case enums_1.ProjectFormEnum.TECHNICAL_FORM_B6:
            return "B 6";
        case enums_1.ProjectFormEnum.TECHNICAL_FORM_B7:
            return "B 7";
        case enums_1.ProjectFormEnum.TECHNICAL_FORM_B8:
            return "B 8";
        case enums_1.ProjectFormEnum.TECHNICAL_FORM_B9:
            return "B 9";
        case enums_1.ProjectFormEnum.ACCOMPANYING_REPORT_FORM_A1:
            return "A";
        case enums_1.ProjectFormEnum.CONSTRUCTION_DIARY_FORM:
            return "Stavební deník";
        default:
            throw new Error("Unknown Project Form Type " + type);
    }
}
exports.getProjectFormIdentifier = getProjectFormIdentifier;
