import { useMutation, useQueryClient } from 'react-query'
import { api } from '../api'
import { IDocumentEntity } from '../../interfaces/IDocumentEntity'
import { AxiosResponse } from 'axios'
import { ApiUrlEnum } from '../../enums/ApiUrlEnum'
import { QueryKeyEnum } from '../../enums/QueryKeyEnum'

export const useUploadDocumentMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: any) =>
      api.postForm<any, AxiosResponse<IDocumentEntity>>(ApiUrlEnum.DOCUMENT, data),
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyEnum.FOLDERS])
    },
  })
}
