import { useMutation } from 'react-query'
import { api } from '../api'
import { LoginRequestData } from '../../interfaces/generated'
import { ApiUrlEnum } from '../../enums/ApiUrlEnum'

export const useLoginMutation = () => {
  return useMutation((data: LoginRequestData) => api.post(ApiUrlEnum.AUTH, data), {
    onSuccess: (data: any) => {
      localStorage.setItem('token', data.data.access_token)
    },
  })
}
