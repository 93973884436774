import React from 'react'
import { useProject } from '../../../../../model/hooks/useProject'
import { ProjectLoadingSkeleton } from '../../../layout/ProjectLoadingSkeleton'
import { ProjectLayout } from '../../../layout/ProjectLayout'
import { IdentificationSettings } from '../IdentificationSettings'

interface Props {}

export const ConstructionDiaryInfo: React.FC<Props> = (props) => {
  const { project } = useProject()

  if (!project) {
    return <ProjectLoadingSkeleton />
  }

  return (
    <ProjectLayout project={project}>
      <div>
        <div className="flex flex-col md:flex-row flex-wrap justify-between items-center gap-6 sm:flex-nowrap ">
          <h1 className="text-base font-semibold leading-7 text-gray-900">
            Identifikační údaje stavby podle projektové dokumentace
          </h1>
        </div>
        <div className="mt-5">
          <IdentificationSettings />
        </div>
      </div>
    </ProjectLayout>
  )
}
