import { useMutation, useQueryClient } from 'react-query'
import { api } from '../api'
import { ApiUrlEnum } from '../../enums/ApiUrlEnum'
import { QueryKeyEnum } from '../../enums/QueryKeyEnum'

export const useDeleteFolderMutation = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (folderId: string) => api.delete(`${ApiUrlEnum.FOLDER}/${folderId}`),
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyEnum.FOLDERS])
    },
  })
}
