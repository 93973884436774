import React from 'react'
import { BasicLayout } from '../../components/BasicLayout'
import { CardTable } from '../../components/CardTable'
import { useGetContacts } from '../../model/api/contact/useGetContacts'
import { ContactEntityData } from '../../model/interfaces/generated'
import { contactToString, contactTypeToLabel } from '../../model/helpers/contactHelpers'
import { Flex, Heading, Spacer, useDisclosure } from '@chakra-ui/react'
import { AddProjectDrawer } from '../../components/drawers/AddProjectDrawer'
import { Button } from '../../components/Button'
import { ContactFormModal } from '../../components/modals/ContactFormModal'

interface Props {}

export const Contacts: React.FC<Props> = (props) => {
  const { data: contactsData, isLoading: isLoadingContacts } = useGetContacts()
  const contactFormModalDisclosure = useDisclosure()
  const [editingContact, setEditingContact] = React.useState<undefined | ContactEntityData>()

  if (!contactsData || !contactsData.data) {
    return null
  }

  const onContactClicked = (contact: ContactEntityData) => {
    setEditingContact(contact)
    contactFormModalDisclosure.onOpen()
  }

  return (
    <BasicLayout title="Adresář">
      <Flex justifyContent="space-between" className="px-8">
        <Heading></Heading>
        <Spacer />
        <Button onClick={contactFormModalDisclosure.onOpen} size="2xl">
          Přidat
        </Button>
      </Flex>
      <ContactFormModal
        disclosure={{
          ...contactFormModalDisclosure,
          onClose: () => {
            setEditingContact(undefined)
            contactFormModalDisclosure.onClose()
          },
        }}
        editContact={editingContact}
      />
      <CardTable<ContactEntityData>
        columns={[
          {
            heading: 'Jmeno',
            cellCallback: (option) => {
              const contact = contactToString(option)
              return <>{contact}</>
            },
          },
          {
            heading: 'Email',
            cellAccessor: 'email',
          },
          {
            heading: 'Telefon',
            cellAccessor: 'phone',
          },
          {
            heading: 'Typ',
            cellCallback: (option) => {
              return <>{contactTypeToLabel(option.type)}</>
            },
          },
        ]}
        data={contactsData.data.contacts!}
        rowAction={onContactClicked}
      />
    </BasicLayout>
  )
}
