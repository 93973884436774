import { PhotoIcon, TrashIcon } from '@heroicons/react/24/outline'
import { FieldProps } from 'formik'
import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { Button } from './Button'
import { getFieldValidity } from '../model/helpers/formHelpers'
import { IDocumentEntity } from '../model/interfaces/IDocumentEntity'
import { AuthImage } from './AuthImage'
import { FormLabel } from './FormLabel'
import { ImageBlobReduceStatic } from 'image-blob-reduce'
import { Spinner } from '@chakra-ui/react'

interface Props extends FieldProps {
  className?: string
  label?: string
  accept?: { [key in string]: string[] }
}

const reduce = (window as any).ImageBlobReduce as ImageBlobReduceStatic

export const ImageDropzoneInput: React.FC<Props> = (props) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const { isInvalid, message } = getFieldValidity(props.form, props.field.name)

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    if (isLoading) {
      return
    }

    setIsLoading(true)
    try {
      const processedFiles = await Promise.all(
        acceptedFiles.map(async (file) => {
          const resultBlob = await reduce().toBlob(file, { max: 1024 })
          return new File([resultBlob], file.name, {
            type: file.type,
            lastModified: file.lastModified,
          })
        }),
      )

      return props.form.setFieldValue(props.field.name, processedFiles[0])
    } catch (err) {
      console.log({ err })
    } finally {
      setIsLoading(false)
    }
  }, [])

  const onRemove = useCallback(() => {
    if (window.confirm('Opravdu chcete smazat fotku?')) {
      props.form.setFieldValue(props.field.name, undefined)
    }
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: props.accept,
    maxFiles: 1,
  })

  // File = just uploaded, undefined = empty, IDocumentEntity = on edit when image is already uploaded
  const value = props.field.value as File | undefined | IDocumentEntity | any

  if (value) {
    return (
      <>
        {props.label && <FormLabel>{props.label}</FormLabel>}
        <div className="mt-3 mb-3 max-w-80 flex justify-center">
          {typeof value.id !== 'undefined' ? (
            <AuthImage
              className="rounded max-h-[500px] drop-shadow-md"
              image={value}
              alt="preview"
            />
          ) : (
            <img className="rounded max-h-[500px]" src={URL.createObjectURL(value)} alt="preview" />
          )}
        </div>
        <div className="flex justify-end mt-3">
          <Button onClick={onRemove} color="danger" className="flex justify-center">
            <TrashIcon className="h-5 w-5 mr-2" />
            Smazat fotku
          </Button>
        </div>
      </>
    )
  }

  return (
    <>
      {props.label && (
        <label
          htmlFor={props.field.name}
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          {props.label}
          {isInvalid && <span className="text-red-500 ml-1">{message}</span>}
        </label>
      )}
      <div
        className={`mt-2 flex justify-center rounded-lg border border-dashed ${
          isInvalid ? 'border-red-500' : 'border-gray-900/25'
        } px-6 py-10`}
        {...getRootProps()}
      >
        {isLoading ? (
          <div className="flex justify-center items-center mx-auto">
            <Spinner size="lg" />
          </div>
        ) : (
          <div className="text-center">
            <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
            <div className="mt-4 flex text-sm leading-6 text-gray-600">
              <label
                htmlFor="file-upload"
                className={`relative cursor-pointer rounded-md bg-white font-semibold text-blue-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-600 focus-within:ring-offset-2 hover:text-blue-500`}
              >
                <span>Vyberte fotku</span>
                <input
                  multiple={false}
                  name="file-upload"
                  type="file"
                  accept="image/*"
                  className="sr-only"
                  {...getInputProps()}
                />
              </label>
              <p className="pl-1">nebo ji přetáhněte sem</p>
            </div>
            <p className="text-xs leading-5 text-gray-600">PNG, JPG, GIF</p>
          </div>
        )}
      </div>
    </>
  )
}
