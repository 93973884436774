import { useQuery } from 'react-query'
import { QueryKeyEnum } from '../../enums/QueryKeyEnum'
import { api } from '../api'
import { ApiUrlEnum } from '../../enums/ApiUrlEnum'

export const useGetConstructionDiaryEntry = (projectId: string, date: string) => {
  return useQuery({
    queryFn: () =>
      api.get(`${ApiUrlEnum.PROJECT}/${projectId}${ApiUrlEnum.CONSTRUCTION_DIARY}/date/${date}`),
    queryKey: [QueryKeyEnum.CONSTRUCTION_DIARY, date],
  })
}
