export const CONSTRUCTION_DIARY_ENTRY_INITIAL_DATA = {
  photos: [],
  description: '',
  workers: '',
  weather: {},
  workDone: [],
  materials: '',
  mechanization: '',
  dustPrevention: '',
  accessibility: '',
  signatures: [],
  signature: '',
}

export const CONSTRUCTION_DIARY_OTHER_ENTRY_TYPES = [
  'a) předání a převzetí staveniště (mezi stavebníkem a zhotoviteli)',
  'b) zahájení prací, případně termíny a důvody jejich přerušení a obnovení, včetně technologických přestávek',
  'c) nástupy, provádění prací a ukončení činností poddodavatelů,',
  'd) seznámení a proškolení pracovníků s podmínkami bezpečnosti prací, požární ochranou, ochranou životního prostředí, dále s technologickými postupy prací a montáží a s možnými riziky při stavebních pracích,',
  'e) údaje o opatřeních týkajících se bezpečnosti a ochrany zdraví při práci, požární ochrany a ochrany životního prostředí,',
  'f) zvláštní opatření při bouracích pracích, pracích ve výškách, za provozu, v ochranných pásmech apod.,',
  'g) manipulace se zeminami, stavební sutí a nakládání s odpady,',
  'h) geodetická měření,',
  'i) montáže a demontáže dočasných stavebních konstrukcí (lešení, pažení, bednění apod.), jejich předání a převzetí,',
  'j) výsledky kvantitativních a kvalitativních přejímek dodávek pro stavbu (vstupní kontroly),',
  'k) opatření k zajištění stavby, zabudovaných nebo skladovaných výrobků a zařízení proti poškození, odcizení apod.,',
  'l) provádění a výsledky kontrol, zkoušek a měření,',
  'm) souhlas se zakrýváním prací (základové spáry, výztuž do betonu, podzemní vedení apod.),',
  'n) odůvodnění a schvalování změn materiálů, technického řešení stavby a odchylek od ověřené projektové dokumentace,',
  'o) skutečnosti důležité pro věcné, časové a finanční plnění smluv (vícepráce, nepředvídatelné vlivy, výskyt překážek na staveništi, výsledky dodatečných technických průzkumů, mimořádné klimatické vlivy, archeologický výzkum, práce za provozu apod.),',
  'p) dílčí přejímky ukončených prací,',
  'q) škody způsobené stavební nebo jinou činností, havárie, nehody, ztráty, úrazy a jiné mimořádné události, včetně přijatých opatření,',
  'r) předávání a přejímky díla nebo jeho ucelených částí,',
  's) odstranění vad a nedodělků,',
  't) výsledky kontrolních prohlídek stavby,',
  'u) výsledky činnosti autorizovaného inspektora,',
  'v) zřízení, provozování a odstranění zařízení staveniště,',
  'w) nepředvídané nálezy kulturně cenných předmětů, detailů stavby nebo chráněných částí přírody anebo archeologické nálezy.',
]
