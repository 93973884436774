import React from 'react'
import { TechnicalReportFormB1 } from './TechnicalReportFormB1'
import { ProjectFormEnum } from '../../../../../model/enums/ProjectFormEnum'
import { B1_INITIAL_VALUES } from 'shared'
import { TechnicalFormWrapper } from '../B2ConstructionDescription/TechnicalFormWrapper'
import { useProject } from '../../../../../model/hooks/useProject'
import { ProjectLoadingSkeleton } from '../../../layout/ProjectLoadingSkeleton'

interface Props {}

export const B1Area: React.FC<Props> = (props) => {
  const { project } = useProject()

  if (!project) {
    return <ProjectLoadingSkeleton />
  }

  return (
    <TechnicalFormWrapper
      form={ProjectFormEnum.TECHNICAL_FORM_B1}
      initialValues={B1_INITIAL_VALUES}
      title="B.1"
    >
      <TechnicalReportFormB1 />
    </TechnicalFormWrapper>
  )
}
