import React from 'react'
import { WorkspaceSettingsLayout } from '../WorkspaceSettingsLayout'
import { WorkspaceUsersTable } from '../components/WorkspaceUsersTable'

const stats = [
  { name: 'Využité uložistě', stat: '1.2 GB / 10 GB' },
  { name: 'Počet spolupracovníků', stat: '8 / 10' },
  { name: 'Počet projektů', stat: '7 / 20' },
]

interface Props {}

export const WorkspaceUsers: React.FC<Props> = (props) => {
  return (
    <WorkspaceSettingsLayout subtitle="Přehled">
      <WorkspaceUsersTable />
    </WorkspaceSettingsLayout>
  )
}
