import React from 'react'
import { Field, FormikProps } from 'formik'
import { TextInput } from '../../../../../components/inputs/TextInput'
import { RichTextInput } from '../../../../../components/inputs/RichTextInput/RichTextInput'
import { Button } from '../../../../../components/Button'
import { MinusIcon, PlusIcon } from '@heroicons/react/20/solid'
import { FormSectionTwoColumns } from '../../../../../components/forms/FormSectionTwoColumns'
import { DropzoneInput } from '../../../../../components/inputs/DropzoneInput'

interface Props {
  formik: FormikProps<any>
}

export const TechnicalFormB1C: React.FC<Props> = ({ formik }) => {
  return (
    <FormSectionTwoColumns
      title="c) Výjimky"
      subtitle="informace o vydaných rozhodnutích o povolení výjimky z obecných požadavků na využívání území"
      sectionKey="b1c"
    >
      {formik.values.b1c.issuedExceptions.map((exception: any, index: number) => {
        const isLast = formik.values.b1c.issuedExceptions.length === index + 1
        const isFirst = index === 0
        const isEmpty = formik.values.b1c.issuedExceptions.length === 0
        const isOnly = formik.values.b1c.issuedExceptions.length === 1

        return (
          <React.Fragment key={index}>
            <div className={`sm:col-span-6 ${!isFirst && 'mt-4'}`}>
              <Field
                component={TextInput}
                name={`b1c.issuedExceptions[${index}].name`}
                label="Název výjimky"
              />
              <div className="mt-4">
                <Field
                  component={RichTextInput}
                  name={`b1c.issuedExceptions[${index}].description`}
                />
              </div>
              <div className="mt-4">
                <Field
                  component={DropzoneInput}
                  name={`b1c.issuedExceptions[${index}].files`}
                  label="Dokumenty"
                  fileLabel={`B1C - Výjimky ${exception.name ? ' - ' + exception.name : ''}`}
                />
              </div>
              <div className="mt-4 text-right">
                {!isOnly && (
                  <Button
                    className="mr-2"
                    onClick={() =>
                      formik.setFieldValue(
                        'b1c.issuedExceptions',
                        formik.values.b1c.issuedExceptions.filter(
                          (_: any, i: number) => i !== index,
                        ),
                      )
                    }
                  >
                    <MinusIcon className="h-6 inline" />
                  </Button>
                )}
                {(isLast || isEmpty) && (
                  <Button
                    onClick={() =>
                      formik.setFieldValue('b1c.issuedExceptions', [
                        ...formik.values.b1c.issuedExceptions,
                        {},
                      ])
                    }
                  >
                    <span>
                      <PlusIcon className="h-6 inline" />
                    </span>
                  </Button>
                )}
              </div>
            </div>
          </React.Fragment>
        )
      })}
    </FormSectionTwoColumns>
  )
}
