import { useQuery } from 'react-query'
import { ApiUrlEnum } from '../../enums/ApiUrlEnum'
import { api } from '../api'
import { IProjectFormRichText, ProjectFormEnum } from 'shared'
import queryString from 'query-string'
import { ProjectEntityData } from '../../interfaces/generated'

export const useGetProjectFormSectionHelp = (
  currentProjectId: string,
  formEnum: ProjectFormEnum,
) => {
  const query = queryString.stringify({ currentProjectId, formEnum })

  return useQuery({
    queryFn: () =>
      api.get<
        {
          data?: any
          status?: string
          preview?: string
          id: string
          project: ProjectEntityData
        }[]
      >(`${ApiUrlEnum.PROJECT_FORM_SECTION_HELP}?${query}`),
    queryKey: [ApiUrlEnum.PROJECT_FORM_SECTION_HELP, formEnum, currentProjectId],
    enabled: false,
  })
}
