import { useMutation, useQueryClient } from 'react-query'
import { QueryKeyEnum } from '../../enums/QueryKeyEnum'
import { api } from '../api'
import { ApiUrlEnum } from '../../enums/ApiUrlEnum'

export const useUpdateConstructionDiaryOtherEntryMutation = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (data: any) =>
      api.patch(
        `${ApiUrlEnum.PROJECT}/${data.projectId}${ApiUrlEnum.CONSTRUCTION_DIARY_OTHER}/${data.id}`,
        data,
      ),
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeyEnum.CONSTRUCTION_DIARY_OTHER)
    },
  })
}
