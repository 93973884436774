import { IssueFeedTypeEnum } from '../enums/IssueFeedTypeEnum'

export const transformStatusToActionName = (status: IssueFeedTypeEnum) => {
  switch (status) {
    case IssueFeedTypeEnum.COMMENTED:
      return 'komentoval'
    case IssueFeedTypeEnum.REPORTED:
      return 'nahlásil'
    case IssueFeedTypeEnum.FIXED:
      return 'opravil'
    case IssueFeedTypeEnum.CHECKED:
      return 'zkontroloval'
  }
}
