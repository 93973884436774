import { Disclosure } from '@headlessui/react'
import { ChevronRightIcon } from '@heroicons/react/24/outline'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { ISidebarNavigationItem } from '../model/interfaces/ISidebarNavigationItem'

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ')
}

interface Props {
  navigation: ISidebarNavigationItem[]
}

export const SidebarNavigation: React.FC<Props> = ({ navigation }) => {
  const location = useLocation()

  return (
    <div className="mt-3 h-full grow flex-col gap-y-5 overflow-y-autobg-white pr-5">
      <nav className="flex flex-1 flex-col">
        <ul role="list" className="flex flex-1 flex-col gap-y-7">
          <li>
            <ul role="list" className="-mx-2 space-y-1">
              {navigation.map((item) => {
                const current = item.href === location.pathname
                return (
                  <li key={item.name}>
                    {!item.children ? (
                      <Link
                        to={item.href}
                        className={classNames(
                          current ? 'bg-blue-600 text-white' : 'hover:bg-blue-600 hover:text-white',
                          'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold text-gray-700 group',
                        )}
                      >
                        <item.icon
                          className={classNames(
                            current ? 'text-white' : 'group-hover:text-white',
                            'h-6 w-6 shrink-0 text-gray-400',
                          )}
                          aria-hidden="true"
                        />
                        {item.name}
                      </Link>
                    ) : (
                      <Disclosure as="div" defaultOpen={true}>
                        {({ open }) => (
                          <>
                            <Disclosure.Button
                              className={classNames(
                                current
                                  ? 'bg-blue-600 text-white'
                                  : 'hover:bg-blue-600 hover:text-white',
                                'group flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold text-gray-700',
                              )}
                            >
                              <item.icon
                                className={classNames(
                                  current ? 'text-white' : 'group-hover:text-white',
                                  'h-6 w-6 shrink-0 text-gray-400',
                                )}
                                aria-hidden="true"
                              />
                              {item.name}
                              <ChevronRightIcon
                                className={classNames(
                                  open ? 'rotate-90 text-gray-500' : 'text-gray-400',
                                  'ml-auto h-5 w-5 shrink-0',
                                )}
                                aria-hidden="true"
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel as="ul" className="mt-1 px-2">
                              {item.children?.map((subItem) => {
                                const current = subItem.href === location.pathname
                                return (
                                  <li key={subItem.name}>
                                    {/* 44px */}
                                    <Link
                                      to={subItem.href}
                                      className={classNames(
                                        current
                                          ? 'bg-blue-600 text-white'
                                          : 'hover:bg-blue-600 hover:text-white',
                                        'rounded-md py-2 pr-2 pl-9 text-sm leading-6 text-gray-700 flex gap-x-3',
                                      )}
                                    >
                                      {subItem.name}
                                    </Link>
                                  </li>
                                )
                              })}
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    )}
                  </li>
                )
              })}
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  )
}
