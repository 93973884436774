import { useQuery } from 'react-query'
import { api } from '../api'
import querystring from 'query-string'
import { IMunicipalityEntity } from '../../interfaces/IMunicipalityEntity'

export const useSearchMunicipality = (query: string) => {
  const queryString = querystring.stringify({ query })
  return useQuery({
    queryKey: ['municipality', queryString],
    queryFn: () => api.get<IMunicipalityEntity[]>(`/municipality?${queryString}`),
    keepPreviousData: true,
    staleTime: 500,
  })
}
