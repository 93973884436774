import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Field, Form, Formik } from 'formik'
import { TextInput } from '../../components/inputs/TextInput'
import { Button } from '../../components/Button'
import * as Yup from 'yup'
import { UserResetPasswordWrapper } from './UserResetPasswordWrapper'
import { useGetUserPasswordResetHashStatus } from '../../model/api/auth/useGetUserPasswordResetHashStatus'
import { Spinner, useToast } from '@chakra-ui/react'
import { useUpdateUserPasswordResetMutation } from '../../model/api/auth/useUpdateUserPasswordResetMutation'
import { RoutesEnum } from '../../model/enums/RoutesEnum'

interface Props {}

const userResetPasswordValidationSchema = Yup.object().shape({
  password: Yup.string()
    .required('Heslo je povinné')
    .matches(
      /^(?=.*[A-Z])(?=.*\d).{6,}$/,
      'Heslo musí obsahovat minimálně 6 znaků, jedno velké písmeno a jedno číslo',
    ),
  confirmPassword: Yup.string()
    .required('Potvrzení hesla je povinné')
    .oneOf([Yup.ref('password')], 'Hesla se neshodují'),
})

export const UserResetPassword: React.FC<Props> = (props) => {
  const toast = useToast()
  const navigate = useNavigate()
  const { hash } = useParams<{ hash: string }>()
  const { data, isLoading, isError } = useGetUserPasswordResetHashStatus(hash)
  const { mutateAsync, isLoading: isUpdatingPassword } = useUpdateUserPasswordResetMutation()

  if (!hash) {
    return <UserResetPasswordWrapper>Nefunkční odkaz</UserResetPasswordWrapper>
  }

  if (isLoading) {
    return (
      <UserResetPasswordWrapper>
        <Spinner />
      </UserResetPasswordWrapper>
    )
  }

  if (data?.data.valid === false || isError) {
    return <UserResetPasswordWrapper>Nefunkční odkaz</UserResetPasswordWrapper>
  }

  return (
    <UserResetPasswordWrapper>
      <Formik
        validationSchema={userResetPasswordValidationSchema}
        initialValues={{ password: '', confirmPassword: '' }}
        onSubmit={async (values) => {
          await mutateAsync({ password: values.password, hash })
          toast({
            title: 'Heslo bylo úspěšně změněno',
            status: 'success',
            duration: 5000,
            isClosable: true,
          })
          navigate(RoutesEnum.LOGIN)
        }}
      >
        {() => (
          <Form>
            <div className="flex flex-col">
              <div>
                <Field label="Heslo" name="password" component={TextInput} type="password" />
              </div>
              <div className="mt-5">
                <Field
                  label="Heslo znovu"
                  name="confirmPassword"
                  component={TextInput}
                  type="password"
                />
              </div>
              <Button isLoading={isUpdatingPassword} className="mt-5" type="submit">
                Odeslat
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </UserResetPasswordWrapper>
  )
}
