import { useQuery } from 'react-query'
import { api } from '../api'
import { QueryKeyEnum } from '../../enums/QueryKeyEnum'
import { ApiUrlEnum } from '../../enums/ApiUrlEnum'
import { IObjectEntity } from '../../interfaces/IObjectEntity'

export const useGetObjects = (projectId?: string) => {
  return useQuery({
    queryKey: [QueryKeyEnum.OBJECTS, projectId],
    queryFn: () =>
      api.get<IObjectEntity[]>(`${ApiUrlEnum.PROJECT}/${projectId}${ApiUrlEnum.OBJECT}`),
    enabled: !!projectId,
  })
}
