import React from 'react'
import { CheckBadgeIcon } from '@heroicons/react/20/solid'
import { Field, FormikProps, useFormikContext } from 'formik'
import { RichTextInput } from '../inputs/RichTextInput/RichTextInput'
import { LinkButton } from '../LinkButton'
import { get } from 'lodash'
import { FormSectionAsideMenu } from './FormSectionAsideMenu'

interface Props {
  children: React.ReactNode
  title: string
  subtitle?: string | React.ReactNode
  accepted?: boolean
  sectionKey?: string
}

export const FormSectionTwoColumns: React.FC<Props> = (props) => {
  const formik = useFormikContext() as FormikProps<any>
  const isFullyCustom = props.sectionKey
    ? get(formik.values, `${props.sectionKey}.isFullyCustom`, false)
    : false

  const toggleCustom = () => {
    formik.setFieldValue(`${props.sectionKey}.isFullyCustom`, !isFullyCustom)
  }

  return (
    <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 lg:grid-cols-3">
      <div>
        {props.accepted && (
          <div className="h-10 w-10 text-green-400">
            <CheckBadgeIcon />
          </div>
        )}
        <div className="w-full flex justify-between">
          <h2 className="text-base font-semibold leading-7 text-gray-900">{props.title}</h2>
          <FormSectionAsideMenu />
        </div>
        <p className="mt-1 text-sm leading-6 text-gray-600">{props.subtitle}</p>
        {props.sectionKey && (
          <LinkButton onClick={toggleCustom} className="">
            {isFullyCustom ? 'Použít předpřipravený formulář' : 'Použít vlastní formát'}
          </LinkButton>
        )}
      </div>

      <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
        {isFullyCustom ? (
          <div className="sm:col-span-6">
            <Field name={`${props.sectionKey}.fullCustomContent`} component={RichTextInput} />
          </div>
        ) : (
          <>{props.children}</>
        )}
      </div>
    </div>
  )
}
