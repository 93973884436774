import React from 'react'
import { IDocumentEntity } from '../model/interfaces/IDocumentEntity'
import { useFetchImage } from '../model/api/document/useFetchImage'

interface Props {
  image: IDocumentEntity
  className?: string
  onClick?: (e: React.MouseEvent) => void
  alt?: string
}

export const AuthImage: React.FC<Props> = (props) => {
  const { image } = props

  return (
    <img
      key={image.id}
      src={`/uploads/generic/${image.name}`}
      alt={props.alt || image.name || undefined}
      className={`${props.className} bg-gray-200`}
      onClick={props.onClick}
    />
  )
}
