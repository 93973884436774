import React from 'react'
import { ContextMenu } from '../../../../components/ContextMenu'
import { ObjectGridRoomRow } from './RoomFormModal'
import { ObjectGridRow } from './ObjectsGrid'
import { IRoomEntity } from '../../../../model/interfaces/IRoomEntity'
import { useDrag } from 'react-dnd'
import { DropTypeEnum } from '../../../../model/enums/DropTypeEnum'
import { IRoomDropItem } from '../../../../model/interfaces/IRoomDropItem'
import { ArrowsPointingOutIcon } from '@heroicons/react/24/outline'

interface Props {
  room: IRoomEntity
  setRoomFormInit: (value: ObjectGridRoomRow | undefined) => void
  onDeleteRoom: (value: IRoomEntity) => void
  object?: ObjectGridRow
}

export const RoomRow: React.FC<Props> = ({ room, setRoomFormInit, onDeleteRoom, object }) => {
  const dropItem: IRoomDropItem = { id: room.id, objectId: room.object?.id }
  const [{ opacity }, dragRef] = useDrag(
    () => ({
      type: DropTypeEnum.ROOM,
      item: dropItem,
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.5 : 1,
      }),
    }),
    [],
  )

  return (
    <div key={room.id} className="flex justify-between gap-x-4 py-3">
      <dt ref={dragRef} className="flex items-center cursor-pointer text-gray-700">
        <ArrowsPointingOutIcon className="w-4 h-4 text-gray-600 mr-3" />
        {room.name}
      </dt>
      <ContextMenu
        actions={[
          {
            label: 'Upravit',
            onClick: () => {
              setRoomFormInit({
                id: room.id,
                name: room.name,
                objectId: { value: object?.id || '' },
              })
            },
          },
          {
            label: 'Odstranit',
            onClick: () => {
              onDeleteRoom(room)
            },
          },
        ]}
      />
    </div>
  )
}
