import React from 'react'
import { Field, Form, Formik } from 'formik'
import { TextInput } from '../../../../components/inputs/TextInput'
import { ComboInput } from '../../../../components/inputs/ComboInput'
import { contactsToOptions } from '../../../../model/helpers/contactHelpers'
import { ProjectEntityData } from '../../../../model/interfaces/generated'
import { useGetContacts } from '../../../../model/api/contact/useGetContacts'
import { Button } from '../../../../components/Button'
import { useSimpleProjectUpdateMutation } from '../../../../model/api/project/useSimpleProjectUpdateMutation'
import { useToast } from '@chakra-ui/react'
import { ContactSelectInput } from '../../../../components/inputs/ContactSelectInput'
import { MapInput } from '../../../../components/inputs/MapInput/MapInput'
import { FormLabel } from '../../../../components/FormLabel'

interface Props {
  project: ProjectEntityData
}

export const ProjectSimpleForm: React.FC<Props> = (props) => {
  const toast = useToast()
  const { data: investors, isLoading: investorsIsLoading } = useGetContacts()
  const { mutateAsync: updateProject, isLoading: isUpdatingProject } =
    useSimpleProjectUpdateMutation()
  return (
    <Formik
      initialValues={{
        name: props.project.name,
        subject: props.project.subject,
        number: props.project.number,
        investor: props.project.investor?.id,
        latitude: props.project.latitude,
        longitude: props.project.longitude,
      }}
      onSubmit={async (data) => {
        await updateProject({ ...data, id: props.project.id })
        toast({
          title: 'Uloženo',
          status: 'success',
          isClosable: true,
          position: 'top',
        })
      }}
    >
      {(formik) => (
        <Form>
          <div className="mt-3 grid grid-cols-3 gap-x-6 gap-y-3">
            <div className="sm:col-12">
              <Field name="name" component={TextInput} label="Název projektu" />
            </div>
            <div className="sm:col-12">
              <Field name="subject" component={TextInput} label="Předmět" />
            </div>
            <div className="sm:col-12">
              <Field name="number" component={TextInput} label="Číslo projektu" />
            </div>
          </div>
          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-3">
            <div className="sm:col-12">
              <Field
                name="investor"
                label="Investor"
                component={ContactSelectInput}
                options={[]}
                forbidRemove={true}
              />
            </div>
          </div>
          <div className="mt-3 mb-8">
            <div className="mb-3">
              <FormLabel>Poloha</FormLabel>
            </div>
            <div className="grid grid-cols-4 gap-3">
              <div className="col-span-3">
                <MapInput
                  existingPointLngLat={
                    formik.values.longitude && formik.values.latitude
                      ? [formik.values.longitude, formik.values.latitude]
                      : undefined
                  }
                  onPointSelectedLngLat={(lngLat) => {
                    formik.setFieldValue('longitude', lngLat[0])
                    formik.setFieldValue('latitude', lngLat[1])
                  }}
                />
              </div>
              <div className="flex flex-col gap-3">
                <Field name="latitude" label="Latitude" component={TextInput} disabled={true} />
                <Field name="longitude" label="Longitude" component={TextInput} disabled={true} />
              </div>
            </div>
          </div>
          <div className="flex justify-end mt-5">
            <Button type="submit" isLoading={isUpdatingProject}>
              Uložit
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}
