import React from 'react'
import DatePicker, { ReactDatePickerProps } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { FieldProps } from 'formik'
import '../../assets/react-datepicker.css'
import { FormLabel } from '../FormLabel'

interface Props extends ReactDatePickerProps, FieldProps {
  label?: string | React.ReactNode
}

export const DatePickerInput: React.FC<Props> = (props) => {
  const onChange = (date: Date | null) => {
    if (date === null) {
      props.form.setFieldValue(props.field.name, '')
      return
    }

    props.form.setFieldValue(props.field.name, date)
  }

  return (
    <div>
      {props.label && <FormLabel>{props.label}</FormLabel>}
      <DatePicker
        {...props}
        onChange={onChange}
        selected={(props.field.value && new Date(props.field.value)) || null}
        dateFormat={props.showMonthYearPicker ? 'MM/yyyy' : 'dd/MM/yyyy'}
        preventOpenOnFocus={true}
        className="max-h-[37px] mt-2"
      />
    </div>
  )
}
