import React from 'react'
import { BasicLayout } from '../../components/BasicLayout'
import { Flex, Heading, Spacer } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { AddProjectDrawer } from '../../components/drawers/AddProjectDrawer'
import { Button } from '../../components/Button'
import { cn } from 'src/lib/utils'
import { useGetProjects } from '../../model/api/project/useGetProjects'
import { CardTable } from '../../components/CardTable'
import { contactToString } from '../../model/helpers/contactHelpers'
import { RoutesEnum } from '../../model/enums/RoutesEnum'
import { DataTableFacetedFilter } from '../../components/ui/data-table-faceted-filter'
import { formatDate, formatDateTime } from '../../model/helpers/dateHelpers'

export const Projects: React.FC<{}> = (props) => {
  const navigate = useNavigate()
  const { data: projectsResponse, isLoading } = useGetProjects()
  cn('')

  return (
    <BasicLayout title="Projekty">
      <Flex justifyContent="space-between" className="px-8">
        <Heading></Heading>
        <Spacer />
        <AddProjectDrawer>
          <Button size="2xl">Přidat</Button>
        </AddProjectDrawer>
      </Flex>
      <div className="mt-6">
        {isLoading || !projectsResponse || !projectsResponse.data ? (
          <div>Loading</div>
        ) : (
          <CardTable
            columns={[
              {
                heading: 'Projekt',
                cellAccessor: 'name',
              },
              {
                heading: 'Číslo projektu',
                cellAccessor: 'number',
              },
              {
                heading: 'Předmět',
                cellAccessor: 'subject',
              },
              {
                heading: 'Založeno',
                cellCallback: (option) => {
                  const { createdAt } = option
                  return formatDate(createdAt)
                },
              },
              {
                heading: 'Investor',
                cellCallback: (option) => {
                  const { investor } = option

                  if (!investor) {
                    return null
                  }
                  return <>{contactToString(investor)}</>
                },
              },
            ]}
            data={projectsResponse.data.projects!}
            rowAction={(row) => navigate(`${RoutesEnum.PROJECTS}/${row.id}`)}
          />
        )}
      </div>
    </BasicLayout>
  )
}
