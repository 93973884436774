import React from 'react'
import { useCreateRoomMutation } from '../../../../model/api/project/useCreateRoomMutation'
import { useUpdateRoomMutation } from '../../../../model/api/project/useUpdateRoomMutation'
import { UseDisclosureProps } from '@chakra-ui/react'
import { ObjectGridRow } from './ObjectsGrid'
import { IRoomEntity } from '../../../../model/interfaces/IRoomEntity'
import { Field, Form, Formik } from 'formik'
import { TextInput } from '../../../../components/inputs/TextInput'
import { Button } from '../../../../components/Button'
import { TailwindModal } from '../../../../components/TailwindModal'
import { SelectInput } from '../../../../components/inputs/SelectInput'
import { useGetObjects } from '../../../../model/api/project/useGetObjects'

export interface ObjectGridRoomRow {
  id?: string
  name: string
  objectId: {
    value: string
  }
}

interface Props {
  disclosure: UseDisclosureProps
  roomInit: ObjectGridRoomRow
  projectId: string
}

export const RoomFormModal: React.FC<Props> = (props) => {
  const { mutateAsync: createRoomMutation, isLoading: isCreatingRoom } = useCreateRoomMutation()
  const { mutateAsync: updateRoomMutation, isLoading: isUpdatingRoom } = useUpdateRoomMutation()
  const { data: objectsData, isLoading: isLoadingObjects } = useGetObjects(props.projectId)

  const isLoading = isCreatingRoom || isUpdatingRoom || isLoadingObjects

  const onSubmit = async (values: ObjectGridRoomRow) => {
    const roomId = props?.roomInit?.id
    const mutationParams = {
      name: values.name,
      projectId: props.projectId,
      objectId: values.objectId.value,
    }

    roomId
      ? await updateRoomMutation({ ...mutationParams, roomId: roomId })
      : await createRoomMutation(mutationParams)

    props.disclosure.onClose?.()
  }

  return (
    <TailwindModal
      disclosure={props.disclosure}
      dialogTitle={typeof props.roomInit?.id === 'undefined' ? 'Nová místnost' : 'Úprava místnosti'}
    >
      <Formik initialValues={{ ...props.roomInit }} onSubmit={onSubmit}>
        {(formik) => {
          console.log({ values: formik.values })
          return (
            <Form>
              <div className="flex flex-col text-left mb-32">
                <Field name="name" label="Název" component={TextInput} />
                <div className="mt-5">
                  <Field
                    name="objectId"
                    label="Objekt"
                    component={SelectInput}
                    options={
                      objectsData?.data?.map((ob) => ({
                        value: ob.id,
                        label: ob.name,
                      })) || []
                    }
                    forbidCustom={true}
                  />
                </div>
                <div className="flex justify-end mt-5">
                  <Button type="submit" isLoading={isLoading}>
                    Uložit
                  </Button>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </TailwindModal>
  )
}
