import { useQuery } from 'react-query'
import { api } from '../api'
import { QueryKeyEnum } from '../../enums/QueryKeyEnum'
import { ApiUrlEnum } from '../../enums/ApiUrlEnum'
import { IRoomEntity } from '../../interfaces/IRoomEntity'

export const useGetRoom = (projectId: string, roomId: string) => {
  return useQuery({
    queryKey: [QueryKeyEnum.ROOMS, projectId, roomId],
    queryFn: () =>
      api.get<IRoomEntity>(`${ApiUrlEnum.PROJECT}/${projectId}${ApiUrlEnum.ROOM}/${roomId}`),
    enabled: roomId !== 'unassigned',
  })
}
