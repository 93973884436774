import React from 'react'
import { Field, useFormikContext } from 'formik'
import { FormLabel } from '../../../../../components/FormLabel'
import { SignPadInput } from '../../../../../components/inputs/SignPadInput'
import { TextInput } from '../../../../../components/inputs/TextInput'
import { generateRandomString } from 'shared'
import { Button } from '../../../../../components/ui/button'
import { PlusCircleIcon, TrashIcon } from '@heroicons/react/20/solid'
import { get } from 'lodash'

interface Props {
  name: string
  label?: string
  buttonLabel?: string
  onAddSignAlert?: string
}

export const SignaturesArray: React.FC<Props> = (props) => {
  const formik = useFormikContext<any>()
  const path = props.name

  const signatures: Array<{ id: string; author: string; role: string; signature: any }> = get(
    formik.values,
    path,
    [],
  )

  const anySigned = signatures.some((signature) => !!signature.signature)

  const onRemove = (id: string) => {
    if (window.confirm('Opravdu chcete smazat tento záznam?')) {
      const signatures = get(formik.values, path, [])
      formik.setFieldValue(
        path,
        signatures.filter((signature: any) => signature.id !== id),
      )
    }
  }

  const onAdd = () => {
    if (props.onAddSignAlert && !anySigned && !window.confirm(props.onAddSignAlert)) {
      return
    }

    formik.setFieldValue(path, [
      ...get(formik.values, path, []),
      {
        id: generateRandomString(12),
        author: '',
        signature: '',
        role: '',
      },
    ])
  }

  return (
    <div className="mt-8 mb-8">
      <FormLabel>{props.label || 'Podpisy dalších osob'}</FormLabel>
      <div className="mt-3 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3">
        {signatures.map((signature, signatureIndex) => {
          const pathWithIndex = `${path}[${signatureIndex}]`
          const disabled = !! signature?.signature
          return (
            <div key={signature.id} className="bg-gray-50 rounded-md p-5 relative">
              <div className="flex justify-end absolute right-1 top-1 w-4 h-4">
                <Button onClick={() => onRemove(signature.id)} variant="ghost" type="button">
                  <TrashIcon className="h-4 text-red-600" />
                </Button>
              </div>
              <Field
                name={`${pathWithIndex}.author`}
                component={TextInput}
                label="Podepisující osoba"
                disabled={disabled}
              />
              <div className="mt-3">
                <Field
                  name={`${pathWithIndex}.role`}
                  component={TextInput}
                  label="Role podepisující osoby"
                  disabled={disabled}
                />
              </div>
              <Field name={`${pathWithIndex}.signature`} component={SignPadInput} />
            </div>
          )
        })}
      </div>
      <div className="w-full mt-3">
        <Button onClick={onAdd} type="button" variant="outline" className="w-full">
          <PlusCircleIcon className="h-6 text-blue-600 mr-2" />
          {props.buttonLabel ?? 'Přidat další podepsanou osobu'}
        </Button>
      </div>
    </div>
  )
}
