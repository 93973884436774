import React from 'react'
import { useProject } from '../../../../model/hooks/useProject'
import { ProjectLoadingSkeleton } from '../../layout/ProjectLoadingSkeleton'
import { ProjectLayout } from '../../layout/ProjectLayout'
import { CalendarGrid } from '../../../../components/CalendarGrid'
import moment from 'moment'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { RoutesEnum } from '../../../../model/enums/RoutesEnum'
import { dateFormatForUrl } from '../../../../model/constants/dateConstants'
import { useGetCalendarData } from '../../../../model/api/constructionDiary/useGetCalendarData'
import { useGetLatestConstructionDiaryEntries } from '../../../../model/api/constructionDiary/useGetLatestConstructionDiaryEntries'
import { ConstructionDiaryGrid } from './ConstructionDiaryGrid'
import { Tabs, TabsList, TabsTrigger } from '../../../../components/ui/tabs'
import { Button } from '../../../../components/ui/button'
import { ActionPanel } from '../../../../components/ActionPanel'
import { AlertTriangleIcon } from 'lucide-react'
import { ConstructionDiaryDailyEntries } from './ConstructionDiaryDailyEntries'
import { ConstructionDiaryOtherEntries } from './ConstructionDiaryOtherEntries'
import { SmartDrawer } from '../../../../components/SmartDrawer'
import { ConstructionDiaryOtherEntryForm } from './ConstructionDiaryOtherEntryForm'
import { useDisclosure } from '@chakra-ui/react'
import { TailwindModal } from '../../../../components/TailwindModal'

interface Props {}

enum ConstructionDiaryViewsEnum {
  DAILY_ENTRIES = 'dailyEntries',
  OTHER_ENTRIES = 'otherEntries',
}

export const ConstructionDiary: React.FC<Props> = (props) => {
  const otherEntriesFormDisclosure = useDisclosure()
  const showInfoWarning = true
  const [searchParams, setSearchParamsRaw] = useSearchParams({
    view: ConstructionDiaryViewsEnum.DAILY_ENTRIES,
  })
  const view: ConstructionDiaryViewsEnum = (searchParams.get('view') ??
    ConstructionDiaryViewsEnum.DAILY_ENTRIES) as ConstructionDiaryViewsEnum
  const { project, id: projectId } = useProject()
  const navigate = useNavigate()

  if (!project) {
    return <ProjectLoadingSkeleton />
  }

  const onAdd = () => {
    if (view === ConstructionDiaryViewsEnum.OTHER_ENTRIES) {
      otherEntriesFormDisclosure.onOpen()
      return
    }

    const date = moment().format(dateFormatForUrl)
    navigate(
      `${RoutesEnum.PROJECTS}/${projectId}/${RoutesEnum.CONSTRUCTION_DIARY}/${RoutesEnum.CONSTRUCTION_DIARY_ENTRY}/${date}`,
    )
  }

  return (
    <ProjectLayout project={project}>
      <TailwindModal dialogTitle="Přidat záznam" disclosure={otherEntriesFormDisclosure}>
        <ConstructionDiaryOtherEntryForm
          projectId={projectId}
          onSuccess={otherEntriesFormDisclosure.onClose}
        />
      </TailwindModal>
      <div>
        <div className="flex flex-col md:flex-row flex-wrap justify-between items-center gap-6 sm:flex-nowrap ">
          <h1 className="text-base font-semibold leading-7 text-gray-900">Stavební&nbsp;deník</h1>
          <Tabs defaultValue={view} className="w-full">
            <TabsList className="grid w-full grid-cols-2">
              <TabsTrigger
                onClick={() => {
                  setSearchParamsRaw({
                    view: ConstructionDiaryViewsEnum.DAILY_ENTRIES,
                  })
                }}
                value={ConstructionDiaryViewsEnum.DAILY_ENTRIES}
              >
                Denní záznamy
              </TabsTrigger>
              <TabsTrigger
                onClick={() => {
                  setSearchParamsRaw({
                    view: ConstructionDiaryViewsEnum.OTHER_ENTRIES,
                  })
                }}
                value={ConstructionDiaryViewsEnum.OTHER_ENTRIES}
              >
                Ostatní záznamy
              </TabsTrigger>
            </TabsList>
          </Tabs>
          <div className="md:w-auto w-full">
            <Button className="md:w-auto w-full" type="button" onClick={onAdd}>
              Přidat
            </Button>
          </div>
        </div>
        {showInfoWarning && (
          <div className="mt-5 mb-8">
            <ActionPanel
              title={
                <div className="flex items-center gap-3">
                  <AlertTriangleIcon className="text-orange-400" size={24} /> Chybějící
                  identifikační údaje
                </div>
              }
              description="Identifikační údaje zatím nebyly vyplněny. Dle vyhlášky č. 131/2024 Sb., příhloha č. 12 je musí stavební deník obsahovat."
              buttonText="Vyplnit identifikační údaje"
              onClick={() => {
                navigate(
                  `${RoutesEnum.PROJECTS}/${projectId}/${RoutesEnum.SETTINGS}/${RoutesEnum.SETTINGS_CONSTRUCTION_DIARY_INFO}`,
                )
              }}
            />
          </div>
        )}
        {view === ConstructionDiaryViewsEnum.DAILY_ENTRIES && (
          <ConstructionDiaryDailyEntries projectId={projectId} />
        )}
        {view === ConstructionDiaryViewsEnum.OTHER_ENTRIES && (
          <ConstructionDiaryOtherEntries projectId={projectId} />
        )}
      </div>
    </ProjectLayout>
  )
}
