import { useMutation, useQueryClient } from 'react-query'
import { InviteUserValues } from 'shared'
import { api } from '../api'
import { ApiUrlEnum } from '../../enums/ApiUrlEnum'
import { QueryKeyEnum } from '../../enums/QueryKeyEnum'

export const useInviteUserMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (values: InviteUserValues) => api.post(`${ApiUrlEnum.INVITE_USER}`, values),
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeyEnum.USERS)
    },
  })
}
