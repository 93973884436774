import React from 'react'
import { useProject } from '../../../../model/hooks/useProject'
import { ProjectLoadingSkeleton } from '../../layout/ProjectLoadingSkeleton'
import { ProjectLayout } from '../../layout/ProjectLayout'
import { ProjectSimpleForm } from './ProjectSimpleForm'
import { Divider } from '../../../../components/Divider'
import { ObjectGridRow, ObjectsGrid } from './ObjectsGrid'
import { useGetObjects } from '../../../../model/api/project/useGetObjects'
import { useGetRooms } from '../../../../model/api/project/useGetRooms'
import { IdentificationSettings } from './IdentificationSettings'

interface Props {}

export const Settings: React.FC<Props> = (props) => {
  const { project } = useProject()
  const { data: objectsData, isLoading: isLoadingObjects } = useGetObjects(project?.id)
  const { data: roomsData, isLoading: isLoadingRooms } = useGetRooms(project?.id)

  const objectGridRows: ObjectGridRow[] = React.useMemo(() => {
    const out: ObjectGridRow[] = []

    if (objectsData?.data) {
      out.push(...objectsData.data)
    }

    if (roomsData && roomsData.data) {
      out.push({ id: undefined, name: 'Společné', rooms: roomsData.data })
    }

    return out
  }, [roomsData, objectsData])

  if (!project) {
    return <ProjectLoadingSkeleton />
  }

  return (
    <ProjectLayout project={project}>
      <h1 className="text-base font-semibold leading-7 text-gray-900 mb-5">Nastavení projektu</h1>
      <Divider title="Základní informace" />
      <ProjectSimpleForm project={project} />
      <Divider className="my-8" title="Objekty & Místnosti" />
      <ObjectsGrid objects={objectGridRows} projectId={project.id} />
      <div id="identification">
        <Divider className="my-8" title="Identifikační údaje stavby podle projektové dokumentace" />
        <IdentificationSettings />
      </div>
    </ProjectLayout>
  )
}
