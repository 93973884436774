import React from 'react'
import { useProject } from '../../../../../model/hooks/useProject'
import { ProjectLoadingSkeleton } from '../../../layout/ProjectLoadingSkeleton'
import { ProjectLayout } from '../../../layout/ProjectLayout'
import { Button } from '../../../../../components/ui/button'
import { ObjectGridRow, ObjectsGrid } from '../ObjectsGrid'
import { useGetObjects } from '../../../../../model/api/project/useGetObjects'
import { useGetRooms } from '../../../../../model/api/project/useGetRooms'

interface Props {}

export const ObjectsAndRooms: React.FC<Props> = (props) => {
  const { project } = useProject()
  const { data: objectsData, isLoading: isLoadingObjects } = useGetObjects(project?.id)
  const { data: roomsData, isLoading: isLoadingRooms } = useGetRooms(project?.id)

  const objectGridRows: ObjectGridRow[] = React.useMemo(() => {
    const out: ObjectGridRow[] = []

    if (objectsData?.data) {
      out.push(...objectsData.data)
    }

    if (roomsData && roomsData.data) {
      out.push({ id: undefined, name: 'Společné', rooms: roomsData.data })
    }

    return out
  }, [roomsData, objectsData])

  if (!project) {
    return <ProjectLoadingSkeleton />
  }

  return (
    <ProjectLayout project={project}>
      <div>
        <div className="flex flex-col md:flex-row flex-wrap justify-between items-center gap-6 sm:flex-nowrap ">
          <h1 className="text-base font-semibold leading-7 text-gray-900">Objekty a místnosti</h1>
          <div className="md:w-auto w-full"></div>
        </div>
        <ObjectsGrid objects={objectGridRows} projectId={project.id} />
      </div>
    </ProjectLayout>
  )
}
