import { useMutation } from 'react-query'
import { api } from '../api'
import { ApiUrlEnum } from '../../enums/ApiUrlEnum'
import { ProjectFormEnum } from '../../enums/ProjectFormEnum'
import { useToast } from '@chakra-ui/react'

export const useStoreProjectFormMutation = (form: ProjectFormEnum) => {
  const toast = useToast()
  return useMutation({
    mutationFn: (data: any) => api.post(`${ApiUrlEnum.PROJECT_FORM}/${data.id}/${form}`, data),
    onSuccess: () => {
      toast({
        title: 'Uloženo',
        position: 'bottom-right',
        duration: 1000,
        status: 'success',
      })
    },
    onError: () => {
      toast({
        title: 'Chyba',
        description: 'Uložení selhalo',
        position: 'bottom-right',
        duration: 5000,
        status: 'error',
      })
    },
  })
}
