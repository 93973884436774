import React, { useRef, useState } from 'react'
import { FieldProps } from 'formik'
import ReactSignatureCanvas from 'react-signature-canvas'
import { Button } from '../ui/button'
import { SmartDrawer } from '../SmartDrawer'
import { PlusCircleIcon } from '@heroicons/react/20/solid'
import { LucideClipboardSignature } from 'lucide-react'
import { TailwindModal } from '../TailwindModal'
import { useDisclosure } from '@chakra-ui/react'

interface Props extends FieldProps {}

export const SignPadInput: React.FC<Props> = (props) => {
  const [signatureCanvas, setSignatureCanvas] = useState<null | ReactSignatureCanvas>(null)
  const modalDisclosure = useDisclosure()

  const ref = useRef()

  const canvasWrapperStyle = {
    width: 505,
    height: 285,
  }

  const submit = () => {
    if (signatureCanvas) {
      props.form.setFieldValue(props.field.name, signatureCanvas.toDataURL('image/png'))
    }
    modalDisclosure.onClose()
  }

  return (
    <>
      {props.field.value ? (
        <div>
          <div className="mt-2 rounded-md border-dotted border-2">
            <img src={props.field.value} alt="Podpis" className="object-contain" />
          </div>
          <Button
            className="mt-1 w-full"
            type="button"
            variant="outline"
            onClick={() => modalDisclosure.onOpen()}
          >
            Změnit
          </Button>
        </div>
      ) : (
        <Button
          onClick={() => modalDisclosure.onOpen()}
          variant="outline"
          type="button"
          className="mt-3 w-full"
        >
          <LucideClipboardSignature className="h-6 text-blue-600 mr-2" />
          Podepsat
        </Button>
      )}
      <TailwindModal
        dialogTitle="Podpis"
        disclosure={modalDisclosure}
        icon={<LucideClipboardSignature className="h-6 text-gray-700" />}
      >
        <div style={canvasWrapperStyle} className="rounded-md border-dotted border-2">
          <ReactSignatureCanvas
            backgroundColor="white"
            canvasProps={{ width: 500, height: 280, className: 'sigCanvas' }}
            onBegin={() => {}}
            ref={(ref: ReactSignatureCanvas) => setSignatureCanvas(ref)}
          />
        </div>

        <div className="mt-3">
          <Button type="button" onClick={submit}>
            Vložit
          </Button>
        </div>
      </TailwindModal>
    </>
  )
}
