import React from 'react'
import { Field, FormikProps } from 'formik'
import { TextInput } from '../../../../../components/inputs/TextInput'
import { RichTextInput } from '../../../../../components/inputs/RichTextInput/RichTextInput'
import { Button } from '../../../../../components/Button'
import { MinusIcon, PlusIcon } from '@heroicons/react/20/solid'
import { FormSectionTwoColumns } from '../../../../../components/forms/FormSectionTwoColumns'
import { InfoPopover } from '../../../../../components/InfoPopover'
import { Link as ChakraLink } from '@chakra-ui/react'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { CheckboxInput } from '../../../../../components/inputs/CheckboxInput'
import { B1_INITIAL_VALUES } from 'shared'
import { DropzoneInput } from '../../../../../components/inputs/DropzoneInput'

interface Props {
  formik: FormikProps<any>
}

export const TechnicalFormB1D: React.FC<Props> = ({ formik }) => {
  return (
    <FormSectionTwoColumns
      sectionKey={'b1d'}
      title="d) Informace o vydaných stanoviscích"
      subtitle={
        <>
          <div className="flex">
            informace o tom, zda a v jakých částech dokumentace jsou zohledněny podmínky závazných
            stanovisek dotčených orgánů
            <InfoPopover
              content={
                <>
                  Pro ověření, které orgány jsou dotčeny vyplňte o územně plánovací
                  <ChakraLink href="#" isExternal>
                    &nbsp;vyplňte žádost&nbsp;
                    <ExternalLinkIcon mx="2px" />
                    &nbsp;
                  </ChakraLink>
                  informaci nebo kontaktujte QIK Services s.r.o
                </>
              }
            />
          </div>
        </>
      }
    >
      <div className="sm:col-span-6">
        <div className="p-4">
          <Field name="b1d.pretext" component={RichTextInput} />
        </div>
        <div className="p-4">Byly splněny podmínky zejména těchto DOSS</div>
        <ul role="list" className="">
          {formik.values.b1d.test.map((test: any, index: number) => {
            const isLast = formik.values.b1d.test.length === index + 1
            const isFirst = index === B1_INITIAL_VALUES.b1d.test.length - 1
            const isEmpty = formik.values.b1d.test.length === 0
            const isOnly = formik.values.b1d.test.length === 1
            return (
              <li key={index} className="px-4 py-4 sm:px-6">
                <div className="flex">
                  <Field
                    component={CheckboxInput}
                    name={`b1d.test[${index}].checked`}
                    label={test.custom ? '' : test.name}
                  />
                  {test.custom && (
                    //   TODO: Jako soubory
                    <div className="-mt-3 w-full">
                      <Field
                        component={TextInput}
                        name={`b1d.test[${index}].name`}
                        placeholder="Vlastní"
                      />
                    </div>
                  )}
                </div>
                {test.checked && (
                  <div className="mt-3">
                    <Field
                      name={`b1d.test[${index}].files`}
                      component={DropzoneInput}
                      label="Dokumenty"
                      fileLabel={'B1D - DOSS - ' + test.name}
                    />
                  </div>
                )}
                {(isLast || isEmpty) && (
                  <div className="mt-3 w-100 flex justify-end">
                    {!isOnly && !isFirst && (
                      <Button
                        className="mr-2"
                        onClick={() =>
                          formik.setFieldValue(
                            'b1d.test',
                            formik.values.b1d.test.filter((_: any, i: number) => i !== index),
                          )
                        }
                      >
                        <MinusIcon className="h-6 inline" />
                      </Button>
                    )}
                    <Button
                      onClick={() =>
                        formik.setFieldValue('b1d.test', [
                          ...formik.values.b1d.test,
                          {
                            custom: true,
                            name: '',
                            checked: false,
                            description: '',
                          },
                        ])
                      }
                    >
                      <span>
                        <PlusIcon className="h-6 inline" />
                      </span>
                    </Button>
                  </div>
                )}
              </li>
            )
          })}
        </ul>
      </div>
    </FormSectionTwoColumns>
  )
}
