import { useMutation, useQueryClient } from 'react-query'
import { api } from '../api'
import { QueryKeyEnum } from '../../enums/QueryKeyEnum'
import { ApiUrlEnum } from '../../enums/ApiUrlEnum'

export const useUpdateIssueMutation = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (data: any) => {
      const { projectId, issueId, ...rest } = data
      return api.patchForm(`${ApiUrlEnum.PROJECT}/${projectId}${ApiUrlEnum.ISSUE}/${issueId}`, rest)
    },
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyEnum.ISSUES])
    },
  })
}
