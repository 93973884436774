import { useQuery } from 'react-query'
import { QueryKeyEnum } from '../../enums/QueryKeyEnum'
import { api } from '../api'
import { ApiUrlEnum } from '../../enums/ApiUrlEnum'
import { IIssueEntity } from '../../interfaces/IIssueEntity'
import { IssueStatusEnum } from '../../enums/IssueStatusEnum'
import { IPaginatedResponse } from '../../interfaces/IPaginatedResponse'
import queryString from 'query-string'

export const useGetIssues = (
  projectId?: string,
  page?: string,
  limit?: string,
  status?: IssueStatusEnum,
  object?: string,
) => {
  const query = queryString.stringify(
    { status, page, limit, object },
    { skipEmptyString: true, skipNull: true },
  )

  return useQuery({
    queryKey: [QueryKeyEnum.ISSUES, projectId, query],
    queryFn: () =>
      api.get<IPaginatedResponse<IIssueEntity>>(
        `${ApiUrlEnum.PROJECT}/${projectId}${ApiUrlEnum.ISSUE}?${query}`,
      ),
    enabled: !!projectId,
    keepPreviousData: true,
  })
}
