import React from 'react'
import { useProject } from '../../../../model/hooks/useProject'
import { useGetPhotoDocumentationGrouped } from '../../../../model/api/photoDocumentation/useGetPhotoDocumentationGrouped'
import { PhotoDocumentationGroupsList } from './PhotoDocumentationGroupsList'
import { useSearchParams } from 'react-router-dom'
import { PhotoDocumentationRoomView } from './PhotoDocumentationRoomView'
import { Spinner } from '@chakra-ui/react'

interface Props {}

export const PhotoDocumentationGroups: React.FC<Props> = (props) => {
  const { id: projectId } = useProject()
  const [searchParams] = useSearchParams()
  const { data, isLoading } = useGetPhotoDocumentationGrouped(projectId)
  const room = searchParams.get('room')

  const groups = data?.data

  if (isLoading) {
    return (
      <div className="flex justify-center">
        <Spinner size="xl" />
      </div>
    )
  }

  if (!groups) {
    return null
  }

  return (
    <div>
      {room ? (
        <PhotoDocumentationRoomView roomId={room} />
      ) : (
        <PhotoDocumentationGroupsList groups={groups} />
      )}
    </div>
  )
}
