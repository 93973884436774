import React, { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid'
import { classNames } from '../../../../model/helpers/classNameHelpers'
import { RoomRow } from './RoomRow'
import { ObjectGridRoomRow } from './RoomFormModal'
import { ObjectGridRow } from './ObjectsGrid'
import { IRoomEntity } from '../../../../model/interfaces/IRoomEntity'
import { useDrop } from 'react-dnd'
import { DropTypeEnum } from '../../../../model/enums/DropTypeEnum'
import { usePatchRoomMutation } from '../../../../model/api/project/usePatchRoomMutation'
import { IRoomDropItem } from '../../../../model/interfaces/IRoomDropItem'

interface Props {
  object: ObjectGridRow
  setRoomFormInit: (value: ObjectGridRoomRow | undefined) => void
  setObjectFormInit: (value: ObjectGridRow) => void
  onDeleteObject: (value: ObjectGridRow) => void
  onDuplicateObject: (value: ObjectGridRow) => void
  onDeleteRoom: (value: IRoomEntity) => void
  projectId: string
}

export const ObjectRow: React.FC<Props> = ({
  object,
  setRoomFormInit,
  setObjectFormInit,
  onDeleteObject,
  onDuplicateObject,
  onDeleteRoom,
  projectId,
}) => {
  const { mutate: patchRoom } = usePatchRoomMutation()
  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: DropTypeEnum.ROOM,
    drop: (item: IRoomDropItem, monitor) => {
      const { id: roomId } = item
      patchRoom({ projectId, roomId, objectId: object.id })
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }))

  return (
    <li
      ref={drop}
      className={classNames(
        'overflow-hidden rounded-xl border border-gray-200',
        canDrop && 'border-dashed border-2',
        isOver && 'shadow-xl',
      )}
    >
      <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
        <div className="text-sm font-medium leading-6 text-gray-900">{object.name}</div>
        <Menu as="div" className="relative ml-auto">
          <Menu.Button className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
            <span className="sr-only">Open options</span>
            <EllipsisHorizontalIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
              <Menu.Item>
                {({ active }) => (
                  <span
                    onClick={() =>
                      setRoomFormInit({
                        name: '',
                        objectId: { value: object.id || '' },
                      })
                    }
                    className={classNames(
                      active ? 'bg-gray-50' : '',
                      'block px-3 py-1 text-sm leading-6 text-gray-900 cursor-pointer',
                    )}
                  >
                    Přidat místnost<span className="sr-only">, {object.name}</span>
                  </span>
                )}
              </Menu.Item>
              {typeof object.id !== 'undefined' && (
                <>
                  <Menu.Item>
                    {({ active }) => (
                      <span
                        onClick={() => setObjectFormInit(object)}
                        className={classNames(
                          active ? 'bg-gray-50' : '',
                          'block px-3 py-1 text-sm leading-6 text-gray-900 cursor-pointer',
                        )}
                      >
                        Upravit
                      </span>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <span
                        onClick={() => onDuplicateObject(object)}
                        className={classNames(
                          active ? 'bg-gray-50' : '',
                          'block px-3 py-1 text-sm leading-6 text-gray-900 cursor-pointer',
                        )}
                      >
                        Duplikovat
                      </span>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <span
                        onClick={() => onDeleteObject(object)}
                        className={classNames(
                          active ? 'bg-gray-50' : '',
                          'block px-3 py-1 text-sm leading-6 text-gray-900 cursor-pointer',
                        )}
                      >
                        Odstranit<span className="sr-only">, {object.name}</span>
                      </span>
                    )}
                  </Menu.Item>
                </>
              )}
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
      <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6 pb-20">
        {object.rooms.length > 0 ? (
          <>
            {object.rooms.map((room) => (
              <RoomRow
                key={room.id}
                room={room}
                setRoomFormInit={setRoomFormInit}
                onDeleteRoom={onDeleteRoom}
                object={typeof object.id !== 'undefined' ? object : undefined}
              />
            ))}
          </>
        ) : (
          <div className="flex justify-between gap-x-4 py-3">
            <dt className="text-gray-500">Žádné místnosti</dt>
          </div>
        )}
      </dl>
    </li>
  )
}
