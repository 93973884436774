import React from 'react'
import { useParams } from 'react-router-dom'
import { useGetProject } from '../../../model/api/project/useGetProject'
import { ProjectEntityData } from '../../../model/interfaces/generated'
import { Spinner } from '@chakra-ui/react'
import { ProjectLayout } from '../layout/ProjectLayout'
import { useGetFolderForProjectForm } from '../../../model/api/document/useGetFolderForProjectForm'
import { FolderKeyEnum } from '../../../model/enums/FolderKeyEnum'
import { DocumentsTable } from '../../../components/tables/DocumentsTable/DocumentsTable'
import { ProjectLoadingSkeleton } from '../layout/ProjectLoadingSkeleton'

interface Props {
  folderKey: FolderKeyEnum
}

export const DocumentationSection: React.FC<Props> = (props) => {
  const { id } = useParams<{ id: string }>()
  const { data: projectData, isLoading: projectIsLoading } = useGetProject(id || '')
  const project: ProjectEntityData | undefined = projectData?.data?.project
  const { data: folderData, isLoading: folderIsLoading } = useGetFolderForProjectForm(
    props.folderKey,
    id!,
  )

  if (typeof project === 'undefined' || folderIsLoading || projectIsLoading) {
    return <ProjectLoadingSkeleton />
  }

  return (
    <ProjectLayout project={project}>
      <DocumentsTable rootFolder={folderData!.data.id} />
    </ProjectLayout>
  )
}
