import { useMutation, useQueryClient } from 'react-query'
import { api } from '../api'
import { QueryKeyEnum } from '../../enums/QueryKeyEnum'
import { ApiUrlEnum } from '../../enums/ApiUrlEnum'

export const useCreateIssueMutation = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (data: any) => {
      const { projectId, ...rest } = data
      return api.postForm(`${ApiUrlEnum.PROJECT}/${projectId}${ApiUrlEnum.ISSUE}`, rest)
    },
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyEnum.ISSUES])
    },
  })
}
