import { useMutation, useQueryClient } from 'react-query'
import { api } from '../api'
import { ApiUrlEnum } from '../../enums/ApiUrlEnum'
import { QueryKeyEnum } from '../../enums/QueryKeyEnum'

export const useUpdateFolderMutation = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (data: { id: string; name: string }) =>
      api.patch(`${ApiUrlEnum.FOLDER}/${data.id}`, { name: data.name }),
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyEnum.FOLDERS])
    },
  })
}
