import { useQuery } from 'react-query'
import { api } from '../api'
import { ApiUrlEnum } from '../../enums/ApiUrlEnum'
import { IDocumentEntity } from '../../interfaces/IDocumentEntity'

export const downloadDocument = (documentEntity: IDocumentEntity) => {
  api
    .get(`${ApiUrlEnum.DOCUMENT}/${documentEntity.id}`, { responseType: 'blob' })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', documentEntity.originalFilename)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    })
    .catch((error) => {
      console.error('Error downloading document:', error)
    })
}
