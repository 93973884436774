import React from 'react'
import { PaperClipIcon } from '@heroicons/react/20/solid'
import { IDocumentEntity } from '../model/interfaces/IDocumentEntity'
import { transformSizeForDisplay } from '../model/helpers/documentHelpers'
import { downloadDocument } from '../model/api/document/downloadDocument'
import { useDeleteDocumentMutation } from '../model/api/document/useDeleteDocumentMutation'

interface Props {
  documents: IDocumentEntity[]
}

export const DocumentGrid: React.FC<Props> = (props) => {
  const { mutate: deleteDocument } = useDeleteDocumentMutation()
  const handleDownload = (document: IDocumentEntity) => {
    downloadDocument(document)
  }

  const handleDelete = (document: IDocumentEntity) => {
    if (window.confirm(`Smazat soubor ${document.originalFilename}?`)) deleteDocument(document.id)
  }

  return (
    <div className="py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
      <dt className="text-sm font-medium leading-6 text-gray-900"></dt>
      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-3 sm:mt-0">
        <ul role="list" className="divide-y divide-gray-100 rounded-md border border-gray-200">
          {props.documents.map((d) => (
            <li
              key={d.id}
              className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6"
            >
              <div className="flex w-0 flex-1 items-center">
                <PaperClipIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                <div className="ml-4 flex min-w-0 flex-1 gap-2">
                  <span className="truncate font-medium">{d.originalFilename}</span>
                  <span className="invisible: sm:visible flex-shrink-0 text-gray-400">
                    {transformSizeForDisplay(d.size)}
                  </span>
                </div>
              </div>
              <div className="ml-4 flex flex-shrink-0 space-x-4">
                <button
                  type="button"
                  className="rounded-md bg-white font-medium text-blue-600 hover:text-blue-500"
                  onClick={() => handleDownload(d)}
                >
                  Stáhnout
                </button>
                <span className="text-gray-200" aria-hidden="true">
                  |
                </span>
                <button
                  type="button"
                  className="rounded-md bg-white font-medium text-gray-900 hover:text-gray-800"
                  onClick={() => handleDelete(d)}
                >
                  Smazat
                </button>
              </div>
            </li>
          ))}
        </ul>
      </dd>
    </div>
  )
}
