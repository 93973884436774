import React from 'react'
import { useProject } from '../../../../model/hooks/useProject'
import { Button } from '../../../../components/ui/button'
import { ArrowLeft } from 'lucide-react'
import { useSearchParams } from 'react-router-dom'
import { useGetRoom } from '../../../../model/api/project/useGetRoom'
import { useGetPhotoDocumentationForRoom } from '../../../../model/api/photoDocumentation/useGetPhotoDocumentationForRoom'
import { PhotosGrid } from './PhotosGrid'
import { Separator } from '@radix-ui/react-separator'
import { Spinner } from '@chakra-ui/react'

interface Props {
  roomId: string | 'unassigned'
}

export const PhotoDocumentationRoomView: React.FC<Props> = (props) => {
  const { id: projectId } = useProject()
  const [searchParams, setSearchParams] = useSearchParams({
    roomPhotosLimit: '50',
    roomPhotosPage: '1',
  })
  const { data: roomData } = useGetRoom(projectId, props.roomId)
  const { data: photosData, isLoading: isLoadingPhotos } = useGetPhotoDocumentationForRoom(
    projectId,
    props.roomId,
    {
      page: Number(searchParams.get('roomPhotosPage') || 1),
      limit: Number(searchParams.get('roomPhotosLimit') || 50),
    },
  )

  const onBack = () => {
    searchParams.delete('room')
    setSearchParams(searchParams)
  }

  const name =
    props.roomId === 'unassigned'
      ? 'Nepřiřazené'
      : [roomData?.data?.object?.name, roomData?.data?.name].filter(Boolean).join(' - ')

  if (isLoadingPhotos) {
    return (
      <div className="flex justify-center">
        <Spinner size="xl" />
      </div>
    )
  }

  return (
    <div className="">
      <Button onClick={onBack} variant="secondary" className="mb-3">
        <ArrowLeft className="text-gray-500 w-6 h-6 mr-1" />
        Zpět
      </Button>
      <h2 className="text-xl font-bold text-gray-700 mb-5 mt-3">{name} &nbsp;</h2>
      <div className="flex flex-col">
        <PhotosGrid key={props.roomId} photos={photosData?.data?.data || []} />
      </div>
    </div>
  )
}
