import React from 'react'
import { FieldProps } from 'formik'

interface Props extends FieldProps {
  label?: string
  placeholder?: string
  icon: React.ReactNode
}

export const TextInputWithLeadingIcon: React.FC<Props> = (props) => {
  return (
    <div>
      {props.label && (
        <label
          htmlFor={props.field.name}
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          {props.label}
        </label>
      )}
      <div className="relative mt-2 rounded-md shadow-sm">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          {props.icon}
        </div>
        <input
          type="text"
          name={props.field.name}
          id={props.field.name}
          value={props.field.value}
          className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
          placeholder={props.placeholder}
          onChange={props.field.onChange}
        />
      </div>
    </div>
  )
}
