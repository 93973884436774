import React from 'react'
import { RadioGroup } from '@headlessui/react'
import { FieldProps } from 'formik'
import { ISelectOption } from '../model/interfaces/ISelectOption'

const settings = [
  {
    name: 'Public access',
    description: 'This project would be available to anyone who has the link',
  },
  {
    name: 'Private to Project Members',
    description: 'Only members of this project would be able to access',
  },
  { name: 'Private to you', description: 'You are the only one able to access this project' },
]

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ')
}

interface Props extends FieldProps {
  options: ISelectOption[]
  label?: string | React.ReactNode
}

export const RadioInputPanel: React.FC<Props> = (props) => {
  return (
    <RadioGroup
      value={props.field.value}
      onChange={(value) => props.form.setFieldValue(props.field.name, value)}
    >
      {props.label && (
        <RadioGroup.Label className="block text-sm font-medium leading-6 text-gray-900 mb-4">
          {props.label}
        </RadioGroup.Label>
      )}
      <RadioGroup.Label className="sr-only">{props.label}</RadioGroup.Label>
      <div className="-space-y-px rounded-md bg-white">
        {props.options.map((option, settingIdx) => (
          <RadioGroup.Option
            key={option.value}
            value={option.value}
            className={({ checked }) =>
              classNames(
                settingIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                settingIdx === settings.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                checked ? 'z-10 border-blue-200 bg-blue-50' : 'border-gray-200',
                'relative flex cursor-pointer border p-4 focus:outline-none',
              )
            }
          >
            {({ active, checked }) => (
              <>
                <span
                  className={classNames(
                    checked ? 'bg-blue-600 border-transparent' : 'bg-white border-gray-300',
                    active ? 'ring-2 ring-offset-2 ring-blue-600' : '',
                    'mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center',
                  )}
                  aria-hidden="true"
                >
                  <span className="rounded-full bg-white w-1.5 h-1.5" />
                </span>
                <span className="ml-3 flex flex-col">
                  <RadioGroup.Label
                    as="span"
                    className={classNames(
                      checked ? 'text-blue-900' : 'text-gray-900',
                      'block text-sm font-medium',
                    )}
                  >
                    {option.label}
                  </RadioGroup.Label>
                  <RadioGroup.Description
                    as="span"
                    className={classNames(
                      checked ? 'text-blue-700' : 'text-gray-500',
                      'block text-sm',
                    )}
                  >
                    {option.description}
                  </RadioGroup.Description>
                </span>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  )
}
