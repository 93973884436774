import React from 'react'
import { UseDisclosureProps } from '@chakra-ui/react'
import { ContactForm } from '../forms/ContactForm/ContactForm'
import { ContactEntityData } from '../../model/interfaces/generated'
import { TailwindModal } from '../TailwindModal'

interface Props {
  disclosure: UseDisclosureProps
  onSuccess?: (contract?: ContactEntityData) => void
  editContact?: ContactEntityData
}

export const ContactFormModal: React.FC<Props> = (props) => {
  const onSuccess = (contact?: ContactEntityData) => {
    props.onSuccess && props.onSuccess(contact)
    props.disclosure.onClose && props.disclosure.onClose()
  }

  return (
    <TailwindModal
      className="w-full sm:max-w-4xl"
      disclosure={props.disclosure}
      dialogTitle="Kontakt"
    >
      <ContactForm onSuccess={onSuccess} editContact={props.editContact} />
    </TailwindModal>
  )
}
