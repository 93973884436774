import { useQuery } from 'react-query'
import { api } from '../api'
import { QueryKeyEnum } from '../../enums/QueryKeyEnum'
import { ApiUrlEnum } from '../../enums/ApiUrlEnum'
import { PaginatedInterface, PaginationParams } from 'shared'
import queryString from 'query-string'
import { IRoomEntity } from '../../interfaces/IRoomEntity'
import { IPhotoDocumentationGrouped } from '../../interfaces/IPhotoDocumentationGrouped'

export const useGetPhotoDocumentationGrouped = (projectId: string) => {
  return useQuery({
    queryFn: () =>
      api.get<IPhotoDocumentationGrouped>(
        `${ApiUrlEnum.PROJECT}/${projectId}${ApiUrlEnum.PHOTO_DOCUMENTATION}/grouped`,
      ),
    queryKey: [QueryKeyEnum.PHOTO_DOCUMENTATION, projectId, 'grouped'],
  })
}
