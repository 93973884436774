import React from 'react'
import { ISelectOption } from '../../model/interfaces/ISelectOption'
import { FieldProps } from 'formik'
import { FormLabel } from '../FormLabel'

interface Props extends FieldProps {
  options: ISelectOption[]
  label?: string
}

const RadioInput: React.FC<Props> = (props) => {
  return (
    <fieldset>
      <FormLabel>{props.label}</FormLabel>
      <legend className="sr-only">{props.label}</legend>
      <div className="space-y-2 mt-3">
        {props.options.map((option) => (
          <div key={option.value} className="relative flex items-start">
            <div className="flex h-6 items-center">
              <input
                id={`${props.field.name}-${option.value}`}
                aria-describedby={`${option.value}-description`}
                name={props.field.name}
                type="radio"
                checked={option.value === props.field.value}
                className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                onClick={() => props.form.setFieldValue(props.field.name, option.value)}
              />
            </div>
            <div className="ml-3 text-sm leading-6">
              <label
                htmlFor={`${props.field.name}-${option.value}`}
                className="font-medium text-gray-900"
              >
                {option.label}
              </label>{' '}
              <span id={`${option.value}-description`} className="text-gray-500">
                {option.description && <>{option.description}</>}
              </span>
            </div>
          </div>
        ))}
      </div>
    </fieldset>
  )
}

export default RadioInput
