export enum QueryKeyEnum {
  CONTACTS = 'contacts',
  PROJECTS = 'projects',
  PROJECT_FORMS = 'project-forms',
  CURRENT_USER = 'current-user',
  FOLDERS = 'folders',
  ROOMS = 'rooms',
  OBJECTS = 'objects',
  ISSUES = 'issues',
  DOCUMENTS = 'documents',
  USERS = 'users',
  PROJECT_FORM_RICH_TEXT = 'project-form-rich-text',
  PHOTO_DOCUMENTATION = 'photo-documentation',
  CONSTRUCTION_DIARY = 'construction-diary',
  CONSTRUCTION_DIARY_OTHER = 'construction-diary-other',
  TASK = 'task',
}
