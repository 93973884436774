import React, { useState } from 'react'
import { Drawer } from './Drawer'
import { AddProjectForm } from '../../pages/Projects/AddProjectForm'
import { SmartDrawer } from '../SmartDrawer'
import { Button } from '../ui/button'

export const AddProjectDrawer: React.FC<{ children: React.ReactNode }> = (props) => {
  const [open, setOpen] = useState(false)

  return (
    <Drawer
      {...props}
      open={open}
      setOpen={setOpen}
      title="Nový projekt"
      description="Vytvořte nový projekt"
      content={<AddProjectForm closeDrawer={() => setOpen(false)} />}
    />
  )
}
