import React from 'react'
import { CalendarGrid } from '../../../../components/CalendarGrid'
import moment from 'moment/moment'
import { RoutesEnum } from '../../../../model/enums/RoutesEnum'
import { dateFormatForUrl } from '../../../../model/constants/dateConstants'
import { ConstructionDiaryGrid } from './ConstructionDiaryGrid'
import { useGetCalendarData } from '../../../../model/api/constructionDiary/useGetCalendarData'
import { useNavigate } from 'react-router-dom'

interface Props {
  projectId: string
}

export const ConstructionDiaryDailyEntries: React.FC<Props> = ({ projectId }) => {
  const { data: calendarData } = useGetCalendarData(projectId)
  const navigate = useNavigate()

  return (
    <div className="md:mt-5 grid grid-cols-1 xl:grid xl:grid-cols-12 xl:gap-x-16">
      <CalendarGrid
        year={2024}
        month={8}
        onDateClicked={(date: moment.Moment) => {
          navigate(
            `${RoutesEnum.PROJECTS}/${projectId}/${RoutesEnum.CONSTRUCTION_DIARY}/${
              RoutesEnum.CONSTRUCTION_DIARY_ENTRY
            }/${date.format(dateFormatForUrl)}`,
          )
        }}
        selectedDates={calendarData?.data.filter((item) => item.isSigned).map((item) => item.date)}
        unfilledDates={calendarData?.data.filter((item) => !item.isSigned).map((item) => item.date)}
      />
      <div className="mt-4 divide-y divide-gray-100 text-sm leading-6 xl:col-span-8">
        <ConstructionDiaryGrid />
      </div>
    </div>
  )
}
