import { useMutation, useQueryClient } from 'react-query'
import { api } from '../api'
import { ApiUrlEnum } from '../../enums/ApiUrlEnum'
import { QueryKeyEnum } from '../../enums/QueryKeyEnum'

export const useUpdateRoomMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({
      name,
      projectId,
      roomId,
      objectId,
    }: {
      name: string
      projectId: string
      roomId: string
      objectId?: string
    }) =>
      api.put(`${ApiUrlEnum.PROJECT}/${projectId}${ApiUrlEnum.ROOM}/${roomId}`, {
        name,
        objectId,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyEnum.OBJECTS])
      queryClient.invalidateQueries([QueryKeyEnum.ROOMS])
    },
  })
}
