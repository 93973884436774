import React from 'react'
import { Field, Form, Formik } from 'formik'
import { TextInput } from '../../../../components/inputs/TextInput'
import { DatePickerInput } from '../../../../components/inputs/DatePickerInput'
import { NumberInput } from '../../../../components/inputs/NumberInput'

interface Props {}

export const IdentificationSettings: React.FC<Props> = (props) => {
  return (
    <div className="">
      <Formik initialValues={{}} onSubmit={() => {}}>
        {(formik) => (
          <Form>
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-3">
              <Field name="name" component={TextInput} label="Název stavby" />
              <Field name="date" component={DatePickerInput} label="Datum vydání" />
              <Field name="number" component={TextInput} label="Číslo jednací" />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}
