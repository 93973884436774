import { createBrowserRouter, Navigate } from 'react-router-dom'
import { Projects } from './pages/Projects/Projects'
import { RoutesEnum } from './model/enums/RoutesEnum'
import { Playground } from './pages/Playground/Playground'
import React from 'react'
import { Project } from './pages/Project/Project'
import { B1Area } from './pages/Project/subpages/TechnicalReportForm/B1Area/B1Area'
import { Login } from './pages/Login/Login'
import { Contacts } from './pages/Contacts/Contacts'
import { DocumentationSection } from './pages/Project/subpages/DocumentationSection'
import { FolderKeyEnum } from './model/enums/FolderKeyEnum'
import { Issues } from './pages/Project/subpages/Issues/Issues'
import { IssueForm } from './pages/Project/subpages/Issues/IssueForm'
import { Settings } from './pages/Project/subpages/Settings/Settings'
import { AccompanyingReport } from './pages/Project/subpages/AccompanyingReport/AccompanyingReport'
import { ProjectFormEnum } from './model/enums/ProjectFormEnum'
import { TechnicalReportForm } from './pages/Project/subpages/TechnicalReportForm/TechnicalReportForm'
import { B2ConstructionDescription } from './pages/Project/subpages/TechnicalReportForm/B2ConstructionDescription/B2ConstructionDescription'
import { B3TechnicalConnections } from './pages/Project/subpages/TechnicalReportForm/B3TechnicalConnections/B3TechnicalConnections'
import { B4TransportSolutions } from './pages/Project/subpages/TechnicalReportForm/B4TransportSolutions/B4TransportSolutions'
import { B5Terrain } from './pages/Project/subpages/TechnicalReportForm/B5Terrain/B5Terrain'
import { B6EnvironmentProtection } from './pages/Project/subpages/TechnicalReportForm/B6EnvironmentProtection/B6EnvironmentProtection'
import { B7PopulationProtection } from './pages/Project/subpages/TechnicalReportForm/B7PopulationProtection/B7PopulationProtection'
import { B9Water } from './pages/Project/subpages/TechnicalReportForm/B9Water/B9Water'
import { B8Organization } from './pages/Project/subpages/TechnicalReportForm/B8Organization/B8Organization'
import { ProjectPreview } from './pages/Preview/ProjectPreview'
import { UserProfile } from './pages/UserProfile/UserProfile'
import { UserResetPassword } from './pages/UserResetPassword/UserResetPassword'
import { UserForgottenPassword } from './pages/UserForgottenPassword/UserForgottenPassword'
import { WorkspaceSettings } from './pages/WorkspaceSettings/WorkspaceSettings'
import { WorkspaceUsers } from './pages/WorkspaceSettings/subpages/WorkspaceUsers'
import { WorkspaceBilling } from './pages/WorkspaceSettings/subpages/WorkspaceBilling'
import { AI } from './pages/AI/AI'
import { PhotoDocumentation } from './pages/Project/subpages/PhotoDocumentation/PhotoDocumentation'
import { ConstructionDiary } from './pages/Project/subpages/ConstructionDiary/ConstructionDiary'
import { ConstructionDiaryEntry } from './pages/Project/subpages/ConstructionDiary/ConstructionDiaryEntry/ConstructionDiaryEntry'
import { BasicInformation } from './pages/Project/subpages/Settings/BasicInformation/BasicInformation'
import { ObjectsAndRooms } from './pages/Project/subpages/Settings/ObjectsAndRooms/ObjectsAndRooms'
import { ConstructionDiaryInfo } from './pages/Project/subpages/Settings/ConstructionDiaryInfo/ConstructionDiaryInfo'
import { Tasks } from './pages/Project/subpages/Tasks/Tasks'
import { Tasks as TasksPage } from './pages/Tasks/Tasks'

export const router = createBrowserRouter([
  {
    path: RoutesEnum.LOGIN,
    element: <Login />,
  },
  {
    path: RoutesEnum.PROJECTS,
    element: <Projects />,
  },
  {
    path: `${RoutesEnum.PROJECTS}/:id`,
    element: <Project />,
  },
  {
    path: `${RoutesEnum.PROJECTS}/:id/${RoutesEnum.TECHNICAL_REPORT_FORM}`,
    element: <TechnicalReportForm />,
  },
  {
    path: `${RoutesEnum.PROJECTS}/:id/${RoutesEnum.TECHNICAL_REPORT_FORM}/${ProjectFormEnum.TECHNICAL_FORM_B1}`,
    element: <B1Area />,
  },
  {
    path: `${RoutesEnum.PROJECTS}/:id/${RoutesEnum.TECHNICAL_REPORT_FORM}/${ProjectFormEnum.TECHNICAL_FORM_B2}`,
    element: <B2ConstructionDescription />,
  },
  {
    path: `${RoutesEnum.PROJECTS}/:id/${RoutesEnum.TECHNICAL_REPORT_FORM}/${ProjectFormEnum.TECHNICAL_FORM_B3}`,
    element: <B3TechnicalConnections />,
  },
  {
    path: `${RoutesEnum.PROJECTS}/:id/${RoutesEnum.TECHNICAL_REPORT_FORM}/${ProjectFormEnum.TECHNICAL_FORM_B4}`,
    element: <B4TransportSolutions />,
  },
  {
    path: `${RoutesEnum.PROJECTS}/:id/${RoutesEnum.TECHNICAL_REPORT_FORM}/${ProjectFormEnum.TECHNICAL_FORM_B5}`,
    element: <B5Terrain />,
  },
  {
    path: `${RoutesEnum.PROJECTS}/:id/${RoutesEnum.TECHNICAL_REPORT_FORM}/${ProjectFormEnum.TECHNICAL_FORM_B6}`,
    element: <B6EnvironmentProtection />,
  },
  {
    path: `${RoutesEnum.PROJECTS}/:id/${RoutesEnum.TECHNICAL_REPORT_FORM}/${ProjectFormEnum.TECHNICAL_FORM_B7}`,
    element: <B7PopulationProtection />,
  },
  {
    path: `${RoutesEnum.PROJECTS}/:id/${RoutesEnum.TECHNICAL_REPORT_FORM}/${ProjectFormEnum.TECHNICAL_FORM_B8}`,
    element: <B8Organization />,
  },
  {
    path: `${RoutesEnum.PROJECTS}/:id/${RoutesEnum.TECHNICAL_REPORT_FORM}/${ProjectFormEnum.TECHNICAL_FORM_B9}`,
    element: <B9Water />,
  },
  {
    path: `${RoutesEnum.PROJECTS}/:id/${RoutesEnum.DOCUMENTATION_SECTION}`,
    element: <DocumentationSection folderKey={FolderKeyEnum.PROJECT_FORM_ATTACHMENTS} />,
  },
  {
    path: `${RoutesEnum.PROJECTS}/:id/${RoutesEnum.RESEARCH_AND_SURVEY}`,
    element: <DocumentationSection folderKey={FolderKeyEnum.PROJECT_RESEARCH_AND_SURVEYS} />,
  },
  {
    path: `${RoutesEnum.PROJECTS}/:id/${RoutesEnum.SITUATION_DOCUMENTATION}`,
    element: <DocumentationSection folderKey={FolderKeyEnum.PROJECT_SITUATION_DOCUMENTATION} />,
  },
  {
    path: `${RoutesEnum.PROJECTS}/:id/${RoutesEnum.ENGINEERING}`,
    element: <DocumentationSection folderKey={FolderKeyEnum.ENGINEERING} />,
  },
  {
    path: `${RoutesEnum.PROJECTS}/:id/${RoutesEnum.OBJECT_DOCUMENTATION}`,
    element: <DocumentationSection folderKey={FolderKeyEnum.OBJECT_DOCUMENTATION} />,
  },
  {
    path: `${RoutesEnum.PROJECTS}/:id/${RoutesEnum.ISSUES}`,
    element: <Issues />,
  },
  {
    path: `${RoutesEnum.PROJECTS}/:id/${RoutesEnum.ISSUES}/${RoutesEnum.ISSUES_FORM}?/:issueId`,
    element: <IssueForm />,
  },
  {
    path: `${RoutesEnum.PROJECTS}/:id/${RoutesEnum.SETTINGS}`,
    element: <Settings />,
  },
  {
    path: `${RoutesEnum.PROJECTS}/:id/${RoutesEnum.SETTINGS}/${RoutesEnum.SETTINGS_BASIC_INFO}`,
    element: <BasicInformation />,
  },
  {
    path: `${RoutesEnum.PROJECTS}/:id/${RoutesEnum.SETTINGS}/${RoutesEnum.SETTINGS_OBJECTS_AND_ROOMS}`,
    element: <ObjectsAndRooms />,
  },
  {
    path: `${RoutesEnum.PROJECTS}/:id/${RoutesEnum.SETTINGS}/${RoutesEnum.SETTINGS_CONSTRUCTION_DIARY_INFO}`,
    element: <ConstructionDiaryInfo />,
  },
  {
    path: `${RoutesEnum.PROJECTS}/:id/${RoutesEnum.ACCOMPANYING_REPORT}`,
    element: <AccompanyingReport />,
  },
  {
    path: `${RoutesEnum.PROJECTS}/:id/${RoutesEnum.PHOTO_DOCUMENTATION}`,
    element: <PhotoDocumentation />,
  },
  {
    path: `${RoutesEnum.PROJECTS}/:id/${RoutesEnum.CONSTRUCTION_DIARY}`,
    element: <ConstructionDiary />,
  },
  {
    path: `${RoutesEnum.PROJECTS}/:id/${RoutesEnum.CONSTRUCTION_DIARY}/${RoutesEnum.CONSTRUCTION_DIARY_ENTRY}/:date`,
    element: <ConstructionDiaryEntry />,
  },
  {
    path: `${RoutesEnum.PROJECTS}/:id/${RoutesEnum.TASKS}`,
    element: <Tasks />,
  },
  {
    path: RoutesEnum.CONTACTS,
    element: <Contacts />,
  },
  {
    path: RoutesEnum.USER_PROFILE,
    element: <UserProfile />,
  },
  {
    path: `${RoutesEnum.USER_RESET_PASSWORD}/:hash`,
    element: <UserResetPassword />,
  },
  {
    path: `${RoutesEnum.USER_FORGOTTEN_PASSWORD}`,
    element: <UserForgottenPassword />,
  },
  {
    path: `${RoutesEnum.WORKSPACE_SETTINGS}`,
    element: <WorkspaceSettings />,
  },
  {
    path: `${RoutesEnum.WORKSPACE_SETTINGS_USERS}`,
    element: <WorkspaceUsers />,
  },
  {
    path: `${RoutesEnum.WORKSPACE_SETTINGS_BILLING}`,
    element: <WorkspaceBilling />,
  },
  {
    path: `${RoutesEnum.TASKS}`,
    element: <TasksPage />,
  },
  {
    path: `${RoutesEnum.AI}`,
    element: <AI />,
  },
  {
    path: '/playground',
    element: <Playground />,
  },
  {
    path: 'preview/project/:projectId',
    element: <ProjectPreview />,
  },
  {
    path: '/',
    element: <Navigate to={RoutesEnum.PROJECTS} replace />,
  },
])
