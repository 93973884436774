import { QuestionIcon } from '@chakra-ui/icons'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react'
import React from 'react'
import { SecondaryButton } from './SecondaryButton'

interface Props {
  title?: string
  content: string | React.ReactNode
}

export const InfoPopover: React.FC<Props> = (props) => {
  const { isOpen, onClose, onOpen } = useDisclosure()
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{props.title || 'Nápověda'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{props.content}</ModalBody>
          <ModalFooter>
            <SecondaryButton onClick={onClose}>Zavřít</SecondaryButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <span className="inline ml-3 cursor-pointer text-blue-600" onClick={onOpen}>
        <QuestionIcon />
      </span>
    </>
  )
}
