import React from 'react'
import { AuthImage } from '../../../../components/AuthImage'
import { IPhotoDocumentationGrouped } from '../../../../model/interfaces/IPhotoDocumentationGrouped'
import { useSearchParams } from 'react-router-dom'

interface Props {
  room?: IPhotoDocumentationGrouped['rooms'][0]['room']
  photoDocumentations: IPhotoDocumentationGrouped['rooms'][0]['photoDocumentations']
}

export const PhotoDocumentationRoomCard: React.FC<Props> = ({ room, photoDocumentations }) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const onRoomClick = () => {
    searchParams.set('room', room?.id ?? 'unassigned')
    setSearchParams(searchParams)
  }

  return (
    <div
      onClick={onRoomClick}
      className="bg-white transform-all hover:scale-[1.02] hover:shadow-md cursor-pointer rounded-md flex flex-col p-4 border-solid border border-gray-200 hover:border-blue-700 transition-all"
    >
      <span className="text-lg text-gray-700 font-bold">
        {room ? [room?.object?.name, room?.name].filter(Boolean).join(' - ') : 'Nepřiřazené'}
      </span>
      <div className="grid grid-cols-2 gap-3 pt-3">
        {photoDocumentations.data.map((photoDocumentation, photoDocumentationIndex) => (
          <div className="" key={photoDocumentation.id}>
            <AuthImage
              image={photoDocumentation.image}
              alt={photoDocumentation.name}
              className="h-24 w-full mx-auto object-cover shadow-md rounded-md"
            />
          </div>
        ))}
        {photoDocumentations.totalCount > 3 && (
          <div className="w-full h-full text-2xl bg-gray-50 border-dotted border-2 rounded-md flex justify-center justify-items-center align-baseline">
            <div className="mx-auto my-auto">{`+${photoDocumentations.totalCount - 3}`}</div>
          </div>
        )}
      </div>
    </div>
  )
}
