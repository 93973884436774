import React from 'react'

interface Props {
  onClick?: () => void
  children: React.ReactNode
  className?: string
}

export const LinkButton: React.FC<Props> = (props) => {
  return (
    <button
      onClick={props.onClick}
      type="button"
      className={`${
        props.className ? props.className : ''
      } py-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-blue-600 hover:text-blue-800 disabled:opacity-50 disabled:pointer-events-none dark:text-blue-500 dark:hover:text-blue-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600`}
    >
      {props.children}
    </button>
  )
}
