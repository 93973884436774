import { useQuery } from 'react-query'
import { api } from '../api'
import { QueryKeyEnum } from '../../enums/QueryKeyEnum'
import { ApiUrlEnum } from '../../enums/ApiUrlEnum'
import queryString from 'query-string'

export const useGetRooms = (projectId?: string, objectId?: string) => {
  const query = queryString.stringify(
    { object: objectId },
    { skipNull: true, skipEmptyString: true },
  )

  return useQuery({
    queryKey: [QueryKeyEnum.ROOMS, projectId],
    queryFn: () => api.get(`${ApiUrlEnum.PROJECT}/${projectId}${ApiUrlEnum.ROOM}?${query}`),
    enabled: !!projectId,
  })
}
