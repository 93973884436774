import { api } from '../api'
import { ApiUrlEnum } from '../../enums/ApiUrlEnum'

export const downloadPreview = async (projectId: string, section: 'a' | 'b') => {
  try {
    let response = await api.get(
      `/project-form/${projectId}/technical-form/preview?section=${section}`,
      {
        responseType: 'blob',
      },
    )
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'zprava.pdf')
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  } catch (error) {
    console.error('Error downloading document:', error)
  }
}
