import { Field, FormikProps } from 'formik'
import React from 'react'
import { ACCOMPANYING_REPORT_FORM_INITIAL_DATA } from './constants'
import { Button } from '../../../../components/Button'
import { MinusIcon } from '@chakra-ui/icons'
import { PlusIcon } from '@heroicons/react/24/outline'
import { SecondaryButton } from '../../../../components/SecondaryButton'
import { TextInput } from '../../../../components/inputs/TextInput'
import { removeElement } from '../../../../model/helpers/arrayHelpers'
import { generateRandomString } from '../../../../model/helpers/stringHelpers'
import { Divider } from '../../../../components/Divider'

interface Props {
  formik: FormikProps<typeof ACCOMPANYING_REPORT_FORM_INITIAL_DATA>
}

export const AccompanyingReportA3: React.FC<Props> = ({ formik }) => {
  return (
    <>
      <Divider title="A.3 Seznam vstupních podkladů" className="mt-8" />
      {formik.values.a3.baseDocumentations.map((s, sIndex) => (
        <div key={s.indexIdentifier} className="grid grid-cols-1 mt-3">
          <div className="flex justify-center gap-3">
            <Field
              placeholder="Název vstupního podkladu"
              wrapperClassname="w-full"
              name={`a3.baseDocumentations[${sIndex}].type`}
              component={TextInput}
            />
            <SecondaryButton
              className="mt-2"
              onClick={() =>
                formik.setFieldValue(
                  'a3.baseDocumentations',
                  removeElement(formik.values.a3.baseDocumentations, sIndex),
                )
              }
            >
              <MinusIcon className="h-5 w-5" />
            </SecondaryButton>
          </div>
        </div>
      ))}
      <div className="flex justify-end mt-3 gap-3">
        <Button
          onClick={() =>
            formik.setFieldValue('a3.baseDocumentations', [
              ...formik.values.a3.baseDocumentations,
              { indexIdentifier: generateRandomString() },
            ])
          }
        >
          <PlusIcon className="h-5 w-5" />
        </Button>
      </div>
    </>
  )
}
