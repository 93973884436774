import React, { JSX } from 'react'
import { MinusIcon, PlusIcon } from '@heroicons/react/20/solid'
import { Button } from './Button'
import { FormikProps, useFormikContext } from 'formik'
import { get } from 'lodash'

interface Props {
  rows?: any[]
  name: string
  setFieldValue?: (name: string, value: any) => void
  initializationObject: any
  children: (name: string, row: any, index: number) => React.ReactNode | JSX.Element
}

export const ArrayForm: React.FC<Props> = (props) => {
  const formik = useFormikContext() as FormikProps<any>
  const rows = get(formik.values, props.name) as unknown as Array<any>

  return (
    <ul className="p-0 m-0 w-100">
      {rows.map((row, index) => {
        const isLast = rows.length === index + 1
        const isFirst = index === 0

        return (
          <li key={index} className="w-100">
            <div className="">{props.children(`${props.name}[${index}]`, row, index)}</div>
            {isLast && (
              <div className="mt-3 w-100 flex justify-end">
                {!isFirst && (
                  <Button
                    className="mr-2"
                    onClick={() =>
                      formik.setFieldValue(
                        props.name,
                        rows.filter((_: any, i: number) => i !== index),
                      )
                    }
                  >
                    <MinusIcon className="h-6 inline" />
                  </Button>
                )}
                <Button
                  onClick={() =>
                    formik.setFieldValue(props.name, [...rows, { ...props.initializationObject }])
                  }
                >
                  <span>
                    <PlusIcon className="h-6 inline" />
                  </span>
                </Button>
              </div>
            )}
          </li>
        )
      })}
    </ul>
  )
}
