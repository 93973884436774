import { MinusIcon } from '@chakra-ui/icons'
import { Divider } from '@chakra-ui/react'
import { PlusIcon } from '@heroicons/react/24/outline'
import { Field, FormikProps } from 'formik'
import React from 'react'
import { Button } from '../../../../components/Button'
import { SecondaryButton } from '../../../../components/SecondaryButton'
import { ContactSelectInput } from '../../../../components/inputs/ContactSelectInput'
import { MunicipalitySearchInput } from '../../../../components/inputs/MunicipalitySearchInput'
import RadioInput from '../../../../components/inputs/RadioInput'
import { SelectInput } from '../../../../components/inputs/SelectInput'
import { TextInput } from '../../../../components/inputs/TextInput'
import { removeElement } from '../../../../model/helpers/arrayHelpers'
import { generateRandomString } from '../../../../model/helpers/stringHelpers'
import { TYPE_OF_PLOT_SELECT_OPTIONS } from '../../constants/options'
import { ACCOMPANYING_REPORT_FORM_INITIAL_DATA } from './constants'
import { NumberInput } from '../../../../components/inputs/NumberInput'

interface Props {
  formik: FormikProps<typeof ACCOMPANYING_REPORT_FORM_INITIAL_DATA>
}

export const AccompanyingReportA1: React.FC<Props> = ({ formik }) => {
  return (
    <div className="">
      <Divider title="A.1 Identifikační údaje" />
      <p className="font-semibold mt-1 text-base leading-6 text-gray-900">A.1.1 Údaje o stavbě</p>
      <p className="font-semibold mt-3 text-base leading-6 text-gray-800">a) Název stavby</p>
      <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
        <div className="sm:col-span-2">
          <Field name="a1.constructionName" component={TextInput} />
        </div>
      </div>
      <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
        <div className="sm:col-span-6">
          <p className="font-semibold mt-1 text-base leading-6 text-gray-800">b) Místo stavby</p>
        </div>
      </div>
      {formik.values.a1.places.map((place, placeIndex) => (
        <div className={placeIndex !== 0 ? 'mt-8' : 'mt-3'} key={place.indexIdentifier}>
          <div className="w-full grid grid-cols-1 sm:grid-cols-2 mt-3 gap-3">
            <Field
              label="Katastrální území"
              name={`a1.places[${placeIndex}].cadastre`}
              component={MunicipalitySearchInput}
            />
          </div>
          <div className="w-full grid grid-cols-1 sm:grid-cols-8 mt-3 gap-3">
            <Field
              label="Parcelní číslo"
              name={`a1.places[${placeIndex}].parcelNumber`}
              component={TextInput}
              wrapperClassname="col-span-1 sm:col-span-2"
            />
            <Field
              label="Číslo popisné"
              name={`a1.places[${placeIndex}].streetNumber`}
              component={TextInput}
              wrapperClassname="col-span-1 sm:col-span-2"
            />
            <Field
              label="Výměra"
              name={`a1.places[${placeIndex}].area`}
              component={NumberInput}
              wrapperClassname="col-span-1 sm:col-span-1"
              trailing={
                <span className="text-gray-500 sm:text-sm" id="price-currency">
                  m<sup>2</sup>
                </span>
              }
            />
            <Field
              wrapperClassname="col-span-1 sm:col-span-3"
              label="Druh pozemku"
              name={`a1.places[${placeIndex}].type`}
              component={SelectInput}
              options={TYPE_OF_PLOT_SELECT_OPTIONS}
            />
          </div>
          <div className="flex justify-end mt-3">
            <SecondaryButton
              onClick={() =>
                formik.setFieldValue(
                  'a1.places',
                  removeElement(formik.values.a1.places, placeIndex),
                )
              }
            >
              <MinusIcon className="h-5 w-5" />
            </SecondaryButton>
          </div>
        </div>
      ))}
      <div className="flex justify-end mt-3">
        <Button
          onClick={() =>
            formik.setFieldValue('a1.places', [
              ...formik.values.a1.places,
              { indexIdentifier: generateRandomString() },
            ])
          }
        >
          <PlusIcon className="h-5 w-5" />
        </Button>
      </div>
      <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2">
        <div className="sm:col-span-6">
          <p className="font-semibold mt-1 text-base leading-6 text-gray-800">
            c) Předmět dokumentace
          </p>
        </div>
      </div>
      <div className="w-full grid grid-cols-1 sm:grid-cols-3 mt-3 gap-5">
        <Field
          name="a1.isNewConstruction"
          component={RadioInput}
          options={[
            {
              label: 'Nová stavba',
              value: true,
            },
            {
              label: 'Změna dokončené stavby',
              value: false,
            },
          ]}
        />
        <Field
          name="a1.isPersistent"
          component={RadioInput}
          options={[
            {
              label: 'Trvalá stavba',
              value: true,
            },
            {
              label: 'Dočasná stavba',
              value: false,
            },
          ]}
        />
        <Field name="a1c.purpose" placeholder="Účel užívání" component={TextInput} />
      </div>

      <p className="font-semibold mt-12 text-base leading-6 text-gray-900">
        A.1.2 Údaje o stavebníkovi
      </p>
      <Field name="builder" component={ContactSelectInput} options={[]} />

      <p className="font-semibold mt-12 text-base leading-6 text-gray-900">
        A.1.3 Údaje o zpracovateli společné dokumentace
      </p>
      <div className="mt-3">
        <Field
          name="a1.documentationAuthor"
          label="Zpracovatel"
          component={ContactSelectInput}
          options={[]}
        />
      </div>
      <div className="mt-5">
        <Field
          name="a1.primaryDocumentationAuthor"
          label="Hlavní projektant"
          component={ContactSelectInput}
        />
      </div>
      <div className="mt-8">
        <p className="font-semibold text-md">Projektanti jednotlivých částí společné dokumentace</p>
        {formik.values.a1.otherAuthors.map((other, otherIndex) => (
          <div className="mt-3 grid grid-cols-1 sm:grid-cols-4 gap-3" key={otherIndex}>
            <div className="col-span-1">
              <Field
                name={`a1.otherAuthors[${otherIndex}].label`}
                component={TextInput}
                label="Část společné dokumentace"
              />
            </div>
            <div className="col-span-3">
              <Field
                name={`a1.otherAuthors[${otherIndex}].author`}
                component={ContactSelectInput}
                label="Projektant"
                extraButtons={
                  <SecondaryButton
                    className="ml-3"
                    onClick={() =>
                      formik.setFieldValue(
                        'a1.otherAuthors',
                        removeElement(formik.values.a1.otherAuthors, otherIndex),
                      )
                    }
                  >
                    <MinusIcon className="h-5 w-5" />
                  </SecondaryButton>
                }
              />
            </div>
          </div>
        ))}
        <div className="flex justify-end mt-3 gap-3">
          <Button
            onClick={() =>
              formik.setFieldValue('a1.otherAuthors', [
                ...formik.values.a1.otherAuthors,
                { indexIdentifier: generateRandomString() },
              ])
            }
          >
            <PlusIcon className="h-5 w-5" />
          </Button>
        </div>
      </div>
    </div>
  )
}
