import { useMutation, useQueryClient } from 'react-query'
import { UpdatePhotoDocumentationValues } from 'shared'
import { api } from '../api'
import { ApiUrlEnum } from '../../enums/ApiUrlEnum'
import { QueryKeyEnum } from '../../enums/QueryKeyEnum'

export const useUpdatePhotoDocumentationMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({
      projectId,
      photoId,
      data,
    }: {
      projectId: string
      photoId: string
      data: UpdatePhotoDocumentationValues
    }) =>
      api.post(
        `${ApiUrlEnum.PROJECT}/${projectId}${ApiUrlEnum.PHOTO_DOCUMENTATION}/${photoId}`,
        data,
      ),
    onSuccess: () => {
      return queryClient.invalidateQueries([QueryKeyEnum.PHOTO_DOCUMENTATION])
    },
  })
}
