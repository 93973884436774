import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import React from 'react'

interface Props {
  page: number
  limit: number
  totalCount: number
  totalPages: number
  setPage: (page: number) => void
}

export const PaginationButton: React.FC<Props> = (props) => {
  const canGoBack = props.page > 1
  const canGoForward = props.page < props.totalPages

  const goForward = () => {
    if (canGoForward) {
      props.setPage(props.page + 1)
    }
  }

  const goBack = () => {
    if (canGoBack) {
      props.setPage(props.page - 1)
    }
  }

  return (
    <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
      <div className="flex flex-1 justify-between sm:hidden">
        <div>
          {canGoBack && (
            <button
              onClick={goBack}
              className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              Předchozí
            </button>
          )}
        </div>
        <div>
          {canGoForward && (
            <button
              onClick={goForward}
              className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              Další
            </button>
          )}
        </div>
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Stránka <span className="font-medium">{props.page}</span> z{' '}
            <span className="font-medium">{props.totalPages}</span>. Celkem záznamů:{' '}
            <span className="font-medium">{props.totalCount}</span>
          </p>
        </div>
        <div>
          <nav
            className="isolate inline-flex -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            <div>
              {canGoBack && (
                <button
                  onClick={goBack}
                  className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                >
                  <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              )}
            </div>
            <div>
              {canGoForward && (
                <button
                  onClick={goForward}
                  className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                >
                  <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              )}
            </div>
          </nav>
        </div>
      </div>
    </div>
  )
}
