import React from 'react'
import { useProject } from '../../../../model/hooks/useProject'
import { ProjectLoadingSkeleton } from '../../layout/ProjectLoadingSkeleton'
import { ProjectLayout } from '../../layout/ProjectLayout'
import { Field, Formik, FormikHelpers } from 'formik'
import { SelectInput } from '../../../../components/inputs/SelectInput'
import { TextAreaInput } from '../../../../components/inputs/TextAreaInput'
import { ImageDropzoneInput } from '../../../../components/ImageDropzoneInput'
import { Button } from '../../../../components/Button'
import { IssueSeverityEnum } from '../../../../model/enums/IssueSeverityEnum'
import { ISSUE_FORM_VALIDATION_SCHEMA } from '../../constants/ValidationSchemas'
import { useGetRooms } from '../../../../model/api/project/useGetRooms'
import { useCreateIssueMutation } from '../../../../model/api/project/useCreateIssueMutation'
import { useToast } from '@chakra-ui/react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { RoutesEnum } from '../../../../model/enums/RoutesEnum'
import { useGetIssue } from '../../../../model/api/project/useGetIssue'
import { IssueStatusEnum } from '../../../../model/enums/IssueStatusEnum'
import { RadioInputPanel } from '../../../../components/RadioInputPanel'
import { useUpdateIssueMutation } from '../../../../model/api/project/useUpdateIssueMutation'
import { ForwardIcon, TrashIcon } from '@heroicons/react/24/outline'
import { useDeleteIssueMutation } from '../../../../model/api/project/useDeleteIssueMutation'
import { IssuesFeed } from './IssuesFeed'
import { Divider } from '../../../../components/Divider'
import { useGetObjects } from '../../../../model/api/project/useGetObjects'
import queryString from 'query-string'

let submitAction: 'SAVE_AND_CLOSE' | 'SAVE_AND_CONTINUE' = 'SAVE_AND_CLOSE'

interface Props {}

export const IssueForm: React.FC<Props> = (props) => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const [selectedObjectId, setSelectedObjectId] = React.useState<undefined | string>(undefined)
  const toast = useToast()
  const { issueId } = useParams<{ issueId?: string }>()
  const { id: projectId, project } = useProject()
  const {
    data: roomsData,
    isLoading: roomsLoading,
    isRefetching: roomsIsRefetching,
    refetch: refetchRooms,
  } = useGetRooms(project?.id, selectedObjectId)
  const { data: objectData, isLoading: objectsLoading } = useGetObjects(project?.id)
  const { mutateAsync: createIssue, isLoading: isCreatingIssue } = useCreateIssueMutation()
  const { mutateAsync: updateIssue, isLoading: isUpdatingIssue } = useUpdateIssueMutation()
  const { mutateAsync: deleteIssue, isLoading: isDeletingIssue } = useDeleteIssueMutation()

  const { data: issueData, isLoading: issueLoading } = useGetIssue(
    projectId,
    issueId === 'issues-form' ? undefined : issueId,
  )

  React.useEffect(() => {
    if (typeof issueData?.data?.room?.object?.id !== 'undefined') {
      setSelectedObjectId(issueData?.data?.room?.object?.id)
      setTimeout(refetchRooms, 100)
    }
  }, [issueData?.data?.room?.object?.id])

  if (typeof project === 'undefined' || (!!issueId && issueLoading)) {
    return <ProjectLoadingSkeleton />
  }

  const onSubmit = async (values: any, form: FormikHelpers<any>) => {
    const { submitter, ...formValues } = values
    const room = values.room.value
    const roomIsCustom = values.room.isCustom
    const object = values.object.value
    const objectIsCustom = values.object.isCustom

    typeof issueData !== 'undefined'
      ? await updateIssue({
          ...formValues,
          room,
          roomIsCustom,
          object,
          objectIsCustom,
          issueId: issueData.data.id,
          projectId: project?.id,
        })
      : await createIssue({
          projectId: project?.id,
          ...formValues,
          room,
          roomIsCustom,
          object,
          objectIsCustom,
        })

    toast({
      title: 'Uloženo',
      status: 'success',
      position: 'top',
      isClosable: true,
    })

    const params = queryString.stringify(
      {
        object: searchParams.get('object'),
        room: roomIsCustom ? undefined : room.value,
      },
      {
        skipNull: true,
        skipEmptyString: true,
      },
    )
    navigate(`${RoutesEnum.PROJECTS}/${project?.id}/${RoutesEnum.ISSUES}?${params}`)
  }

  const onDeleteIssue = async () => {
    if (window.confirm('Skutečně smazat?')) {
      await deleteIssue({ issueId: issueData!.data.id, projectId: project!.id })
      toast({
        title: 'Smazáno',
        status: 'success',
        position: 'top',
        isClosable: true,
      })
      const params = queryString.stringify(
        {
          object: searchParams.get('object'),
        },
        {
          skipNull: true,
          skipEmptyString: true,
        },
      )
      navigate(`${RoutesEnum.PROJECTS}/${project?.id}/${RoutesEnum.ISSUES}?${params}`)
    }
  }

  const isLoading = isCreatingIssue || isUpdatingIssue || isDeletingIssue

  return (
    <ProjectLayout project={project}>
      <Formik
        initialValues={
          issueData
            ? {
                ...issueData!.data,
                room: { value: issueData!.data.room.id || '' },
                object: { value: issueData!.data.room?.object?.id || '' },
              }
            : {
                status: IssueStatusEnum.REPORTED,
                severity: null,
                object: { value: searchParams.get('object') || '', isCustom: false },
                room: { value: searchParams.get('room') || '', isCustom: false },
              }
        }
        onSubmit={onSubmit}
        validationSchema={ISSUE_FORM_VALIDATION_SCHEMA}
      >
        {(formik) => {
          const { values } = formik
          if (values.object?.value !== selectedObjectId && !values.object?.isCustom) {
            setSelectedObjectId(values.object?.value)
            setTimeout(refetchRooms, 100)
          }

          if (values.object?.isCustom && typeof selectedObjectId !== 'undefined') {
            setSelectedObjectId(undefined)
            setTimeout(refetchRooms, 100)
          }

          return (
            <form onSubmit={formik.handleSubmit}>
              <div className="space-y-12">
                <div className="pb-12">
                  <div className="flex flex-col gap-3 px-8">
                    <div className="">
                      <Field
                        name="object"
                        component={SelectInput}
                        isLoading={objectsLoading}
                        label="Objekt"
                        options={[
                          ...(objectData?.data?.map((r: any) => ({
                            value: r.id,
                            label: r.name,
                          })) || []),
                        ]}
                        eraseOnCustom={true}
                        onChange={() => {
                          formik.setFieldValue('room', {
                            value: '',
                            isCustom: formik.values.room?.isCustom,
                          })
                        }}
                      />
                    </div>
                    <div className="">
                      <Field
                        name="room"
                        component={SelectInput}
                        label="Místnost"
                        options={[
                          ...(roomsData?.data?.map((r: any) => ({
                            value: r.id,
                            label: r.name,
                          })) || []),
                        ]}
                        eraseOnCustom={true}
                      />
                    </div>
                    <div className="">
                      <Field name="description" component={TextAreaInput} label="Popis" />
                    </div>
                    <div>
                      <Field
                        name="image"
                        component={ImageDropzoneInput}
                        label="Foto vady"
                        accept={{ images: ['image/*'] }}
                      />
                    </div>
                    <div className="">
                      <Field
                        name="status"
                        component={RadioInputPanel}
                        label="Stav"
                        options={[
                          {
                            label: 'Nahlášeno',
                            value: IssueStatusEnum.REPORTED,
                          },
                          {
                            label: 'Opraveno',
                            value: IssueStatusEnum.FIXED,
                          },
                          {
                            label: 'Zkontrolováno',
                            value: IssueStatusEnum.CHECKED,
                          },
                        ]}
                      />
                    </div>
                    {values.status !== IssueStatusEnum.REPORTED && (
                      <div>
                        <Field
                          name="afterFixImage"
                          component={ImageDropzoneInput}
                          label="Foto po opravě"
                          accept={{ images: ['image/*'] }}
                        />
                      </div>
                    )}
                    <div className="mt-3">
                      <Field
                        name="severity"
                        label="Závažnost"
                        component={RadioInputPanel}
                        options={[
                          {
                            label: 'Neurčeno',
                            value: null,
                          },
                          {
                            label: 'Nízká',
                            value: IssueSeverityEnum.LOW,
                          },
                          {
                            label: 'Střední',
                            value: IssueSeverityEnum.MEDIUM,
                          },
                          {
                            label: 'Vysoká',
                            value: IssueSeverityEnum.HIGH,
                          },
                        ]}
                      />
                    </div>
                    <div className="flex justify-end gap-3">
                      {issueData && (
                        <Button
                          isLoading={isLoading}
                          color="danger"
                          className="flex justify-center items-center"
                          onClick={onDeleteIssue}
                        >
                          <TrashIcon className="w-5 h-5 mr-1" />
                          Smazat
                        </Button>
                      )}
                      <Button
                        className="flex justify-center items-center"
                        name="SAVE_AND_CLOSE"
                        isLoading={isLoading}
                        onClick={() => {
                          submitAction = 'SAVE_AND_CLOSE'
                          formik.handleSubmit()
                        }}
                      >
                        Uložit
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          )
        }}
      </Formik>
      {issueData && projectId && (
        <>
          <Divider title="Historie" />
          <div className="mt-8 px-8">
            <IssuesFeed issueId={issueData.data.id} projectId={projectId} />
          </div>
        </>
      )}
    </ProjectLayout>
  )
}
