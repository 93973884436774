import React from 'react'
import { IPhotoDocumentationGrouped } from '../../../../model/interfaces/IPhotoDocumentationGrouped'
import { PhotoDocumentationRoomCard } from './PhotoDocumentationRoomCard'

interface Props {
  groups: IPhotoDocumentationGrouped
}

export const PhotoDocumentationGroupsList: React.FC<Props> = ({ groups }) => {
  return (
    <div className="grid grid-cols-2 sm:grid-cols-3 gap-3">
      <PhotoDocumentationRoomCard
        key="unassigned"
        photoDocumentations={groups.unassigned.photoDocumentations || []}
      />
      {(groups.rooms || []).map(({ room, photoDocumentations }) => (
        <PhotoDocumentationRoomCard
          photoDocumentations={photoDocumentations}
          room={room}
          key={room.id}
        />
      ))}
    </div>
  )
}
