import { useQuery } from 'react-query'
import { api } from '../api'
import { ApiUrlEnum } from '../../enums/ApiUrlEnum'
import { IProjectFormListItem } from 'shared'
import { QueryKeyEnum } from '../../enums/QueryKeyEnum'

export const useGetTechnicalFormsList = (projectId: string) => {
  return useQuery({
    queryFn: () =>
      api.get<{ list: { a: IProjectFormListItem[]; b: IProjectFormListItem[] } }>(
        `${ApiUrlEnum.PROJECT_FORM}/${projectId}/technical-form/list`,
      ),
    queryKey: [QueryKeyEnum.PROJECT_FORMS],
  })
}
