import { useMutation, useQueryClient } from 'react-query'
import { api } from '../api'
import { ApiUrlEnum } from '../../enums/ApiUrlEnum'
import { QueryKeyEnum } from '../../enums/QueryKeyEnum'

export const useUpdateUserMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (userUpdateData: { name: string }) => {
      return api.patch(ApiUrlEnum.USER, userUpdateData)
    },
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyEnum.CURRENT_USER])
    },
  })
}
