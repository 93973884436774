"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.A_FORMS = exports.B_FORMS = exports.ACCOMPANYING_REPORT_FORM_INITIAL_DATA = exports.B9_FORM_INITIAL_VALUES = exports.B8_FORM_INITIAL_VALUES = exports.B7_FORM_INITIAL_VALUES = exports.B6_FORM_INITIAL_VALUES = exports.B5_FORM_INITIAL_VALUES = exports.B4_FORM_INITIAL_VALUES = exports.B3_FORM_INITIAL_VALUES = exports.B2_FORM_INIT_VALUES = exports.B1_INITIAL_VALUES = void 0;
const utils_1 = require("./utils");
const enums_1 = require("./enums");
const B3_SECTION_INITIAL_VALUES = {
    enabled: false,
    description: '',
    size: '',
    power: '',
    length: '',
    label: '',
};
exports.B1_INITIAL_VALUES = {
    test: '',
    radio: 'email',
    person: 'Person 1',
    b1a: {
        aiGenerated: '',
        aiGeneratedAccepted: false,
        isBuildingPlot: true,
        typeOfPlot: '',
        placement: {
            value: '',
            custom: false,
        },
        shape: '',
        landSlope: '',
        previousUtilization: '',
        urbanization: '',
        surroundingArea: '',
        landscapeCharacter: true,
        landscapeCharacterDescription: '',
    },
    b1b: {
        complianceWithPlanningDocumentation: true,
        complianceWithPlanningDocumentationDescription: '',
        complianceWithPlanningGoalsTasks: true,
        complianceWithPlanningGoalsTasksDescription: '',
    },
    b1c: {
        issuedExceptions: [
            {
                name: '',
                file: '',
                description: '',
            },
        ],
    },
    b1d: {
        pretext: `
        V projektové dokumentaci byly zohledněny podmínky závazných stanovisek dotčených
        orgánů, a to především v její textové části, situačních výkresech a
        architektonicko-stavební části. Veškerá stanoviska jsou přílohou projektové
        dokumentace (E - dokladová část).
    `,
        test: [
            {
                name: 'Krajská hygienická stanice',
                checked: false,
                description: '',
            },
            {
                name: 'Odbor dopravy',
                checked: false,
                description: '',
            },
            {
                name: 'Odbor územního plánování',
                checked: false,
                description: '',
            },
            {
                name: 'Odbor životního prostředí',
                checked: false,
                description: '',
            },
            {
                name: 'Odbor ochrany prostředí',
                checked: false,
                description: '',
            },
            {
                name: 'Odbor dopravy',
                checked: false,
                description: '',
            },
            {
                name: 'Hasičský záchranný sbor',
                checked: false,
                description: '',
            },
            {
                name: 'Vodoprávní úřad',
                checked: false,
                description: '',
            },
            {
                name: 'Obecní úřad s rozšířenou působností',
                checked: false,
                description: '',
            },
            {
                custom: true,
                name: '',
                checked: false,
                description: '',
            },
        ],
    },
    b1e: {
        geologicalSurvey: false,
        geologicalSurveyConclusion: '',
        hydrogeologicalSurvey: false,
        hydrogeologicalSurveyConclusion: '',
        historicalBuildingSurvey: false,
        historicalBuildingSurveyConclusion: '',
        engineerGeologySurvey: false,
        engineerGeologySurveyConclusion: '',
        radonMeasurement: false,
        radonMeasurementLevel: '',
        radonMeasurementConclusion: '',
        custom: [
            {
                name: '',
                enabled: false,
                conclusion: '',
                files: [],
            },
        ],
    },
    b1f: {
        territoryProtection: {
            enabled: false,
            conclusion: '',
        },
    },
    b1g: {
        floodArea: {
            enabled: false,
            measures: '',
            positions: '',
            forms: '',
        },
        underminedArea: {
            enabled: false,
            measures: '',
            positions: '',
            forms: '',
        },
        others: [
            {
                name: '',
                enabled: false,
                measures: '',
            },
        ],
    },
    b1h: {
        impactOnSurroundingBuildings: {
            enabled: false,
            measures: '',
        },
        impactOnSurroundingLands: {
            enabled: false,
            measures: '',
        },
        protectionOfSurroundings: {
            enabled: false,
            measures: '',
        },
        impactOnDrainageConditions: {
            enabled: false,
            measures: '',
        },
    },
    b1i: {
        sanitation: {
            enabled: false,
            details: '',
            permissionRequired: '',
            form: '',
            extraApproval: false,
        },
        demolition: {
            enabled: false,
            details: '',
            permissionRequired: '',
            form: '',
            extraApproval: false,
        },
        treeFelling: {
            enabled: false,
            details: '',
            permissionRequired: '',
            form: '',
        },
    },
    b1j: {
        landSeizureAgricultural: {
            enabled: false,
            details: '',
        },
        landSeizureForest: {
            enabled: false,
            details: '',
        },
    },
    b1k: {
        connectionToTransportInfrastructure: {
            enabled: false,
            newOrExistingExit: '',
        },
        connectionToTechnicalInfrastructure: {
            waterSupply: {
                enabled: false,
                newOrExistingConnection: '',
            },
            sewerage: {
                enabled: false,
                newOrExistingConnection: '',
            },
            stormDrain: {
                enabled: false,
                newOrExistingConnection: '',
                disposalOnOwnLand: '',
            },
            gasPipeline: {
                enabled: false,
                newOrExistingConnection: '',
            },
            electricity: {
                enabled: false,
                newOrExistingConnection: '',
            },
            communicationLines: {
                enabled: false,
                newOrExistingConnection: '',
            },
            other: '',
            custom: [
                {
                    name: '',
                    enabled: false,
                    newOrExistingConnection: '',
                    description: '',
                },
            ],
        },
        barrierFreeAccess: {
            enabled: false,
            details: '',
        },
    },
    b1l: {
        objectAndTimeConnections: {
            allAtOnce: true,
            custom: '',
        },
        conditionalInvestments: false,
        conditionalInvestmentsDetails: '',
        triggeredInvestments: false,
        triggeredInvestmentsDetails: '',
        relatedInvestments: false,
        relatedInvestmentsDetails: '',
    },
};
exports.B2_FORM_INIT_VALUES = {
    b2: {
        characteristics: {
            a: {
                newOrChange: '',
                currentState: '',
                technicalState: '',
                historicalState: '',
                bearingConstructionsCheck: '',
            },
            b: {
                usage: '',
            },
            c: {
                temporaryOrPermanent: '',
                temporaryFrom: new Date(),
                temporaryTo: new Date(),
            },
            d: {
                barrierFreeExceptions: false,
                technicalExceptions: false,
                technicalExceptionsDescription: '',
                barrierFreeExceptionsDescription: '',
            },
            e: {
                doss: '',
            },
            f: {
                constructionProtection: false,
            },
            g: {
                buildUpArea: '',
                enclosedArea: '',
                usableArea: '',
                units: [
                    {
                        value: '',
                    },
                ],
            },
            h: {
                constructionBalanceSubstanceConsumtion: false,
                constructionBalanceRainWater: false,
                constructionBalanceWasteAndEmissions: false,
                constructionBalanceEnergyConsumption: false,
            },
            i: {
                divisionToStages: '',
            },
            j: {
                estimatedCosts: '',
            },
        },
        barrierFreeUsage: {
            isBarrierFree: false,
            description: 'Stavba je navržena a bude provedena v souladu s vyhláškou č. 398/2009 Sb. - Vyhláška o obecných technických požadavcích zabezpečujících bezbariérové užívání staveb  ',
        },
        safety: {
            description: 'Stavba je navržena a bude provedena v souladu se všemi  platnými normami, předpisy a vyhláškami, které zabezpečují bezpečnost při užívání. Mezi hlavní patří:<br/>' +
                '<br/>' +
                '- vyhláška č. 268/2009 Sb. o obecných technických požadavcích na stavby<br/>' +
                '- vyhláška č. 398/2009 Sb., o obecných technických požadavcích zabezpečujících bezbariérové užívání staveb<br/>' +
                '- Vyhláška č. 246/2001 Sb. o stanovení podmínek požární bezpečnosti a výkonu státního požárního dozoru (vyhláška o požární prevenci)<br/>' +
                '- ČSN 73 4130 – schodiště a šikmé rampy<br/>' +
                '- ČSN 74 3305 – ochranná zábradlí<br/>' +
                '- ČSN 73 0810 – Požární bezpečnost staveb – společná ustanovení<br/>' +
                '- ČSN 33 2000-4-41 ED.3 - elektrické instalace nízkého napětí - Část 4-41: Ochranná opatření pro zajištění bezpečnosti - Ochrana před úrazem elektrickým proudem<br/>' +
                '- A další<br/>' +
                '<br/>' +
                'Hlavní uzávěry, vypínače a další prvky, které to vyžadují, budou označeny příslušnými tabulkami.<br/>' +
                '<br/>' +
                'Veškeré technologie umístěné v objektu, budou dodány včetně dokladů o způsobu bezpečného užívání. <br/>' +
                '<br/>' +
                'K jednotlivým profesím, zařízením, instalacím a rozvodům budou vystaveny revizní zprávy o způsobilosti k bezpečnému provozu. <br/>' +
                '<br/>' +
                'Zařízení technického vybavení objektu budou podléhat pravidelnému servisu a budou ovládána zaškolenými pracovníky.“<br/>',
        },
        fireSafety: {
            description: 'Řešeno samostatnou částí projektové dokumentace D.1.3 - Požárně-bezpečnostní řešení',
        },
        energyAndHeat: {
            description: 'Viz. průkaz energetické náročnosti budovy, který je součástí dokladové části projektové dokumentace.',
        },
        hygiene: {
            others: [
                {
                    indexIdentifier: (0, utils_1.generateRandomString)(),
                    name: '',
                    description: '',
                },
            ],
        },
        externalFactorsProtection: {
            wayWardStream: false,
            technicalSeismicity: false,
            noise: false,
            floodProtection: false,
            underMining: false,
            methane: false,
            others: [
                {
                    name: '',
                    description: '',
                },
            ],
        },
        objectCharacteristics: {
            building: '',
            mechanicsAndStability: '',
            constructionAndMaterial: {
                vertical: {
                    description: '',
                    grid: [
                        {
                            name: '',
                            width: '',
                            description: '',
                            layers: [
                                {
                                    name: '',
                                    width: '',
                                    description: '',
                                },
                            ],
                        },
                    ],
                },
                horizontal: {
                    description: '',
                    grid: [
                        {
                            name: '',
                            width: '',
                            description: '',
                            layers: [
                                {
                                    name: '',
                                    width: '',
                                    description: '',
                                },
                            ],
                        },
                    ],
                },
                foundation: {
                    description: '',
                    grid: [
                        {
                            name: '',
                            width: '',
                            description: '',
                            layers: [
                                {
                                    name: '',
                                    width: '',
                                    description: '',
                                },
                            ],
                        },
                    ],
                },
                roof: {
                    description: '',
                    grid: [
                        {
                            name: '',
                            width: '',
                            description: '',
                            layers: [
                                {
                                    name: '',
                                    width: '',
                                    description: '',
                                },
                            ],
                        },
                    ],
                },
                isolation: {
                    description: '',
                    grid: [
                        {
                            name: '',
                            width: '',
                            description: '',
                            layers: [
                                {
                                    name: '',
                                    width: '',
                                    description: '',
                                },
                            ],
                        },
                    ],
                },
                fillings: {
                    description: '',
                    grid: [
                        {
                            name: '',
                            width: '',
                            description: '',
                            layers: [
                                {
                                    name: '',
                                    width: '',
                                    description: '',
                                },
                            ],
                        },
                    ],
                },
                other: {
                    description: '',
                    grid: [
                        {
                            name: '',
                            width: '',
                            description: '',
                            layers: [
                                {
                                    name: '',
                                    width: '',
                                    description: '',
                                },
                            ],
                        },
                    ],
                },
            },
        },
    },
};
exports.B3_FORM_INITIAL_VALUES = {
    b3: {
        waterSupplySystem: Object.assign(Object.assign({}, B3_SECTION_INITIAL_VALUES), { label: 'Vodovod' }),
        wasterWaterSystem: Object.assign(Object.assign({}, B3_SECTION_INITIAL_VALUES), { label: 'Splašková kanalizace' }),
        rainWaterSystem: Object.assign(Object.assign({}, B3_SECTION_INITIAL_VALUES), { label: 'Dešťová kanalizace' }),
        gasPipeline: Object.assign(Object.assign({}, B3_SECTION_INITIAL_VALUES), { label: 'Plynovod' }),
        electricLowVoltage: Object.assign(Object.assign({}, B3_SECTION_INITIAL_VALUES), { label: 'Elektro NN' }),
        electricHighVoltage: Object.assign(Object.assign({}, B3_SECTION_INITIAL_VALUES), { label: 'Elektro VN' }),
        communicationLines: Object.assign(Object.assign({}, B3_SECTION_INITIAL_VALUES), { label: 'Sdělovací prostředky' }),
    },
};
exports.B4_FORM_INITIAL_VALUES = {
    b4: {
        currentSolution: {
            communications: {
                firstClass: false,
                secondClass: false,
                thirdClass: false,
                local: false,
                purpose: false,
                custom: false,
                barrierFreeSolution: false,
                barrierFreeSolutionDescription: '',
            },
        },
        connections: {
            newOrExisting: '',
        },
        transportationInPeace: {
            solvedInProject: '',
        },
        pedestrianAndCyclingRoutes: {
            solvedInProject: '',
        },
    },
};
exports.B5_FORM_INITIAL_VALUES = {
    b5: {
        terrainChange: {
            enabled: false,
            description: '',
            label: 'a) terénní úpravy',
        },
        usedVegetation: {
            enabled: false,
            description: '',
            label: 'b) použité vegetační prvky',
        },
        bioTech: {
            enabled: false,
            description: '',
            label: 'c) biotechnická opatření',
        },
    },
};
exports.B6_FORM_INITIAL_VALUES = {
    b6: {
        environment: {
            hasNoImpact: true,
            predefinedDescription: `
        <p>
          Stavba nebude mít trvalý vliv na životní prostředí. V průběhu vlastní výstavby dojde k
          dočasnému zhoršení podmínek dané lokality (hluk, různá omezení vyplývající 
          z postupu výstavby). Na staveništi budou učiněna veškerá možná opatření jejich 
          eliminaci.
        </p>
        <p>
          Komunální odpad bude shromažďován do kontejnerových nádob s pravidelným
          týdenním režimem odvozu.
        </p>
        <p>
          V průběhu výstavby musí zhotovitel dodržovat zejména ustanovení uvedených 
          zákonů a zákonných opatření:
        </p>
        <ul>
          <li>Zákon č. 185/2001 Sb. o odpadech</li>
          <li>Vyhláška MŽP ČR a MZD ČR č. 376/2001 Sb. o hodnocení nebezpečných
          vlastností odpadů</li>
          <li>Vyhláška MŽP ČR č. 381/2001 Sb., stanovující katalog odpadů, seznam
          nebezpečných odpadů a seznamy odpadů a států pro účely vývozu, dovozu a
          tranzitu odpadů a postup k udělování souhlasu k vývozu, dovozu a tranzitu
          odpadů (Katalog odpadů)</li>
          <li>311/1991 Sb. o státní správě</li>
          <li>401/1991 Sb. o programech odpadového hospodářství</li>
          <li>521/1991 Sb. o vedení evidence odpadů</li>
        </ul>
      `,
            sections: {
                air: {
                    enabled: false,
                    description: '',
                    label: 'Vliv na ovzduší',
                },
                noise: {
                    enabled: false,
                    description: '',
                    label: 'Vliv na hluk',
                },
                waste: {
                    enabled: false,
                    description: '',
                    label: 'Odpady',
                },
                soil: {
                    enabled: false,
                    description: '',
                    label: 'Vliv na půdu',
                },
            },
        },
        landscape: {
            sections: {
                wood: {
                    enabled: false,
                    description: '',
                    label: 'Ochrana dřevin',
                },
                protectedTrees: {
                    enabled: false,
                    description: '',
                    label: 'Ochrana památných stromů',
                },
                flowersAndAnimals: {
                    enabled: false,
                    description: '',
                    label: 'Ochrana rostlin a živočichů',
                },
                ecoRelationships: {
                    enabled: false,
                    description: '',
                    label: 'Zachování ekologických funkcí a vazeb v krajině',
                },
            },
            custom: [
                {
                    label: '',
                    description: '',
                },
            ],
        },
        natura2000: {
            hasImpact: false,
            description: '',
        },
        integratedPrevention: {
            hasImpact: false,
            description: '',
        },
        protectedArea: {
            sections: {
                protectionOfArea: {
                    enabled: false,
                    description: '',
                    label: 'Navrhovaná ochranná a bezpečnostní pásma',
                },
                scaleOfProtection: {
                    enabled: false,
                    description: '',
                    label: 'Rozsah omezení',
                },
                rulesForProtection: {
                    enabled: false,
                    description: '',
                    label: 'Podmínky ochrany podle jiných právních předpisů',
                },
            },
        },
    },
};
exports.B7_FORM_INITIAL_VALUES = {
    b7: {
        protection: 'S ohledem na druh výstavby není ochrana obyvatelstva projektem řešena.',
    },
};
exports.B8_FORM_INITIAL_VALUES = {
    b8: {
        consumption: `
      <p>
        Potřeby a spotřeby médií budou vždy konkrétně závislé na druhu prováděné stavební 
        činnosti. Jejich zajištění je závislé na požadavcích provozovatelů sítí.
      </p>
      <p>
        Samotné stavební práce budou probíhat na pozemcích, které jsou v osobním 
        vlastnictví stavebníka. 
      </p>
    `,
        water: `
      <p>
        Staveniště není potřeba zvlášť odvodňovat. Pouze v případě nutnosti je možno potřebnou plochu odvodnit pomocí svahované jámy, do které prosakuje voda svahy a dnem. Prosakující vodu dále u pasu svahu zachytit systémem obvodových rigolů, anebo drenů. V prostoru dna výkopu plošnými dreny, a svézt do několika sběrných studní, kde se voda odčerpá a vyveze. Odvodnění staveniště bude detailněji řešeno s hlavním dodavatelem stavby.
      </p>
    `,
        connections: {
            traffic: '',
            water: false,
            drainage: false,
            electricity: false,
            other: '',
        },
        surroundingAreaImpact: `
      <p>Stavba nebude mít trvalý vliv na životní prostředí. V průběhu vlastní výstavby dojde k
      dočasnému zhoršení podmínek dané lokality (hluk, různá omezení vyplývající 
      z postupu výstavby). Na staveništi budou učiněna veškerá možná opatření k jejich 
      eliminaci. Komunální odpad bude shromažďován do kontejnerových nádob s pravidelným
      týdenním režimem odvozu</p>    
    `,
        surroundingAreaProtection: `
      <p>
        Staveniště bude oploceno plotem výšky 1,8 m, čímž bude zamezeno přístupu
        nepovolaných osob na staveniště. Dále je třeba minimalizovat hluk ze stavebních
        mechanismů, prašnost ze stavební činnosti a udržovat v čistotě výjezd ze staveniště.
        Veškeré odpady ze stavební činnosti budou uchovávány v kontejnerech a likvidovány
        na řízených skládkách. Požadavky na asanace, demolice ani kácení dřevin se zde nevyskytují.
      </p>    
    `,
        maxTemporaryArea: `
      <p>
        Výstavba proběhne pouze na pozemcích ve vlastnictví stavebníka. Nejsou tedy nutné žádné další dočasné ani trvalé zábory.  
      </p>
    `,
        trailsProtection: `
      <p>
        Požadavky na bezbariérové obchozí trasy se zde nevyskytují
      </p>
    `,
        maxEmissions: `
      <p>
        Přesné místo likvidace bude určeno realizační firmou. V případě výskytu nebezpečných 
        a ekologicky či zdravotně závadných materiálu, se s nimi bude rovněž manipulovat dle 
        platné legislativy v posledním znění.
      </p>
    `,
        excavatedSoilSpreads: `
      <p>
        Vytěžená zemina se rozprostře na parcele investora. Požadavky na přísun ani deponie zemin se v projektu nevyskytují.
      </p>
    `,
        environmentProtection: `
      <p>
        Vlastní výstavba se realizuje v místě s okolní zástavbou. Je proto třeba respektovat tuto skutečnost a vytvořit oplocením uzavřené staveniště. Dále je třeba minimalizovat hluk ze stavebních mechanismů, prašnost ze stavební činnosti a udržovat v čistotě výjezd ze staveniště. Stavba nebude mít trvalý vliv na životní prostředí. V průběhu vlastní výstavby dojde k dočasnému zhoršení podmínek dané lokality (hluk, různá omezení vyplývající z postupu výstavby). Na staveništi budou učiněna veškerá možná opatření k jejich eliminaci. Veškeré odpady ze stavební činnosti budou uchovávány v přepravních obalech a likvidovány na řízených skládkách.
      </p>
    `,
        healthProtection: ``,
        barrierFreeProtection: `
      <p>S ohledem na druh výstavby není projektem řešeno. .</p>
    `,
        engineeringPracticesProtection: `
      <p>S ohledem na druh výstavby není projektem řešeno. .</p>
    `,
        specialConditions: `
      <p>S ohledem na druh výstavby není projektem řešeno. .</p>
    `,
        buildingProcedure: `
      <p>Zahájení stavby je uvažováno bezprostředně po vydání stavebního povolení. Stavba 
      proběhne najednou jako celek.</p>    
    `,
    },
};
exports.B9_FORM_INITIAL_VALUES = {
    b9: {
        waterSolution: 'Dešťové vody budou zachyceny soustavou svodů a budou odvedeny do akumulační' +
            'nádrže o obejmu ……m3. Dešťová voda bude využívána dále k zálivce. Přebytky budou likvidovány …… ',
    },
};
exports.ACCOMPANYING_REPORT_FORM_INITIAL_DATA = {
    a1: {
        constructionName: '',
        builder: undefined,
        builders: [undefined],
        documentationAuthor: undefined,
        primaryDocumentationAuthor: undefined,
        otherAuthors: [
            {
                label: '',
                author: undefined,
                indexIdentifier: (0, utils_1.generateRandomString)(),
            },
        ],
        places: [{ indexIdentifier: (0, utils_1.generateRandomString)() }],
    },
    a2: {
        structuralDivisions: [{ indexIdentifier: (0, utils_1.generateRandomString)() }],
    },
    a3: {
        baseDocumentations: [{ indexIdentifier: (0, utils_1.generateRandomString)() }],
    },
};
exports.B_FORMS = [
    enums_1.ProjectFormEnum.TECHNICAL_FORM_B1,
    enums_1.ProjectFormEnum.TECHNICAL_FORM_B2,
    enums_1.ProjectFormEnum.TECHNICAL_FORM_B3,
    enums_1.ProjectFormEnum.TECHNICAL_FORM_B4,
    enums_1.ProjectFormEnum.TECHNICAL_FORM_B5,
    enums_1.ProjectFormEnum.TECHNICAL_FORM_B6,
    enums_1.ProjectFormEnum.TECHNICAL_FORM_B7,
    enums_1.ProjectFormEnum.TECHNICAL_FORM_B8,
    enums_1.ProjectFormEnum.TECHNICAL_FORM_B9,
];
exports.A_FORMS = [
    enums_1.ProjectFormEnum.ACCOMPANYING_REPORT_FORM_A1,
];
