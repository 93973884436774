import React from 'react'
import { Field, FormikProps, useFormikContext } from 'formik'
import { B2_FORM_INIT_VALUES } from '../B2ConstructionDescription/constants'
import { B5_FORM_INITIAL_VALUES } from './constants'
import { CheckboxInput } from '../../../../../components/inputs/CheckboxInput'
import { RichTextInput } from '../../../../../components/inputs/RichTextInput/RichTextInput'
import { FormSectionTwoColumns } from '../../../../../components/forms/FormSectionTwoColumns'

interface Props {}

type B5Keys = keyof typeof B5_FORM_INITIAL_VALUES.b5

const sections: B5Keys[] = ['terrainChange', 'usedVegetation', 'bioTech']

export const B5TerrainForm: React.FC<Props> = (props) => {
  const formik = useFormikContext() as FormikProps<typeof B5_FORM_INITIAL_VALUES>
  return (
    <>
      <FormSectionTwoColumns sectionKey="b5" title="">
        <div className="sm:col-span-6">
          {sections.map((section) => (
            <div className="mt-3" key={section}>
              <Field
                name={`b5.${section}.enabled`}
                component={CheckboxInput}
                label={formik.values.b5[section].label}
              />
              {formik.values.b5[section].enabled && (
                <div className="pl-7">
                  <Field name={`b5.${section}.description`} component={RichTextInput} />
                </div>
              )}
            </div>
          ))}
        </div>
      </FormSectionTwoColumns>
    </>
  )
}
