import React, { useContext } from 'react'
import { Drawer, DrawerContent, DrawerHeader, DrawerTitle } from '../../ui/drawer'
import { Field, Form, Formik } from 'formik'
import { TextInput } from '../TextInput'
import { SmartDrawer } from '../../SmartDrawer'
import { ProjectFormIdentityContext } from '../../../model/context/ProjectFormIdentityContext'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { createProjectFormRichTextHelpSchema, CreateProjectFormRichTextHelpValues } from 'shared'
import { FormLabel } from '../../FormLabel'
import { Button } from '../../ui/button'
import { Separator } from '../../ui/separator'
import { useCreateProjectFormRichTextHelpMutation } from '../../../model/api/project/useCreateProjectFormRichTextHelpMutation'
import { Spinner, useToast } from '@chakra-ui/react'

interface Props {
  open: boolean
  setOpen: (val: boolean) => void
  fieldName: string
  fieldValue: string
}

export const SaveRichTextModalForm: React.FC<Props> = (props) => {
  const context = useContext(ProjectFormIdentityContext)
  const { mutateAsync, isLoading } = useCreateProjectFormRichTextHelpMutation()
  const toast = useToast()

  return (
    <Formik<CreateProjectFormRichTextHelpValues>
      enableReinitialize={true}
      validationSchema={toFormikValidationSchema(createProjectFormRichTextHelpSchema)}
      initialValues={{
        key: props.fieldName,
        text: props.fieldValue,
        name: '',
        projectId: context.projectId,
        formEnum: context.formEnum,
      }}
      onSubmit={async (values) => {
        try {
          await mutateAsync(values)
          toast({
            title: 'Úspěch',
            description: 'Text byl úspěšně uložen do oblíbených',
            status: 'success',
            duration: 5000,
            isClosable: true,
          })
          props.setOpen(false)
        } catch (err) {
          toast({
            title: 'Chyba',
            description: 'Nepodařilo se uložit text do oblíbených',
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
        }
      }}
    >
      {(formik) => {
        return (
          <SmartDrawer
            title="Uložit do oblíbených"
            description="Takto uložené texty lze jednoduše použít ve formulářích na dalších projektech"
            open={props.open}
            setOpen={props.setOpen}
          >
            <form onSubmit={formik.handleSubmit}>
              <div className="flex items-center justify-center space-x-2">
                <div className="w-full flex flex-col gap-3">
                  <Field
                    name="name"
                    label="Název"
                    placeholder="Nepovinný"
                    component={TextInput}
                    wrapperClassname="w-full"
                  />
                  <div>
                    <FormLabel>Text</FormLabel>
                    <div
                      className="p-3 border rounded-md"
                      dangerouslySetInnerHTML={{ __html: props.fieldValue }}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-3 flex justify-end gap-3">
                <Button type="button" variant="secondary" onClick={() => props.setOpen(false)}>
                  Zrušit
                </Button>
                <Button disabled={isLoading} type="submit">
                  {isLoading ? <Spinner /> : 'Uložit'}
                </Button>
              </div>
            </form>
          </SmartDrawer>
        )
      }}
    </Formik>
  )
}
