import { Field, FormikProps } from 'formik'
import React from 'react'
import { CheckboxInput } from '../../../../../components/inputs/CheckboxInput'
import { RichTextInput } from '../../../../../components/inputs/RichTextInput/RichTextInput'
import { FormSectionTwoColumns } from '../../../../../components/forms/FormSectionTwoColumns'

interface Props {
  formik: FormikProps<any>
}

export const TechnicalFormB1L: React.FC<Props> = ({ formik }) => {
  return (
    <FormSectionTwoColumns
      sectionKey={'b1l'}
      title="l) Věcné a časové vazby"
      subtitle="věcné a časové vazby stavby, podmiňující, vyvolané, související investice"
    >
      <div className="sm:col-span-6">
        <Field
          component={CheckboxInput}
          name="b1l.objectAndTimeConnections.allAtOnce"
          label="Stavba proběhne najednou jako celek"
        />
        {!formik.values.b1l.objectAndTimeConnections.allAtOnce && (
          <div className="mt-3">
            <Field
              component={RichTextInput}
              name="b1l.objectAndTimeConnections.custom"
              label="Věcné a časové vazby"
            />
          </div>
        )}
      </div>
      <div className="sm:col-span-6">
        <Field
          component={CheckboxInput}
          name="b1l.conditionalInvestments"
          label="Podmiňující investice"
        />

        {formik.values.b1l.conditionalInvestments && (
          <div className="mt-3">
            <Field component={RichTextInput} name="b1l.conditionalInvestmentsDetails" />
          </div>
        )}
      </div>
      <div className="sm:col-span-6">
        <Field
          component={CheckboxInput}
          name="b1l.triggeredInvestments"
          label="Vyvolané investice"
        />
        {formik.values.b1l.triggeredInvestments && (
          <div className="mt-3">
            <Field component={RichTextInput} name="b1l.triggeredInvestmentsDetails" />
          </div>
        )}
      </div>
      <div className="sm:col-span-6">
        <Field
          component={CheckboxInput}
          name="b1l.relatedInvestments"
          label="Související investice"
        />
        {formik.values.b1l.relatedInvestments && (
          <div className="mt-3">
            <Field component={RichTextInput} name="b1l.relatedInvestmentsDetails" label="Jaké" />
          </div>
        )}
      </div>
    </FormSectionTwoColumns>
  )
}
