import React from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router'
import axios from 'axios'
import { IssuesTable } from './IssuesTable'
import { Modal } from './Modal'
import { Field, Form, Formik } from 'formik'
import { CheckIcon, PrinterIcon } from '@heroicons/react/24/outline'
import { Dialog } from '@headlessui/react'
import queryString from 'query-string'
import { useSearchParams } from 'react-router-dom'
import { ImageDropzoneInput } from '../../components/ImageDropzoneInput'
import { TextInput } from '../../components/inputs/TextInput'
import { PaginationButton } from '../../components/PaginationButton'
import { IssuesFilter } from '../Project/subpages/Issues/IssuesFilter'
import moment from 'moment/moment'
import { IssuesList } from '../Project/subpages/Issues/IssuesList'

export const ProjectPreview: React.FC = () => {
  const [selectedIssue, setSelectedIssue] = React.useState<any>(undefined)
  const [searchParams, setSearchParamsRaw] = useSearchParams({
    page: '1',
    limit: '10',
  })
  const navigate = useNavigate()
  const { projectId } = useParams<{ projectId: string }>()

  if (!projectId) {
    navigate('/')
  }

  const queryClient = useQueryClient()
  const queryText = queryString.stringify(
    {
      page: searchParams.get('page') || 1,
      limit: searchParams.get('limit') || 10,
      object: searchParams.get('object'),
    },
    {
      skipEmptyString: true,
      skipNull: true,
    },
  )
  const query = useQuery({
    queryKey: ['projectData', projectId, queryText],
    queryFn: () => axios.get(`/preview/project/${projectId}/data?${queryText}`),
    onError: () => {
      navigate('/')
    },
    keepPreviousData: false,
  })
  const mutation = useMutation({
    mutationFn: (data: { solver: string }) => {
      const issueId = selectedIssue.id
      return axios.postForm(`/preview/project/${projectId}/issue/${issueId}`, data)
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['projectData'])
      setSelectedIssue(undefined)
    },
  })
  const setSearchParams = (params: any) => {
    let needsPageReset = false
    for (const key in params) {
      if (key !== 'page' && params[key] !== searchParams.get(key)) {
        needsPageReset = true
      }
      searchParams.set(key, params[key])
    }
    if (needsPageReset) {
      searchParams.set('page', '1')
    }
    setSearchParamsRaw(searchParams)
    setTimeout(() => {
      query.refetch()
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }, 100)
  }

  return (
    <>
      <Modal
        open={typeof selectedIssue !== 'undefined'}
        setOpen={() => setSelectedIssue(undefined)}
      >
        <Formik
          initialValues={{
            solver: '',
          }}
          onSubmit={(data) => mutation.mutate(data)}
        >
          {(formik) => {
            const invalid = formik.values.solver === ''
            return (
              <Form>
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                    <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      Označit vadu jako odstraněnou
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Tímto se daná vada označí v systému jako "ke kontrole"
                      </p>
                    </div>
                    <div className="mt-5">
                      <Field
                        name="afterFixImage"
                        component={ImageDropzoneInput}
                        label="Foto po opravě (nepovinné)"
                      />
                    </div>
                    <div className="mt-5">
                      <Field name="solver" component={TextInput} label="Vase jmeno" />
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    disabled={invalid}
                    type="submit"
                    className={`inline-flex w-full justify-center rounded-md ${
                      invalid ? 'bg-gray-300' : 'bg-blue-600'
                    } px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:col-start-2`}
                  >
                    Potvrdit
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                    onClick={() => setSelectedIssue(undefined)}
                  >
                    Zrusit
                  </button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </Modal>
      <div className="min-h-full">
        <header className="bg-white shadow">
          <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold tracking-tight text-gray-900">
              {query.data?.data?.project?.name}
            </h1>
          </div>
        </header>
        <main>
          <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h2 className="text-2xl font-semibold leading-6 text-gray-900">
                    Vady a nědodělky
                    <span className="invisible print:visible">
                      {' '}
                      / {moment().format('DD.MM.YYYY')}
                    </span>
                  </h2>
                </div>
                <p className="mt-2 text-sm text-gray-700 print:invisible">
                  <button
                    onClick={window.print}
                    type="button"
                    className="inline-flex items-center gap-x-1.5 rounded-md bg-blue-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                  >
                    <PrinterIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                    Tisknout
                  </button>
                </p>
                <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none"></div>
              </div>
              <div className="print:hidden md:-ml-6">
                <Formik
                  initialValues={{
                    object: { value: '' },
                    limit: { value: searchParams.get('limit') || '10' },
                  }}
                  onSubmit={() => {}}
                >
                  <IssuesFilter
                    setSearchParams={setSearchParams}
                    objects={query?.data?.data?.project?.objects || []}
                    hideStatus={true}
                  />
                </Formik>
              </div>
              <div className="print:hidden bg-white p-4 shadow-md rounded-md mt-5">
                <IssuesList
                  issues={query?.data?.data?.issues?.data || []}
                  projectId={query?.data?.data?.project?.id}
                  forPreview={true}
                  onReportFixed={(issue) => setSelectedIssue(issue)}
                />
                <PaginationButton
                  page={Number(searchParams.get('page'))}
                  limit={Number(searchParams.get('limit'))}
                  totalCount={query.data?.data?.issues?.totalCount}
                  totalPages={query.data?.data?.issues?.totalPages}
                  setPage={(page) => {
                    setSearchParams({ page })
                  }}
                />
              </div>
              <div className="hidden print:block">
                <IssuesTable
                  onIssueSelected={(issue) => setSelectedIssue(issue)}
                  issues={query.data?.data?.issues?.data || []}
                />
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  )
}
