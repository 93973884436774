// @ts-nocheck
import React, { useEffect } from 'react'
import { FieldProps } from 'formik'
import { bbox } from './utils'

interface Props {
  existingPointLngLat?: [number, number]
  onPointSelectedLngLat?: (lngLat: [number, number]) => void
}

const API_KEY = 'sN4-v3oSKVwg25_ss8CJrRS5H2ZWn2IH8OB4CA2eo40'

const markers = []
let map = null

export const MapInput: React.FC<Props> = (props) => {
  useEffect(() => {
    const newMap = new maplibregl.Map({
      container: 'map-root',
      center: props.existingPointLngLat ? props.existingPointLngLat : [16.20251, 49.66719],
      zoom: props.existingPointLngLat ? 16 : 6,
      style: {
        version: 8,
        sources: {
          'basic-tiles': {
            type: 'raster',
            url: `https://api.mapy.cz/v1/maptiles/basic/tiles.json?apikey=${API_KEY}`,
            tileSize: 256,
          },
        },
        layers: [
          {
            id: 'tiles',
            type: 'raster',
            source: 'basic-tiles',
          },
        ],
      },
    })

    /*
    We also require you to include our logo somewhere over the map.
    We create our own map control implementing a documented interface,
    that shows a clickable logo.
    See https://maplibre.org/maplibre-gl-js-docs/api/markers/#icontrol
    */
    class LogoControl {
      onAdd(map) {
        this._map = map
        this._container = document.createElement('div')
        this._container.className = 'maplibregl-ctrl'
        this._container.innerHTML =
          '<a href="http://mapy.cz/" target="_blank"><img  width="100px" src="https://api.mapy.cz/img/api/logo.svg" ></a>'

        return this._container
      }

      onRemove() {
        this._container.parentNode.removeChild(this._container)
        this._map = undefined
      }
    }

    // we add our LogoControl to the map
    newMap.addControl(new LogoControl(), 'bottom-left')
    newMap.on('click', async (event) => {
      markers.forEach((marker) => marker.remove())
      markers.length = 0

      let lngLat = [event.lngLat.lng, event.lngLat.lat]
      const newMarker = new maplibregl.Marker().setLngLat(lngLat).addTo(newMap)
      markers.push(newMarker)
      props.onPointSelectedLngLat(lngLat)
    })

    if (props.existingPointLngLat) {
      const newMarker = new maplibregl.Marker().setLngLat(props.existingPointLngLat).addTo(newMap)
      markers.push(newMarker)
    }

    async function geocode(query) {
      try {
        const url = new URL(`https://api.mapy.cz/v1/geocode`)

        url.searchParams.set('lang', 'cs')
        url.searchParams.set('apikey', API_KEY)
        url.searchParams.set('query', query)
        url.searchParams.set('limit', '15')
        ;[
          'regional.municipality',
          'regional.municipality_part',
          'regional.street',
          'regional.address',
        ].forEach((type) => url.searchParams.append('type', type))

        const response = await fetch(url.toString(), {
          mode: 'cors',
        })
        const json = await response.json()

        console.log('geocode', json)

        const source = map.getSource('markers')

        if (source) {
          source.setData({
            type: 'FeatureCollection',
            features: json.items.map((item) => ({
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [item.position.lon, item.position.lat],
              },
              properties: {
                name: item.name,
                label: item.label,
                location: item.location,
                longitude: item.position.lon,
                latitude: item.position.lat,
              },
            })),
          })
        }

        // finally we set the map to show the whole geometry in the viewport
        map.jumpTo(
          map.cameraForBounds(
            bbox(json.items.slice(0, 1).map((item) => [item.position.lon, item.position.lat])),
            {
              padding: 40,
              maxZoom: 16,
            },
          ),
        )
      } catch (ex) {
        console.log(ex)
      }
    }

    const input = document.querySelector('#geocode-input')
    const geocodeSubmit = document.querySelector('#geocode-submit')

    geocodeSubmit.addEventListener('click', (event) => {
      geocode(input.value)
    })

    map = newMap
  }, [])

  return (
    <div className="min-w-full">
      <div id="map-root" className="relative h-[300px] min-w-full rounded-md shadow-md">
        <div className="absolute top-1 left-1 z-10 flex gap-1">
          <input
            id="geocode-input"
            className="p-1 rounded-md shadow-md border-1"
            placeholder="Vyhledat"
          />
          <button
            type="button"
            id="geocode-submit"
            className="bg-blue-600 text-white rounded-md shadow-md p-1"
          >
            Vyhledat
          </button>
        </div>
      </div>
    </div>
  )
}
