import React, { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid'
import { IRoomEntity } from '../../../../model/interfaces/IRoomEntity'
import { Button } from '../../../../components/Button'
import { useDisclosure } from '@chakra-ui/react'
import { ObjectFormModal } from './ObjectFormModal'
import { useDeleteObjectMutation } from '../../../../model/api/project/useDeleteObjectMutation'
import { classNames } from '../../../../model/helpers/classNameHelpers'
import { ContextMenu } from '../../../../components/ContextMenu'
import { ObjectGridRoomRow, RoomFormModal } from './RoomFormModal'
import { useDeleteRoomMutation } from '../../../../model/api/project/useDeleteRoomMutation'
import { RoomRow } from './RoomRow'
import { ObjectRow } from './ObjectRow'
import { useDuplicateObjectMutation } from '../../../../model/api/project/useDuplicateObjectMutation'

export interface ObjectGridRow {
  name: string
  id?: string
  rooms: IRoomEntity[]
}

interface Props {
  objects: ObjectGridRow[]
  projectId: string
}

export const ObjectsGrid: React.FC<Props> = (props) => {
  const { mutate: deleteObject } = useDeleteObjectMutation()
  const { mutate: duplicateObject } = useDuplicateObjectMutation()
  const { mutate: deleteRoom } = useDeleteRoomMutation()
  const [objectFormInit, setObjectFormInit] = React.useState<ObjectGridRow | undefined>(undefined)
  const [roomFormInit, setRoomFormInit] = React.useState<ObjectGridRoomRow | undefined>(undefined)
  const objectDisclosure = useDisclosure()
  const roomDisclosure = useDisclosure()

  const onDeleteObject = (object: ObjectGridRow) => {
    const objectId = object.id

    if (typeof objectId === 'undefined') {
      return
    }

    const roomsCount = object.rooms.length
    const issuesCount = object.rooms.reduce((count, r) => count + (r.issues?.length || 0), 0)

    if (
      (roomsCount > 0 || issuesCount > 0) &&
      !window.confirm(
        `Vážně chcete smazat objekt ${object.name}? Dojde tím k smazání ${roomsCount} místností a ${issuesCount} vad / nedodělků`,
      )
    ) {
      return
    }

    deleteObject({ projectId: props.projectId, objectId })
  }
  const onDuplicateObject = (object: ObjectGridRow) => {
    const objectId = object.id

    if (typeof objectId === 'undefined') {
      return
    }

    duplicateObject({ projectId: props.projectId, objectId })
  }

  const onDeleteRoom = (room: IRoomEntity) => {
    const issuesCount = room.issues?.length || 0

    if (
      issuesCount > 0 &&
      !window.confirm(
        `Vážně chcete smazat místnost ${room.name}? Dojde tím k smazání ${issuesCount} vad / nedodělků`,
      )
    ) {
      return
    }

    deleteRoom({ projectId: props.projectId, roomId: room.id })
  }

  return (
    <>
      {objectFormInit && (
        <ObjectFormModal
          projectId={props.projectId}
          disclosure={{
            ...objectDisclosure,
            isOpen: typeof objectFormInit !== 'undefined',
            onClose: () => {
              setObjectFormInit(undefined)
              objectDisclosure.onClose()
            },
          }}
          objectInit={objectFormInit}
        />
      )}
      {typeof roomFormInit !== 'undefined' && (
        <RoomFormModal
          roomInit={roomFormInit}
          disclosure={{
            ...roomDisclosure,
            isOpen: typeof roomFormInit !== 'undefined',
            onClose: () => {
              setRoomFormInit(undefined)
              roomDisclosure.onClose()
            },
          }}
          projectId={props.projectId}
        />
      )}
      <div className="flex justify-end mb-5">
        <Button onClick={() => setObjectFormInit({ id: undefined, name: '', rooms: [] })}>
          Přidat objekt
        </Button>
      </div>
      <ul className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
        {props.objects.map((object, objectIndex) => (
          <ObjectRow
            key={object.id || objectIndex}
            object={object}
            setRoomFormInit={setRoomFormInit}
            setObjectFormInit={setObjectFormInit}
            onDeleteObject={onDeleteObject}
            onDuplicateObject={onDuplicateObject}
            onDeleteRoom={onDeleteRoom}
            projectId={props.projectId}
          />
        ))}
      </ul>
    </>
  )
}
