import React from 'react'
import { CardTable, ITableColumn } from '../../../components/CardTable'
import { useGetUsers } from '../../../model/api/user/useGetUsers'
import { IUserEntity } from '../../../model/interfaces/IUserEntity'
import { formatDateTime } from '../../../model/helpers/dateHelpers'
import { UserRoleEnum } from 'shared'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from '../../../components/ui/dropdown-menu'
import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid'
import { ContactIcon, CrownIcon, LockIcon, UserRoundMinus } from 'lucide-react'
import { InviteUserDrawer } from './InviteUserDrawer'
import { useToast } from '@chakra-ui/react'
import { useUpdateUserRoleMutation } from '../../../model/api/auth/useUpdateUserRoleMutation'

export const WorkspaceUsersTable: React.FC = () => {
  const toast = useToast()
  const { mutateAsync: updateUserRole } = useUpdateUserRoleMutation()
  const { data } = useGetUsers('')

  const changeRole = React.useCallback(
    async (userId: string, role: UserRoleEnum) => {
      try {
        await updateUserRole({ userId, values: { role } })
        toast({
          title: 'Role uživatele byla změněna',
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
      } catch (err) {
        toast({
          title: 'Chyba',
          description: 'Nepodařilo se změnit roli uživatele',
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    },
    [toast, updateUserRole],
  )

  const columns = React.useMemo(() => {
    const columns: ITableColumn<IUserEntity>[] = [
      {
        heading: 'Jméno',
        cellAccessor: 'name',
      },
      {
        heading: 'Email',
        cellAccessor: 'email',
      },
      {
        heading: 'Role',
        cellCallback: (user) => {
          if (user.role === UserRoleEnum.USER) {
            return 'Uživatel'
          }

          return 'Administrátor'
        },
      },
      {
        heading: 'Vytvořen',
        cellCallback: (user) => formatDateTime(user.createdAt as any as string),
      },
      {
        heading: 'Akce',
        cellCallback: (user) => (
          <DropdownMenu>
            <DropdownMenuTrigger>
              <EllipsisHorizontalIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuLabel>{user.name}</DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuSub>
                <DropdownMenuSubTrigger>
                  <LockIcon className="mr-2 h-4 w-4" />
                  <span>Změnit roli</span>
                </DropdownMenuSubTrigger>
                <DropdownMenuPortal>
                  <DropdownMenuSubContent>
                    <DropdownMenuItem onClick={() => changeRole(user.id, UserRoleEnum.ADMIN)}>
                      <CrownIcon className="mr-2 h-4 w-4" />
                      <span className={user.role === UserRoleEnum.ADMIN ? 'font-bold' : ''}>
                        Administrátor
                      </span>
                    </DropdownMenuItem>
                    <DropdownMenuItem onClick={() => changeRole(user.id, UserRoleEnum.USER)}>
                      <ContactIcon className="mr-2 h-4 w-4" />
                      <span className={user.role === UserRoleEnum.USER ? 'font-bold' : ''}>
                        Uživatel
                      </span>
                    </DropdownMenuItem>
                  </DropdownMenuSubContent>
                </DropdownMenuPortal>
              </DropdownMenuSub>
              <DropdownMenuItem>
                <UserRoundMinus className="h-4 w-4 mr-2" aria-hidden="true" />
                Zneaktivnit
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        ),
      },
    ]

    return columns
  }, [changeRole])

  return (
    <div>
      <div className="flex justify-end">
        <InviteUserDrawer />
      </div>
      <CardTable<IUserEntity> columns={columns} data={data?.data || []} />
    </div>
  )
}
