import React from 'react'
import { Field, FormikProps } from 'formik'
import { RichTextInput } from '../../../../../components/inputs/RichTextInput/RichTextInput'
import { FormSectionTwoColumns } from '../../../../../components/forms/FormSectionTwoColumns'
import { CheckboxInput } from '../../../../../components/inputs/CheckboxInput'

interface Props {
  formik: FormikProps<any>
}

export const TechnicalFormB1J: React.FC<Props> = ({ formik }) => {
  return (
    <FormSectionTwoColumns
      sectionKey={'b1j'}
      title="j) Požadavky na zábory"
      subtitle="požadavky na maximální dočasné a trvalé zábory zemědělského půdního fondu nebo pozemků určených k plnění funkce lesa"
    >
      <div className="sm:col-span-6">
        <Field
          component={CheckboxInput}
          name="b1j.landSeizureAgricultural.enabled"
          label="Zábory ZPF"
        />
      </div>
      {formik.values.b1j.landSeizureAgricultural.enabled && (
        <div className="sm:col-span-6">
          <Field component={RichTextInput} name="b1j.landSeizureAgricultural.details" label="" />
        </div>
      )}
      <div className="sm:col-span-6">
        <Field
          component={CheckboxInput}
          name="b1j.landSeizureForest.enabled"
          label="Zábory pozemků určených k plnění funkce lesa"
        />
      </div>
      {formik.values.b1j.landSeizureForest.enabled && (
        <div className="sm:col-span-6">
          <Field component={RichTextInput} name="b1j.landSeizureForest.details" label="" />
        </div>
      )}
    </FormSectionTwoColumns>
  )
}
