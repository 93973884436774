import { FileText, Star, WandSparkles } from 'lucide-react'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '../../ui/dropdown-menu'
import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid'
import React from 'react'
import { SaveRichTextModalForm } from './SaveRichTextModalForm'
import { LoadRichTextModal } from './LoadRichTextModal'
import { FieldInputProps, FormikProps } from 'formik'

interface Props {
  field: FieldInputProps<any>
  form: FormikProps<any>
  favoriteKey?: string
}

export const RichTextInputMenu: React.FC<Props> = (props) => {
  const [openSaveForm, setOpenSaveForm] = React.useState(false)
  const [openLoadForm, setOpenLoadForm] = React.useState(false)

  return (
    <>
      <SaveRichTextModalForm
        open={openSaveForm}
        setOpen={setOpenSaveForm}
        fieldName={props.favoriteKey ?? props.field.name}
        fieldValue={props.field.value}
      />
      <LoadRichTextModal
        open={openLoadForm}
        setOpen={setOpenLoadForm}
        fieldName={props.favoriteKey ?? props.field.name}
        onSelected={(text) => props.form.setFieldValue(props.field.name, text)}
      />

      <DropdownMenu>
        <DropdownMenuTrigger>
          <EllipsisHorizontalIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56">
          <DropdownMenuLabel>Textové pole</DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownMenuGroup>
            <DropdownMenuItem onClick={() => setOpenSaveForm(true)}>
              <Star className="mr-2 h-4 w-4" />
              <span>Uložit jako šablonu</span>
            </DropdownMenuItem>
            <DropdownMenuItem onClick={() => setOpenLoadForm(true)}>
              <FileText className="mr-2 h-4 w-4" />
              <span>Použít šablonu</span>
            </DropdownMenuItem>
          </DropdownMenuGroup>
          <DropdownMenuSeparator />
          <DropdownMenuGroup>
            <DropdownMenuItem>
              <WandSparkles className="mr-2 h-4 w-4" />
              <span>Doplnit pomocí AI</span>
            </DropdownMenuItem>
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  )
}
