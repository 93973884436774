import React from 'react'
import { FormSectionTwoColumns } from '../../../../../../components/forms/FormSectionTwoColumns'
import { B2_FORM_INIT_VALUES } from '../constants'
import { Field, FormikProps, useFormikContext } from 'formik'
import { RichTextInput } from '../../../../../../components/inputs/RichTextInput/RichTextInput'
import { TextInput } from '../../../../../../components/inputs/TextInput'
import { ArrayForm } from '../../../../../../components/ArrayForm'
import { ArrayInput } from '../../../../../../components/inputs/ArrayInput'
import { CheckboxInput } from '../../../../../../components/inputs/CheckboxInput'
import { FormLabel } from '../../../../../../components/FormLabel'

interface Props {}

export const B211ExternalFactorsProtection: React.FC<Props> = (props) => {
  const formik = useFormikContext() as FormikProps<typeof B2_FORM_INIT_VALUES>

  return (
    <FormSectionTwoColumns
      sectionKey={'b2.externalFactorsProtection'}
      title="B.2.11 Zásady ochrany stavby před negativními účinky vnějšího prostředí"
    >
      <div className="sm:col-span-6">
        <Field
          name="b2.externalFactorsProtection.radion"
          component={RichTextInput}
          label="a) Ochrana před pronikáním radonu z podloží"
        />
      </div>
      <div className="sm:col-span-6">
        <Field
          name="b2.externalFactorsProtection.wayWardStream"
          component={CheckboxInput}
          label="b) ochrana před bludnými proudy"
        />
        {formik.values.b2.externalFactorsProtection.wayWardStream && (
          <div className="mt-3">
            <Field
              name="b2.externalFactorsProtection.wayWardStreamDescription"
              component={RichTextInput}
              label="Popis"
            />
          </div>
        )}
      </div>
      <div className="sm:col-span-6">
        <Field
          name="b2.externalFactorsProtection.technicalSeismicity"
          component={CheckboxInput}
          label="c) ochrana před technickou seizmicitou"
        />
        {formik.values.b2.externalFactorsProtection.technicalSeismicity && (
          <div className="mt-3">
            <Field
              name="b2.externalFactorsProtection.technicalSeismicityDescription"
              component={RichTextInput}
              label="Popis"
            />
          </div>
        )}
      </div>
      <div className="sm:col-span-6">
        <Field
          name="b2.externalFactorsProtection.noise"
          component={CheckboxInput}
          label="d) ochrana před hlukem"
        />
        {formik.values.b2.externalFactorsProtection.noise && (
          <div className="mt-3">
            <Field
              name="b2.externalFactorsProtection.noiseDescription"
              component={RichTextInput}
              label="Popis"
            />
          </div>
        )}
      </div>
      <div className="sm:col-span-6">
        <Field
          name="b2.externalFactorsProtection.floodProtection"
          component={CheckboxInput}
          label="e) protipovodňová opatření"
        />
        {formik.values.b2.externalFactorsProtection.floodProtection && (
          <div className="mt-3">
            <Field
              name="b2.externalFactorsProtection.floodProtectionDescription"
              component={RichTextInput}
              label="Popis"
            />
          </div>
        )}
      </div>
      <div className="sm:col-span-6">
        <FormLabel>f) ostatní účinky - vliv poddolování, výskyt metanu apod.</FormLabel>
        <div className="mt-3">
          <Field
            name="b2.externalFactorsProtection.underMining"
            component={CheckboxInput}
            label="vliv poddolování"
          />
          {formik.values.b2.externalFactorsProtection.underMining && (
            <div className="mt-3">
              <Field
                name="b2.externalFactorsProtection.underMiningDescription"
                component={RichTextInput}
                label="Popis"
              />
            </div>
          )}
        </div>
        <div className="mt-5">
          <Field
            name="b2.externalFactorsProtection.methane"
            component={CheckboxInput}
            label="vliv metanu"
          />
          {formik.values.b2.externalFactorsProtection.methane && (
            <div className="mt-3">
              <Field
                name="b2.externalFactorsProtection.methaneDescription"
                component={RichTextInput}
                label="Popis"
              />
            </div>
          )}
        </div>
        <div className="mt-3 pl-7 sm:col-span-6">
          <ArrayInput
            name="b2.externalFactorsProtection.others"
            initializationObject={{ name: '', description: '' }}
          >
            {(name, row, index) => (
              <div className="mt-3">
                <Field name={`${name}.name`} component={TextInput} label={`Název`} />
                <div className="mt-3">
                  <Field name={`${name}.description`} component={RichTextInput} label={`Popis`} />
                </div>
              </div>
            )}
          </ArrayInput>
        </div>
      </div>
    </FormSectionTwoColumns>
  )
}
