import React from 'react'
import { SmartDrawer } from '../../../../components/SmartDrawer'
import { PhotoDocumentationForm } from './PhotoDocumentationForm'

interface Props {
  open: boolean
  setOpen: (open: boolean) => void
  projectId: string
}

export const PhotoDocumentationSmartDrawer: React.FC<Props> = (props) => {
  return (
    <SmartDrawer title="Fotodokumentace" open={props.open} setOpen={props.setOpen}>
      <PhotoDocumentationForm projectId={props.projectId} onSuccess={() => props.setOpen(false)} />
    </SmartDrawer>
  )
}
