import { ChevronRightIcon, HomeIcon } from '@heroicons/react/20/solid'
import React from 'react'
import { IBreadcrumb } from '../model/interfaces/IBreadcrumb'

const pages = [
  { name: 'Projects', href: '#', current: false },
  { name: 'Project Nero', href: '#', current: true },
]

interface Props {
  items: IBreadcrumb[]
}

export const Breadcrumbs: React.FC<Props> = (props) => {
  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol role="list" className="flex items-center">
        {props.items.map((page, pageIndex) => (
          <li key={pageIndex}>
            <div className="flex items-center cursor-pointer">
              {pageIndex !== 0 && (
                <ChevronRightIcon
                  className="h-5 w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
              )}
              <a
                onClick={page.action}
                className="text-sm font-medium text-gray-500 hover:text-gray-700"
              >
                {page.name}
              </a>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  )
}
