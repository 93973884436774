import React from 'react'
import { Avatar } from './Avatar'
import { HoverCard, HoverCardTrigger } from '@radix-ui/react-hover-card'
import { HoverCardContent } from './ui/hover-card'

interface Props {
  user: any
  minimalist?: boolean
}

export const UserAvatar: React.FC<Props> = ({ user, minimalist = false }) => {
  return (
    <HoverCard>
      <HoverCardTrigger asChild>
        <div className="flex items-center gap-2">
          <Avatar name={user.name} />
          {!minimalist && (
            <span className="text-sm hidden sm:block hover:underline">{user.name}</span>
          )}
        </div>
      </HoverCardTrigger>
      <HoverCardContent>
        <div className="flex gap-3">
          <div>
            <Avatar name={user.name} />
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-sm">{user.name}</span>
            <span className="text-sm">{user.email}</span>
          </div>
        </div>
      </HoverCardContent>
    </HoverCard>
  )
}
