import React from 'react'
import { useProject } from '../../../../../model/hooks/useProject'
import { ProjectLoadingSkeleton } from '../../../layout/ProjectLoadingSkeleton'
import { B2_FORM_INIT_VALUES } from './constants'
import { useTechnicalReportForm } from '../../../../../model/hooks/useTechnicalReportForm'
import { ProjectFormEnum } from '../../../../../model/enums/ProjectFormEnum'
import { TechnicalFormWrapper } from './TechnicalFormWrapper'
import { B2ConstructionDescriptionForm } from './B2ConstructionDescriptionForm'

interface Props {}

export const B2ConstructionDescription: React.FC<Props> = (props) => {
  return (
    <TechnicalFormWrapper
      form={ProjectFormEnum.TECHNICAL_FORM_B2}
      initialValues={B2_FORM_INIT_VALUES}
      title="B2. Celkový popis stavby"
    >
      <B2ConstructionDescriptionForm />
    </TechnicalFormWrapper>
  )
}
