import React from 'react'
import { Field, FormikProps } from 'formik'
import { TextInput } from '../../../../../components/inputs/TextInput'
import { RichTextInput } from '../../../../../components/inputs/RichTextInput/RichTextInput'
import { Button } from '../../../../../components/Button'
import { FormSectionTwoColumns } from '../../../../../components/forms/FormSectionTwoColumns'
import { InfoPopover } from '../../../../../components/InfoPopover'
import {
  Link as ChakraLink,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { CheckboxInput } from '../../../../../components/inputs/CheckboxInput'
import { SecondaryButton } from '../../../../../components/SecondaryButton'
import { ArrayForm } from '../../../../../components/ArrayForm'

interface Props {
  formik: FormikProps<any>
}

export const TechnicalFormB1G: React.FC<Props> = ({ formik }) => {
  const drainageMap =
    'https://heis.vuv.cz/data/webmap/isapi.dll?map=isvs_zapluz&lon=17.6708556&lat=49.2304732&scale=1890'
  const minningMap = 'https://mapy.geology.cz/dulni_dila_poddolovani/'
  const { isOpen, onClose, onOpen } = useDisclosure()
  const { isOpen: miningIsOpen, onClose: miningOnClose, onOpen: mininOnOpen } = useDisclosure()

  return (
    <>
      <Modal size="4xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Mapa záplavového území</ModalHeader>
          <ModalCloseButton onClick={onClose} />
          <ModalBody>
            <iframe src={drainageMap} width="100%" height="750px" />
          </ModalBody>
          <ModalFooter>
            <ChakraLink isExternal href={drainageMap}>
              Otevřít na stránce poskytovatele <ExternalLinkIcon mx="2px" />
            </ChakraLink>
            <Button onClick={onClose} className="ml-3">
              Zavřít
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal size="4xl" isOpen={miningIsOpen} onClose={miningOnClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Mapa poddolovaného území</ModalHeader>
          <ModalCloseButton onClick={miningOnClose} />
          <ModalBody>
            <iframe src={minningMap} width="100%" height="750px" />
          </ModalBody>
          <ModalFooter>
            <ChakraLink isExternal href={minningMap}>
              Otevřít na stránce poskytovatele <ExternalLinkIcon mx="2px" />
            </ChakraLink>
            <Button onClick={miningOnClose} className="ml-3">
              Zavřít
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <FormSectionTwoColumns
        sectionKey={'b1g'}
        title="g) Poloha vzhledem k záplavovému území, poddolovanému území apod."
        subtitle={
          <div className="flex flex-col gap-4">
            <SecondaryButton onClick={onOpen}>Mapa záplavového území</SecondaryButton>
            <SecondaryButton onClick={mininOnOpen}>Mapa poddolovaného území</SecondaryButton>
          </div>
        }
      >
        <div className="sm:col-span-6">
          <div className="flex">
            <Field component={CheckboxInput} name="b1g.floodArea.enabled" label="Záplavové území" />
            <InfoPopover content="Test" />
          </div>
        </div>
        {formik.values.b1g.floodArea.enabled && (
          <div className="sm:col-span-6">
            <Field component={RichTextInput} name="b1g.floodArea.measures" label="Opatření" />
            <ChakraLink isExternal href="#">
              Formuláře <ExternalLinkIcon mx="2px" />
            </ChakraLink>
          </div>
        )}
        <div className="sm:col-span-6">
          <Field
            component={CheckboxInput}
            name="b1g.underminedArea.enabled"
            label="Poddolované území"
          />
        </div>
        {formik.values.b1g.underminedArea.enabled && (
          <div className="sm:col-span-6">
            <Field component={RichTextInput} name="b1g.underminedArea.measures" label="Opatření" />
            <ChakraLink isExternal href="#">
              Formuláře <ExternalLinkIcon mx="2px" />
            </ChakraLink>
          </div>
        )}

        <div className="sm:col-span-6">
          <ArrayForm
            name="b1g.others"
            rows={formik.values.b1g.others || []}
            setFieldValue={formik.setFieldValue}
            initializationObject={{ name: '', enabled: false, measures: '' }}
          >
            {(name: string, row: any, index: number) => (
              <div className={`w-100 ${index !== 0 ? 'mt-8' : ''}`}>
                <div className="flex w-100">
                  <Field name={`${name}.enabled`} component={CheckboxInput} />
                  <div className="-mt-3 w-full">
                    <Field name={`${name}.name`} component={TextInput} placeholder="Vlastní" />
                  </div>
                </div>
                {row.enabled && (
                  <div className="mt-5">
                    <Field name={`${name}.measures`} component={RichTextInput} label="Opatření" />
                  </div>
                )}
              </div>
            )}
          </ArrayForm>
        </div>
      </FormSectionTwoColumns>
    </>
  )
}
