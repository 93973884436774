import React from 'react'
import { Field, Form, Formik } from 'formik'
import { TextInput } from '../../inputs/TextInput'
import RadioInput from '../../inputs/RadioInput'
import { PersonTypeEnum } from '../../../model/enums/PersonTypeEnum'
import { ContactEntityData } from '../../../model/interfaces/generated'
import { SelectInput } from '../../inputs/SelectInput'
import { Divider } from '@chakra-ui/react'
import { Button } from '../../Button'
import { CONTACT_FORM_INITIAL_VALUES } from './constants'
import { useCreateContactMutation } from '../../../model/api/contact/useCreateContactMutation'
import { SecondaryButton } from '../../SecondaryButton'
import { useUpdateContactMutation } from '../../../model/api/contact/useUpdateContactMutation'
import { useDeleteContactMutation } from '../../../model/api/contact/useDeleteContactMutation'
import { AxiosResponse } from 'axios'
import { TasksGrid } from '../../../pages/Tasks/components/TasksGrid'

interface Props {
  onSuccess?: (contact?: ContactEntityData) => void
  editContact?: ContactEntityData
}

export const ContactForm: React.FC<Props> = (props) => {
  const { mutate: createContact, isLoading: isCreatingContact } = useCreateContactMutation()
  const { mutate: updateContact, isLoading: isUpdatingContact } = useUpdateContactMutation()
  const { mutate: deleteContact, isLoading: isDeletingContact } = useDeleteContactMutation()
  const isLoading = isCreatingContact || isUpdatingContact || isDeletingContact

  const onSuccess = (data?: AxiosResponse<ContactEntityData>) => {
    if (typeof props.onSuccess !== 'undefined' && data?.data) {
      props.onSuccess(data.data)
    }
  }

  const onSubmit = (values: any) => {
    values.country = values.country.value ?? 'Česká republika'
    if (typeof props.editContact === 'undefined') {
      createContact(values, {
        onSuccess,
      })
      return
    }

    updateContact({ ...values, id: props.editContact.id }, { onSuccess })
  }

  return (
    <div className="container grid grid-cols-1 sm:grid-cols-3 border-t border-dotted py-5">
      <div className="border-r border-dotted col-span-1 sm:col-span-2 pr-5">
        <Formik
          key={props.editContact?.id}
          initialValues={
            props.editContact
              ? { ...props.editContact, country: { value: props.editContact?.country } }
              : { ...CONTACT_FORM_INITIAL_VALUES }
          }
          onSubmit={onSubmit}
        >
          {(formik) => (
            <Form className="text-left">
              <div className="row">
                <div className="sm:col-span-6">
                  <Field
                    name="type"
                    component={RadioInput}
                    options={[
                      {
                        label: 'Fyzická osoba',
                        value: PersonTypeEnum.PERSON,
                      },
                      {
                        label: 'Právnická osoba',
                        value: PersonTypeEnum.COMPANY,
                      },
                      {
                        label: 'Fyzická osoba podnikající',
                        value: PersonTypeEnum.PERSON_COMPANY,
                      },
                    ]}
                  />
                </div>
                {formik.values.type && (
                  <>
                    {[PersonTypeEnum.PERSON, PersonTypeEnum.PERSON_COMPANY].includes(
                      formik.values.type!,
                    ) && (
                      <>
                        <div className="sm:col-span-6 mt-5 grid grid-cols-5 space-x-3">
                          <Field name="title" component={TextInput} label="Titul" />
                          <Field
                            wrapperClassname="col-span-2"
                            name="name"
                            component={TextInput}
                            label="Jméno"
                          />
                          <Field
                            wrapperClassname="col-span-2"
                            name="lastName"
                            component={TextInput}
                            label="Příjmení"
                          />
                        </div>
                        {formik.values.type === PersonTypeEnum.PERSON_COMPANY && (
                          <div className="sm:col-span-6 mt-5">
                            <Field name="ico" component={TextInput} label="IČO" />
                          </div>
                        )}
                      </>
                    )}
                    {formik.values.type === PersonTypeEnum.COMPANY && (
                      <>
                        <div className="sm:col-span-6 mt-5 flex space-x-3">
                          <Field name="name" component={TextInput} label="Název společnosti" />
                          <Field name="ico" component={TextInput} label="IČO" />
                        </div>
                      </>
                    )}
                    <div className="sm:col-span-6 mt-5">
                      <Field name="email" component={TextInput} label="Email" />
                    </div>
                    <div className="sm:col-span-6 mt-5 mb-5">
                      <Field name="phone" component={TextInput} label="Telefon" />
                    </div>
                    {formik.values.type === PersonTypeEnum.COMPANY && (
                      <div className="sm:col-span-6 mt-5 mb-5">
                        <Field name="contactPerson" component={TextInput} label="Kontaktní osoba" />
                      </div>
                    )}

                    <Divider />
                    <div className="sm:col-span-6 mt-5 mb-5">
                      <Field
                        name="authorizationNumber"
                        component={TextInput}
                        label="Číslo autorizace"
                      />
                    </div>
                    <Divider />
                    <div className="sm:col-span-6 mt-5 flex space-x-3">
                      <Field name="street" component={TextInput} label="Ulice" />
                      <Field name="city" component={TextInput} label="Město" />
                      <Field name="zip" component={TextInput} label="PSČ" />
                    </div>
                    <div className="sm:col-span-6 mt-5">
                      <Field
                        name="country"
                        component={SelectInput}
                        label="Stát"
                        options={[{ label: 'Česká republika', value: 'Česká republika' }]}
                        forbidCustom
                      />
                    </div>
                  </>
                )}
              </div>
              {typeof props.editContact === 'undefined' ? (
                <div className="flex justify-end mt-5 mb-5">
                  <Button isLoading={isCreatingContact} type="submit">
                    Vytvořit
                  </Button>
                </div>
              ) : (
                <div className="flex justify-between mt-5 mb-5">
                  <SecondaryButton
                    isLoading={isLoading}
                    onClick={() => {
                      if (window.confirm('Opravdu chcete smazat kontakt?')) {
                        deleteContact(props.editContact!.id)
                        onSuccess()
                      }
                    }}
                  >
                    Smazat
                  </SecondaryButton>
                  <Button isLoading={isLoading} type="submit">
                    Uložit
                  </Button>
                </div>
              )}
            </Form>
          )}
        </Formik>
      </div>
      {props.editContact && (
        <div className="pl-5">
          <TasksGrid minimalist={true} taskQuery={{ contact: props.editContact!.id }} />
        </div>
      )}
    </div>
  )
}
