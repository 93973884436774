import React from 'react'
import { useGetPhotoDocumentation } from '../../../../model/api/photoDocumentation/useGetPhotoDocumentation'
import { AuthImage } from '../../../../components/AuthImage'
import { PaginationButton } from '../../../../components/PaginationButton'
import { useSearchParams } from 'react-router-dom'
import { Spinner } from '@chakra-ui/react'
import { PhotosGrid } from './PhotosGrid'

interface Props {
  projectId: string
}

export const PhotoDocumentationAllList: React.FC<Props> = (props) => {
  const [searchParams, setSearchParamsRaw] = useSearchParams({
    allPhotosPage: '1',
    allPhotosLimit: '50',
  })

  const {
    data: photosData,
    isLoading: isLoadingPhotos,
    refetch: refetchPhotos,
  } = useGetPhotoDocumentation(props.projectId, {
    page: parseInt(searchParams.get('allPhotosPage') ?? '1'),
    limit: parseInt(searchParams.get('allPhotosLimit') ?? '50'),
  })
  const photos = photosData?.data?.data ?? []

  const setSearchParams = (params: any) => {
    let needsPageReset = false
    for (const key in params) {
      if (key !== 'allPhotosPage' && params[key] !== searchParams.get(key)) {
        needsPageReset = true
      }
      searchParams.set(key, params[key])
    }
    if (needsPageReset) {
      searchParams.set('allPhotosPage', '1')
    }
    setSearchParamsRaw(searchParams)
    setTimeout(() => {
      return refetchPhotos()
    }, 100)
  }

  if (isLoadingPhotos) {
    return (
      <div className="flex justify-center">
        <Spinner size="xl" />
      </div>
    )
  }

  return (
    <div>
      <PhotosGrid photos={photos} />
      <div className="mt-5">
        <PaginationButton
          page={Number(photosData?.data?.page ?? 1)}
          limit={Number(photosData?.data?.limit ?? 50)}
          totalCount={photosData?.data?.totalCount ?? 0}
          totalPages={photosData?.data?.totalPages ?? 0}
          setPage={(page: number) => setSearchParams({ allPhotosPage: page })}
        />
      </div>
    </div>
  )
}
