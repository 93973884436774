import { FormikProps } from 'formik'
import { cloneDeep, merge } from 'lodash'

export const getFieldValidity = (form: FormikProps<any>, fieldName: string) => {
  const isInvalid = !!(form.submitCount && form.errors[fieldName])
  let message: any = form.errors[fieldName]

  // Special handling for 'SelectInput'
  if (typeof message === 'object' && typeof message.value !== 'undefined') {
    message = message.value
  }

  if (isInvalid) {
    return { isInvalid, message: String(message) }
  }

  return { isInvalid, message: undefined }
}

export const mergeInitialValues = <T>(initialValues: T, valuesFromServer?: Partial<T> | any): T => {
  const initialValuesClone = cloneDeep(initialValues)

  if (!valuesFromServer) {
    return initialValues
  }

  if (valuesFromServer === '') {
    return initialValues
  }

  const valuesFromServerClone = cloneDeep(valuesFromServer)

  // Remove all null values from the valuesFromServer
  Object.keys(valuesFromServerClone).forEach((key) => {
    if (valuesFromServerClone[key] === null) {
      delete valuesFromServerClone[key]
    }
  })

  return merge(initialValuesClone, valuesFromServerClone)
}
