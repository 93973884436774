import React from 'react'
import {
  ArrowUpCircleIcon,
  DocumentPlusIcon,
  DocumentTextIcon,
  FolderIcon,
  FolderPlusIcon,
} from '@heroicons/react/24/outline'
import { downloadDocument } from '../../../model/api/document/downloadDocument'
import { useGetDocumentsForPath } from '../../../model/api/document/useGetDocumentsForPath'
import { useGetProjects } from '../../../model/api/project/useGetProjects'
import { CardTable, ITableColumn } from '../../CardTable'
import { useGetFolder } from '../../../model/api/document/useGetFolder'
import { Modal, Spinner, useDisclosure } from '@chakra-ui/react'
import moment from 'moment'
import { Button } from '../../Button'
import {
  FolderOpenIcon,
  HomeIcon,
  PencilIcon,
  PencilSquareIcon,
  PlusCircleIcon,
  TrashIcon,
} from '@heroicons/react/20/solid'
import { TailwindModal } from '../../TailwindModal'
import { SecondaryButton } from '../../SecondaryButton'
import { Field, Formik } from 'formik'
import { TextInputWithLeadingIcon } from '../../inputs/TextInputWithLeadingIcon'
import { useCreateFolderMutation } from '../../../model/api/document/useCreateFolderMutation'
import { DropdownButton } from '../../DropdownButton'
import { useDeleteFolderMutation } from '../../../model/api/document/useDeleteFolderMutation'
import { IFolderEntity } from '../../../model/interfaces/IFolderEntity'
import { useUpdateFolderMutation } from '../../../model/api/document/useUpdateFolderMutation'
import { IDocumentEntity } from '../../../model/interfaces/IDocumentEntity'
import { DownloadIcon } from '@chakra-ui/icons'
import { useDeleteDocumentMutation } from '../../../model/api/document/useDeleteDocumentMutation'
import { FileUploadButton } from '../../FileUploadButton'
import { Breadcrumbs } from '../../Breadcrumbs'
import { IBreadcrumb } from '../../../model/interfaces/IBreadcrumb'
import { useLocation, useNavigate } from 'react-router-dom'

enum FileType {
  DIR = 'DIR',
  GO_UP = 'GO_UP',
  FILE = 'FILE',
}

interface IRow {
  id: string
  type: FileType
  name: string
  createdAt: string
  label?: string
  size?: number
  author?: string
  onClick?: () => void
  originalData: undefined | IFolderEntity | IDocumentEntity
}

interface IDocumentsTableProps {
  simple?: boolean
  rootFolder: string
}

export const DocumentsTable: React.FC<IDocumentsTableProps> = (props) => {
  const location = useLocation()
  const navigate = useNavigate()
  const searchFolderPath = location.search.split('folder=')[1]
  const folderPath = searchFolderPath || props.rootFolder
  const setFolderPath = (path: string) => {
    navigate(location.pathname + '?folder=' + path)
  }

  const [editFolder, setEditFolder] = React.useState<IFolderEntity | undefined>()
  const createFolderDisclosure = useDisclosure()

  const { data: projectsData, isLoading: isLoadingProjects } = useGetProjects()
  const { data: folderData, isLoading: isLoadingFolder } = useGetFolder(folderPath)
  const { mutateAsync: createFolderMutationAsync } = useCreateFolderMutation()
  const { mutateAsync: updateFolderMutationAsync } = useUpdateFolderMutation()
  const { mutate: deleteFolderMutation, isLoading: isDeleteFolderMutationLoading } =
    useDeleteFolderMutation()
  const { mutate: deleteFileMutation, isLoading: isDeleteFileMutationLoading } =
    useDeleteDocumentMutation()

  if (!projectsData || isLoadingProjects || isLoadingFolder || !folderData) {
    return <Spinner />
  }

  const breadcrumbs: IBreadcrumb[] = (() => {
    const breadcrumbs: IBreadcrumb[] = []
    const hierarchy = [folderData.data, ...folderData.data.hierarchy]
    const rootIndex = hierarchy.findIndex((h) => h.id === props.rootFolder)
    hierarchy
      .slice(0, rootIndex + 1)
      .reverse()
      .forEach((h) => {
        console.log({ h })
        breadcrumbs.push({
          name:
            h.id === props.rootFolder ? (
              <div className="flex items-center gap-3">
                <HomeIcon className="h-5 w-5" /> {h.name}
              </div>
            ) : (
              h.name
            ),
          action: () => setFolderPath(h.id),
        })
      })

    return breadcrumbs
  })()

  const rows: IRow[] = []
  if (folderData.data.parent && folderData.data.id !== props.rootFolder) {
    rows.push({
      id: '',
      type: FileType.GO_UP,
      name: '...',
      createdAt: '',
      onClick: () => setFolderPath(folderData.data.parent!.id),
      originalData: undefined,
    })
  }
  folderData.data.children.forEach((child) => {
    rows.push({
      id: child.id,
      type: FileType.DIR,
      name: child.name,
      createdAt: child.createdAt,
      onClick: () => setFolderPath(child.id),
      originalData: child,
    })
  })
  folderData.data.documents.forEach((document) => {
    rows.push({
      id: document.id,
      type: FileType.FILE,
      name: document.originalFilename || '',
      createdAt: document.createdAt,
      label: document.label || '',
      size: document.size,
      onClick: () => {
        downloadDocument(document)
      },
      originalData: document,
    })
  })

  const columns: ITableColumn<IRow>[] = [
    {
      heading: '',
      cellCallback: (row) => {
        if (row.type === FileType.DIR) {
          return <FolderIcon className="h-6 w-6 text-gray-400" />
        }

        if (row.type === FileType.GO_UP) {
          return <ArrowUpCircleIcon className="h-6 w-6 text-gray-400" />
        }

        return <DocumentTextIcon className="h-6 w-6 text-gray-400" />
      },
    },
    {
      heading: 'Název',
      cellAccessor: 'name',
    },
  ]

  if (!props.simple) {
    columns.push(
      {
        heading: 'Popis',
        cellAccessor: 'label',
      },
      {
        heading: 'Datum vytvoření',
        cellCallback: (row) => {
          if (!row.createdAt) {
            return null
          }

          return <>{moment(row.createdAt).format('DD. MM. YYYY HH:mm:ss')}</>
        },
      },
      {
        heading: 'Velikost',
        cellCallback: (row) => {
          if (row.type !== FileType.FILE || !row.size) {
            return null
          }

          return <>{Math.round(row.size / 1000)} KB</>
        },
      },
      {
        heading: 'Autor',
        cellCallback: () => <>Jan Novák</>,
      },
      {
        heading: '',
        cellCallback: (row) => {
          if (row.type === FileType.GO_UP) {
            return ''
          }

          if (row.type === FileType.FILE) {
            return (
              <DropdownButton
                items={[
                  [
                    {
                      name: 'Stáhnout',
                      icon: <DownloadIcon />,
                      callback: async () => {
                        downloadDocument(row.originalData as IDocumentEntity)
                      },
                    },
                  ],
                  [
                    {
                      name: 'Odstranit',
                      icon: isDeleteFileMutationLoading ? <Spinner /> : <TrashIcon />,
                      callback: async () => {
                        if (window.confirm(`Skutečně vymazat soubor ${row.name}?`)) {
                          deleteFileMutation(row.id)
                        }
                      },
                    },
                  ],
                ]}
              />
            )
          }

          return (
            <DropdownButton
              items={[
                [
                  {
                    name: 'Upravit',
                    icon: <PencilSquareIcon />,
                    callback: async () => {
                      setEditFolder(row.originalData as IFolderEntity)
                      createFolderDisclosure.onOpen()
                    },
                  },
                ],
                [
                  {
                    name: 'Odstranit',
                    icon: isDeleteFolderMutationLoading ? <Spinner /> : <TrashIcon />,
                    callback: async () => {
                      if (window.confirm(`Skutečně vymazat složku ${row.name} a její obsah?`)) {
                        deleteFolderMutation(row.id)
                      }
                    },
                  },
                ],
              ]}
            />
          )
        },
      },
    )
  }

  return (
    <>
      <TailwindModal
        disclosure={{
          ...createFolderDisclosure,
          onClose() {
            createFolderDisclosure.onClose()
            setTimeout(() => setEditFolder(undefined), 200)
          },
        }}
        dialogTitle={editFolder ? 'Upravit' : 'Vytvořit složku'}
        icon={
          editFolder ? (
            <FolderOpenIcon className="h-6 w-6 text-yellow-400" />
          ) : (
            <FolderPlusIcon className="h-6 w-6 text-green-400" />
          )
        }
      >
        <Formik
          initialValues={{ folderName: editFolder ? editFolder.name : '' }}
          onSubmit={async (values) => {
            editFolder
              ? await updateFolderMutationAsync({ id: editFolder.id, name: values.folderName })
              : await createFolderMutationAsync({ name: values.folderName, parent: folderPath })
            setEditFolder(undefined)
            createFolderDisclosure.onClose()
          }}
        >
          {({ handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              <Field
                component={TextInputWithLeadingIcon}
                name="folderName"
                icon={
                  editFolder ? (
                    <FolderOpenIcon className="h-5 w-5 text-gray-400" />
                  ) : (
                    <FolderPlusIcon className="h-5 w-5 text-gray-400" />
                  )
                }
                placeholder="Název složky"
              />

              <div className="flex justify-end gap-1 mt-5">
                <SecondaryButton onClick={createFolderDisclosure.onClose}>Zavřít</SecondaryButton>
                <Button type="submit" isLoading={isSubmitting}>
                  {editFolder ? 'Upravit' : 'Vytvořit'}
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </TailwindModal>
      <div className="flex justify-between">
        <Breadcrumbs items={breadcrumbs} />
        <div className="flex justify-end gap-3">
          <Button onClick={createFolderDisclosure.onOpen} className="flex items-center">
            <FolderPlusIcon className="h-5 mr-1" />
            Vytvořit složku
          </Button>
          {props.rootFolder !== folderData.data.id && (
            <Button
              onClick={() => {
                setEditFolder(folderData.data)
                createFolderDisclosure.onOpen()
              }}
              className="flex items-center"
            >
              <PencilSquareIcon className="h-5 mr-1" />
              Upravit složku
            </Button>
          )}
          <FileUploadButton folder={folderPath} />
        </div>
      </div>

      <CardTable<IRow>
        columns={columns}
        data={rows}
        rowAction={(row) => row.onClick && row.onClick()}
      />
    </>
  )
}
