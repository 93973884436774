import { classNames } from '../../../../model/helpers/classNameHelpers'
import React from 'react'
import { ProjectFormEnum } from '../../../../model/enums/ProjectFormEnum'
import { useProject } from '../../../../model/hooks/useProject'
import { useLocation, useNavigate } from 'react-router-dom'
import { RoutesEnum } from '../../../../model/enums/RoutesEnum'
import { FormikProps, useFormikContext } from 'formik'
import { Tooltip } from '@chakra-ui/react'
import { CheckIcon } from '@radix-ui/react-icons'
import { useGetTechnicalFormsList } from '../../../../model/api/project/useGetTechnicalFormsList'
import { TechnicalFormStatusEnum } from 'shared'

interface Props {
  title?: string
}

const tabs = [
  { name: 'B.1', href: ProjectFormEnum.TECHNICAL_FORM_B1, longName: 'B.1', current: false },
  {
    name: 'B.2',
    href: ProjectFormEnum.TECHNICAL_FORM_B2,
    longName: 'B2. Celkový popis stavby',
    current: true,
  },
  {
    name: 'B.3',
    href: ProjectFormEnum.TECHNICAL_FORM_B3,
    longName: 'B.3 Připojení na technickou infrastrukturu',
    current: false,
  },
  {
    name: 'B.4',
    href: ProjectFormEnum.TECHNICAL_FORM_B4,
    longName: 'B.4 Dopravní řešení',
    current: false,
  },
  {
    name: 'B.5',
    href: ProjectFormEnum.TECHNICAL_FORM_B5,
    longName: 'B.5 Řešení vegetace a souvisejících terénních úprav',
    current: false,
  },
  {
    name: 'B.6',
    href: ProjectFormEnum.TECHNICAL_FORM_B6,
    longName: 'B.6 Řešení vegetace a souvisejících terénních úprav',
    current: false,
  },
  {
    name: 'B.7',
    href: ProjectFormEnum.TECHNICAL_FORM_B7,
    longName: 'B.7 Ochrana obyvatelstva',
    current: false,
  },
  {
    name: 'B.8',
    href: ProjectFormEnum.TECHNICAL_FORM_B8,
    longName: 'B.8 Zásady organizace výstavby',
    current: false,
  },
  {
    name: 'B.9',
    href: ProjectFormEnum.TECHNICAL_FORM_B9,
    longName: 'B.9 Celkové vodohospodářské řešení',
    current: false,
  },
]

export const TabsInPills: React.FC<Props> = (props) => {
  const { id } = useProject()
  const navigate = useNavigate()
  const location = useLocation()
  const formik = useFormikContext() as FormikProps<any>

  const { data: projectFormsListData, isLoading: projectFormsListIsLoading } =
    useGetTechnicalFormsList(id)

  return (
    <div className="">
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only"></label>
        <select
          onChange={(event) => {
            const href = event.target.value

            if (formik.dirty) {
              formik.submitForm()
            }
            navigate(`${RoutesEnum.PROJECTS}/${id}/${RoutesEnum.TECHNICAL_REPORT_FORM}/${href}`)
          }}
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
          defaultValue={
            (projectFormsListData?.data?.list?.b || []).find((tab) =>
              location.pathname.includes(`${RoutesEnum.TECHNICAL_REPORT_FORM}/${tab.type}`),
            )?.type
          }
        >
          {(projectFormsListData?.data?.list?.b || []).map((tab) => (
            <option key={tab.name} value={tab.type}>
              {tab.name}
              {tab.status === TechnicalFormStatusEnum.PREVIEW && <> ✔</>}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="flex space-x-4" aria-label="Tabs">
          {(projectFormsListData?.data?.list?.b || []).map((tab, tabIndex) => {
            const current = location.pathname.includes(
              `${RoutesEnum.TECHNICAL_REPORT_FORM}/${tab.type}`,
            )
            return (
              <Tooltip label={tab.name}>
                <button
                  onClick={() => {
                    if (formik.dirty) {
                      formik.submitForm()
                    }
                    navigate(
                      `${RoutesEnum.PROJECTS}/${id}/${RoutesEnum.TECHNICAL_REPORT_FORM}/${tab.type}`,
                    )
                  }}
                  type="submit"
                  key={tab.name}
                  className={classNames(
                    current
                      ? 'bg-blue-600 text-white'
                      : 'text-gray-500 hover:bg-blue-600 hover:text-white',
                    'rounded-md px-3 py-2 text-sm font-medium whitespace-nowrap',
                  )}
                  aria-current={current ? 'page' : undefined}
                >
                  <div className="flex justify-center align-middle gap-1">
                    {tab.status === TechnicalFormStatusEnum.PREVIEW && (
                      <CheckIcon className="w-4 h-4 bg-white rounded-full text-blue-700 mt-[2px]" />
                    )}
                    {tab.identifier.split(' ').join('.')}
                  </div>
                </button>
              </Tooltip>
            )
          })}
        </nav>
      </div>
    </div>
  )
}
