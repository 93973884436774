import { useQuery } from 'react-query'
import { QueryKeyEnum } from '../../enums/QueryKeyEnum'
import { api } from '../api'
import { ApiUrlEnum } from '../../enums/ApiUrlEnum'
import { IUserEntity } from '../../interfaces/IUserEntity'
import queryString from 'query-string'

export const useGetUsers = (query?: string) => {
  const queryStringified = queryString.stringify(
    { query },
    { skipNull: true, skipEmptyString: true },
  )
  return useQuery(QueryKeyEnum.USERS, () => api.get<IUserEntity[]>(ApiUrlEnum.USER))
}
