import React, { useContext } from 'react'
import { FieldProps } from 'formik'
import { ProjectFormContext } from '../../model/context/ProjectFormContext'
import { Spinner } from '@chakra-ui/react'
import { IDocumentEntity } from '../../model/interfaces/IDocumentEntity'
import { useGetFolderForProjectForm } from '../../model/api/document/useGetFolderForProjectForm'
import { FolderKeyEnum } from '../../model/enums/FolderKeyEnum'
import { DocumentGrid } from '../DocumentGrid'
import { FileUploadButton } from '../FileUploadButton'
import { IsDisabledContext } from '../../model/context/IsDisabledContext'
import { useParams } from 'react-router-dom'

interface Props extends FieldProps {
  label?: string
  commentHeading?: string
  comment?: string
  icon?: React.ReactNode
  fileLabel?: string
  folderKey?: string
}

export const DropzoneInput: React.FC<Props> = (props) => {
  const isDisabled = useContext(IsDisabledContext) ?? false
  const { id: projectId } = useParams<{ id: string }>()
  const projectFormContext = useContext(ProjectFormContext)
  const { data: folderData, isLoading: isLoadingFolder } = useGetFolderForProjectForm(
    props.folderKey ?? FolderKeyEnum.PROJECT_FORM_ATTACHMENTS,
    projectId!,
  )

  if (isLoadingFolder || !folderData) {
    return <Spinner />
  }

  const values: IDocumentEntity[] =
    folderData?.data?.documents?.filter((d) => d.keyPath === props.field.name) || []

  return (
    <div className="col-span-full">
      {(!isDisabled || values.length > 0) && (
        <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-gray-900">
          {props.label}
        </label>
      )}

      {values.length > 0 && <DocumentGrid documents={values} />}

      {!isDisabled && (
        <div className="flex items-center justify-end w-full">
          <FileUploadButton
            folder={folderData.data.id}
            folderKey={props.folderKey}
            additionalData={{
              keyPath: props.field.name,
              project: projectFormContext.projectId,
              projectForm: projectFormContext.projectFormId,
              label: props.fileLabel,
            }}
          />
        </div>
      )}
    </div>
  )
}
