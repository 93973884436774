import React, { useState } from 'react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { Combobox } from '@headlessui/react'
import { ISelectOption } from '../../model/interfaces/ISelectOption'
import { FieldProps } from 'formik'
import { classNames } from '../../model/helpers/classNameHelpers'

interface Props extends FieldProps {
  options: Array<ISelectOption>
  label: string
}

export const ComboInput: React.FC<Props> = (props) => {
  const [query, setQuery] = useState('')

  const filteredOptions = React.useMemo(() => {
    if (query === '') {
      return props.options
    }

    return props.options.filter((option) => {
      return option.label.toLowerCase().includes(query.toLowerCase())
    })
  }, [props.options, query])

  const selectedOption = props.options.find((option) => option.value === props.field.value)

  return (
    <Combobox
      as="div"
      value={props.field.value}
      onChange={(value) => props.form.setFieldValue(props.field.name, value)}
    >
      <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">
        {props.label}
      </Combobox.Label>
      <div className="relative mt-2">
        <Combobox.Input
          placeholder="Vyberte..."
          className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
          onChange={(e) => setQuery(e.currentTarget.value)}
          displayValue={() => selectedOption?.label ?? ''}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>

        {filteredOptions.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredOptions.map((option) => (
              <Combobox.Option
                key={option.value}
                value={option.value}
                className={({ active }) =>
                  classNames(
                    'relative cursor-default select-none py-2 pl-3 pr-9',
                    active ? 'bg-blue-600 text-white' : 'text-gray-900',
                  )
                }
              >
                {({ active }) => {
                  const selected = selectedOption?.value === option.value
                  return (
                    <>
                      <span className={classNames('block truncate', selected && 'font-semibold')}>
                        {option.displayOnlyLabel ?? option.label}
                      </span>

                      {selected && (
                        <span
                          className={classNames(
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                            active ? 'text-white' : 'text-blue-600',
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )
                }}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  )
}
