import React from 'react'
import { useGetConstructionDiaryOtherEntries } from '../../../../model/api/constructionDiary/useGetConstructionDiaryOtherEntries'
import { ConstructionDiaryOtherEntry } from './ConstructionDiaryOtherEntry'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '../../../../components/ui/accordion'

interface Props {
  projectId: string
}

export const ConstructionDiaryOtherEntries: React.FC<Props> = (props) => {
  const { data, isLoading } = useGetConstructionDiaryOtherEntries(props.projectId)

  return (
    <div className="mt-8">
      <Accordion type="multiple">
        {data?.data?.map((group: any) => (
          <AccordionItem value={group.type} key={group.type}>
            <AccordionTrigger className="text-left">
              {group.type} ({group.entities.length})
            </AccordionTrigger>
            <AccordionContent>
              <div className="">
                {group.entities.map((entity: any) => (
                  <ConstructionDiaryOtherEntry
                    projectId={props.projectId}
                    entity={entity}
                    key={entity.id}
                  />
                ))}
              </div>
            </AccordionContent>
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  )
}
