import React from 'react'
import { get } from 'lodash'

const people = [
  {
    name: 'Lindsay Walton',
    title: 'Front-end Developer',
    email: 'lindsay.walton@example.com',
    role: 'Member',
  },
  // More people...
]

export interface ITableColumn<T> {
  heading: string
  cellAccessor?: string
  cellCallback?: (option: T) => React.ReactNode
}

interface Props<T> {
  columns: ITableColumn<T>[]
  data: T[]
  rowAction?: (row: T) => void
}

type GenericComponent = <T>(props: Props<T>) => React.ReactElement

export const CardTable: GenericComponent = (props) => {
  return (
    <div className="">
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 pb-64">
            <div className="shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="">
                  <tr>
                    {props.columns.map((column, columnIndex) => (
                      <th
                        key={columnIndex}
                        scope="col"
                        className={`py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 ${
                          columnIndex === 0 ? 'sm:pl-6' : ''
                        }`}
                      >
                        {column.heading}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {props.data.map((data, dataIndex) => (
                    <tr
                      key={dataIndex}
                      className="hover:bg-slate-100 cursor-pointer"
                      onClick={() => props.rowAction && props.rowAction(data)}
                    >
                      {props.columns.map((column, columnIndex) => (
                        <>
                          <td
                            className={`px-3 py-4 text-sm text-gray-900 ${
                              columnIndex === 0 ? 'sm:pl-6' : ''
                            }`}
                          >
                            {typeof column.cellAccessor !== 'undefined'
                              ? get(data, column.cellAccessor)
                              : column.cellCallback!(data)}
                          </td>
                        </>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
