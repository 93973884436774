import { ContactEntityData } from '../../../model/interfaces/generated'
import * as Yup from 'yup'
import { PersonTypeEnum } from '../../../model/enums/PersonTypeEnum'

export const CONTACT_FORM_INITIAL_VALUES: Partial<ContactEntityData> = {
  type: undefined,
  name: '',
  lastName: '',
  email: '',
  phone: '',
  street: '',
  city: '',
  zip: '',
  country: { value: 'Česká republika' },
}

export const CONTACT_FORM_VALIDATION_SCHEMA = Yup.object().shape({
  type: Yup.string().required('Povinné pole'),
  name: Yup.string().required('Povinné pole'),
  lastName: Yup.string().when('type', {
    is: (value: any) => [PersonTypeEnum.PERSON, PersonTypeEnum.PERSON_COMPANY].includes(value),
    then: (schema: any) => schema.required('Povinné pole'),
  }),
  email: Yup.string().email('Vyplňte validní email'),
})
