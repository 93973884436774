export enum ProjectFormEnum {
  ACCOMPANYING_REPORT_FORM_A1 = 'ACCOMPANYING_REPORT_FORM_A1',
  TECHNICAL_FORM_B1 = 'TECHNICAL_FORM_B1',
  TECHNICAL_FORM_B2 = 'TECHNICAL_FORM_B2',
  TECHNICAL_FORM_B3 = 'TECHNICAL_FORM_B3',
  TECHNICAL_FORM_B4 = 'TECHNICAL_FORM_B4',
  TECHNICAL_FORM_B5 = 'TECHNICAL_FORM_B5',
  TECHNICAL_FORM_B6 = 'TECHNICAL_FORM_B6',
  TECHNICAL_FORM_B7 = 'TECHNICAL_FORM_B7',
  TECHNICAL_FORM_B8 = 'TECHNICAL_FORM_B8',
  TECHNICAL_FORM_B9 = 'TECHNICAL_FORM_B9',
}
