import { useMutation, useQueryClient } from 'react-query'
import { api } from '../api'
import { ApiUrlEnum } from '../../enums/ApiUrlEnum'
import { QueryKeyEnum } from '../../enums/QueryKeyEnum'

export const useCreateFolderMutation = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (data: { name: string; parent: string }) => api.post(ApiUrlEnum.FOLDER, data),
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyEnum.FOLDERS])
    },
  })
}
