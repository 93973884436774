import { useMutation, useQueryClient } from 'react-query'
import { CreateProjectRequestData } from '../../interfaces/generated'
import { api } from '../api'
import { QueryKeyEnum } from '../../enums/QueryKeyEnum'
import { ApiUrlEnum } from '../../enums/ApiUrlEnum'

export const useCreateProjectMutation = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (project: CreateProjectRequestData) => api.post(ApiUrlEnum.PROJECT, project),
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeyEnum.PROJECTS])
    },
  })
}
