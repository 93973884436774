export function formatNumberWithDelimiters(number: number | string | undefined) {
  if (typeof number === 'undefined') {
    return ''
  }

  if (typeof number === 'number') {
    number = number.toString()
  }

  if (number === '') {
    return ''
  }

  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

export function removeNumberDelimiters(formattedNumber: string | number | undefined) {
  if (typeof formattedNumber === 'number' || typeof formattedNumber === 'undefined') {
    return formattedNumber
  }

  const numberWithoutDelimiters = formattedNumber.replace(/\s+/g, '')

  const parsedNumber = numberWithoutDelimiters.includes('.')
    ? parseFloat(numberWithoutDelimiters)
    : parseInt(numberWithoutDelimiters, 10)

  if (isNaN(parsedNumber)) {
    return 0
  }

  return parsedNumber
}
