import React from 'react'
import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react'
import { TrashIcon } from '@heroicons/react/20/solid'
import { AuthImage } from '../../../../components/AuthImage'
import { Field, Form, Formik } from 'formik'
import { RichTextInput } from '../../../../components/inputs/RichTextInput/RichTextInput'
import { Button } from '../../../../components/ui/button'
import { SelectInput } from '../../../../components/inputs/SelectInput'
import { useGetRooms } from '../../../../model/api/project/useGetRooms'
import { useProject } from '../../../../model/hooks/useProject'
import { useUpdatePhotoDocumentationMutation } from '../../../../model/api/photoDocumentation/useUpdatePhotoDocumentationMutation'
import { useToast } from '@chakra-ui/react'
import { useDeletePhotoDocumentationMutation } from '../../../../model/api/photoDocumentation/useDeletePhotoDocumentationMutation'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '../../../../components/ui/accordion'

interface Props {
  open: boolean
  onClose: () => void
  photo: any
}

export const PhotoModal: React.FC<Props> = (props) => {
  const toast = useToast()
  const { id: projectId } = useProject()
  const { data: roomsData, isLoading: roomsLoading } = useGetRooms(projectId, 'all')
  const { mutateAsync: update } = useUpdatePhotoDocumentationMutation()
  const { mutateAsync: remove, isLoading: isRemoving } = useDeletePhotoDocumentationMutation()

  if (typeof props.photo === 'undefined') {
    props.onClose()
    return null
  }

  const photo = props.photo

  const onDelete = async () => {
    if (window.confirm('Opravdu chcete smazat tuto fotku?')) {
      await remove({ projectId, photoId: photo.id })
      toast({
        title: 'Fotka byla smazána',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top',
      })
      props.onClose()
    }
  }

  return (
    <Dialog open={props.open} onClose={props.onClose} className="relative z-10">
      <DialogBackdrop className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in" />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center text-center sm:items-center">
          <DialogPanel className="relative transform overflow-hidden bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-4xl rounded-t-md data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95">
            <AuthImage image={photo.image} className="w-auto object-contain mx-auto rounded-t-md" />
            <div className="flex justify-end mr-2 mt-2">
              <Button onClick={onDelete} variant="ghost" isLoading={isRemoving}>
                <TrashIcon className="w-5 h-5 mr-2 text-red-600" />
              </Button>
            </div>
            <div className="p-6">
              <Formik
                initialValues={{
                  description: photo.description,
                  room: { value: photo?.room?.id },
                }}
                onSubmit={async (values: any, formikHelpers) => {
                  try {
                    await update({
                      projectId,
                      photoId: photo.id,
                      data: {
                        description: values.description,
                        room: values.room.value,
                      },
                    })
                    toast({
                      title: 'Změny uloženy',
                      status: 'success',
                      duration: 5000,
                      isClosable: true,
                      position: 'top',
                    })
                    props.onClose()
                  } catch (e) {
                    toast({
                      title: 'Něco se pokazilo',
                      status: 'error',
                      duration: 5000,
                      isClosable: true,
                    })
                    console.error(e)
                  }
                }}
              >
                {(formik) => (
                  <Form>
                    <Field
                      isLoading={roomsLoading}
                      name="room"
                      component={SelectInput}
                      options={[
                        ...(
                          roomsData?.data?.map((r: any) => ({
                            value: r.id,
                            label: [r.object?.name, r.name].filter(Boolean).join(' - '),
                          })) || []
                        ).sort((a: any, b: any) => a.label.localeCompare(b.label)),
                      ]}
                      forbidCustom={true}
                    />
                    <Accordion type="single" collapsible className="w-full mt-3">
                      <AccordionItem value="item-1">
                        <AccordionTrigger>Popis</AccordionTrigger>
                        <AccordionContent>
                          <Field
                            name="description"
                            component={RichTextInput}
                            noMenu={true}
                            disableFullscreen={true}
                          />
                        </AccordionContent>
                      </AccordionItem>
                    </Accordion>
                    <div className="mt-6 grid grid-flow-row-dense grid-cols-2 gap-3">
                      <Button type="button" variant="outline" onClick={props.onClose}>
                        Zavřít
                      </Button>
                      <Button
                        isLoading={formik.isSubmitting}
                        disabled={!formik.dirty || isRemoving}
                        type="submit"
                      >
                        Uložit
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  )
}
