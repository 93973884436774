import React from 'react'

interface Props {
  title: string
  className?: string
}

export const Divider: React.FC<Props> = (props) => {
  return (
    <div className={`relative ${props.className ? props.className : ''}`}>
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-gray-300" />
      </div>
      <div className="relative flex justify-center">
        <span className="bg-white px-3 text-base font-semibold leading-6 text-gray-900">
          {props.title}
        </span>
      </div>
    </div>
  )
}
