import { Spinner } from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import { merge } from 'lodash'
import React from 'react'
import { useGetProjectTechnicalForm } from '../../../../model/api/project/useGetProjectTechnicalForm'
import { useStoreProjectFormMutation } from '../../../../model/api/project/useStoreProjectFormMutation'
import { ProjectFormEnum } from '../../../../model/enums/ProjectFormEnum'
import { useProject } from '../../../../model/hooks/useProject'
import { ProjectLayout } from '../../layout/ProjectLayout'
import { ProjectLoadingSkeleton } from '../../layout/ProjectLoadingSkeleton'
import { ACCOMPANYING_REPORT_FORM_INITIAL_DATA } from './constants'
import { AccompanyingReportA1 } from './AccompanyingReportA1'
import { AccompanyingReportA2 } from './AccompanyingReportA2'
import { AccompanyingReportA3 } from './AccompanyingReportA3'
import { Button } from '../../../../components/ui/button'
import { AccompanyingReportMenu } from './AccompanyingReportMenu'
import { TechnicalFormStatusEnum } from 'shared'
import { SectionHeading } from '../../../../components/SectionHeading'
import { useDeletePreviewProjectTechnicalFormMutation } from '../../../../model/api/project/useDeletePreviewProjectTechnicalFormMutation'
import { useSubmitForPreviewProjectFormMutation } from '../../../../model/api/project/useSubmitForPreviewProjectFormMutation'
import { CheckCircleIcon } from '@heroicons/react/24/solid'

interface Props {}

export const AccompanyingReport: React.FC<Props> = (props) => {
  const { project } = useProject()
  const { mutate: storeAccompanyingReport, isLoading: isStoringAccompanyingReport } =
    useStoreProjectFormMutation(ProjectFormEnum.ACCOMPANYING_REPORT_FORM_A1)
  const { data: accompanyingReportForm, isLoading: accompanyingReportFormIsLoading } =
    useGetProjectTechnicalForm(ProjectFormEnum.ACCOMPANYING_REPORT_FORM_A1, project?.id)
  const { mutate: deletePreviewProjectTechnicalForm, isLoading: isDeletingPreview } =
    useDeletePreviewProjectTechnicalFormMutation()
  const { mutate: submitForPreviewProjectForm, isLoading: isSubmittingForPreview } =
    useSubmitForPreviewProjectFormMutation()

  if (!project || accompanyingReportFormIsLoading) {
    return <ProjectLoadingSkeleton />
  }

  return (
    <ProjectLayout project={project}>
      <Formik
        initialValues={
          accompanyingReportForm?.data?.data && accompanyingReportForm?.data?.data !== ''
            ? (merge(
                ACCOMPANYING_REPORT_FORM_INITIAL_DATA,
                {
                  a1: { constructionName: project.name || '' },
                },
                accompanyingReportForm?.data?.data,
              ) as typeof ACCOMPANYING_REPORT_FORM_INITIAL_DATA)
            : {
                ...ACCOMPANYING_REPORT_FORM_INITIAL_DATA,
                a1: {
                  ...ACCOMPANYING_REPORT_FORM_INITIAL_DATA.a1,
                  constructionName: project.name || '',
                },
              }
        }
        onSubmit={(values) => {
          storeAccompanyingReport({
            data: { ...values },
            id: project.id,
          })
        }}
      >
        {(formik) => {
          return (
            <Form>
              <div className="flex justify-between">
                <SectionHeading title="A) Průvodní zpráva" />
                <div className="flex align-middle">
                  {accompanyingReportForm?.data?.status === TechnicalFormStatusEnum.PREVIEW ? (
                    <Button
                      type="button"
                      onClick={() =>
                        deletePreviewProjectTechnicalForm({
                          id: project.id,
                          form: ProjectFormEnum.ACCOMPANYING_REPORT_FORM_A1,
                        })
                      }
                    >
                      {isDeletingPreview ? <Spinner size="sm" /> : 'Editovat'}
                    </Button>
                  ) : (
                    <Button type="submit">
                      {isStoringAccompanyingReport ? <Spinner size="sm" /> : 'Uložit'}
                    </Button>
                  )}
                  <AccompanyingReportMenu
                    projectId={project.id}
                    status={accompanyingReportForm?.data?.status || TechnicalFormStatusEnum.PREVIEW}
                  />
                </div>
              </div>
              {accompanyingReportForm?.data?.status === TechnicalFormStatusEnum.PREVIEW ? (
                <div
                  className="p-3"
                  dangerouslySetInnerHTML={{
                    __html: accompanyingReportForm!.data?.preview || '',
                  }}
                ></div>
              ) : (
                <>
                  <AccompanyingReportA1 formik={formik} />
                  <AccompanyingReportA2 formik={formik} />
                  <AccompanyingReportA3 formik={formik} />
                  <div className="flex justify-end">
                    <Button
                      className="mt-5"
                      onClick={async () => {
                        await formik.submitForm()
                        setTimeout(() => {
                          submitForPreviewProjectForm({
                            id: project.id,
                            form: ProjectFormEnum.ACCOMPANYING_REPORT_FORM_A1,
                          })
                        }, 100)
                      }}
                    >
                      {isSubmittingForPreview ? (
                        <Spinner size="sm" />
                      ) : (
                        <>
                          <CheckCircleIcon className="w-4 h-4 mr-1" />
                          Dokončit
                        </>
                      )}
                    </Button>
                  </div>
                </>
              )}
            </Form>
          )
        }}
      </Formik>
    </ProjectLayout>
  )
}
