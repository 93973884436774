import React from 'react'
import { FieldProps } from 'formik'

interface Props extends FieldProps {
  label?: string
  placeholder?: string
}

export const TextAreaInput: React.FC<Props> = (props) => {
  return (
    <div>
      {props.label && (
        <label
          htmlFor={props.field.name}
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          {props.label}
        </label>
      )}
      <div className="mt-2">
        <textarea
          rows={4}
          name={props.field.name}
          id={props.field.name}
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
          value={props.field.value}
          onChange={props.field.onChange}
          placeholder={props.placeholder}
        />
      </div>
    </div>
  )
}
