import React from 'react'
import { useProject } from '../../../../model/hooks/useProject'
import { ProjectLoadingSkeleton } from '../../layout/ProjectLoadingSkeleton'
import { ProjectLayout } from '../../layout/ProjectLayout'
import { TasksGrid } from '../../../Tasks/components/TasksGrid'

interface Props {}

export const Tasks: React.FC<Props> = (props) => {
  const { project, id: projectId } = useProject()

  if (!project) {
    return <ProjectLoadingSkeleton />
  }

  return (
    <>
      <ProjectLayout project={project}>
        <TasksGrid taskQuery={{ projectId }} />
      </ProjectLayout>
    </>
  )
}
