import { ProjectFormEnum } from '../enums/ProjectFormEnum'
import { useStoreProjectFormMutation } from '../api/project/useStoreProjectFormMutation'
import { useGetProjectTechnicalForm } from '../api/project/useGetProjectTechnicalForm'
import { merge } from 'lodash'
import { useSubmitForPreviewProjectFormMutation } from '../api/project/useSubmitForPreviewProjectFormMutation'
import { useDeletePreviewProjectTechnicalFormMutation } from '../api/project/useDeletePreviewProjectTechnicalFormMutation'

export const useTechnicalReportForm = (
  projectId: string,
  formEnum: ProjectFormEnum,
  initialValues: object,
) => {
  const { mutate: storeTechnicalForm, isLoading: storeTechnicalFormIsLoading } =
    useStoreProjectFormMutation(formEnum)
  const { mutate: submitForPreviewProjectForm, isLoading: submitForPreviewIsLoading } =
    useSubmitForPreviewProjectFormMutation()
  const { mutate: deletePreviewProjectTechnicalForm, isLoading: deletePreviewIsLoading } =
    useDeletePreviewProjectTechnicalFormMutation()

  const {
    data: technicalForm,
    isLoading: technicalFormIsLoading,
    isRefetching: technicalFormIsRefetching,
  } = useGetProjectTechnicalForm(formEnum, projectId!)

  return {
    storeTechnicalForm,
    storeTechnicalFormIsLoading,
    technicalForm,
    technicalFormIsLoading: technicalFormIsLoading,
    technicalFormIsRefetching,
    initialValues: merge(structuredClone(initialValues), technicalForm?.data?.data || {}) as any,
    submitForPreviewIsLoading,
    submitForPreviewProjectForm: () =>
      submitForPreviewProjectForm({ id: projectId, form: formEnum }),
    deletePreviewIsLoading,
    deletePreviewProjectTechnicalForm: () =>
      deletePreviewProjectTechnicalForm({ id: projectId, form: formEnum }),
  }
}
