import React from 'react'
import { FieldProps } from 'formik'
import { CellChange, Column, ReactGrid } from '@silevis/reactgrid'
import '@silevis/reactgrid/styles.css'

interface GridColumn extends Column {
  label: string
}

interface Props extends FieldProps {
  columns: GridColumn[]
}

export const GridInput: React.FC<Props> = (props) => {
  const values = props.field.value

  if (!Array.isArray(values)) {
    throw new Error('GridInput must be used with an array field')
  }

  const rows = [
    {
      rowId: 'header',
      cells: props.columns.map((column) => ({
        type: 'header',
        text: column.label,
      })),
    },
    ...values.map((value, idx) => ({
      rowId: idx,
      cells: props.columns.map((column) => ({
        type: 'text',
        text: (value as any)[column.columnId],
      })),
    })),
  ]

  const handleChange = (change: CellChange[]) => {
    const newValues = [...values]
    change.forEach((c) => {
      const row = (newValues as any)[c.rowId]
      ;(row as any)[c.columnId] = (c.newCell as any).text
    })
    props.form.setFieldValue(props.field.name, newValues)
  }

  return (
    <ReactGrid
      disableVirtualScrolling={true}
      columns={props.columns}
      rows={rows}
      onCellsChanged={handleChange}
    />
  )
}
