import React from 'react'
import { Field, Form, Formik } from 'formik'
import { CONSTRUCTION_DIARY_OTHER_ENTRY_TYPES } from './constants'
import { ComboInput } from '../../../../components/inputs/ComboInput'
import { RichTextInput } from '../../../../components/inputs/RichTextInput/RichTextInput'
import { Button } from '../../../../components/ui/button'
import { DatePickerInput } from '../../../../components/inputs/DatePickerInput'
import { FormLabel } from '../../../../components/FormLabel'
import { SignPadInput } from '../../../../components/inputs/SignPadInput'
import { useCreateConstructionDiaryOtherEntryMutation } from '../../../../model/api/constructionDiary/useCreateConstructionDiaryOtherEntryMutation'
import { useUpdateConstructionDiaryOtherEntryMutation } from '../../../../model/api/constructionDiary/useUpdateConstructionDiaryOtherEntryMutation'

interface Props {
  projectId: string
  initValues?: any
  onSuccess?: () => void
}

export const ConstructionDiaryOtherEntryForm: React.FC<Props> = (props) => {
  const { mutateAsync: create } = useCreateConstructionDiaryOtherEntryMutation()
  const { mutateAsync: update } = useUpdateConstructionDiaryOtherEntryMutation()

  return (
    <div className="text-left mt-5">
      <Formik
        initialValues={
          props.initValues || {
            date: new Date(),
            type: '',
            description: '',
            signature: '',
          }
        }
        onSubmit={async (values) => {
          if (props.initValues && props.initValues.id) {
            await update({
              ...props.initValues,
              ...values,
              projectId: props.projectId,
            })

            props.onSuccess?.()
            return
          }

          await create({
            ...values,
            projectId: props.projectId,
          })
          props.onSuccess?.()
        }}
      >
        {(formik) => (
          <Form>
            <div className="">
              <div className="mt-1">
                <Field name="date" component={DatePickerInput} label="Datum" />
              </div>
            </div>
            <div className="mt-5">
              <Field
                label="Typ záznamu"
                name="type"
                component={ComboInput}
                options={CONSTRUCTION_DIARY_OTHER_ENTRY_TYPES.map((s) => ({ label: s, value: s }))}
              />
            </div>
            <div className="mt-5">
              <Field name="description" component={RichTextInput} label="Popis" />
            </div>
            <div className="mt-5">
              <Field name="signature" component={SignPadInput} label="Podpis" />
            </div>
            <div className="mt-5 flex justify-end">
              <Button
                disabled={!formik.values.signature}
                isLoading={formik.isSubmitting}
                type="submit"
              >
                Uložit
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}
