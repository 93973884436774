import React, { useEffect, useRef } from 'react'
import { BasicLayout } from '../../components/BasicLayout'
import { Button } from '../../components/ui/button'
import { Message } from './components/Message'

interface Props {}

export const AI: React.FC<Props> = (props) => {
  const lastElementRef = useRef<HTMLLIElement>(null)

  useEffect(() => {
    lastElementRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [lastElementRef.current])

  return (
    <BasicLayout title="Stavebník (AI BETA)">
      <div className="my-3">
        <div
          className="mt-2 bg-gray-100 border border-gray-200 text-sm text-gray-800 rounded-lg p-4 dark:bg-white/10 dark:border-white/20 dark:text-white"
          role="alert"
        >
          <span className="font-bold">Stavebník (ALPHA)</span>
          &nbsp;je AI asistent vytrénovaný na Stavebním zákoně. Dokáže zodpovědět spoustu otázek
          ohledně stavebního zákona. Stále se učí, občas udělá chybu(!), takže je potřeba jeho
          výstup kontrolovat. Do budoucna (se svolením uživatele) bude schopen chápat jednotlivé
          projekty uložené v QIK APP a pomáhat s jejich projektovou dokumentací.
        </div>
        <p className="text-gray-800 text-lg font-bold"></p>
      </div>
      <div className="max-h-80 overflow-scroll p-10 rounded-md border shadow-sm">
        <ul className="space-y-5">
          <li className="max-w-lg flex gap-x-2 sm:gap-x-4">
            <div className="bg-white border border-gray-200 rounded-2xl p-4 space-y-3">
              <h2 className="font-medium text-gray-800">Co pro Vás můžu udělat?</h2>
              <div className="space-y-1.5">
                <p className="mb-1.5 text-sm text-gray-800">Můžete se mě třeba zeptat:</p>
                <ul className="list-disc list-outside space-y-1.5 ps-3.5">
                  <li className="text-sm text-gray-800">Co je obsahem projektové dokumentace?</li>

                  <li className="text-sm text-gray-800">Co musí obsahovat technická zpráva?</li>

                  <li className="text-sm text-gray-800">
                    Je tento text správný z hlediska zákoníku?
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <li ref={lastElementRef} id="lastElement" />
        </ul>
      </div>
      <div className="mt-5">
        <label htmlFor="comment" className="block text-sm font-medium leading-6 text-gray-900">
          Vaše otázka
        </label>
        <div className="mt-2">
          <textarea
            rows={4}
            name="comment"
            id="comment"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-200 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            defaultValue={''}
          />
        </div>
      </div>
      <div className="mt-3 flex w-full justify-end">
        <Button>Odeslat</Button>
      </div>
    </BasicLayout>
  )
}
