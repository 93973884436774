import {useMutation, useQueryClient} from 'react-query'
import { ApiUrlEnum } from '../../enums/ApiUrlEnum'
import { api } from '../api'
import {QueryKeyEnum} from "../../enums/QueryKeyEnum";

export const useUpdateTask = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (data: { id: string; values: any }) =>
      api.put(`${ApiUrlEnum.TASK}/${data.id}`, data.values),
    onSuccess: () => {
      return queryClient.invalidateQueries([QueryKeyEnum.TASK])
    }
  })
}
