import React from 'react'
import { WorkspaceSettingsLayout } from './WorkspaceSettingsLayout'
import { WorkspacePricing } from './components/WorkspacePricing'
import { Separator } from '../../components/ui/separator'

const stats = [
  { name: 'Využité uložistě', stat: '1.2 GB / 10 GB' },
  { name: 'Počet spolupracovníků', stat: '8 / 10' },
  { name: 'Počet projektů', stat: '7 / 20' },
]

interface Props {}

export const WorkspaceSettings: React.FC<Props> = (props) => {
  return (
    <WorkspaceSettingsLayout>
      <div className="mt-5">
        <h3 className="text-3xl font-bold text-center leading-6 text-gray-900">
          Vaše aktuální využití tarifu <span className="italic text-blue-700">Růst</span>
        </h3>
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
          {stats.map((item) => (
            <div
              key={item.name}
              className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6"
            >
              <dt className="truncate text-sm font-medium text-gray-500">{item.name}</dt>
              <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                {item.stat}
              </dd>
            </div>
          ))}
        </dl>
      </div>
      <div className="my-8"></div>
      <div className="">
        <WorkspacePricing />
      </div>
    </WorkspaceSettingsLayout>
  )
}
