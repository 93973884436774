import React from 'react'
import { Field, FormikProps } from 'formik'
import { RichTextInput } from '../../../../../components/inputs/RichTextInput/RichTextInput'
import { FormSectionTwoColumns } from '../../../../../components/forms/FormSectionTwoColumns'
import { CheckboxInput } from '../../../../../components/inputs/CheckboxInput'
import { InfoPopover } from '../../../../../components/InfoPopover'
import { Link } from 'react-router-dom'

interface Props {
  formik: FormikProps<any>
}

export const TechnicalFormB1I: React.FC<Props> = ({ formik }) => {
  return (
    <FormSectionTwoColumns
      sectionKey={'b1i'}
      title="i) Požadavky na asanace, demolice, kácení dřevin"
    >
      <div className="sm:col-span-6">
        <Field component={CheckboxInput} name="b1i.sanitation.enabled" label="Asanace" />
      </div>
      {formik.values.b1i.sanitation.enabled && (
        <div className="sm:col-span-6">
          <div className="">
            <Field component={RichTextInput} name="b1i.sanitation.details" />
          </div>
        </div>
      )}
      <div className="sm:col-span-6">
        <Field component={CheckboxInput} name="b1i.demolition.enabled" label="Demolice" />
      </div>
      {formik.values.b1i.demolition.enabled && (
        <div className="sm:col-span-6">
          <div className="">
            <Field component={RichTextInput} name="b1i.demolition.details" />
          </div>
        </div>
      )}
      <div className="sm:col-span-6">
        <div className="flex">
          <Field component={CheckboxInput} name="b1i.treeFelling.enabled" label="Kácení dřevin" />
          <InfoPopover
            content={
              <>
                <p>
                  Povolení nevyžadují: <i>(Platné květen 2022)</i>
                </p>
                <ol className="list-decimal ml-6 mt-3 mb-3">
                  <li>
                    stromy do obvodu 80 cm ve výšce 130 cm nad zemí (což odpovídá průměru něco málo
                    přes 25 cm). Platí jen v případě, že se nejedná o významný krajinný prvek nebo
                    stromořadí. Nový pojem stromořadí znamená souvislou řadu nejméně deseti stromů s
                    pravidelnými rozestupy. Speciální zřetel rovněž vyžadují dřeviny, které jsou
                    památným stromem, popř. zvláště chráněným druhem rostliny! (z našich dřevin dřín
                    obecný, dub pýřitý, některé druhy vrb). V některých případech by mohlo kácením
                    dojít k nežádoucí změně krajinného rázu. K takovému kácení je nezbytný souhlas
                    orgánu ochrany přírody dle § 12 zákona (Ochrana krajinného rázu a přírodní
                    park).
                  </li>
                  <li>
                    zapojené porosty dřevin do celkové plochy 40 m2, přičemž se nerozlišuje mezi
                    stromy a keři. Zapojený porost je soubor dřevin, v němž se nadzemní části dřevin
                    jednoho patra vzájemně dotýkají, prorůstají nebo překrývají. Platí za
                    předpokladu, že dřeviny mají obvod kmene do 80 cm. Opět platí jen v případě, že
                    se nejedná o významný krajinný prvek, památný strom, zvláště chráněný druh
                    rostliny a že nebude negativně změněn krajinný ráz (viz výše).
                  </li>
                  <li>
                    ovocné dřeviny rostoucí na pozemcích v zastavěném území evidovaných v katastru
                    nemovitostí jako druh pozemku zahrada, zastavěná plocha a nádvoří. Musí být ale
                    splněno, že dřeviny nejsou součástí stromořadí nebo významného krajinného prvku.
                  </li>
                  <li>
                    dřeviny pěstované na pozemcích vedených v katastru nemovitostí ve způsobu
                    využití jako plantáž dřevin. Může se jednat např. o porosty topolů, vrb
                    pěstovaných na zemědělské půdě pro energetické účely.
                  </li>
                  <li>
                    je-li stavem dřevin bezprostředně ohrožen život či zdraví nebo hrozí-li škoda
                    značného rozsahu – tzv. havarijní kácení (v tomto případě je nutné podat orgánu
                    ochrany přírody nejpozději 15 dní po provedení kácení oznámení o kácení dřevin
                    rostoucích mimo les). Tento případ se týká opravdu bezprostředně hrozících
                    dřevin – např. stromů nahnutých po vichřici, částečně vyvrácených apod.
                  </li>
                  <li>
                    jde-li o kácení z důvodů pěstebních (obnova nebo výchovná probírka porostů, tzn.
                    zásah je součástí způsobu jejich pěstování) či zdravotních (likvidace ohnisek
                    chorob nebo škůdců, tzn. dřeviny trpí chorobami, popř. z jiných příčin zasychají
                    a jejich léčení není možné nebo účelné), při údržbě břehových porostů (správa
                    vodních toků), dále ke kácení v ochranném pásmu zařízení elektrizační a
                    plynárenské soustavy. V těchto případech je třeba podat orgánu ochrany přírody
                    oznámení o kácení nejméně 15 dní před započetím kácení, kácet lze pouze v
                    případě, že orgán ochrany přírody nezahájí v těchto 15 dnech správní řízení,
                    kterým může zamýšlený zásah omezit či zakázat.”
                  </li>
                </ol>
                <a href="#">
                  Tuto žádost vyplňte v případě nutnosti povolení kácení dřevin nebo kontaktujte QIK
                  Services s.r.o
                </a>
              </>
            }
          />
        </div>
      </div>
      {formik.values.b1i.treeFelling.enabled && (
        <div className="sm:col-span-6">
          <Field component={RichTextInput} name="b1i.treeFelling.details" label="" />
        </div>
      )}
    </FormSectionTwoColumns>
  )
}
