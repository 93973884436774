import React from 'react'
import { FormSectionTwoColumns } from '../../../../../../components/forms/FormSectionTwoColumns'
import { Field } from 'formik'
import { TextAreaInput } from '../../../../../../components/inputs/TextAreaInput'
import { B2_FORM_INIT_VALUES } from '../constants'

interface Props {}

export const B28FireSafetySection: React.FC<Props> = (props) => {
  return (
    <FormSectionTwoColumns
      sectionKey="b2.fireSafety"
      title="B.2.8 Zásady požárně bezpečnostního řešení"
    >
      <div className="sm:col-span-6">
        <Field name="b2.fireSafety.description" component={TextAreaInput} />
      </div>
    </FormSectionTwoColumns>
  )
}
