import { ChevronRightIcon } from '@heroicons/react/20/solid'
import React from 'react'
import { Link } from 'react-router-dom'
import { Avatar } from '../../../../components/Avatar'
import { ProjectFormEnum } from '../../../../model/enums/ProjectFormEnum'
import { RoutesEnum } from '../../../../model/enums/RoutesEnum'
import { useGetTechnicalFormsList } from '../../../../model/api/project/useGetTechnicalFormsList'
import { Spinner } from '@chakra-ui/react'
import { TechnicalFormStatusEnum } from 'shared'

interface Props {
  projectId: string
}

export const TechnicalReportFormsList: React.FC<Props> = (props) => {
  const { data, isLoading } = useGetTechnicalFormsList(props.projectId)

  if (isLoading) {
    return (
      <div className="mt-5 flex justify-center">
        <Spinner size="xl" />
      </div>
    )
  }

  const items = data?.data?.list?.b || []

  return (
    <ul className="divide-y divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl">
      {items.map((form) => (
        <li
          key={form.identifier}
          className="relative flex justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6"
        >
          <div className="flex items-center min-w-0 gap-x-4">
            <div>
              <Avatar
                name={form.identifier}
                colour={form.status === TechnicalFormStatusEnum.PREVIEW ? 'bg-blue-500' : undefined}
              />
            </div>
            <div className="min-w-0 flex-auto">
              <p className="text-sm font-semibold leading-6 text-gray-900">
                <Link
                  to={`${RoutesEnum.PROJECTS}/${props.projectId}/${RoutesEnum.TECHNICAL_REPORT_FORM}/${form.type}`}
                >
                  <span className="absolute inset-x-0 -top-px bottom-0" />
                  {form.name}
                </Link>
              </p>
            </div>
          </div>
          <div className="flex shrink-0 items-center gap-x-4">
            <ChevronRightIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
          </div>
        </li>
      ))}
    </ul>
  )
}
