import React from 'react'
import { FormSectionTwoColumns } from '../../../../../../components/forms/FormSectionTwoColumns'
import { Field } from 'formik'
import { B2_FORM_INIT_VALUES } from '../constants'
import { RichTextInput } from '../../../../../../components/inputs/RichTextInput/RichTextInput'

interface Props {}

export const B25SafetySection: React.FC<Props> = () => {
  return (
    <FormSectionTwoColumns sectionKey="b2.safety" title="B.2.5 Bezpečnost při užívání stavby">
      <div className="sm:col-span-6">
        <Field name="b2.safety.description" component={RichTextInput} />
      </div>
    </FormSectionTwoColumns>
  )
}
