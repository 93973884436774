import React from 'react'
import { Spinner } from '@chakra-ui/react'

interface Props {
  children: React.ReactNode
  onClick?: (e: React.MouseEvent) => void
  size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl'
  className?: string
  isLoading?: boolean
  disabled?: boolean
}

export const SecondaryButton: React.FC<Props> = (props) => {
  const size = props.size || 'lg'
  const classNames = React.useMemo(() => {
    switch (size) {
      case 'sm':
        return 'rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
      case 'md':
        return 'rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
      case 'lg':
        return 'rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
      case 'xl':
        return 'rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
      case '2xl':
        return 'rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
    }
  }, [size])

  const className = React.useMemo(() => {
    return `${classNames} ${props.className || ''}`
  }, [classNames, props.className])

  return (
    <>
      <button type="button" className={className} onClick={props.onClick} disabled={props.disabled}>
        {props.isLoading ? <Spinner /> : <>{props.children}</>}
      </button>
    </>
  )
}
