import { useMutation, useQueryClient } from 'react-query'
import { ContactEntityData, CreateContactRequestData } from '../../interfaces/generated'
import { api } from '../api'
import { QueryKeyEnum } from '../../enums/QueryKeyEnum'
import { ApiUrlEnum } from '../../enums/ApiUrlEnum'
import { AxiosResponse } from 'axios'

export const useCreateContactMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (contract: CreateContactRequestData) =>
      api.post<CreateContactRequestData, AxiosResponse<ContactEntityData>>(
        ApiUrlEnum.CONTACT,
        contract,
      ),
    onSuccess: (data) => {
      return queryClient.invalidateQueries([QueryKeyEnum.CONTACTS])
    },
  })
}
