import React from 'react'
import { Field, Form, Formik } from 'formik'
import { TextInput } from '../../components/inputs/TextInput'
import { useGetContacts } from '../../model/api/contact/useGetContacts'
import { Button } from '../../components/Button'
import { useCreateProjectMutation } from '../../model/api/project/useCreateProjectMutation'
import { ContactSelectInput } from '../../components/inputs/ContactSelectInput'
import { FormLabel } from '../../components/FormLabel'
import { MapInput } from '../../components/inputs/MapInput/MapInput'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { createProjectSchema } from 'shared'

interface Props {
  closeDrawer: () => void
}

const initialValues: any = {
  name: '',
  subject: '',
  number: '',
  investorId: undefined,
  latitude: '',
  longitude: '',
}
export const AddProjectForm: React.FC<Props> = (props) => {
  const { data: investors, isLoading: investorsIsLoading } = useGetContacts()
  const { mutateAsync: createMutationAsync, isLoading: createIsLoading } =
    useCreateProjectMutation()

  const onSubmit = (values: any) => {
    createMutationAsync(values).then(() => props.closeDrawer())
  }
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={toFormikValidationSchema(createProjectSchema)}
    >
      {(formik) => (
        <Form>
          <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 z-20">
            <div className="sm:col-12">
              <Field name="name" component={TextInput} label="Název projektu" />
            </div>
            <div className="sm:col-12">
              <Field name="subject" component={TextInput} label="Předmět" />
            </div>
            <div className="sm:col-12">
              <Field name="number" component={TextInput} label="Číslo projektu" />
            </div>
            <div className="sm:col-12 z-20">
              <Field
                name="investorId"
                label="Investor"
                component={ContactSelectInput}
                options={[]}
              />
            </div>
          </div>

          <div className="mt-3 mb-8">
            <div className="mb-3">
              <FormLabel>Poloha</FormLabel>
            </div>
            <div className="grid grid-cols-1 gap-3">
              <div className="col-span-1">
                <MapInput
                  onPointSelectedLngLat={(lngLat) => {
                    formik.setFieldValue('longitude', lngLat[0])
                    formik.setFieldValue('latitude', lngLat[1])
                  }}
                />
              </div>
              <div className="flex gap-3">
                <Field
                  name="latitude"
                  placeholder="Vložte špendlík na mapu"
                  label="Latitude"
                  component={TextInput}
                  disabled={true}
                />
                <Field
                  placeholder="Vložte špendlík na mapu"
                  name="longitude"
                  label="Longitude"
                  component={TextInput}
                  disabled={true}
                />
              </div>
            </div>
          </div>

          <Button isLoading={createIsLoading} className="mt-3" onClick={formik.submitForm}>
            Vytvořit
          </Button>
        </Form>
      )}
    </Formik>
  )
}
