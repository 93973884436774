import { ISelectOption } from '../../../model/interfaces/ISelectOption'

export const TYPE_OF_PLOT_SELECT_OPTIONS: ISelectOption[] = [
  { label: 'orná půda', value: 'orná půda' },
  { label: 'chmelnice', value: 'chmelnice' },
  { label: 'vinice', value: 'vinice' },
  { label: 'zahrada', value: 'zahrada' },
  { label: 'ovocný sad', value: 'ovocný sad' },
  { label: 'trvalý travní porost', value: 'trvalý travní porost' },
  { label: 'lesní pozemek', value: 'lesní pozemek' },
  { label: 'vodní plocha', value: 'vodní plocha' },
  {
    label: 'zastavěná plocha a nádvoří',
    value: 'zastavěná plocha a nádvoří',
  },
  { label: 'ostatní plocha', value: 'ostatní plocha' },
]
