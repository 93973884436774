import React from 'react'
import { IssueStatusEnum } from '../../../model/enums/IssueStatusEnum'
import { Badge } from '../../../components/Badge'
import { CheckCircleIcon, FlagIcon, MegaphoneIcon } from '@heroicons/react/24/outline'

interface Props {
  status: IssueStatusEnum
}

export const IssueStatusBadge: React.FC<Props> = (props) => {
  const color = React.useMemo(() => {
    switch (props.status) {
      case IssueStatusEnum.REPORTED:
        return 'red'
      case IssueStatusEnum.FIXED:
        return 'yellow'
      case IssueStatusEnum.CHECKED:
        return 'green'
    }
  }, [props.status])

  const text = React.useMemo(() => {
    switch (props.status) {
      case IssueStatusEnum.REPORTED:
        return (
          <>
            <MegaphoneIcon className="w-4 h-4 mr-1" />
            Nahlášeno
          </>
        )
      case IssueStatusEnum.FIXED:
        return (
          <>
            <FlagIcon className="w-4 h-4 mr-1" />
            Opraveno
          </>
        )
      case IssueStatusEnum.CHECKED:
        return (
          <>
            <CheckCircleIcon className="w-4 h-4 mr-1" />
            Zkontrolováno
          </>
        )
    }
  }, [props.status])

  return <Badge color={color} text={text} />
}
