export enum RoutesEnum {
  PROJECTS = '/projects',
  LOGIN = '/login',
  ADD_PROJECT = '/add-project',
  ADD_CONTACT = '/add-contact',
  CONTACTS = '/contacts',
  USER_PROFILE = '/user-profile',
  USER_RESET_PASSWORD = '/user-reset-password',
  USER_FORGOTTEN_PASSWORD = '/user-forgotten-password',
  WORKSPACE_SETTINGS = '/settings',
  WORKSPACE_SETTINGS_USERS = '/settings/users',
  WORKSPACE_SETTINGS_BILLING = '/settings/billing',
  AI = '/ai',

  // SHARED
  TASKS = '/tasks',

  // INSIDE PROJECT
  TECHNICAL_REPORT_FORM = 'technical-report-form',
  DOCUMENTATION_SECTION = 'documentation-section',
  RESEARCH_AND_SURVEY = 'research-and-survey',
  SITUATION_DOCUMENTATION = 'situation-documentation',
  OBJECT_DOCUMENTATION = 'object-documentation',
  ENGINEERING = 'engineering',
  ISSUES = 'issues',
  ISSUES_FORM = 'issues-form',
  SETTINGS = 'settings',
  ACCOMPANYING_REPORT = 'accompanying-report',
  PHOTO_DOCUMENTATION = 'photo-documentation',
  CONSTRUCTION_DIARY = 'construction-diary',
  CONSTRUCTION_DIARY_ENTRY = 'construction-diary-entry',
  SETTINGS_BASIC_INFO = 'basic-info',
  SETTINGS_OBJECTS_AND_ROOMS = 'objects-and-rooms',
  SETTINGS_CONSTRUCTION_DIARY_INFO = 'construction-diary-info',
}
