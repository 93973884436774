import React from 'react'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '../../../../components/ui/dropdown-menu'
import { EllipsisHorizontalIcon, PencilIcon } from '@heroicons/react/20/solid'
import { FileText, WandSparkles } from 'lucide-react'
import { Button } from '../../../../components/ui/button'
import { LoadFormSectionModal } from '../../../../components/forms/LoadFormSectionModal'
import { DocumentIcon } from '@heroicons/react/24/outline'
import { CheckIcon } from '@radix-ui/react-icons'
import { useGenerateReportAction } from '../../../../model/hooks/useGenerateReportAction'
import { useGetTechnicalFormsList } from '../../../../model/api/project/useGetTechnicalFormsList'
import { TechnicalFormStatusEnum } from 'shared'

interface Props {
  isInPreviewMode?: boolean
  revertFromPreview: () => void
  submitForPreview: () => void
  projectId: string
}

export const TechnicalFormHeadingMenu: React.FC<Props> = (props) => {
  const [loadSectionOpen, setLoadSectionOpen] = React.useState(false)
  const { onDownload, isLoading: isDownloading } = useGenerateReportAction(props.projectId, 'b')
  const { data: listData } = useGetTechnicalFormsList(props.projectId)

  const list = listData?.data?.list?.b || []
  const allDone =
    list.length > 0 && list.every((item) => item.status === TechnicalFormStatusEnum.PREVIEW)

  return (
    <>
      <LoadFormSectionModal open={loadSectionOpen} setOpen={setLoadSectionOpen} />
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" size="sm" type="button" className="ml-1">
            <EllipsisHorizontalIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56">
          <DropdownMenuLabel>Menu</DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownMenuGroup>
            <DropdownMenuItem disabled={props.isInPreviewMode} onClick={props.submitForPreview}>
              <CheckIcon className="mr-2 h-4 w-4" />
              <span>Dokončit</span>
            </DropdownMenuItem>
            <DropdownMenuItem disabled={!props.isInPreviewMode} onClick={props.revertFromPreview}>
              <PencilIcon className="mr-2 h-4 w-4" />
              <span>Editovat</span>
            </DropdownMenuItem>
          </DropdownMenuGroup>
          <DropdownMenuSeparator />
          <DropdownMenuGroup>
            <DropdownMenuItem
              disabled={props.isInPreviewMode}
              onClick={() => setLoadSectionOpen(true)}
            >
              <FileText className="mr-2 h-4 w-4" />
              <span>Předvyplnit z projektu</span>
            </DropdownMenuItem>
            <DropdownMenuItem disabled={props.isInPreviewMode}>
              <WandSparkles className="mr-2 h-4 w-4" />
              <span>Doplnit pomocí AI</span>
            </DropdownMenuItem>
          </DropdownMenuGroup>
          <DropdownMenuSeparator />
          <DropdownMenuGroup>
            <DropdownMenuItem disabled={!allDone} onClick={onDownload}>
              <DocumentIcon className="mr-2 h-4 w-4" />
              <span>Stáhnout výstup</span>
            </DropdownMenuItem>
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  )
}
