import { useMutation, useQueryClient } from 'react-query'
import { api } from '../api'
import { ApiUrlEnum } from '../../enums/ApiUrlEnum'
import { CreateProjectFormRichTextHelpValues } from 'shared'
import { QueryKeyEnum } from '../../enums/QueryKeyEnum'

export const useCreateProjectFormRichTextHelpMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: CreateProjectFormRichTextHelpValues) =>
      api.post(ApiUrlEnum.PROJECT_FORM_RICH_TEXT, data),
    onSuccess: () => {
      return queryClient.invalidateQueries(QueryKeyEnum.PROJECT_FORM_RICH_TEXT)
    },
  })
}
