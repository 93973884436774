import React from 'react'
import { ContactEntityData } from '../model/interfaces/generated'
import { EnvelopeIcon, PhoneIcon, UserCircleIcon } from '@heroicons/react/20/solid'
import { contactToString } from '../model/helpers/contactHelpers'
import { useDisclosure } from '@chakra-ui/react'
import { ContactFormModal } from './modals/ContactFormModal'
import { useGetContacts } from '../model/api/contact/useGetContacts'
import { useGetContact } from '../model/api/contact/useGetContact'

interface Props {
  contactId: string
}

export const InvestorSmallCard: React.FC<Props> = (props) => {
  const contactFormDisclosure = useDisclosure()
  const { data: contactData } = useGetContact(props.contactId)
  const contact: ContactEntityData | undefined = contactData?.data
  console.log({ contact, props })

  if (!contact) {
    return null
  }

  return (
    <>
      <ContactFormModal disclosure={contactFormDisclosure} editContact={contact} />
      <div className="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5">
        <dl className="flex flex-wrap">
          <div className="mt-2 flex w-full flex-none gap-x-4 px-6 pt-6">
            <span className="font-bold text-md">Investor</span>
          </div>
          <div className="mt-2 flex w-full flex-none gap-x-4 px-6 pt-6">
            <dt className="flex-none">
              <span className="sr-only">Client</span>
              <UserCircleIcon className="h-6 w-5 text-gray-400" aria-hidden="true" />
            </dt>
            <dd className="text-sm font-medium leading-6 text-gray-900">
              {contactToString(contact)}
            </dd>
          </div>
          <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
            <dt className="flex-none">
              <span className="sr-only">Email</span>
              <EnvelopeIcon className="h-6 w-5 text-gray-400" aria-hidden="true" />
            </dt>
            <dd className="text-sm leading-6 text-gray-500">{contact.email}</dd>
          </div>
          <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
            <dt className="flex-none">
              <span className="sr-only">Telephone</span>
              <PhoneIcon className="h-6 w-5 text-gray-400" aria-hidden="true" />
            </dt>
            <dd className="text-sm leading-6 text-gray-500">{contact.phone}</dd>
          </div>
        </dl>
        <div className="mt-6 border-t border-gray-900/5 px-6 py-6">
          <span
            onClick={contactFormDisclosure.onOpen}
            className="cursor-pointer text-sm font-semibold leading-6 text-gray-900"
          >
            Detail kontaktu <span aria-hidden="true">&rarr;</span>
          </span>
        </div>
      </div>
    </>
  )
}
