import React from 'react'
import { B2_FORM_INIT_VALUES } from '../B2ConstructionDescription/constants'
import { Field, FormikProps, useFormikContext } from 'formik'
import { B3_FORM_INITIAL_VALUES } from './constants'
import { CheckboxInput } from '../../../../../components/inputs/CheckboxInput'
import { RichTextInput } from '../../../../../components/inputs/RichTextInput/RichTextInput'
import { TextInput } from '../../../../../components/inputs/TextInput'
import { FormSectionTwoColumns } from '../../../../../components/forms/FormSectionTwoColumns'

interface Props {}

type B3Keys = keyof typeof B3_FORM_INITIAL_VALUES.b3

const sections: B3Keys[] = [
  'waterSupplySystem',
  'wasterWaterSystem',
  'rainWaterSystem',
  'gasPipeline',
  'electricLowVoltage',
  'electricHighVoltage',
  'communicationLines',
]

export const B3TechnicalConnectionsForm: React.FC<Props> = (props) => {
  const formik = useFormikContext() as FormikProps<typeof B3_FORM_INITIAL_VALUES>
  return (
    <>
      <FormSectionTwoColumns sectionKey="b3" title="">
        {sections.map((section) => (
          <div className="sm:col-span-6 mt-5">
            <Field
              name={`b3.${section}.enabled`}
              component={CheckboxInput}
              label={formik.values.b3[section].label}
            />
            {formik.values.b3[section].enabled && (
              <>
                <div className="mt-3 pl-7">
                  <Field name={`b3.${section}.description`} component={RichTextInput} />
                </div>
                <div className="mt-3 pl-7 flex flex-col gap-3">
                  <Field
                    name={`b3.${section}.size`}
                    component={TextInput}
                    label="Připojovací rozměr"
                  />
                  <Field
                    name={`b3.${section}.power`}
                    component={TextInput}
                    label="Výkonová kapacita"
                  />
                  <Field name={`b3.${section}.length`} component={TextInput} label="Délka" />
                </div>
              </>
            )}
          </div>
        ))}
      </FormSectionTwoColumns>
    </>
  )
}
