import React from 'react'

interface Props {
  title?: string
  buttons?: React.ReactElement
}

export const SectionHeading: React.FC<Props> = (props) => {
  return (
    <div className="pb-5 sm:flex sm:items-center sm:justify-between">
      <h3 className="text-base font-semibold leading-6 text-gray-900">{props.title}</h3>
      <div className="mt-3 sm:ml-4 sm:mt-0">{props.buttons}</div>
    </div>
  )
}
